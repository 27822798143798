<template>
  <div>
    <!-- 报价单列表 -->

    <!-- 搜索功能 -->
    <el-row>
        <el-col :span="24">
            <el-form :inline="true">

                <el-form-item label="调价编号">
                    <el-input v-model="info.search.adjustNumber" maxlength="20" class="search-150"></el-input>
                </el-form-item>

                <el-form-item label="部门">
                    <el-tree-select v-model="info.search.selectDepartmentList" :data="departmentList" multiple clearable :default-expand-all="true" collapse-tags collapse-tags-tooltip class="search-200">
                    </el-tree-select>
                </el-form-item>

                <el-form-item label="大区">
                    <el-select v-model="info.search.areaNumberList" multiple clearable collapse-tags collapse-tags-tooltip class="search-200">
                        <el-option v-for="userArea in userAreaList" :key="userArea.areaNumber" :label="userArea.areaName" :value="userArea.areaNumber"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="状态">
                    <el-select v-model="info.search.state" class="search-150">
                        <el-option :value="0" label="全部"></el-option>
                        <el-option :value="1" label="新建单"></el-option>
                        <el-option :value="2" label="待审核"></el-option>
                        <el-option :value="3" label="已驳回"></el-option>
                        <!-- <el-option :value="4" label="已审核并上报"></el-option> -->
                        <el-option :value="5" label="已生效"></el-option>
                        <el-option :value="9" label="已作废"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="备注">
                    <el-input v-model="info.search.remark" maxlength="50" class="search-150"></el-input>
                </el-form-item>

                <el-form-item label="调价日期">
                    <el-date-picker
                        v-model="info.search.datePoint"
                        type="daterange"
                        range-separator="To"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        style="width:250px"
                    />
                </el-form-item>

                <el-form-item>
                    <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
                    <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
                </el-form-item>

            </el-form>
        </el-col>
    </el-row>

    <div>
        <!-- 功能栏 -->
        <el-row :gutter="10" class="operation">
            <el-col :span="15">
                <el-button v-if="CheckUserButtonAuth('BtnPriceAdjustAdd')" class="iconfont icon-add" type="primary" plain @click="btnEditInfo('0')">新增</el-button>
                <el-button v-if="CheckUserButtonAuth('BtnPriceAdjustDelete')" class="iconfont icon-ashbin" type="danger" plain @click="btnBatchDeleteInfo">删除</el-button>
            </el-col>

            <el-col :span="9" class="operation_hidden">
                <el-button-group>
                                                    
                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="流程图"
                    placement="top">
                    <el-button class="iconfont icon-icon__liuchengtu" @click="btnNoFlow"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="打印"
                    placement="top">
                    <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="导出数据"
                    placement="top">
                    <el-button class="iconfont icon-download" @click="btnNoExport"></el-button>
                    </el-tooltip>
                </el-button-group>        
                
            </el-col>
        </el-row>

        <!-- 数据列表 -->
        <el-table :data="dataList" border stripe :height="tableHeight" style="width: 100%" @select="btnCheckbox" @select-all="btnCheckbox">
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column label="编号"  width="165">
                <template v-slot="scope">
                    <span>{{scope.row.adjustNumber}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="departmentName" label="部门名称" min-width="100">
                <template v-slot="scope">
                  <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.departmentName"
                    placement="top"
                  >
                  <div class="cellOverFlow">{{scope.row.departmentName}}</div>
                  </el-tooltip>
                </template>
            </el-table-column>

            <el-table-column prop="areaName" label="大区" min-width="90" show-overflow-tooltip>
            </el-table-column>
           
            <el-table-column label="备注" min-width="100">
                <template v-slot="scope">
                  <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.remark"
                    placement="top"
                  >
                  <div class="cellOverFlow">{{scope.row.remark}}</div>
                  </el-tooltip>
                </template>
            </el-table-column>

            <el-table-column label="状态">
                <template #default="scope">
                    <el-tag v-if="scope.row.state ==1">新建单</el-tag>
                    <el-tag v-if="scope.row.state ==2">待审核</el-tag>
                    <el-tag v-if="scope.row.state ==3" type="danger">已驳回</el-tag>
                    <el-tag v-if="scope.row.state ==4" type="warning">已审核并上报</el-tag>
                    <el-tag v-if="scope.row.state ==5" type="success">已生效</el-tag>
                    <el-tag v-if="scope.row.state ==9" type="info">已作废</el-tag>
                </template>
            </el-table-column>

            <el-table-column label="创建人">
                <template v-slot="scope">
                    <span>{{scope.row.createName}}</span>
                </template>
            </el-table-column>

            <el-table-column label="调价时间" width="110">
                <template #default="scope">
                    <el-tooltip
                        class="box-item"
                        effect="dark"
                        :content="scope.row.adjustDate"
                        placement="top"
                    >
                    <span>{{DateFormat(scope.row.adjustDate)}}</span>
                    </el-tooltip>                
                </template>
            </el-table-column>

            <el-table-column label="创建时间" width="110">
                <template #default="scope">
                    <el-tooltip
                        class="box-item"
                        effect="dark"
                        :content="scope.row.createTime"
                        placement="top"
                    >
                    <span>{{DateFormat(scope.row.createTime)}}</span>
                    </el-tooltip>                
                </template>
            </el-table-column>

            <el-table-column label="操作" width="130" fixed="right">
              <template v-slot="scope">
                <el-button size="small" type="primary" @click="btnEditInfo(scope.row.adjustNumber)">详情</el-button>
                <el-button v-if="CheckUserButtonAuth('BtnPriceAdjustDelete')" size="small" type="danger" @click="btnDeleteInfo(scope.row.adjustNumber)">删除</el-button>
              </template>
            </el-table-column>
        </el-table>
    </div>

    <el-affix position="bottom">
        <el-row class="page_two">
            <el-col :span="24">
                <!-- 分页 -->
                <el-container class="page_right">
                    <el-pagination
                    v-model:currentPage="info.page"
                    v-model:page-size="info.size"
                    :page-sizes="[50, 200, 500, 1000]"
                    :background="true"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="info.total"
                    @current-change="btnPagechange"
                    @size-change="btnSizechange"
                    >
                    </el-pagination>
                </el-container>
            </el-col>
        </el-row>        
    </el-affix>

    <!-- 新增、编辑 弹出框 -->
    <el-drawer
      :title="BoxEditInfo.title" 
      v-model="BoxEditInfo.isVisible"
      direction="ltr"
      size="85%"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      @closed="GetDataList">
        <EditPriceOffer :editId="info.id" :dataList="dataList" @closeBox="btnCloseBox"></EditPriceOffer>
    </el-drawer>

  </div>
</template>

<script>
import UserDptList from '@/utils/mixins/UserDptList.js'   //用户关联部门列表
import UserAreaList from '@/utils/mixins/UserAreaList.js'  //用户关联大区列表
import TableHeight from '@/utils/mixins/TableHeight.js'  //设置Table高度

import EditPriceOffer from '@/views/Fms/PriceAdjust/EditPriceAdjust.vue'  
import { ElMessage, ElMessageBox } from 'element-plus'
export default {
    mixins:[UserDptList,UserAreaList,TableHeight],
    components:{
        EditPriceOffer
    },
    data(){
        return{
            dataList:[],  //table数据
            info:{
                page:1,
                size:50,
                total:0,  //总记录数
                id:0,  //新增、编辑 的参数
                ids:[],   //删除 的参数
                search:{
                    adjustNumber:'',
                    selectDepartmentList:[],  //部门
                    areaNumberList:[],   //大区列表
                    datePoint:'',   //报价日期
                    state:0,      //状态
                    remark:'',   //备注
                },
            },
            //编辑-弹出框
            BoxEditInfo:{
              isVisible:false,
              title:'调价表详情'
            },
        }
    },
    methods:{

        //加载数据列表
        GetDataList(){
            //日期范围
            if(this.info.search.datePoint == null){
                this.info.search.datePoint = ''
            }

            //获取、转换部门
            let newDepartmentList = []
            if(this.info.search.selectDepartmentList!=null){
                newDepartmentList = this.info.search.selectDepartmentList.map(item=>{
                    return item
                })
            }

            let param = {
                adjustNumber:this.info.search.adjustNumber,
                departmentList:newDepartmentList,
                areaNumberList:this.info.search.areaNumberList,  //大区
                remark:this.info.search.remark,
                state:this.info.search.state,   //状态
                startDate:this.DateFormat(this.info.search.datePoint[0]),
                endDate:this.DateFormat(this.info.search.datePoint[1]),
                page:this.info.page,
                size:this.info.size
            }
            this.$api.finance.PagePriceAdjustList(param).then(res=>{
                if(res.data.status === 200){
                    this.dataList = res.data.data;
                    //分页
                    this.info.total = res.data.total;
                    this.info.page = res.data.pageNumber;
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

        //选中多选框
        btnCheckbox(selection){
            this.info.ids = selection.map(item=>item.adjustNumber)
        },
         //分页-页数变化时
        btnPagechange(page){
            this.info.page = page
            this.GetDataList() //加载Table数据
        },
        //分页-单页记录数变化时
        btnSizechange(size){
            this.info.size = size
            this.info.page = 1
            this.GetDataList() //加载Table数据
        },
        //新增编辑信息
        btnEditInfo(id){
            this.info.id = id
            if(id==0){
                this.BoxEditInfo.title = "新增调价表"
            }else{
                this.BoxEditInfo.title = "编辑调价表"
            }  
            this.BoxEditInfo.isVisible = true;
        },

        //删除按钮
        btnDeleteInfo(adjustNumber){
            ElMessageBox.confirm('确定要删除该调价表吗?','删除提示',{
                confirmButtonText: '确认',
                cancelButtonText: '取消'
            }).then(()=>{
                let param = [adjustNumber]
                this.$api.finance.DeletePriceAdjust(param).then(res=>{
                    if(res.data.status === 200){
                        ElMessage.success(res.data.msg)
                        this.GetDataList()
                    }
                    else{
                        ElMessage.error(res.data.msg)
                    }
                })
            }).catch(()=>{
                //取消
            })
        },
        //批量删除
        btnBatchDeleteInfo(){
            if(this.info.ids.length<1){
                ElMessage.error('请选择要删除的选项')
                return false
            }

            ElMessageBox.confirm('确定要删除该调价表吗?','删除提示',{
                confirmButtonText: '确认',
                cancelButtonText: '取消'
            }).then(()=>{
                this.$api.finance.DeletePriceAdjust(this.info.ids).then(res=>{
                    if(res.data.status === 200){
                        ElMessage.success(res.data.msg)
                        this.GetDataList()
                    }
                    else{
                        ElMessage.error(res.data.msg)
                    }
                })
            }).catch(()=>{
                //取消
            })  
        },

        //重置按钮
        btnResetSearch(){
            this.info.search.adjustNumber="";  //调价编号
            this.info.search.remark="";   //备注
            this.info.search.selectDepartmentList = [];
            this.info.search.datePoint = "";
            this.info.search.state=0;
            this.info.ids = [];  //编号数组
            this.info.page = 1;
            this.GetDataList()
        }, 

        //关闭弹出框
        btnCloseBox(){
            this.BoxEditInfo.isVisible = false;
        },

    },
    mounted(){
        this.GetDataList();
    },
}
</script>

<style>

</style>