<template>
  <div>

    <el-form :inline="true"
        v-model="differenceInfo.info"
        label-width="100px"
        label-position="right">
            <el-row v-if="differenceInfo.info">
                
                <el-col :md="8" :sm="12" :xs="24">
                    <el-form-item label="单据编号">
                        <el-input v-model="differenceInfo.info.billNumber" disabled class="search-200"></el-input>
                    </el-form-item>
                </el-col>
                
                <el-col :md="8" :sm="12" :xs="24">
                    <el-form-item label="所属部门">
                        <template #label>
                            <span style="color:red;">*</span>
                            <span>所属部门</span>
                        </template>
                        <el-tree-select v-model="differenceInfo.info.departmentId" :data="departmentList" :default-expand-all="true" class="search-200"/>
                    </el-form-item>
                </el-col>

                <el-col :md="8" :sm="12" :xs="24">
                    <el-form-item label="所属大区">
                        <template #label>
                            <span style="color:red;">*</span>
                            <span>所属大区</span>
                        </template>
                        <el-select v-model="differenceInfo.info.areaNumber" class="search-200">
                            <el-option v-for="userArea in userAreaList" :key="userArea.areaNumber" :label="userArea.areaName" :value="userArea.areaNumber"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :md="8" :sm="12" :xs="24">
                    <el-form-item label="交易者">
                        <template #label>
                            <span style="color:red;">*</span>
                            <span>交易者</span>
                        </template>
                        <el-select v-model="differenceInfo.info.tradeNumber" filterable class="search-200">
                            <el-option v-for="trade in partTradeList" :key="trade.tradeNumber" :label="trade.tradeName+ '(' + trade.tradeTypeName+')'" :value="trade.tradeNumber" />
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :md="8" :sm="12" :xs="24">
                    <el-form-item label="原始单类型">
                        <el-select v-model="differenceInfo.info.originType" class="search-200">
                            <el-option label="采购单" :value="1"></el-option>
                            <el-option label="销售单" :value="2"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :md="8" :sm="12" :xs="24">
                    <el-form-item label="差额说明">
                      <el-input v-model="differenceInfo.info.content" class="search-200"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :md="8" :sm="12" :xs="24">
                    <el-form-item label="记账时间">
                        <el-date-picker v-model="differenceInfo.info.billTime" type="datetime" placeholder="记账时间" value-format="YYYY-MM-DD HH:mm:ss" style="width:200px" />
                    </el-form-item>
                </el-col>

                <el-col :md="8" :sm="12" :xs="24">
                    <el-form-item label="单价">
                      <el-input-number v-model.number="differenceInfo.info.unitPrice" :precision="2" @change="OnChangePrice" class="search-200"></el-input-number>
                    </el-form-item>
                </el-col>

                <el-col :md="8" :sm="12" :xs="24">
                    <el-form-item label="数量">
                      <el-input-number v-model.number="differenceInfo.info.realNum" :min="0" @change="OnChangePrice" class="search-200"></el-input-number>
                    </el-form-item>
                </el-col>

                <el-col :md="8" :sm="12" :xs="24">
                    <el-form-item label="备注">
                      <el-input v-model="differenceInfo.info.remark" class="search-200"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :md="8" :sm="12" :xs="24">
                    <el-form-item label="总价">
                      <el-input v-model="differenceInfo.info.totalPrice" disabled class="search-200"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :md="8" :sm="12" :xs="24">
                    <el-form-item label="状态">
                      <el-tag v-if="differenceInfo.info.state ==1">新单据</el-tag>
                      <el-tag v-if="differenceInfo.info.state ==2" type="danger">已驳回</el-tag>
                      <el-tag v-if="differenceInfo.info.state ==3" type="success">已审核</el-tag>
                      <el-tag v-if="differenceInfo.info.state ==10">已关单</el-tag>
                    </el-form-item>
                </el-col>


            </el-row>
      </el-form>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import UserDptList from '@/utils/mixins/UserDptList.js'
import PartTradeList from '@/utils/mixins/PartTradeList.js'  //交易者列表
export default {
    mixins:[UserDptList,PartTradeList],
    data(){
        return{
            userAreaList:[],    //用户关联的大区
        }
    },
    computed:{
        ...mapState('Erp',['differenceInfo'])
    },
    methods:{
        
        //加载用户关联的大区列表
        SelectUserAreaList(){
            this.$api.system.SelectUserAreaList().then(res=>{
                if(res.data.status === 200){
                    this.userAreaList = res.data.data;
                    //新增时，默认加载第一个大区
                    if(this.userAreaList.length>0 && this.differenceInfo.info.billNumber==''){
                        this.differenceInfo.info.areaNumber = this.userAreaList[0].areaNumber;
                    }
                }else{
                    this.userAreaList = [];
                }
            })
        },      

        //单价发生改变后
        OnChangePrice(){
            this.differenceInfo.info.totalPrice = this.myRound(this.differenceInfo.info.unitPrice * this.differenceInfo.info.realNum,2);
        }
    },
}
</script>

<style>

</style>