<template>
  <div>
    <!-- 搜索功能 -->
    <el-row>
        <el-col :span="24">
            <el-form :inline="true">          

            <el-form-item label="对账单号">
                <el-input v-model="info.search.cycleNumber"></el-input>
            </el-form-item>

            <el-form-item label="对账说明">
                <el-input v-model="info.search.title"></el-input>
            </el-form-item>

            <el-form-item label="交易者">
                <el-select v-model="info.search.tradeNumber" filterable clearable class="search-150">
                    <el-option v-for="trade in allTradeList" :key="trade.tradeNumber" :label="trade.tradeName+ '(' + trade.tradeTypeName+')'" :value="trade.tradeNumber" />
                </el-select>
            </el-form-item>

            <el-form-item label="排款日期">
                <el-date-picker
                    v-model="info.search.queueDatePoint"
                    type="daterange"
                    range-separator="To"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    style="width:250px"
                />
            </el-form-item>

            <el-form-item label="用款日期">
                <el-date-picker
                    v-model="info.search.payDatePoint"
                    type="daterange"
                    range-separator="To"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    style="width:250px"
                />
            </el-form-item>

            <el-form-item>
            <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
            <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
            </el-form-item>

            </el-form>
        </el-col>
    </el-row>

    <!-- 主内容区 -->
    <div>
        <!-- 功能栏 -->
        <el-row :gutter="10" class="operation">
            <el-col :span="15">
                <el-button class="iconfont icon-add" type="primary" plain @click="btnEditInfo('0')">新增</el-button>
                <el-popconfirm 
                    title="确认要删除吗?"
                    @confirm="btnBatchDeleteInfo">
                    <template #reference>
                        <el-button class="iconfont icon-ashbin" type="danger" plain>删除</el-button>
                    </template>
                </el-popconfirm>
            </el-col>

            <el-col :span="9" class="operation_hidden">
                <el-button-group>
                                            
                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="流程图"
                    placement="top">
                    <el-button class="iconfont icon-icon__liuchengtu" @click="btnNoFlow"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="打印"
                    placement="top">
                    <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="导出数据"
                    placement="top">
                    <el-button class="iconfont icon-download" @click="btnExportInfo"></el-button>
                    </el-tooltip>
                </el-button-group>       
                
            </el-col>
        </el-row>

        <!-- 列表 -->
        <el-table :data="dataList" border stripe show-summary :height="GetDataHeight" style="width: 100%" @select="btnCheckbox" @select-all="btnCheckbox">
            <el-table-column prop="cycleNumber" type="selection" width="55" />  
            <el-table-column label="对账单号" width="165">
                <template v-slot="scope">
                    <span>{{scope.row.cycleNumber}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="title" label="对账说明" width="140">
                <template v-slot="scope">
                    <el-tooltip
                        class="box-item"
                        effect="dark"
                        :content="scope.row.title"
                        placement="top"
                    >
                    <div class="cellOverFlow">{{scope.row.title}}</div>
                    </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column label="交易者类型" width="95">
                <template #default="scope">
                    <el-tag v-if="scope.row.tradeType == 1" type="danger">供应商</el-tag>
                    <el-tag v-if="scope.row.tradeType == 2" type="success">客户</el-tag>
                    <el-tag v-if="scope.row.tradeType == 3" type="info">员工</el-tag>
                    <el-tag v-if="scope.row.tradeType == 4" type="info">散客</el-tag>
                    <el-tag v-if="scope.row.tradeType == 5" type="info">会员</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="tradeName" label="交易者" width="140" >
                <template v-slot="scope">
                    <el-tooltip
                        class="box-item"
                        effect="dark"
                        :content="scope.row.tradeName"
                        placement="top"
                    >
                    <div class="cellOverFlow">{{scope.row.tradeName}}</div>
                    </el-tooltip>
                </template>
            </el-table-column>
            
            <el-table-column prop="startDate" label="账单周期" width="205">
                <template #default="scope">
                <span>{{DateFormat(scope.row.startDate)}}</span> 至 <span>{{DateFormat(scope.row.endDate)}}</span>
                </template>
            </el-table-column>

            <el-table-column prop="totalPrice" label="总金额(元)" min-width="100"/>
        
        <el-table-column prop="accountName" label="支付方式"></el-table-column>

            <el-table-column label="排款日期" width="110">
                <template #default="scope">
                <span>{{DateFormat(scope.row.queueTime)}}</span>
                </template>
            </el-table-column>

            <el-table-column label="用款时间" width="110">
                <template #default="scope">
                <span>{{DateFormat(scope.row.payTime)}}</span>
                </template>
            </el-table-column>

            <el-table-column label="是否符合付款条件" width="90">
                <template #default="scope">
                    <el-tag v-if="scope.row.isFit ==0" type="danger">否</el-tag>
                    <el-tag v-if="scope.row.isFit ==1" type="success">符合</el-tag>
                </template>
            </el-table-column>

            <el-table-column label="创建日期" width="110">
                <template #default="scope">
                <span>{{DateFormat(scope.row.createTime)}}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="140" fixed="right">
                <template v-slot="scope">              
                <el-button size="small" type="primary" @click="btnEditInfo(scope.row.cycleNumber)">详情</el-button>
                <el-popconfirm 
                    title="确认要删除吗?"
                    @confirm="btnDeleteInfo(scope.row.cycleNumber)">
                    <template #reference>
                        <el-button size="small" type="danger">删除</el-button>
                    </template>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>

    </div>

    <el-affix position="bottom">
        <el-row class="page_two">
            <el-col :span="12">
                
            </el-col>
            <el-col :span="12">
                <!-- 分页 -->
                <el-container class="page_right">
                    <el-pagination
                    v-model:currentPage="info.page"
                    v-model:page-size="info.size"
                    :page-sizes="[50, 200, 500, 1000]"
                    :background="true"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="info.total"
                    @current-change="btnPagechange"
                    @size-change="btnSizechange"
                    >
                    </el-pagination>
                </el-container>
            </el-col>
        </el-row>        
    </el-affix>

    <!-- 新增、编辑 弹出框 -->
    <el-dialog :title="BoxEditInfo.title" 
    v-model="BoxEditInfo.isVisible"
    width="85%"
    :draggable="true"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    @closed="GetDataList">
        <EditCycle :editId="info.id" :dataList="dataList" :numberList="null" :newCycle="null" @closeBox="btnCloseBox"></EditCycle>
    </el-dialog>

  </div>
</template>

<script>
import AllTradeList from '@/utils/mixins/AllTradeList.js'  //交易者列表

import EditCycle from '@/views/Fms/Cycle/EditCycle.vue'
import { ElMessage } from 'element-plus'
export default {
    name:'ListCycle',
    mixins:[AllTradeList],
    components:{
        EditCycle,
    },
    data(){
        return{
            dataList:[],  //Table数据
            info:{                
                page:1,
                size:50,
                total:0,  //总记录数
                id:0,  //新增、编辑 的参数
                ids:[],   //删除 的参数
                search:{
                    tradeType:0,   //交易者类型 0:全部 1:供应商 2:客户
                    cycleNumber:'', 
                    title:'',
                    tradeNumber:'',
                    queueDatePoint:'',  //排款时间范围
                    payDatePoint:'',  //用款时间范围
                },
            },
            //编辑-弹出框
            BoxEditInfo:{
              isVisible:false,
              title:'对账单'
            },
            
        }
    },
    computed:{
        //自适应窗体高度
        GetDataHeight(){
            return window.innerHeight-290
        }
    },
    methods:{

        //加载数据列表
        GetDataList(){
            //排款时间范围
            if(this.info.search.queueDatePoint == null){
                this.info.search.queueDatePoint = ''
            }
            //用款时间范围
            if(this.info.search.payDatePoint == null){
                this.info.search.payDatePoint = ''
            }


            let param = {
                cycleNumber:this.info.search.cycleNumber,
                title:this.info.search.title,
                tradeType:this.info.search.tradeType,    //交易者类型
                tradeNumber:this.info.search.tradeNumber,  //交易者编号
                startQueueDate:this.DateFormat(this.info.search.queueDatePoint[0]),   //排款时间
                endQueueDate:this.DateFormat(this.info.search.queueDatePoint[1]),
                startPayDate:this.DateFormat(this.info.search.payDatePoint[0]),    //用款时间
                endPayDate:this.DateFormat(this.info.search.payDatePoint[1]),
                page:this.info.page,
                size:this.info.size
            }
            this.$api.finance.PageCycleList(param).then(res=>{
                if(res.data.status === 200){
                    this.dataList = res.data.data;
                    //分页
                    this.info.total = res.data.total
                    this.info.page = res.data.pageNumber
                }
            }).catch(e=>{
                console.log(e)
            })
        },

        //选中多选框
        btnCheckbox(selection){
            this.info.ids = selection.map(item=>item.cycleNumber)
        },
        //分页-页数变化时
        btnPagechange(page){
            this.info.page = page
            this.GetDataList() //加载Table数据
        },
        //分页-单页记录数变化时
        btnSizechange(size){
            this.info.size = size
            this.info.page = 1
            this.GetDataList() //加载Table数据
        },

        //编辑
        btnEditInfo(cycleNumber){
            this.info.id = cycleNumber
            this.BoxEditInfo.isVisible = true;
        },
        //关闭弹出框
        btnCloseBox(){
            this.BoxEditInfo.isVisible = false;
        },

        //删除按钮
        btnDeleteInfo(cycleNumber){
            let param = [cycleNumber]
            this.$api.finance.DeleteCycleAllInfo(param).then(res=>{
            if(res.data.status === 200){
                ElMessage.success(res.data.msg)
                this.GetDataList()
            }
            else{
                ElMessage.error(res.data.msg)
            }
            })
        },
        //批量删除
        btnBatchDeleteInfo(){
            if(this.info.ids.length<1){
                ElMessage.error('请选择要删除的选项')
                return false
            }

            this.$api.finance.DeleteCycleAllInfo(this.info.ids).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg)
                    this.GetDataList()
                }
                else{
                    ElMessage.error(res.data.msg)
                }
            })
        },

        //重置按钮
        btnResetSearch(){
            this.info.search.cycleNumber='';
            this.info.search.title = '';
            this.info.search.tradeType = 0;
            this.info.search.tradeNumber='';
            this.info.search.queueDatePoint = '';  //排款时间
            this.info.search.payDatePoint = '';  //付款时间
            this.info.page = 1,
            this.GetDataList()
        },  
        //导出账期列表
        btnExportInfo(){
            //排款时间范围
            if(this.info.search.queueDatePoint == null){
                this.info.search.queueDatePoint = ''
            }
            //用款时间范围
            if(this.info.search.payDatePoint == null){
                this.info.search.payDatePoint = ''
            }

            let param = {
                cycleNumber:this.info.search.cycleNumber,
                title:this.info.search.title,
                tradeType:this.info.search.tradeType,    //交易者类型
                tradeNumber:this.info.search.tradeNumber,  //交易者编号
                startQueueDate:this.DateFormat(this.info.search.queueDatePoint[0]),   //排款时间
                endQueueDate:this.DateFormat(this.info.search.queueDatePoint[1]),
                startPayDate:this.DateFormat(this.info.search.payDatePoint[0]),    //用款时间
                endPayDate:this.DateFormat(this.info.search.payDatePoint[1]),
                page:this.info.page,
                size:this.info.size
            }
            this.$api.erp.ExportCycle(param).then(res=>{
                if(res.data.status === 200){
                    const alink = document.createElement('a');
                    alink.href = res.data.data;
                    document.body.appendChild(alink);
                    alink.click();
                    document.body.removeChild(alink)
                }
                else
                {
                    ElMessage.error(res.data.msg)
                }
            }).catch(err=>{
                console.log(err)
            })
        },
        
        
    },
    mounted(){
        this.GetDataList();  //加载数据
    }
}
</script>

<style>

</style>