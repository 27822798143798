<template>
  <div>
    <!-- 产品列表 -->

    <!-- 搜索功能 -->
    <el-row>
        <el-col :span="24">
            <el-form :inline="true">

                <el-form-item label="商品类目">
                <el-tree-select v-model="info.search.cid" :data="selectCategoryList" :default-expand-all="true" filterable class="search-200"/>
                </el-form-item>

                <el-form-item label="名称">
                <el-input v-model="info.search.spuName" placeholder="商品名称" class="search-200"></el-input>
                </el-form-item>

                <el-form-item>
                <el-button class="iconfont icon-search" type="primary" plain  @click="GetDataList">搜索</el-button>
                <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
                </el-form-item>

            </el-form>
        </el-col>
    </el-row>

    <!-- 主内容区 -->
    <div>
        <!-- 功能栏 -->
        <el-row :gutter="10" class="operation">
        <el-col :span="15">
            <el-button class="iconfont icon-add" type="primary" plain @click="btnEditInfo(0)" >新增</el-button>
            <el-button class="iconfont icon-ashbin" type="danger" plain @click="btnBatchDeleteInfo">删除</el-button>
        </el-col>

        <el-col :span="9" class="operation_hidden">
            <el-button-group>
                
                <el-tooltip
                class="box-item"
                effect="dark"
                content="流程介绍"
                placement="top">
                <el-button class="iconfont icon-electronics"  @click="BoxFlow.isVisible = true"></el-button>
                </el-tooltip>

                <el-tooltip
                class="box-item"
                effect="dark"
                content="打印"
                placement="top">
                <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
                </el-tooltip>

                <el-tooltip
                class="box-item"
                effect="dark"
                content="导出数据"
                placement="top">
                <el-button class="iconfont icon-download" @click="btnNoExport"></el-button>
                </el-tooltip>
            </el-button-group>
            
        </el-col>
        </el-row>

        <el-table :data="dataList" border stripe :height="GetDataHeight" style="width: 100%" @select="btnCheckbox" @select-all="btnCheckbox"> 
            <el-table-column prop="id" type="selection" width="50" />  
            <el-table-column prop="spuNumber" label="商品编码" min-width="130" />
            <el-table-column prop="cidName" label="类目" />
            <el-table-column prop="spuName" label="商品名称" min-width="100">
            <template v-slot="scope">
                <el-tooltip
                class="box-item"
                effect="dark"
                :content="scope.row.spuName"
                placement="top"
                >
                <div class="cellOverFlow">{{scope.row.spuName}}</div>
                </el-tooltip>
            </template>
            </el-table-column>   

            <el-table-column prop="sort" label="排序" />

            <el-table-column label="显示标识" min-width="85">
                <template #default="scope">
                    <el-tag v-if="scope.row.isShow == 1">显示</el-tag>
                    <el-tag v-if="scope.row.isShow == 0" type="info">隐藏</el-tag>
                </template>
            </el-table-column>     
            
            <el-table-column label="操作" width="130" fixed="right">
            <template v-slot="scope">              
                <el-button size="small" type="primary" @click="btnEditInfo(scope.row.spuNumber)">详情</el-button>
                <el-button size="small" type="danger" @click="btnDeleteInfo(scope.row.spuNumber)">删除</el-button>           
            </template>
            </el-table-column>
            
        </el-table>
    </div>

    <el-affix position="bottom">
        <el-row class="page_two">
            <el-col :span="24">
                <!-- 分页 -->
                <el-container class="page_right">
                    <el-pagination
                    v-model:currentPage="info.page"
                    v-model:page-size="info.size"
                    :page-sizes="[50, 200, 500, 1000]"
                    :background="true"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="info.total"
                    @current-change="btnPagechange"
                    @size-change="btnSizechange"
                    >
                    </el-pagination>
                </el-container>
            </el-col>
        </el-row>        
    </el-affix>

    <!-- 新增、编辑 弹出框 -->
    <el-dialog :title="BoxEditInfo.title" 
    v-model="BoxEditInfo.isVisible"
    width="750"
    :draggable="true"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    @closed="GetDataList">
        <EditSpu :editId="info.id" :dataList="dataList" @closeBox="btnCloseBox"></EditSpu>
    </el-dialog>

  </div>
</template>

<script>
import EditSpu from '@/views/Goods/Spu/EditSpu.vue'
import CategoryList from '@/utils/mixins/CategoryList.js'
import { ElMessage, ElMessageBox } from 'element-plus'
export default {
    mixins:[CategoryList],
    components:{
        EditSpu,
    },
    data(){
        return{
            dataList:[],  //Table数据
            info:{
                page:1,
                size:50,
                total:0,  //总记录数
                id:0,  //新增、编辑 的参数
                ids:[],   //删除 的参数
                search:{
                    cid:0,
                    spuName:'',  //产品名称
                }
            },
            //编辑-弹出框
            BoxEditInfo:{
                isVisible:false,
                title:'新增商品'
            },
        }
    },
    computed:{
        //自适应窗体高度
        GetDataHeight(){
            return window.innerHeight-235
        }
    },
    methods:{
        //加载数据
        GetDataList(){
            let param = {
                cid:this.info.search.cid,
                spuName:this.info.search.spuName,
                page:this.info.page,
                size:this.info.size
            }
            this.$api.goods.PageSpuList(param).then(res=>{
                if(res.data.status ===200){
                    this.dataList = res.data.data;
                    //分页
                    this.info.total = res.data.total
                    this.info.page = res.data.pageNumber
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

        //选中多选框
        btnCheckbox(selection){
            this.info.ids = selection.map(item=>item.spuNumber)
        },
        //分页-页数变化时
        btnPagechange(page){
            this.info.page = page
            this.GetDataList() //加载Table数据
        },
        //分页-单页记录数变化时
        btnSizechange(size){
            this.info.size = size
            this.info.page = 1
            this.GetDataList() //加载Table数据
        },
        //新增编辑
        btnEditInfo(id){
            this.info.id = id
            if(id==0){
                this.BoxEditInfo.title = "新增商品"
            }else{
                this.BoxEditInfo.title = "编辑商品"
            }  
            this.BoxEditInfo.isVisible = true;
        },

        //删除按钮
        btnDeleteInfo(spuNumber){
            ElMessageBox.confirm('确定要删除该信息吗?','删除提示',{
                confirmButtonText: '确认',
                cancelButtonText: '取消'
            }).then(()=>{
                let param = [spuNumber]
                this.$api.goods.DeleteSpu(param).then(res=>{
                    if(res.data.status === 200){
                        ElMessage({type:'success',message:res.data.msg})
                        this.GetDataList()
                    }
                    else{
                        ElMessage({type:'error',message:res.data.msg})
                    }
                })
            }).catch(()=>{
                //取消
            })
        },
        //批量删除
        btnBatchDeleteInfo(){
            if(this.info.ids.length<1){
                ElMessage.error('请选择要删除的选项')
                return false
            }

            ElMessageBox.confirm('确定要删除该信息吗?','删除提示',{
                confirmButtonText: '确认',
                cancelButtonText: '取消'
            }).then(()=>{
                this.$api.goods.DeleteSpu(this.info.ids).then(res=>{
                    if(res.data.status === 200){
                        ElMessage.success(res.data.msg)
                        this.GetDataList();
                        this.info.ids = [];
                    }
                    else{
                        ElMessage.error(res.data.msg)
                    }
                })
            }).catch(()=>{
                //取消
            })
        },

        //关闭
        btnCloseBox(){
            this.BoxEditInfo.isVisible =false
        },
                
        //重置按钮
        btnResetSearch(){
            this.info.search.cid=0,
            this.info.search.spuName='',
            this.GetDataList()
        },
    },
    mounted(){
        this.GetDataList();
    },

}
</script>

<style>

</style>