<template>
  <div>
    <!-- 交班记录 -->
    
    <!-- 搜索功能 -->
    <el-row>
        <el-col :span="24">
            <el-form :inline="true">

                <el-form-item label="班次编号">
                    <el-input v-model="info.search.shiftNumber" class="search-150" placeholder="班次编号"></el-input>
                </el-form-item>

                <el-form-item label="部门">
                    <el-tree-select v-model="info.search.selectDepartmentList" :data="departmentList" multiple clearable :default-expand-all="true" collapse-tags collapse-tags-tooltip class="search-200">
                    </el-tree-select>
                </el-form-item>

                <el-form-item label="状态">
                    <el-select v-model="info.search.state" class="search-200">
                        <el-option label="全部" :value="0" />
                        <el-option label="未开始" :value="1" />
                        <el-option label="在班中" :value="2" />
                        <el-option label="已交班" :value="3" />
                        <el-option label="待审核" :value="4" />
                        <el-option label="已驳回" :value="5" />
                        <el-option label="已审核" :value="6" />
                        <el-option label="已完成" :value="10" />
                    </el-select>
                </el-form-item>
                
                <el-form-item label="创建日期">
                    <el-date-picker
                        v-model="info.search.datePoint"
                        type="daterange"
                        range-separator="To"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        style="width:250px"
                    />
                </el-form-item>

                <el-form-item>
                    <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
                    <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
                </el-form-item>

            </el-form>
        </el-col>
    </el-row>

    <!-- 数据列表 -->
    <div>
        <!-- 功能栏 -->
        <el-row :gutter="10" class="operation">
            <el-col :span="15">
                <el-button v-if="CheckUserButtonAuth('BtnShiftAdd')" class="iconfont icon-add" type="primary" plain @click="btnEditInfo('0')">新增</el-button>
                <el-popconfirm v-if="CheckUserButtonAuth('BtnShiftDelete')"
                    title="确认要删除吗?"
                    @confirm="btnBatchDeleteInfo">
                    <template #reference>
                        <el-button class="iconfont icon-ashbin" type="danger" plain>删除</el-button>
                    </template>
                </el-popconfirm>       
            </el-col>

            <el-col :span="9" class="operation_hidden">
                <el-button-group>
                                                
                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="流程图"
                    placement="top">
                    <el-button class="iconfont icon-icon__liuchengtu" @click="btnNoFlow"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="打印"
                    placement="top">
                    <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="导出数据"
                    placement="top">
                    <el-button class="iconfont icon-download" @click="btnNoExport"></el-button>
                    </el-tooltip>
                </el-button-group>    
                
            </el-col>
        </el-row>

        <!-- 列表 -->
        <el-table :data="dataList" show-summary border stripe :height="GetDataHeight" style="width: 100%" @select="btnCheckbox" @select-all="btnCheckbox">
            <el-table-column prop="shiftNumber" type="selection" width="55" />
            <el-table-column label="编号" width="150">
                <template #default="scope">
                    <span>{{scope.row.shiftNumber}}</span>
                </template>
            </el-table-column>
            <el-table-column label="部门" min-width="110">
                <template v-slot="scope">
                    <el-tooltip
                        class="box-item"
                        effect="dark"
                        :content="scope.row.departmentName"
                        placement="top">
                        <div class="cellOverFlow">{{scope.row.departmentName}}</div>
                    </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column prop="classDate" label="排班日期" min-width="100"/>
            <el-table-column label="排班类型" min-width="150">
                <template v-slot="scope">
                    <span v-if="scope.row.classType==1">早班</span>
                    <span v-if="scope.row.classType==2">午班</span>
                    <span v-if="scope.row.classType==3">晚班</span>
                </template>
            </el-table-column>
            <el-table-column label="开始时间" min-width="110">
                <template #default="scope">
                    <el-tooltip
                        class="box-item"
                        effect="dark"
                        :content="scope.row.startTime"
                        placement="top"
                    >
                    <span>{{DateFormat(scope.row.startTime)}}</span>
                    </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column label="结束时间" min-width="110">
                <template #default="scope">
                    <el-tooltip
                        class="box-item"
                        effect="dark"
                        :content="scope.row.endTime"
                        placement="top"
                    >
                    <span>{{DateFormat(scope.row.endTime)}}</span>
                    </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column prop="totalPrice" label="系统发生额" min-width="100"/>
            <el-table-column prop="realPrice" label="实际交易额" min-width="100"/>
            <el-table-column prop="diffPrice" label="差额" min-width="100"/>
            <el-table-column prop="retailGroupName" label="在班班组" min-width="100"/>
            <el-table-column prop="accountName" label="状态" min-width="100">
                <template v-slot="scope">
                    <el-tag v-if="scope.row.state ==1" type="info">未开始</el-tag>
                    <el-tag v-if="scope.row.state ==2">在班中</el-tag>
                    <el-tag v-if="scope.row.state ==3" type="warning">已交班</el-tag>
                    <el-tag v-if="scope.row.state ==4">待审核</el-tag>
                    <el-tag v-if="scope.row.state ==5" type="danger">已驳回</el-tag>
                    <el-tag v-if="scope.row.state ==6" type="info">已审核</el-tag>
                    <el-tag v-if="scope.row.state ==10" type="info">完成</el-tag>
                </template>
            </el-table-column>

            <el-table-column label="创建日期" width="110">
                <template #default="scope">
                    <el-tooltip
                        class="box-item"
                        effect="dark"
                        :content="scope.row.createTime"
                        placement="top"
                    >
                    <span>{{DateFormat(scope.row.createTime)}}</span>
                    </el-tooltip>                
                </template>
            </el-table-column>
            <el-table-column label="操作" width="130" fixed="right">
                <template v-slot="scope">              
                <el-button size="small" type="primary" @click="btnEditInfo(scope.row.shiftNumber)">详情</el-button>
                <el-popconfirm  v-if="CheckUserButtonAuth('BtnShiftDelete')"
                    title="确认要删除吗?"
                    @confirm="btnDeleteInfo(scope.row.shiftNumber)">
                    <template #reference>
                        <el-button size="small" type="danger">删除</el-button>
                    </template>
                </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>

    </div>

    <!-- 分页 -->
    <el-affix position="bottom">
        <el-row class="page_two">
            <el-col :span="24">
                <!-- 分页 -->
                <el-container class="page_right">
                    <el-pagination
                    v-model:currentPage="info.page"
                    v-model:page-size="info.size"
                    :page-sizes="[50, 200, 500, 1000]"
                    :background="true"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="info.total"
                    @current-change="btnPagechange"
                    @size-change="btnSizechange"
                    >
                    </el-pagination>
                </el-container>
            </el-col>
        </el-row>        
    </el-affix>

    <!-- 新增、编辑 弹出框 -->
    <el-drawer
      :title="BoxEditInfo.title" 
      v-model="BoxEditInfo.isVisible"
      direction="ltr"
      size="85%"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      @closed="GetDataList">
        <EditShiftRecord :editId="info.id" :dataList="dataList" @closeBox="btnCloseBox"></EditShiftRecord>
    </el-drawer>

  </div>
</template>

<script>
import UserDptList from '@/utils/mixins/UserDptList.js'

import EditShiftRecord from '@/views/Rms/ShiftRecord/EditShiftRecord.vue'
import { ElMessage } from 'element-plus'
export default {
    mixins:[UserDptList],
    components:{
        EditShiftRecord
    },
    data(){
        return{
            dataList:[],  //Table数据
            info:{
                page:1,
                size:50,
                total:0,  //总记录数
                id:0,  //新增、编辑 的参数
                ids:[],   //删除 的参数
                search:{
                    shiftNumber:'',  //班次单号
                    selectDepartmentList:[],
                    state:0,  //状态 
                    datePoint:'',  //时间范围 
                }
            },
            //编辑-弹出框
            BoxEditInfo:{
                isVisible:false,
                title:'班次记录'
            },
        }
    },
    computed:{
        //自适应窗体高度
        GetDataHeight(){
            return window.innerHeight-235
        }
    },
    methods:{

        //加载数据列表
        GetDataList(){
            //日期
            if(this.info.search.datePoint == null){
                this.info.search.datePoint = ''
            }

            //获取、转换部门
            let newDepartmentList = []
            if(this.info.search.selectDepartmentList!=null){
                newDepartmentList = this.info.search.selectDepartmentList.map(item=>{
                    return item
                })
            }

            let param = {
                shiftNumber:this.info.search.shiftNumber,
                departmentList:newDepartmentList,
                state:this.info.search.state,
                startDate:this.DateFormat(this.info.search.datePoint[0]),
                endDate:this.DateFormat(this.info.search.datePoint[1]),
                page:this.info.page,
                size:this.info.size
            }

            this.$api.erp.PageRetailShiftRecordList(param).then(res=>{
                if(res.data.status === 200){
                    this.dataList = res.data.data;
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

        //选中多选框
        btnCheckbox(selection){
            this.info.ids = selection.map(item=>item.shiftNumber)
        },
        //分页-页数变化时
        btnPagechange(page){
            this.info.page = page
            this.GetDataList() //加载Table数据
        },
        //分页-单页记录数变化时
        btnSizechange(size){
            this.info.size = size
            this.info.page = 1
            this.GetDataList() //加载Table数据
        },
        //新增编辑
        btnEditInfo(id){
            this.info.id = id
            if(id==0){
                this.BoxEditInfo.title = "新增班次记录"
            }else{
                this.BoxEditInfo.title = "编辑班次记录"
            }  
            this.BoxEditInfo.isVisible = true;
        },

        //删除按钮
        btnDeleteInfo(shiftNumber){
            let param = [shiftNumber]
            this.$api.erp.DeleteRetailShiftRecord(param).then(res=>{
            if(res.data.status === 200){
                ElMessage.success(res.data.msg)
                this.GetDataList()
            }
            else{
                ElMessage.error(res.data.msg)
            }
            })
        },
        //批量删除
        btnBatchDeleteInfo(){
            if(this.info.ids.length<1){
                ElMessage.error('请选择要删除的选项')
                return false
            }

            this.$api.erp.DeleteRetailShiftRecord(this.info.ids).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg)
                    this.GetDataList()
                }
                else{
                    ElMessage.error(res.data.msg)
                }
            })
        },

        //重置按钮
        btnResetSearch(){
            this.info.search.shiftNumber = '';
            this.info.search.selectDepartmentList=[],  //所属部门
            this.info.search.state=0;
            this.info.search.datePoint = '';
            this.info.ids = [];  //编号数组
            this.info.page = 1;
            this.GetDataList()
        },

        //关闭弹出框
        btnCloseBox(){
            this.BoxEditInfo.isVisible = false;
        },

    },
    mounted(){
        this.GetDataList();
    },
}
</script>

<style>

</style>