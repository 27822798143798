<template>
  <div>
    <!-- 利润排行统计 -->

    <!-- 标题 -->
    <el-row>
        <el-col :span="24" class="title">
            <h3>利润排行(按产品)</h3>
        </el-col>        
    </el-row>

    <!-- 查询条件 -->
    <div>
      <el-form :inline="true">

        <el-form-item label="大区">
          <el-select v-model="search.areaNumber" class="search-200" :clearable="true">
            <el-option v-for="areaInfo in allAreaList" :key="areaInfo.areaNumber" :label="areaInfo.areaName" :value="areaInfo.areaNumber"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="部门">
          <el-tree-select v-model="search.departmentId" :data="allDptList" :default-expand-all="true" :clearable="true" class="search-200" />
        </el-form-item>

        <el-form-item label="产品分类">
            <el-tree-select v-model="search.cidList" :data="selectCategoryList" multiple filterable :default-expand-all="true" collapse-tags collapse-tags-tooltip class="search-200"/>
        </el-form-item>

        <el-form-item label="名称">
            <el-input v-model="search.searchName" placeholder="品牌、分类、产品名称" class="search-200"></el-input>
        </el-form-item>

        <!-- <el-form-item label="品牌">
            <el-select class="search-200" :clearable="true">
                <el-option></el-option>
            </el-select>
        </el-form-item>

        <el-form-item label="产品名称">
            <el-select class="search-200" :clearable="true">
                <el-option></el-option>
            </el-select>
        </el-form-item> -->

        <el-form-item label="日期">
          <el-date-picker
              v-model="search.datePoint"
              type="daterange"
              range-separator="To"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              style="width:250px"
          />
        </el-form-item>

        <el-form-item>
          <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
          <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
        </el-form-item>
      </el-form>
    </div>

    <!-- 数据结果 -->
    <el-table :data="dataList" border show-summary stripe height="640px">
      <el-table-column prop="spuNumber" label="编号"></el-table-column>
        <el-table-column prop="cidName" label="产品分类"></el-table-column>
        <el-table-column prop="spuName" label="产品名称" sortable></el-table-column>
        <el-table-column prop="brandName" label="品牌"></el-table-column>
        <el-table-column prop="totalCostPrice" label="总成本"></el-table-column>
        <el-table-column prop="totalSalePrice" label="总销售额"></el-table-column>
        <el-table-column prop="totalProfitPrice" label="总利润" sortable></el-table-column>
        <el-table-column prop="profitRate" label="利润率" sortable>
          <template v-slot="scope">
              <span>{{this.myRound(scope.row.profitRate,2)}}</span>
              <span>%</span>
          </template>
        </el-table-column>
    </el-table>

  </div>
</template>

<script>
import AllAreaList from '@/utils/mixins/AllAreaList.js'  //全部大区列表
import AllDptList from '@/utils/mixins/AllDptList.js'  //全部部门列表
import CategoryList from '@/utils/mixins/CategoryList.js'   //产品类目列表
import { ElLoading, ElMessage } from 'element-plus'
export default {
    mixins:[AllAreaList,AllDptList,CategoryList],
    data(){
        return{
            search:{
                areaNumber:'',  //大区编号
                departmentId:0,
                cidList:[],  //产品分类
                searchName:'',   //聚合搜索
                datePoint:[this.beginDate,this.endDate],
            },
            dataList:[],    //数据列表
            allDptList:[],    //所有部门列表    
            beginDate:this.DateFormat(new Date()),   //开始日期
            endDate:this.DateFormat(new Date()),   //结束日期     
        }
    },
    methods:{
      //获取数据
      GetDataList(){
        //打开遮罩层
        const loading = ElLoading.service({
            lock:true,
            text:'正在生成数据,请稍候!',
            background:'rgba(0, 0, 0, 0.7)',
        })

        if(this.search.datePoint == null){
            this.search.datePoint = ''
        }

        let param = {
          areaNumber:this.search.areaNumber,
          departmentId:this.search.departmentId,
          cidList:this.search.cidList,
          searchName:this.search.searchName,
          startDate:this.DateFormat(this.search.datePoint[0]),   //制单日期 
          endDate:this.DateFormat(this.search.datePoint[1]),
        }
        this.$api.stat.StatProfitListBySpu(param).then(res=>{
          loading.close();
          if(res.data.status ==200){
            this.dataList = res.data.data;  //获取数据
          }else{
            ElMessage.error(res.data.msg);
          }
        })
      },

      //重置按钮
      btnResetSearch(){
          this.search.areaNumber='';
          this.search.departmentId=0;
          this.search.cidList=[];
          this.search.searchName='';
          this.search.datePoint = '';
          this.GetbeginDate();
          this.GetDataList();
      },

      //计算日期
      GetbeginDate(){
        var date = new Date();
        date.setDate(date.getDate()-7);
        var year = date.getFullYear();
        var month = (date.getMonth() + 1).toString().padStart(2,'0');
        var day = date.getDate().toString().padStart(2,'0');
        var startDate = year + '-' + month + '-'+day;
        this.beginDate = this.DateFormat(startDate);
        this.search.datePoint = [this.beginDate,this.endDate];
      },

    },
    mounted(){
      this.GetbeginDate();  //设置开始日期
      this.GetDataList();
    }

}
</script>

<style scoped>
/* 标题 */
.title{text-align: center;}
</style>