
 import base from '../base'   //导入接口域名列表
 import axios from '@/utils/http'   //导入http中创建的axios实例


 const erp = {

    //#region 通用单据接口

    //获取备注列表
    GetRecordList(param){
        return axios.get(`${base.base}/Bill/GetRecordList`,{
            params:param
        })
    },

    //获取备注列表+图片列表
    GetRecordList2(param){
        return axios.get(`${base.base}/Bill/GetRecordList2`,{
            params:param
        })
    },

    //保存备注记录
    SaveRecord(param){
        return axios.post(`${base.base}/Bill/SaveRecord`,param)
    },

    //批量保存备注
    BatchSaveRecord(param){
        return axios.post(`${base.base}/Bill/BatchSaveRecord`,param)
    },

    //删除备注记录
    DeleteRecord(param){
        return axios.post(`${base.base}/Bill/DeleteRecord`,param)
    },

    //保存文件记录
    SaveFile(param){
        return axios.post(`${base.base}/Bill/SaveFile`,param)
    },

    //删除文件记录
    DeleteFile(param){
        return axios.post({
            url:`${base.base}/Bill/DeleteFile`,
            method:'post',
            params:param
        })
    },


    //#endregion

    //#region 采购单

    //获取采购单列表
    ListPurchase(param){
        return axios.post(`${base.base}/Purchase/ListPurchase`,param)
    },

    //获取单个采购单(所有信息)
    SinglePurchaseAllInfo(param){
        return axios.get(`${base.base}/Purchase/SinglePurchaseAllInfo`,{
            params:param
        })
    },

    //保存采购单信息(所有信息)
    SavePurchaseAllInfo(param){
        return axios.post(`${base.base}/Purchase/SavePurchaseAllInfo`,param)
    },

    //删除采购单信息(所有信息)
    DeletePurchaseAllInfo(param){
        return axios.post(`${base.base}/Purchase/DeletePurchaseAllInfo`,param)
    },

    //更新采购单状态
    UpdatePurchaseState(param){
        return axios.post(`${base.base}/Purchase/UpdatePurchaseState`,param)
    },

    //采购单生成业务单
    PurchaseCreateBusiness(param){
        return axios.post(`${base.base}/Purchase/CreateBusiness`,param)
    },

    //删除采购单明细
    DeletePurchaseDetail(param){
        return axios({
            url:`${base.base}/Purchase/DeletePurchaseDetail`,
            method:'post',
            params:param
        })
    },

    //撤单-采购单
    CancelPurchase(param){
        return axios({
            url:`${base.base}/Purchase/CancelPurchase`,
            method:'post',
            params:param
        })
    },

    //采购明细列表
    GetPurchaseDetailList(param){
        return axios.get(`${base.base}/Purchase/GetPurchaseDetailList`,{
            params:param
        })
    },

    //获取制采购单时选中的产品列表
    GetPurchaseDetailListByParam(param){
        return axios.post(`${base.base}/Purchase/GetPurchaseDetailListByParam`,param)
    },

    //打印采购回执小票
    PrintPurchaseInfo(param){
        return axios.post(`${base.base}/Purchase/PrintPurchaseInfo`,param)
    },

    //#endregion

    //#region 采购单价历史记录

    //获取采购价格历史列表
    ListPurchasePrice(param){
        return axios.post(`${base.base}/HistoryPrice/ListPurchasePrice`,param)
    },

    //删除采购单单价历史记录
    DeletePurchasePrice(param){
        return axios.post(`${base.base}/HistoryPrice/DeletePurchasePrice`,param)
    },

    //导出采购单价历史记录
    ExportPurchasePrice(param){
        return axios.post(`${base.base}/HistoryPrice/ExportPurchasePrice`,param)
    },

    //#endregion

    //#region 客户管理

    //获取客户列表
    PageCustomerList(param){
        return axios.post(`${base.base}/Customer/PageCustomerList`,param)
    },

    //获取单个客户信息(包含子信息)
    SingleCustomerAllInfo(param){
        return axios.get(`${base.base}/Customer/SingleCustomerAllInfo`,{
            params:param
        })
    },

    //保存单个客户信息(包含子信息)
    SaveCustomerAllInfo(param){
        return axios.post(`${base.base}/Customer/SaveCustomerAllInfo`,param)
    },

    //删除客户信息(包含子信息)
    DeleteCustomerAllInfo(param){
        return axios.post(`${base.base}/Customer/DeleteCustomerAllInfo`,param)
    },

    //删除客户单个发票信息
    DeleteCustomerInvoice(param){
        return axios({
            url:`${base.base}/Customer/DeleteCustomerInvoice`,
            method:'post',
            params:param
        })
    },

    //导出客户信息
    ExportCustomerList(param){
        return axios.post(`${base.base}/Customer/ExportCustomerList`,param)
    },

    //#endregion

    //#region 销售单

    //获取销售单列表
    ListSale(param){
        return axios.post(`${base.base}/Sale/ListSale`,param)
    },

    //获取单个销售单(包含子信息)
    SingleSaleAllInfo(param){
        return axios.get(`${base.base}/Sale/SingleSaleAllInfo`,{
            params:param
        })
    },

    //保存销售单
    SaveSaleAllInfo(param){
        return axios.post(`${base.base}/Sale/SaveSaleAllInfo`,param)
    },

    //删除销售单(包含子信息)
    DeleteSaleAllInfo(param){
        return axios.post(`${base.base}/Sale/DeleteSaleAllInfo`,param)
    },

    //删除销售单明细
    DeleteSaleDetail(param){
        return axios({
            url:`${base.base}/Sale/DeleteSaleDetail`,
            method:'post',
            params:param
        })
    },

    //更新销售单状态
    UpdateSaleState(param){
        return axios.post(`${base.base}/Sale/UpdateSaleState`,param)
    },

    //销售单生成业务单
    SaleCreateBusiness(param){
        return axios.post(`${base.base}/Sale/CreateBusiness`,param)
    },

    //销售明细列表
    GetSaleDetailList(param){
        return axios.get(`${base.base}/Sale/GetSaleDetailList`,{
            params:param
        })
    },

    //撤销销售单
    CancelSale(param){
        return axios({
            url:`${base.base}/Sale/CancelSale`,
            method:'post',
            params:param
        })
    },
    
    //加载销售单选中的产品列表
    GetSaleDetailListByParam(param){
        return axios.post(`${base.base}/Sale/GetSaleDetailListByParam`,param)
    },

    //打印销售-小票
    PrintSaleInfo(param){
        return axios.post(`${base.base}/Sale/PrintSaleInfo`,param)
    },
    //打印配货-小票
    PrintSaleOutStock(param){
        return axios.post(`${base.base}/Sale/PrintSaleOutStock`,param)
    },

    //#endregion

    //#region 零售班组

    //班组列表
    PageRetailGroupList(param){
        return axios.post(`${base.base}/RetailGroup/PageRetailGroupList`,param)
    },

    //用户授权部门下的班组列表
    GetUserRetailGroupList(){
        return axios.get(`${base.base}/RetailGroup/GetUserRetailGroupList`)
    },

    //单个班组信息(包含成员列表)
    GetRetailGroupAllInfo(param){
        return axios.get(`${base.base}/RetailGroup/GetRetailGroupAllInfo`,{
            params:param
        })
    },

    //保存班组
    SaveRetailGroupAllInfo(param){
        return axios.post(`${base.base}/RetailGroup/SaveRetailGroupAllInfo`,param)
    },

    //删除班组
    DeleteRetailGroupByNum(param){
        return axios.post(`${base.base}/RetailGroup/DeleteRetailGroupByNum`,param)
    },

    //删除班组成员
    DeleteRetailGroupById(param){
        return axios({
            url:`${base.base}/RetailGroup/DeleteRetailGroupById`,
            method:'post',
            params:param
        })
    },

    //#endregion

    //#region 班次记录

    //获取班次列表
    PageRetailShiftRecordList(param){
        return axios.post(`${base.base}/RetailShiftRecord/PageRetailShiftRecordList`,param)
    },

    //获取单个信息
    GetRetailShiftRecordAllInfo(param){
        return axios.get(`${base.base}/RetailShiftRecord/GetRetailShiftRecordAllInfo`,{
            params:param
        })
    },

    //保存班次
    SaveRetailShiftRecordAllInfo(param){
        return axios.post(`${base.base}/RetailShiftRecord/SaveRetailShiftRecordAllInfo`,param)
    },

    //删除班次
    DeleteRetailShiftRecord(param){
        return axios.post(`${base.base}/RetailShiftRecord/DeleteRetailShiftRecord`,param)
    },

    //获取用户授权班次列表-下拉列表专用
    GetShiftRecordList(){
        return axios.get(`${base.base}/RetailShiftRecord/GetShiftRecordList`)
    },

    //更新班次状态
    UpdateShiftRecordState(param){
        return axios.post(`${base.base}/RetailShiftRecord/UpdateShiftRecordState`,param)
    },

    //统计指定班次的各账户金额(作废)
    StatRetailShiftRecordDetailList(param){
        return axios.get(`${base.base}/RetailShiftRecord/StatRetailShiftRecordDetailList`,{
            params:param
        })
    },

    //保存指定班次的各账户金额(作废)
    SaveRetailShiftRecordDetail(param){
        return axios.post(`${base.base}/RetailShiftRecord/SaveRetailShiftRecordDetail`,param)
    },

    //统计班次各账户和各单据信息
    StatShiftInfo(param){
        return axios.get(`${base.base}/RetailShiftRecord/StatShiftInfo`,{
            params:param
        })
    },

    //保存班次各账户和各单据信息
    SaveStatShiftInfo(param){
        return axios.post(`${base.base}/RetailShiftRecord/SaveStatShiftInfo`,param)
    },

    //#endregion

    //#region 零售单(线下)

    //获取列表
    PageRetailList(param){
        return axios.post(`${base.base}/Retail/PageRetailList`,param)
    },

    //获取单个信息
    GetRetailAllInfo(param){
        return axios.get(`${base.base}/Retail/GetRetailAllInfo`,{
            params:param
        })
    },

    //保存
    SaveRetailAllInfo(param){
        return axios.post(`${base.base}/Retail/SaveRetailAllInfo`,param)
    },

    //删除单个所有
    DeleteRetailAllInfo(param){
        return axios.post(`${base.base}/Retail/DeleteRetailAllInfo`,param)
    },

    //删除明细
    DeleteRetailDetail(param){
        return axios({
            url:`${base.base}/Retail/DeleteRetailDetail`,
            method:'post',
            params:param
        })
    },

    //零售单生成业务单
    CreateRetailBusiness(param){
        return axios.post(`${base.base}/Retail/CreateBusiness`,param)
    },

    //更新状态
    UpdateRetailState(param){
        return axios.post(`${base.base}/Retail/UpdateRetailState`,param)
    },

    //零售单退款
    RefundRetail(param){
        return axios({
            url:`${base.base}/Retail/RefundRetail`,
            method:'post',
            params:param
        })
    },

    //零售单创建销售单
    SaveRetailToSale(param){
        return axios({
            url:`${base.base}/Retail/SaveRetailToSale`,
            method:'post',
            params:param
        })
    },

    //小票机打印零售单 - 销售小票
    PrintRetailInfo(param){
        return axios.post(`${base.base}/Retail/PrintRetailInfo`,param)
    },
    //小票打印零售单 - 配货小票
    PrintRetailOutStock(param){
        return axios.post(`${base.base}/Retail/PrintRetailOutStock`,param)
    },

    //导出零售单
    ExportRetail(param){
        return axios.post(`${base.base}/Retail/ExportRetail`,param)
    },

    //#endregion

    //#region 退零单

    //列表
    PageReturnRetailList(param){
        return axios.post(`${base.base}/ReturnRetail/PageReturnRetailList`,param)
    },

    //单个信息
    GetReturnRetailAllInfo(param){
        return axios.get(`${base.base}/ReturnRetail/GetReturnRetailAllInfo`,{
            params:param
        })
    },

    //单个信息(零售单号查询)
    GetReturnRetailAllInfoByRetailNumber(param){
        return axios.get(`${base.base}/ReturnRetail/GetReturnRetailAllInfoByRetailNumber`,{
            params:param
        })
    },

    //保存
    SaveReturnRetailAllInfo(param){
        return axios.post(`${base.base}/ReturnRetail/SaveReturnRetailAllInfo`,param)
    },

    //删除单据
    DeleteReturnRetailAllInfo(param){
        return axios.post(`${base.base}/ReturnRetail/DeleteReturnRetailAllInfo`,param)
    },

    //删除单据明细
    DeleteReturnRetailDetail(param){
        return axios({
            url:`${base.base}/ReturnRetail/DeleteReturnRetailDetail`,
            method:'post',
            params:param
        })
    },

    //更新状态
    UpdateReturnRetailState(param){
        return axios.post(`${base.base}/ReturnRetail/UpdateReturnRetailState`,param)
    },

    //退零单退款
    RefundReturnRetail(param){
        return axios({
            url:`${base.base}/ReturnRetail/RefundReturnRetail`,
            method:'post',
            params:param
        })
    },

    //导出退零单
    ExportReturnRetail(param){
        return axios.post(`${base.base}/ReturnRetail/ExportReturnRetail`,param)
    },

    //#endregion

    //#region 调账记录

    //列表
    PageRetailAdjustRecordList(param){
        return axios.post(`${base.base}/RetailAdjustRecord/PageRetailAdjustRecordList`,param)
    },

    //获取单个信息
    GetRetailAdjustRecord(param){
        return axios.get(`${base.base}/RetailAdjustRecord/GetRetailAdjustRecord`,{
            params:param
        })
    },

    //保存调账(人工创建专用)
    SaveRetailAdjustRecord(param){
        return axios.post(`${base.base}/RetailAdjustRecord/SaveRetailAdjustRecord`,param)
    },

    //保存调账(系统创建专用,可以覆盖原来的)
    SaveRetailAdjustRecordBySys(param){
        return axios.post(`${base.base}/RetailAdjustRecord/SaveRetailAdjustRecordBySys`,param)
    },

    //删除调账
    DeleteRetailAdjustRecord(param){
        return axios.post(`${base.base}/RetailAdjustRecord/DeleteRetailAdjustRecord`,param)
    },

    //更新调账状态
    UpdateRetailAdjustRecordState(param){
        return axios.post(`${base.base}/RetailAdjustRecord/UpdateRetailAdjustRecordState`,param)
    },

    //#endregion

    //#region 换钱记录

    //列表
    PageRetailSwapRecordList(param){
        return axios.post(`${base.base}/RetailSwapRecord/PageRetailSwapRecordList`,param)
    },

    //单个
    GetRetailSwapRecord(param){
        return axios.get(`${base.base}/RetailSwapRecord/GetRetailSwapRecord`,{
            params:param
        })
    },

    //保存
    SaveRetailSwapRecord(param){
        return axios.post(`${base.base}/RetailSwapRecord/SaveRetailSwapRecord`,param)
    },


    //#endregion

    //#region 销售单价历史记录

    //获取销售价格历史列表
    ListSalePrice(param){
        return axios.post(`${base.base}/SaleHistoryPrice/ListSalePrice`,param)
    },

    //删除销售单单价历史记录
    DeleteSalePrice(param){
        return axios.post(`${base.base}/SaleHistoryPrice/DeleteSalePrice`,param)
    },

    //导出销售单价历史记录
    ExportSalePrice(param){
        return axios.post(`${base.base}/SaleHistoryPrice/ExportSalePrice`,param)
    },

    //#endregion

    //#region 加工组

    //分页列表
    PageWorkGroupList(param){
        return axios.post(`${base.base}/WorkGroup/PageWorkGroupList`,param)
    },

    //单个
    GetWorkGroupAllInfo(param){
        return axios.get(`${base.base}/WorkGroup/GetWorkGroupAllInfo`,{
            params:param
        })
    },

    //保存
    SaveWorkGroupAllInfo(param){
        return axios.post(`${base.base}/WorkGroup/SaveWorkGroupAllInfo`,param)
    },

    //删除加工组
    DeleteWorkGroupList(param){
        return axios.post(`${base.base}/WorkGroup/DeleteWorkGroupList`,param)
    },

    //删除加工组成员
    DeleteWorkGroupMember(param){
        return axios({
            url:`${base.base}/WorkGroup/DeleteWorkGroupMember`,
            method:'post',
            params:param
        })
    },
    
    //加工组列表-下拉列表
    GetWorkGroupList(param){
        return axios.post(`${base.base}/WorkGroup/GetWorkGroupList`,param)
    },

    //#endregion

    //#region 加工单

    //获取列表
    ListWork(param){
        return axios.post(`${base.base}/Work/ListWork`,param)
    },

    //获取单个信息(包含子信息)
    SingleWorkAllInfo(param){
        return axios.get(`${base.base}/Work/SingleWorkAllInfo`,{
            params:param
        })
    },

    //保存加工单(包含子信息)    
    SaveWorkAllInfo(param){
        return axios.post(`${base.base}/Work/SaveWorkAllInfo`,param)
    },

    //保存退料(仅保存退料)
    SaveWorkWaste(param){
        return axios.post(`${base.base}/Work/SaveWorkWaste`,param)
    },

    //删除加工单
    DeleteWorkAllInfo(param){
        return axios.post(`${base.base}/Work/DeleteWorkAllInfo`,param)
    },

    //删除加工单-物料记录
    DeleteWorkMaterialById(param){
        return axios({
            url:`${base.base}/Work/DeleteWorkMaterialById`,
            method:'post',
            params:param
        })
    },

    //删除加工单-成品记录
    DeleteWorkGoodsById(param){
        return axios({
            url:`${base.base}/Work/DeleteWorkGoodsById`,
            method:'post',
            params:param
        })
    },

    //删除加工单-退料记录
    DeleteWorkWasteById(param){
        return axios({
            url:`${base.base}/Work/DeleteWorkWasteById`,
            method:'post',
            params:param
        })
    },

    //更新加工单状态
    UpdateWorkState(param){
        return axios({
            url:`${base.base}/Work/UpdateWorkState`,
            method:'post',
            params:param
        })
    },

    //保存成品生产记录
    SaveWorkGoodsRecord(param){
        return axios.post(`${base.base}/Work/SaveWorkGoodsRecord`,param)
    },

    //导出加工单
    ExportWork(param){
        return axios.post(`${base.base}/Work/ExportWork`,param)
    },

    //撤销功能
    CancelWork(param){
        return axios({
            url:`${base.base}/Work/CancelWork`,
            method:'post',
            params:param
        })
    },

    //加载加工单选中的物料列表
    GetWorkMaterialDetailListByParam(param){
        return axios.post(`${base.base}/Work/GetWorkMaterialDetailListByParam`,param)
    },

    //#endregion

    //#region 差额单

    //列表
    PageDifferenceList(param){
        return axios.post(`${base.base}/Difference/PageDifferenceList`,param)
    },
    
    //单个记录
    SingleDifferenceAllInfo(param){
        return axios.get(`${base.base}/Difference/SingleDifferenceAllInfo`,{
            params:param
        })
    },

    //保存
    SaveDifferenceAllInfo(param){
        return axios.post(`${base.base}/Difference/SaveDifferenceAllInfo`,param)
    },

    //删除
    DeleteDifference(param){
        return axios.post(`${base.base}/Difference/DeleteDifference`,param)
    },

    //更新状态
    UpdateDifferenceState(param){
        return axios.post(`${base.base}/Difference/UpdateDifferenceState`,param)
    },

    //差额单生成业务单
    CreateBusiness(param){
        return axios.post(`${base.base}/Difference/CreateBusiness`,param)
    },

    //#endregion

    //#region 业务单(明细式)

    //获取业务单列表
    PageBusinessList(param){
        return axios.post(`${base.base}/Business/PageBusinessList`,param)
    },

    //获取单个业务单所有信息(业务单+原始单据状态列表+备注列表)
    SingleBusinessAllInfo(param){
        return axios.get(`${base.base}/Business/SingleBusinessAllInfo`,{
            params:param
        })
    },

    //保存单个业务单信息
    SaveBusinessInfo(param){
        return axios.post(`${base.base}/Business/SaveBusinessInfo`,param)
    },

    //业务单生成财务单检查
    CheckCreateFinance(param){
        return axios.post(`${base.base}/Business/CheckCreateFinance`,param)
    },

    //业务单生成财务单
    CreateFinance(param){
        return axios.post(`${base.base}/Business/CreateFinance`,param)
    },

    //获取交易者列表(供应商+客户+员工)(全部)
    SelectAllTradeList(){
        let param = {
            isShow:0
        }
        return axios.get(`${base.base}/Business/SelectAllTradeList`,{
            params:param
        })
    },

    //获取交易者列表(供应商+客户+员工)(部分)
    SelectPartTradeList(){
        let param = {
            isShow:1
        }
        return axios.get(`${base.base}/Business/SelectAllTradeList`,{
            params:param
        })
    },

    //业务单导出Excel
    ExportBusiness(param){
        return axios.post(`${base.base}/Business/ExportBusiness`,param)
    },

    //业务单生成业务对账单
    CreateCycleBusiness(param){
        return axios.post(`${base.base}/Business/CreateCycleBusiness`,param)
    },
    
    //#endregion

    //#region 业务单(单据式)

    //列表
    PageBusinessOriginList(param){
        return axios.post(`${base.base}/Business/PageBusinessOriginList`,param)
    },

    //单个
    GetBusinessOriginAllInfo(param){
        return axios.get(`${base.base}/Business/GetBusinessOriginAllInfo`,{
            params:param
        })
    },

    //保存
    SaveBusinessOriginAllInfo(param){
        return axios.post(`${base.base}/Business/SaveBusinessOriginAllInfo`,param)
    },


    //#endregion

    //#region 业务对账单

    //列表
    PageCycleBusinessList(param){
        return axios.post(`${base.base}/CycleBusiness/PageCycleBusinessList`,param)
    },

    //获取单个业务对账单
    GetCycleAllInfo(param){
        return axios.get(`${base.base}/CycleBusiness/GetCycleAllInfo`,{
            params:param
        })
    },

    //保存业务对账单(防重复校验)
    SaveCycleBusinessAllInfo(param,header){
        return axios.post(`${base.base}/CycleBusiness/SaveCycleBusinessAllInfo`,param,{
            headers:header
        })
    },

    //添加到业务对账单(业务单专用)
    AddCycleBusinessAllInfo(param){
        return axios.post(`${base.base}/CycleBusiness/AddCycleBusinessAllInfo`,param)
    },

    //删除业务对账单(包含明细)
    DeleteCycleBusiness(param){
        return axios.post(`${base.base}/CycleBusiness/DeleteCycleBusiness`,param)
    },

    //删除对账单关联单据
    DeleteCycleBusinessOrigin(param){
        return axios({
            url:`${base.base}/CycleBusiness/DeleteCycleBusinessOrigin`,
            method:'post',
            params:param
        })
    },

    //业务对账单-下拉列表
    SelectCycleBusinessList(){
        return axios.get(`${base.base}/CycleBusiness/SelectCycleBusinessList`)
    },

    //复制到剪贴板
    CopyShareLink(param){
        return axios.get(`${base.base}/CycleBusiness/CopyShareLink`,{
            params:param
        })
    },

    //#endregion 


    //#region 物流单

    //获取物流单列表
    ListTransport(param){
        return axios.post(`${base.base}/Transport/ListTransport`,param)
    },

    //获取单个物流单信息(所有信息)
    SingleTransportAllInfo(param){
        return axios.get(`${base.base}/Transport/SingleTransportAllInfo`,{
            params:param
        })
    },

    //保存物流单信息(所有信息)
    SaveTransportAllInfo(param){
        return axios.post(`${base.base}/Transport/SaveTransportAllInfo`,param)
    },

    //更新物流单状态
    UpdateTransportState(param){
        return axios.post(`${base.base}/Transport/UpdateTransportState`,param)
    },

    //删除物流单
    DeleteTransportAllInfo(param){
        return axios.post(`${base.base}/Transport/DeleteTransportAllInfo`,param)
    },

    //获取当前用户授权下的物流单列表
    SelectTransportList(param){
        return axios.get(`${base.base}/Transport/SelectTransportList`,{
            params:param
        })
    },

    
    //保存物流单关联原始单信息
    SaveTransportOrigin(param){
        return axios.post(`${base.base}/Transport/SaveTransportOrigin`,param)
    },

    //删除物流单关联原始单
    DeleteTransportOrigin(param){
        return axios.post(`${base.base}/Transport/DeleteTransportOrigin`,param)
    },

    //#endregion
    
    //#region 车辆管理

    //列表
    ListCar(param){
        return axios.post(`${base.base}/Car/ListCar`,param)
    },

    //获取单个信息
    SingleCarInfo(param){
        return axios.get(`${base.base}/Car/SingleCarInfo`,{
            params:param
        })
    },

    //保存车辆
    SaveCarInfo(param){
        return axios.post(`${base.base}/Car/SaveCarInfo`,param)
    },

    //删除车辆
    DeleteCarInfo(param){
        return axios.post(`${base.base}/Car/DeleteCarInfo`,param)
    },

    //#endregion

    //#region 计划单

    //获取计划单列表
    ListPlan(param){
        return axios.post(`${base.base}/Plan/ListPlan`,param)
    },

    //获取单个计划单信息
    SinglePlanAll(param){
        return axios.get(`${base.base}/Plan/SinglePlanAll`,{
            params:param
        })
    },

    //保存计划单
    SavePlanAllInfo(param){
        return axios.post(`${base.base}/Plan/SavePlanAllInfo`,param)
    },

    //删除计划单
    DeletePlan(param){
        return axios.post(`${base.base}/Plan/DeletePlan`,param)
    },

    //更新计划单状态
    UpdatePlanByState(param){
        return axios({
            url:`${base.base}/Plan/UpdatePlanByState`,
            method:'post',
            params:param
        })
    },

    //计划单生成采购单
    CreatePurchase(param){
        return axios.post(`${base.base}/Plan/CreatePurchase`,param)
    },

    //#endregion
    
    //#region 退货单

    //获取列表
    ListReturnSale(param){
        return axios.post(`${base.base}/ReturnSale/ListReturnSale`,param)
    },

    //获取单个信息
    SingleReturnSaleAllInfo(param){
        return axios.get(`${base.base}/ReturnSale/SingleReturnSaleAllInfo`,{
            params:param
        })
    },

    //保存退货单
    SaveReturnSaleAllInfo(param){
        return axios.post(`${base.base}/ReturnSale/SaveReturnSaleAllInfo`,param)
    },

    //删除退货单所有信息
    DeleteReturnSaleAllInfo(param){
        return axios.post(`${base.base}/ReturnSale/DeleteReturnSaleAllInfo`,param)
    },

    //删除退货单明细
    DeleteReturnSaleDetail(param){
        return axios({
            url:`${base.base}/ReturnSale/DeleteReturnSaleDetail`,
            method:'post',
            params:param
        })
    },

    //更新退货单状态
    UpdateReturnSaleByState(param){
        return axios.post(`${base.base}/ReturnSale/UpdateReturnSaleByState`,param)
    },

    //创建业务单
    ReturnSaleCreateBusiness(param){
        return axios.post(`${base.base}/ReturnSale/CreateBusiness`,param)
    },

    //撤销退货单
    CancelReturnSale(param){
        return axios({
            url:`${base.base}/ReturnSale/CancelReturnSale`,
            method:'post',
            params:param
        })
    },

    //#endregion

    //#region 退供单

    //获取列表
    ListReturnPurchase(param){
        return axios.post(`${base.base}/ReturnPurchase/ListReturnPurchase`,param)
    },

    //获取单个信息
    SingleReturnPurchaseAllInfo(param){
        return axios.get(`${base.base}/ReturnPurchase/SingleReturnPurchaseAllInfo`,{
            params:param
        })
    },
    
    //保存
    SaveReturnPurchaseAllInfo(param){
        return axios.post(`${base.base}/ReturnPurchase/SaveReturnPurchaseAllInfo`,param)
    },

    //删除所有信息
    DeleteReturnPurchaseAllInfo(param){
        return axios.post(`${base.base}/ReturnPurchase/DeleteReturnPurchaseAllInfo`,param)
    },

    //删除退供单明细
    DeleteReturnPurchaseDetail(param){
        return axios({
            url:`${base.base}/ReturnPurchase/DeleteReturnPurchaseDetail`,
            method:'post',
            params:param
        })
    },

    //更新退供单状态
    UpdateReturnPurchaseByState(param){
        return axios.post(`${base.base}/ReturnPurchase/UpdateReturnPurchaseByState`,param)
    },

    //创建业务单
    ReturnPurchaseCreateBusiness(param){
        return axios.post(`${base.base}/ReturnPurchase/CreateBusiness`,param)
    },

    //撤销退供单
    CancelReturnPurchase(param){
        return axios({
            url:`${base.base}/ReturnPurchase/CancelReturnPurchase`,
            method:'post',
            params:param
        })
    },

    //#endregion


    //#region 分享

    //创建分享记录
    SaveShareRecord(param){
        return axios({
            url:`${base.base}/ShareSys/SaveShareRecord`,
            method:'post',
            params:param
        })
    },

    //#endregion

    //#region 首页统计

    //首页统计
    GetHomeDataInfo(param){
        return axios.get(`${base.base}/Stat/GetHomeDataInfo`,{
            params:param
        })
    },

    //#endregion

 }


 export default erp