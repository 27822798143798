<template>
  <div>
    <el-divider></el-divider>

    <!-- 添加到分享单 -->
    <el-form :inline="true"
    v-model="selectedInfo"
    label-width="100px"
    label-position="right">
        <el-row v-if="selectedInfo">

            <el-col :span="24">
                <el-form-item label="选择单位">
                    <el-radio-group v-model="selectedInfo.unitType">
                        <el-radio-button :value="1">默认单位</el-radio-button>
                        <el-radio-button :value="2">最小单位</el-radio-button>
                    </el-radio-group>
                </el-form-item>
            </el-col>

            <el-col :span="24">
                <el-form-item label="选择价格">
                    <el-radio-group v-model="selectedInfo.priceType">
                        <el-radio-button :value="1">零售价</el-radio-button>
                        <el-radio-button :value="2">批发价</el-radio-button>
                        <el-radio-button :value="3">VIP价</el-radio-button>
                    </el-radio-group>
                </el-form-item>
            </el-col>

        </el-row>
    </el-form>

    <!-- 搜索功能 -->
    <el-row>
        <el-col :span="24">
            <el-form :inline="true">

                <el-form-item label="部门">
                    <el-tree-select v-model="info.search.selectDepartmentList" :data="departmentList" multiple clearable :default-expand-all="true" collapse-tags collapse-tags-tooltip class="search-200">
                    </el-tree-select>
                </el-form-item>

                <el-form-item label="大区">
                    <el-select v-model="info.search.areaNumberList" multiple clearable collapse-tags collapse-tags-tooltip class="search-200">
                        <el-option v-for="userArea in userAreaList" :key="userArea.areaNumber" :label="userArea.areaName" :value="userArea.areaNumber"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="产品类目">
                    <el-tree-select v-model="info.search.cidList" :data="selectCategoryList" :default-expand-all="true" show-checkbox multiple filterable collapse-tags collapse-tags-tooltip class="search-200"/>
                </el-form-item>

                <el-form-item label="名称">
                    <el-input v-model="info.search.searchName" placeholder="分类、产品名称、品牌" class="search-200"></el-input>
                </el-form-item>

                <el-form-item>
                    <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
                    <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
                </el-form-item>

            </el-form>
        </el-col>
    </el-row>

    <el-table :data="dataList" border stripe height="400px" @select="btnCheckbox" @select-all="btnAllCheckbox" @row-click="TableRowClick" ref="table">
        <el-table-column prop="id" type="selection"></el-table-column>
        <el-table-column prop="cidName" label="产品类目" min-width="90"></el-table-column>
        <el-table-column prop="brandName" label="品牌" min-width="80"></el-table-column>
        <el-table-column prop="spuName" label="产品名称" min-width="90">
            <template v-slot="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.spuName"
                    placement="top">
                    <div class="cellOverFlow">{{scope.row.spuName}}</div>
                </el-tooltip>
            </template>
        </el-table-column>
        <el-table-column prop="skuName" label="规格名称" min-width="90"/>

        <el-table-column label="默认单位" width="85">
            <template #header>
                <div class="defPrice">默认单位</div>
            </template>
            <template v-slot="scope">
                <span>{{scope.row.defUnitName}}</span>
            </template>
        </el-table-column>

        <el-table-column label="成本价" width="115">
            <template #header>
                <div>成本价</div>
                <div class="defPrice PriceSize">(默认单位)</div>
            </template>
            <template v-slot="scope">
                <el-input-number v-if="scope.row.isEdit==1" v-model="scope.row.defCostPrice" :precision="2" :controls="false" style="width:90px"></el-input-number>
                <span v-else>{{this.myRound(scope.row.defCostPrice,2)}}</span>
            </template>
        </el-table-column>

        <el-table-column label="零售价"  width="115">
            <template #header>
                <div>零售价</div>
                <div class="defPrice PriceSize">(默认单位)</div>
            </template>
            <template v-slot="scope">
                <el-input-number v-if="scope.row.isEdit==1" v-model="scope.row.defRetailPrice" :precision="2" :controls="false" style="width:90px"></el-input-number>
                <span v-else>{{scope.row.defRetailPrice}}</span>
            </template>
        </el-table-column>

        <el-table-column  label="批发价"  width="115">
            <template #header>
                <div>批发价</div>
                <div class="defPrice PriceSize">(默认单位)</div>
            </template>
            <template v-slot="scope">
                <el-input-number v-if="scope.row.isEdit==1" v-model="scope.row.defWholePrice" :precision="2" :controls="false" style="width:90px"></el-input-number>
                <span v-else>{{scope.row.defWholePrice}}</span>
            </template>
        </el-table-column>

        <el-table-column  label="VIP价"  width="115">
            <template #header>
                <div>VIP价</div>
                <div class="defPrice PriceSize">(默认单位)</div>
            </template>
            <template v-slot="scope">
                <el-input-number v-if="scope.row.isEdit==1" v-model="scope.row.defVipPrice" :precision="2" :controls="false" style="width:90px"></el-input-number>
                <span v-else>{{scope.row.defVipPrice}}</span>
            </template>
        </el-table-column>

        <el-table-column label="最小单位" width="85">
            <template #header>
                <div class="minPrice">最小单位</div>
            </template>
            <template v-slot="scope">
                <span>{{scope.row.minUnitName}}</span>
            </template>
        </el-table-column>
        <el-table-column label="成本价" width="115">
            <template #header>
                <div>成本价</div>
                <div class="minPrice PriceSize">(最小单位)</div>
            </template>
            <template v-slot="scope">
                <el-input-number v-if="scope.row.isEdit==1" v-model="scope.row.minCostPrice" :precision="2" :controls="false" style="width:90px"></el-input-number>
                <span v-else>{{this.myRound(scope.row.minCostPrice,2)}}</span>
            </template>
        </el-table-column>
        <el-table-column label="零售价" width="115">
            <template #header>
                <div>零售价</div>
                <div class="minPrice PriceSize">(最小单位)</div>
            </template>
            <template v-slot="scope">
                <el-input-number v-if="scope.row.isEdit==1" v-model="scope.row.minRetailPrice" :precision="2" :controls="false" style="width:90px"></el-input-number>
                <span v-else>{{scope.row.minRetailPrice}}</span>
            </template>
        </el-table-column>
        <el-table-column  label="批发价" width="115">
            <template #header>
                <div>批发价</div>
                <div class="minPrice PriceSize">(最小单位)</div>
            </template>
            <template v-slot="scope">
                <el-input-number v-if="scope.row.isEdit==1" v-model="scope.row.minWholePrice" :precision="2" :controls="false" style="width:90px"></el-input-number>
                <span v-else>{{scope.row.minWholePrice}}</span>
            </template>
        </el-table-column>

        <el-table-column  label="VIP价" width="115">
            <template #header>
                <div>VIP价</div>
                <div class="minPrice PriceSize">(最小单位)</div>
            </template>
            <template v-slot="scope">
                <span>{{scope.row.minVipPrice}}</span>
            </template>
        </el-table-column>
    </el-table>

    <el-affix position="bottom">
        <el-row class="page_two">
            <el-col :span="24">
                <!-- 分页 -->
                <el-container class="page_right">
                    <el-pagination
                    v-model:currentPage="info.page"
                    v-model:page-size="info.size"
                    :page-sizes="[50, 200, 500]"
                    :background="true"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="info.total"
                    @current-change="btnPagechange"
                    @size-change="btnSizechange"
                    >
                    </el-pagination>
                </el-container>
            </el-col>
        </el-row>        
    </el-affix>

  </div>
</template>

<script>
import UserDptList from '@/utils/mixins/UserDptList.js'   //用户关联部门列表
import UserAreaList from '@/utils/mixins/UserAreaList.js'  //用户关联大区列表
import CategoryList from '@/utils/mixins/CategoryList.js'  //产品类目
import { ElMessage } from 'element-plus'

export default {
    mixins:[UserDptList,UserAreaList,CategoryList],
    data(){
        return{
            dataList:[],  //数据列表
            info:{
                page:1,
                size:50,
                total:0,  //总记录数
                id:0,  //新增、编辑 的参数
                ids:[],   //删除 的参数
                search:{
                    selectDepartmentList:[],  //部门
                    areaNumberList:[],   //大区列表
                    cidList:[],  //产品类目
                    searchName:'',    //聚合查询
                },
            },
            selectedInfo:{
                unitType:1,
                priceType:1,
            },

            selectedItem:[],  //选中的明细列表
        }
    },
    methods:{
        //加载数据列表
        GetDataList(){

            //获取、转换部门
            let newDepartmentList = []
            if(this.info.search.selectDepartmentList!=null){
                newDepartmentList = this.info.search.selectDepartmentList.map(item=>{
                    return item
                })
            }

            let param = {
                departmentList:newDepartmentList,
                areaNumberList:this.info.search.areaNumberList,  //大区
                cidList:this.info.search.cidList,
                searchName:this.info.search.searchName,
                page:this.info.page,
                size:this.info.size
            }
            this.$api.finance.PagePriceQuoteList(param).then(res=>{
                if(res.data.status === 200){
                    this.dataList = res.data.data;
                    //分页
                    this.info.total = res.data.total;
                    this.info.page = res.data.pageNumber;
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

        //选中多选框
        btnCheckbox(row){
            this.selectedItem = row
        },
        //全选
        btnAllCheckbox(){
            this.selectedItem = this.info.dataList
        },
        //分页-页数变化时
        btnPagechange(page){
            this.info.page = page
            this.GetDataList() //加载Table数据
        },
        //分页-单页记录数变化时
        btnSizechange(size){
            this.info.size = size
            this.info.page = 1
            this.GetDataList() //加载Table数据
        },

        //重置按钮
        btnResetSearch(){
            this.info.search.selectDepartmentList = [];
            this.info.search.areaNumberList=[];
            this.info.search.cidList=[];
            this.info.search.searchName="";   //聚合查询
            this.info.ids = [];  //编号数组
            this.info.page = 1;
            this.GetDataList()
        }, 

        //选中行时(标准模板)
        TableRowClick(row){
            let isExist = false; //判断是否存在
            this.selectedItem = this.selectedItem.filter(item=>{                
                if(row.id != item.id){
                    return item;
                }else{                    
                    //如果存在
                    isExist = true;
                }                
            })
            
            if(isExist){
                this.$refs.table.toggleRowSelection(row,false);
            }else{
                this.selectedItem.push(row)
                this.$refs.table.toggleRowSelection(row,true);
            }
        },

    },
    mounted(){
        this.GetDataList();
    },
}
</script>

<style scoped>
.defPrice{color: rgb(57, 116, 245);}
.minPrice{color: red;}
.PriceSize{font-size: 12px;}
</style>