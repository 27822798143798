<template>
  <div>

    <!-- 搜索功能 -->
    <el-row>
        <el-col :span="24">
            <el-form :inline="true">
            
            <el-form-item label="入库单号">
                <el-input v-model="info.search.inNumber" class="search-150"></el-input>
            </el-form-item>

            <el-form-item label="部门">
                <el-tree-select v-model="info.search.selectDepartmentList" :data="allDptList" multiple clearable :default-expand-all="true" collapse-tags collapse-tags-tooltip class="search-200">
                </el-tree-select>
            </el-form-item>

            <el-form-item label="大区">
                <el-select v-model="info.search.areaNumberList" multiple clearable collapse-tags collapse-tags-tooltip class="search-200">
                    <el-option v-for="userArea in userAreaList" :key="userArea.areaNumber" :label="userArea.areaName" :value="userArea.areaNumber"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="交易者">
                <el-select v-model="info.search.tradeNumber" filterable clearable class="search-200">
                    <el-option v-for="trade in allTradeList" :key="trade.tradeNumber" :label="trade.tradeName+ '(' + trade.tradeTypeName+')'" :value="trade.tradeNumber" />
                </el-select>
            </el-form-item>

            <el-form-item label="原始单据类型">
                <el-select v-model="info.search.originType" class="search-150">
                    <el-option :value="0" label="全部"></el-option>
                    <el-option :value="1" label="采购单"></el-option>
                    <el-option :value="6" label="加工单"></el-option>
                    <el-option :value="13" label="移仓单"></el-option>
                    <el-option :value="15" label="退货单"></el-option>
                    <el-option :value="20" label="入库单"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="原始单号">
                <el-input v-model="info.search.originNumber" class="search-150"></el-input>
            </el-form-item>

            <el-form-item label="状态">
                <el-select v-model="info.search.state" class="search-150">
                    <el-option :value="0" label="全部"></el-option>
                    <el-option :value="1" label="新建单"></el-option>
                    <el-option :value="2" label="入库中"></el-option>
                    <el-option :value="3" label="已入库"></el-option>
                    <el-option :value="10" label="已取消"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="入库日期">
                <el-date-picker
                    v-model="info.search.datePoint"
                    type="daterange"
                    range-separator="To"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    style="width:250px"
                />
            </el-form-item>

            <el-form-item>
                <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
                <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
            </el-form-item>

            </el-form>
        </el-col>
    </el-row>

    <!-- 数据列表 -->
    <div>
        <!-- 功能栏 -->
        <el-row :gutter="10" class="operation">
        <el-col :span="15">
            <el-button v-if="CheckUserButtonAuth('BtnBillInAdd')" class="iconfont icon-add" type="primary" plain @click="btnEditInfo('0')">新增</el-button>
            <el-popconfirm v-if="CheckUserButtonAuth('BtnBillInDelete')" 
                title="确认要删除吗?"
                @confirm="btnBatchDeleteInfo">
                <template #reference>
                    <el-button class="iconfont icon-ashbin" type="danger" plain>删除</el-button>
                </template>
            </el-popconfirm>
            <el-button class="iconfont icon-dingdan" type="warning" @click="BoxBatchBill.isVisible=true">选择单据</el-button>     
        </el-col>

        <el-col :span="9" class="operation_hidden">
            <el-button-group>
                                            
                <el-tooltip
                class="box-item"
                effect="dark"
                content="导出PDF"
                placement="top">
                <el-button class="iconfont icon-xiazaiPDF" @click="btnExportPdf"></el-button>
                </el-tooltip>

                <el-tooltip
                class="box-item"
                effect="dark"
                content="打印"
                placement="top">
                <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
                </el-tooltip>

                <el-tooltip
                class="box-item"
                effect="dark"
                content="导出Excel"
                placement="top">
                <el-button class="iconfont icon-download" @click="btnNoExport"></el-button>
                </el-tooltip>
            </el-button-group>    
            
        </el-col>
        </el-row>

        <!-- 列表 -->
        <el-table :data="dataList" border stripe :height="GetDataHeight" style="width: 100%" @select="btnCheckbox" @select-all="btnCheckbox">
        <el-table-column prop="id" type="selection" width="50" />  
        <el-table-column prop="inNumber" label="入库单号" width="150" />
        <el-table-column prop="departmentName" label="部门" min-width="100" show-overflow-tooltip />       

        <el-table-column prop="areaName" label="大区" min-width="90" show-overflow-tooltip>
        </el-table-column>

        <el-table-column label="交易者类型">
            <template v-slot="scope">
                <el-tag v-if="scope.row.tradeType ==1" type="danger">供应商</el-tag>
                <el-tag v-if="scope.row.tradeType ==2" type="success">客户</el-tag>
                <el-tag v-if="scope.row.tradeType ==3" type="info">员工</el-tag>
                <el-tag v-if="scope.row.tradeType ==4" type="info">散客</el-tag>
            </template>
        </el-table-column>
        <el-table-column label="交易者" min-width="100" show-overflow-tooltip>
            <template v-slot="scope">
                <span>{{scope.row.tradeName}}</span>
            </template>
        </el-table-column>
        <el-table-column prop="originType" label="原始单据类型">
            <template #default="scope">
                <el-tag v-if="scope.row.originType ==0">无</el-tag>
                <el-tag v-if="scope.row.originType ==1">采购单</el-tag>
                <el-tag v-if="scope.row.originType ==2">销售单</el-tag>
                <el-tag v-if="scope.row.originType ==3">零售单</el-tag>
                <el-tag v-if="scope.row.originType ==6">加工单</el-tag>
                <el-tag v-if="scope.row.originType ==13">移仓单</el-tag>
                <el-tag v-if="scope.row.originType ==14">退供单</el-tag>
                <el-tag v-if="scope.row.originType ==15">退货单</el-tag>
                <!-- <el-tag v-if="scope.row.originType ==16">调拨单</el-tag> -->
                <el-tag v-if="scope.row.originType ==20">入库单</el-tag>
                <el-tag v-if="scope.row.originType ==21">出库单</el-tag>
            </template>
        </el-table-column>
        <el-table-column prop="originNumber" label="原始单号" />
        <el-table-column prop="state" label="状态">
            <template #default="scope">
                <el-tag v-if="scope.row.state ==1">新建单</el-tag>
                <el-tag v-if="scope.row.state ==2" type="danger">入库中</el-tag>
                <el-tag v-if="scope.row.state ==3" type="success">已入库</el-tag>
                <el-tag v-if="scope.row.state ==10">已取消</el-tag>
            </template>
        </el-table-column>
        <el-table-column label="入库时间" width="110">
            <template #default="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.inTime"
                    placement="top"
                >
                <span>{{DateFormat(scope.row.inTime)}}</span>
                </el-tooltip>                
            </template>
        </el-table-column>
        <el-table-column label="创建日期" width="110">
            <template #default="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.createTime"
                    placement="top"
                >
                <span>{{DateFormat(scope.row.createTime)}}</span>
                </el-tooltip>
            </template>
        </el-table-column>
        <el-table-column label="操作" width="130" fixed="right">
            <template v-slot="scope">              
                <el-button size="small" type="primary" @click="btnEditInfo(scope.row.inNumber)">详情</el-button>
                <el-popconfirm v-if="CheckUserButtonAuth('BtnBillInDelete')" 
                    title="确认要删除吗?"
                    @confirm="btnDeleteInfo(scope.row.inNumber)">
                    <template #reference>
                        <el-button size="small" type="danger">删除</el-button>
                    </template>
                </el-popconfirm>
            </template>
        </el-table-column>
        </el-table>

    </div>

    <el-affix position="bottom">
        <el-row class="page_two">
            <el-col :span="24">
                <!-- 分页 -->
                <el-container class="page_right">
                    <el-pagination
                    v-model:currentPage="info.page"
                    v-model:page-size="info.size"
                    :page-sizes="[50, 200, 500, 1000]"
                    :background="true"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="info.total"
                    @current-change="btnPagechange"
                    @size-change="btnSizechange"
                    >
                    </el-pagination>
                </el-container>
            </el-col>
        </el-row>        
    </el-affix>

    <!-- 新增、编辑 弹出框 -->
    <el-drawer
      :title="BoxEditInfo.title" 
      v-model="BoxEditInfo.isVisible"
      direction="ltr"
      size="85%"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      @closed="GetDataList">
        <EditBillIn :editId="info.id" :dataList="dataList" @closeBox="btnCloseBox"></EditBillIn>
    </el-drawer>

    <!-- 批量选择入库单-弹出框 -->
    <el-dialog title="选择单据"
    v-model="BoxBatchBill.isVisible"
    width="85%"
    :draggable="true"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    @closed="GetDataList">
        <BoxBatchOriginIn ref="BatchOriginBill"></BoxBatchOriginIn>
        <template #footer>
            <div class="BoxFooter">
                <el-button type="primary" @click="btnBatchSelectBill">生成入库单</el-button>
                <el-button @click="BoxBatchBill.isVisible=false">关闭</el-button>
            </div>
        </template>
    </el-dialog>

  </div>
</template>

<script>
import BoxBatchOriginIn from '@/views/Wms/BillIn/BoxBatchOriginIn.vue'  //批量生成入库单

import EditBillIn from '@/views/Wms/BillIn/EditBillIn.vue'
import UserAreaList from '@/utils/mixins/UserAreaList.js'  //用户关联大区列表
import AllTradeList from '@/utils/mixins/AllTradeList.js'  //交易者列表
import AllDptList from '@/utils/mixins/AllDptList.js'  //全部部门列表
import { ElLoading, ElMessage } from 'element-plus'
export default {
  mixins:[UserAreaList,AllTradeList,AllDptList],
  components:{
    BoxBatchOriginIn,
    EditBillIn
  },
  data(){
    return{
        dataList:[],  //Table数据
        info:{        
            page:1,
            size:50,
            total:0,  //总记录数
            id:0,  //新增、编辑 的参数
            ids:[],   //删除 的参数
            search:{
                inNumber:'',  //入库单号
                selectDepartmentList:[],  //部门列表
                areaNumberList:[],    //大区列表
                tradeNumber:'',  //交易者编号
                originType:0, 
                originNumber:'',  //原始单号
                datePoint:'',
                state:0,
            },
        },
        //编辑-弹出框
        BoxEditInfo:{
            isVisible:false,
            title:'入库单'
        },
        //批量选择入库单-弹出框
        BoxBatchBill:{
            isVisible:false
        },
    }
  },
  computed:{
    //自适应窗体高度
    GetDataHeight(){
        return window.innerHeight-180
    }
  },
  methods:{

    //加载数据列表
    GetDataList(){
        if(this.info.search.datePoint == null){
            this.info.search.datePoint = ''
        }

        //获取、转换部门
        let newDepartmentList = []
        if(this.info.search.selectDepartmentList!=null){
            newDepartmentList = this.info.search.selectDepartmentList.map(item=>{
                return item
            })
        }

        let param = {
            inNumber:this.info.search.inNumber,
            departmentList:newDepartmentList,
            areaNumberList:this.info.search.areaNumberList,   //大区列表
            tradeNumber:this.info.search.tradeNumber,
            originType:this.info.search.originType,
            originNumber:this.info.search.originNumber,
            state:this.info.search.state,
            startDate:this.DateFormat(this.info.search.datePoint[0]),
            endDate:this.DateFormat(this.info.search.datePoint[1]),
            page:this.info.page,
            size:this.info.size
        }
        this.$api.wms.ListBillIn(param).then(res=>{
            if(res.data.status === 200){
                this.dataList = res.data.data; //获取列表
                //分页
                this.info.total = res.data.total
                this.info.page = res.data.pageNumber
            }else{
                ElMessage.error(res.data.msg);
            }
        }).catch(e=>{
            console.log(e);
        })
    },

    //选中多选框
    btnCheckbox(selection){
        this.info.ids = selection.map(item=>item.inNumber)
    },
    //分页-页数变化时
    btnPagechange(page){
        this.info.page = page
        this.GetDataList() //加载Table数据
    },
    //分页-单页记录数变化时
    btnSizechange(size){
        this.info.size = size
        this.info.page = 1
        this.GetDataList() //加载Table数据
    },

    //新增编辑部门信息
    btnEditInfo(inNumber){
      this.info.id = inNumber
      if(inNumber==0){
          this.BoxEditInfo.title = "新增入库单"
      }else{
          this.BoxEditInfo.title = "编辑入库单"
      }
      this.BoxEditInfo.isVisible = true;
    },
    //关闭弹出框
    btnCloseBox(){
        this.BoxEditInfo.isVisible = false;
    },

    //删除按钮
    btnDeleteInfo(inNumber){
        let param = [inNumber]
        this.$api.wms.DeleteBillInAllInfo(param).then(res=>{
          if(res.data.status === 200){
              ElMessage.success(res.data.msg)
              this.GetDataList()
          }
          else{
              ElMessage.error(res.data.msg)
          }
        })
    },
    //批量删除
    btnBatchDeleteInfo(){
        if(this.info.ids.length<1){
            ElMessage.error('请选择要删除的选项')
            return false
        }

        this.$api.wms.DeleteBillInAllInfo(this.info.ids).then(res=>{
            if(res.data.status === 200){
                ElMessage.success(res.data.msg)
                this.GetDataList();
                this.info.ids = [];
            }
            else{
                ElMessage.error(res.data.msg)
            }
        })
    },

    //重置按钮
    btnResetSearch(){
        this.info.search.inNumber = '',
        this.info.search.selectDepartmentList = [];
        this.info.search.areaNumberList=[];  //大区列表
        this.info.search.tradeNumber='';
        this.info.search.originType = 0,
        this.info.search.originNumber='';
        this.info.search.state=0;
        this.info.search.datePoint = '',
        this.info.page = 1,
        this.GetDataList();
    },

    //保存批量入库单
    btnBatchSelectBill(){
        let numberList = this.$refs.BatchOriginBill.info.ids;
        let billType = this.$refs.BatchOriginBill.info.search.billType;
        if(numberList ==null || numberList.length <=0){
            ElMessage.error('请选择要入库的单据');
            return false;
        }
        
        //批量生成入库单
        let param = {
            billType:billType,
            numberList:numberList,
        }
        this.$api.wms.BatchSaveBillIn(param).then(res=>{
            if(res.data.status === 200){
                ElMessage.success(res.data.msg);
                this.BoxBatchBill.isVisible = false;
            }else{
                ElMessage.error(res.data.msg);
            }
        })
    },

    //导出PDF
    btnExportPdf(){
        //打开遮罩层
        const loading = ElLoading.service({
            lock:true,
            text:'正在生成PDF文件,请稍候!',
            background:'rgba(0, 0, 0, 0.7)',
        })

        if(this.info.search.datePoint == null){
            this.info.search.datePoint = ''
        }

        //获取、转换部门
        let newDepartmentList = []
        if(this.info.search.selectDepartmentList!=null){
            newDepartmentList = this.info.search.selectDepartmentList.map(item=>{
                return item
            })
        }

        let param = {
            inNumber:this.info.search.inNumber,
            departmentList:newDepartmentList,
            areaNumberList:this.info.search.areaNumberList,   //大区列表
            tradeNumber:this.info.search.tradeNumber,
            originType:this.info.search.originType,
            originNumber:this.info.search.originNumber,
            state:this.info.search.state,
            startDate:this.DateFormat(this.info.search.datePoint[0]),
            endDate:this.DateFormat(this.info.search.datePoint[1]),
            page:this.info.page,
            size:this.info.size
        }
        this.$api.wms.BatchBillInCreatePdf(param).then(res=>{
            loading.close();
            if(res.data.status === 200){
                const alink = document.createElement('a');
                alink.href = res.data.data;
                document.body.appendChild(alink);
                alink.click();
                document.body.removeChild(alink)
            }else{
                ElMessage.error(res.data.msg);
            }
        })
    },

  },
  mounted(){
    this.GetDataList();  //获取列表
  }

}
</script>

<style scoped>
.BoxFooter{padding-top: 20px;}
</style>