/**
 * 统计管理模块接口列表
 */


 import base from '../base'   //导入接口域名列表
 import axios from '@/utils/http'   //导入http中创建的axios实例

 const stat = {

    //货品量统计
    ListStatGoodsNums(param){
        return axios.post(`${base.base}/Stat/ListStatGoodsNums`,param)
    },


    //账户统计
    StatisticsAccount(param){
        return axios.post(`${base.base}/Stat/StatisticsAccount`,param)
    },
    
    //导出账户统计
    ExportStatisticsAccount(param){
        return axios.post(`${base.base}/Stat/ExportStatisticsAccount`,param)
    },

    //营业额统计
    GetStatTurnover(param){
        return axios.post(`${base.base}/Stat/GetStatTurnover`,param)
    },

    //单品价格走势图
    GetHistoryPriceStat(param){
        return axios.post(`${base.base}/Stat/GetHistoryPriceStat`,param)
    },

    

    //全年财务统计图
    GetStatYearFinance(param){
        return axios.get(`${base.base}/Stat/GetStatYearFinance`,{
            params:param
        })
    },

    //全年财务分类统计
    GetStatYearFinanceBillType(param){
        return axios.get(`${base.base}/Stat/GetStatYearFinanceBillType`,{
            params:param
        })
    },

    //全年财务会计科目统计
    GetStatYearFinanceSub(param){
        return axios.get(`${base.base}/Stat/GetStatYearFinanceSub`,{
            params:param
        })
    },

    //#region 产品统计

    //利润排行(按分类)
    StatProfitListByCid(param){
        return axios.post(`${base.base}/StatGoods/StatProfitListByCid`,param)
    },

    //利润排行(按产品)
    StatProfitListBySpu(param){
        return axios.post(`${base.base}/StatGoods/StatProfitListBySpu`,param)
    },

    //利润排行(按规格)
    StatProfitListBySku(param){
        return axios.post(`${base.base}/StatGoods/StatProfitListBySku`,param)
    },

    //产品销量排行
    StatGoodsRank(param){
        return axios.post(`${base.base}/StatGoods/StatGoodsRank`,param)
    },

    //单品价格折现图
    StatSingleGoodsInfo(param){
        return axios.post(`${base.base}/StatGoods/StatSingleGoodsInfo`,param)
    },

    //#endregion

    //#region 营业员业绩

    //营业员业绩统计
    PageSalerStatList(param){
        return axios.post(`${base.base}/Stat/PageSalerStatList`,param)
    },
    //计算营业员业绩
    BatchStatSaler(param){
        return axios.post(`${base.base}/Stat/BatchStatSaler`,param)
    },
    //删除营业员业绩
    DeleteStatSaler(param){
        return axios.post(`${base.base}/Stat/DeleteStatSaler`,param)
    },

    //#endregion


    //#region 交易量统计

    //单日-交易量、交易额统计
    StatVolumeDayInfo(param){
        return axios.post(`${base.base}/StatTrade/StatVolumeDayInfo`,param)
    },

    //单月-交易量、交易额统计
    StatVolumeMonthInfo(param){
        return axios.post(`${base.base}/StatTrade/StatVolumeMonthInfo`,param)
    },

    //单年-交易量、交易额统计
    StatVolumeYearInfo(param){
        return axios.post(`${base.base}/StatTrade/StatVolumeYearInfo`,param)
    },

    //部门-交易统计
    

    //#endregion

    //#region 客户统计

    //获取客户交易统计排名
    StatCustomerTradeRank(param){
        return axios.post(`${base.base}/StatCustomer/StatCustomerTradeRank`,param)
    },

    //客户利润排行(按交易者)
    StatCustomerProfitList(param){
        return axios.post(`${base.base}/StatCustomer/StatCustomerProfitList`,param)
    },
    //客户利润排行(按分类)
    StatCustomerProfitListByCid(param){
        return axios.post(`${base.base}/StatCustomer/StatCustomerProfitListByCid`,param)
    },

    //应收账款
    StatCustomerNoPayList(param){
        return axios.post(`${base.base}/StatCustomer/StatCustomerNoPayList`,param)
    },

    //应收账款明细
    StatCustomerNoPayDetailList(param){
        return axios.post(`${base.base}/StatCustomer/StatCustomerNoPayDetailList`,param)
    },

    //#endregion

    //#region 供应商统计

    //应付账款
    StatSupplierNoPayList(param){
        return axios.post(`${base.base}/StatSupplier/StatSupplierNoPayList`,param)
    },

    //应付账款明细
    StatSupplierNoPayDetailList(param){
        return axios.post(`${base.base}/StatSupplier/StatSupplierNoPayDetailList`,param)
    },

    //#endregion


 }

 export default stat