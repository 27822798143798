<template>
  <div class="editInfo">
    <!-- 编辑调价表 -->
    <el-divider></el-divider>
    
    <!-- Tab标签 -->
    <el-tabs v-model="info.activeName" class="editTab">
        <el-tab-pane label="调价表" name="tabPrice">
            <TabAdjustInfo ref="tabPrice"></TabAdjustInfo>

            <el-divider>调价表明细</el-divider>
            <TabAdjustDetail></TabAdjustDetail>
        </el-tab-pane>

        <el-tab-pane label="备注记录" name="tabRecord">
            <!-- <TabSaleRecord></TabSaleRecord> -->
        </el-tab-pane>

        <el-tab-pane label="状态记录" name="tabState">
            <!-- <TabSaleState></TabSaleState> -->
        </el-tab-pane>
    </el-tabs>

    <!-- 功能按钮组 -->
    <div class="editFooter" v-if="priceAdjustInfo.info">
        <el-button v-if="CheckUserButtonAuth('BtnPriceAdjustSave') && (priceAdjustInfo.info.state==1 || priceAdjustInfo.info.state==3)" type="primary" @click="btnSaveData">保存</el-button>
        <el-button v-if="CheckUserButtonAuth('BtnPirceAdjustSumbitCheck') && priceAdjustInfo.info.adjustNumber !='' && (priceAdjustInfo.info.state==1 || priceAdjustInfo.info.state == 3)" type="warning" @click="btnUpdateState(2)">提交审核</el-button>
        <el-button v-if="CheckUserButtonAuth('BtnPirceAdjustCheck') && priceAdjustInfo.info.state == 2" type="warning" @click="BoxCheckState.isVisible = true">审核</el-button>
        <el-button @click="btnClose">关闭</el-button>

        <el-button-group>
            <el-button @click="btnPrevious" class="iconfont icon-houtui_shangyibu_zuojiantou_shangyiye">上一条</el-button>
            <el-button @click="btnNext">下一条<el-icon class="iconfont icon-qianjin_xiayibu_youjiantou_xiayiye"></el-icon></el-button>
        </el-button-group>
    </div>

    <!-- 审核-弹框 -->
    <el-dialog title="调价表审核"
    v-model="BoxCheckState.isVisible"
    width="300px">
        <el-divider></el-divider>
        <div>调价表是否审核通过?</div>
        <template #footer>            
            <el-button v-if="CheckUserButtonAuth('BtnPriceAdjustCheckOk')" type="primary" @click="btnUpdateState(5)">通过</el-button>
            <el-button v-if="CheckUserButtonAuth('BtnPriceAdjustCheckReturn')" type="danger" @click="btnUpdateState(3)">驳回</el-button>
            <el-button @click="BoxCheckState.isVisible = false">关闭</el-button>
        </template>
    </el-dialog>

  </div>
</template>

<script>
import TabAdjustInfo from '@/views/Fms/PriceAdjust/TabAdjustInfo.vue'  //调价表主信息
import TabAdjustDetail from '@/views/Fms/PriceAdjust/TabAdjustDetail.vue' //调价表明细列表
import { mapMutations, mapState } from 'vuex'
import { ElLoading, ElMessage } from 'element-plus'
import moment from 'moment'
export default {
  props:['editId','dataList'],
  components:{
    TabAdjustInfo,
    TabAdjustDetail,
  },
  data(){
    return{
      info:{
        activeName:'tabPrice',
        adjustNumber:this.editId,
      },
      //审核-弹出框
      BoxCheckState:{
          isVisible:false,
      },
      //打印框
      BoxPrint:{
          isVisible:false,
      },
    }
  },
  computed:{
    ...mapState('Finance',['priceAdjustInfo'])
  },
  methods:{
    ...mapMutations('Finance',['SetPriceAdjustInfo']),

    //加载数据
    GetDataInfo(){
      if(this.info.adjustNumber!=0){
        let param = {
          adjustNumber:this.info.adjustNumber
        }
        this.$api.finance.GetPriceAdjustAllInfo(param).then(res=>{
          if(res.data.status === 200){
              this.SetPriceAdjustInfo(res.data.data)
          }else{
              this.btnClearData();
          }
          //加载
          this.$refs.tabPrice.SelectUserAreaList();
        })
      }else{
        this.btnClearData();
        //加载
        this.$refs.tabPrice.SelectUserAreaList();
      }
      
    },

    //保存数据
    btnSaveData(){
      //打开遮罩层
      const loading = ElLoading.service({
          lock:true,
          text:'正在提交,请稍候!',
          background:'rgba(0, 0, 0, 0.7)',
      })

      this.$api.finance.SavePriceAdjustAllInfo(this.priceAdjustInfo).then(res=>{
        loading.close();
        if(res.data.status === 200){
          ElMessage.success(res.data.msg);
          //刷新页面
          this.priceAdjustInfo.info.adjustNumber = res.data.data;
          this.info.adjustNumber = res.data.data;
          this.GetDataInfo();
        }else{
          ElMessage.error(res.data.msg);
        }
      })
    },

    //提交审核
    btnUpdateState(state){
      //打开遮罩层
      const loading = ElLoading.service({
          lock:true,
          text:'正在提交,请稍候!',
          background:'rgba(0, 0, 0, 0.7)',
      })

      let param = {
          adjustNumber:this.priceAdjustInfo.info.adjustNumber,
          state:state
      }
      this.$api.finance.UpdatePriceAdjustState(param).then(res=>{
        loading.close();
        if(res.data.status === 200){
              ElMessage.success(res.data.msg);
              //更新
              this.priceAdjustInfo.info.state = state;
              this.BoxCheckState.isVisible = false
          }else{
              ElMessage.error(res.data.msg)
          }
      })
    },

    //关闭按钮
    btnClose(){
        this.$emit('closeBox')  //关闭弹出框
    },

    //清空数据
    btnClearData(){
      var newInfo = {
        info:{
          adjustNumber:'',
          systemNumber:'',
          departmentId:0,
          departmentName:'',
          areaNumber:'',
          areaName:'',
          adjustDate:moment(new Date()).format("YYYY-MM-DD"),
          state:1,
          remark:'',
        },
        detailList:[],
      }
      this.SetPriceAdjustInfo(newInfo);
    },

    //上一条
    btnPrevious(){
        let currentIndex = this.dataList.findIndex((item)=>{
            return item.adjustNumber === this.info.adjustNumber
        })
        let prevIndex = currentIndex -1;
        let prevInfo = this.dataList[prevIndex];
        if(prevInfo==null){
            ElMessage.warning("没有上一条了");
            return false;
        }
        else{
            this.info.adjustNumber = prevInfo.adjustNumber
            this.GetDataInfo()
        }
        
    },
    //下一条
    btnNext(){
        let currentIndex = this.dataList.findIndex((item)=>{
            return item.adjustNumber === this.info.adjustNumber
        })
        let prevIndex = currentIndex + 1;
        let prevInfo = this.dataList[prevIndex];
        if(prevInfo==null){
            ElMessage.warning("没有下一条了");
            return false;
        }
        else{
            this.info.adjustNumber = prevInfo.adjustNumber
            this.GetDataInfo()
        }
    },

  },
  mounted(){
    this.GetDataInfo();
  },

}
</script>

<style>

</style>