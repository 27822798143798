<template>
  <div>
      <!-- 搜索功能 -->
      <el-row>
          <el-col :span="24">
              <el-form :inline="true">

                  <el-form-item label="商品类目">
                    <el-tree-select v-model="info.search.cid" :data="selectCategoryList" @change="ChangeTreeSelect" :default-expand-all="true" filterable class="search-200"/>
                  </el-form-item>

                  <el-form-item label="品牌">
                    <el-select v-model="info.search.brandId" placeholder="品牌" filterable class="search-200">
                            <el-option
                            v-for="item in treeBrandList"
                            :key="item.id"
                            :label="item.label"
                            :value="item.id">
                            </el-option>
                        </el-select>
                  </el-form-item>

                  <el-form-item label="名称">
                    <el-input v-model="info.search.searchName" placeholder="品牌、分类、商品名称" class="search-200"></el-input>
                  </el-form-item>

                  <el-form-item label="商品类型">
                    <el-select v-model="info.search.spuType" class="search-150">
                      <el-option label="全部" :value="0"></el-option>
                      <el-option label="商品" :value="1"></el-option>
                      <el-option label="耗材" :value="2"></el-option>
                      <el-option label="周转物料" :value="3"></el-option>
                    </el-select>
                  </el-form-item>

                  <el-form-item label="显示标识">
                    <el-select v-model="info.search.isShow" class="search-200">
                      <el-option label="全部" :value="-1"></el-option>
                      <el-option label="隐藏" :value="0"></el-option>
                      <el-option label="显示" :value="1"></el-option>
                    </el-select>
                  </el-form-item>

                  <el-form-item>
                  <el-button class="iconfont icon-search" type="primary" plain  @click="GetDataList">搜索</el-button>
                  <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
                  </el-form-item>

              </el-form>
            </el-col>
      </el-row>

      <!-- 主内容区 -->
      <div>
          <!-- 功能栏 -->
          <el-row :gutter="10" class="operation">
            <el-col :span="15">
                <el-button v-if="CheckUserButtonAuth('BtnGoodsAdd')" class="iconfont icon-add" type="primary" plain @click="btnEditInfo(0)" >新增</el-button>
                <el-popconfirm v-if="CheckUserButtonAuth('BtnGoodsDelete')"
                    title="确认要删除吗?"
                    @confirm="btnBatchDeleteInfo">
                    <template #reference>
                        <el-button class="iconfont icon-ashbin" type="danger" plain >删除</el-button>
                    </template>
                </el-popconfirm>
            </el-col>

            <el-col :span="9" class="operation_hidden">
                <el-button-group>
                  
                  <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="流程介绍"
                    placement="top">
                    <el-button class="iconfont icon-electronics"  @click="BoxFlow.isVisible = true"></el-button>
                  </el-tooltip>

                  <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="打印"
                    placement="top">
                    <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
                  </el-tooltip>

                  <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="导出数据"
                    placement="top">
                    <el-button class="iconfont icon-download" @click="btnExportInfo"></el-button>
                  </el-tooltip>
                </el-button-group>
                
            </el-col>
          </el-row>

          <el-table :data="info.dataList" border stripe :height="tableHeight" style="width: 100%" @select="btnCheckbox" @select-all="btnCheckbox"> 
              <el-table-column prop="id" type="selection" width="50" />  
              <el-table-column prop="skuNumber" label="商品编码" min-width="130" />
              <el-table-column prop="cidName" label="类目" />
              <el-table-column prop="spuName" label="商品名称" min-width="150">
                <template v-slot="scope">
                  <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.spuName"
                    placement="top"
                  >
                    <div class="cellOverFlow">{{scope.row.spuName}}</div>
                  </el-tooltip>
                </template>
              </el-table-column>              
              <el-table-column prop="brandName" label="品牌" />
              <el-table-column prop="skuName" label="规格" min-width="150" />
              
              <el-table-column prop="warnStock" label="预警库存" min-width="100">
                <template #header>
                    <div>预警库存</div>
                    <div class="defUnitItem">(默认单位)</div>
                </template>
                <template #default="scope">
                    {{scope.row.warnStock}} {{scope.row.defUnitName}}
                </template>
              </el-table-column>

              <el-table-column prop="skuCode" label="商品条码" width="150">
                <template v-slot="scope">
                  <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.skuCode"
                    placement="top"
                  >
                    <div class="cellOverFlow">{{scope.row.skuCode}}</div>
                  </el-tooltip>
                </template>
              </el-table-column>

              <el-table-column label="商品类型" width="85">
                <template v-slot="scope">
                  <el-tag v-if="scope.row.spuType ==1">商品</el-tag>
                  <el-tag v-if="scope.row.spuType ==2" type="warning">耗材</el-tag>
                  <el-tag v-if="scope.row.spuType ==3" type="danger">周转物料</el-tag>
                </template>
              </el-table-column>

              <el-table-column label="显示标识" min-width="85">
                <template #default="scope">
                  <el-tag v-if="scope.row.isShow == 1">显示</el-tag>
                  <el-tag v-if="scope.row.isShow == 0" type="info">隐藏</el-tag>
                </template>
              </el-table-column>
              
              <el-table-column label="操作" width="130" fixed="right">
              <template v-slot="scope">              
                  <el-button size="small" type="primary" @click="btnEditInfo(scope.row.skuNumber)">详情</el-button>
                  <el-popconfirm v-if="CheckUserButtonAuth('BtnGoodsDelete')"
                  title="确认要删除吗?"
                  @confirm="btnDeleteInfo(scope.row.skuNumber)">
                      <template #reference>
                      <el-button size="small" type="danger">删除</el-button>
                      </template>
                  </el-popconfirm><br/>
                  
              </template>
              </el-table-column>
          </el-table>

      </div>

      <el-affix position="bottom">
          <el-row class="page_two">
              <el-col :span="24">
                  <!-- 分页 -->
                  <el-container class="page_right">
                      <el-pagination
                      v-model:currentPage="info.page"
                      v-model:page-size="info.size"
                      :page-sizes="[50, 200, 500]"
                      :background="true"
                      layout="total, sizes, prev, pager, next, jumper"
                      :total="info.total"
                      @current-change="btnPagechange"
                      @size-change="btnSizechange"
                      >
                      </el-pagination>
                  </el-container>
              </el-col>
          </el-row>        
      </el-affix>

      <!-- 新增、编辑 弹出框 -->
      <el-dialog :title="BoxEditInfo.title" 
      v-model="BoxEditInfo.isVisible"
      width="750"
      :draggable="true"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      @closed="GetDataList">
        <EditSku :editId="info.id" :dataList="info.dataList" @closeBox="btnCloseBox"></EditSku>
      </el-dialog>


  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import EditSku from '@/views/Goods/Sku/EditSku.vue'   //编辑页
import CategoryList from '@/utils/mixins/CategoryList.js'
import TableHeight from '@/utils/mixins/TableHeight.js'  //设置Table高度
export default {
    name:'ListSku',
    components:{
      EditSku,
    },
    mixins:[CategoryList,TableHeight],
    data(){
      return{
        info:{
          dataList:[],  //Table数据
          page:1,   //页数
          size:50,  //单页记录数
          total:0,  //总记录数
          id:0,  //新增、编辑 的参数
          ids:[],   //删除 的参数
          search:{  //搜索条件
            cid:0,
            brandId:0,
            searchName:'',  //
            spuType:0,
            isShow:-1,
          }
        },
        //搜索 - 品牌列表
        treeBrandList:[],
        //编辑-弹出框
        BoxEditInfo:{
          isVisible:false,
          title:'新增商品规格'
        },    

      }
    },
    methods:{
      
      //类目下拉列表改变后
      ChangeTreeSelect(){
        //重新加载品牌
        this.GetTreeBrandList()
      },
      //获取品牌下拉列表
      GetTreeBrandList(){
          let param = {
              cid:this.info.search.cid
          }
          this.$api.goods.SelectCategoryBrandList(param).then(res=>{
              if(res.data.status === 200){
                  this.treeBrandList = res.data.data
              }
          }).catch(e=>{
              console.log(e)
          })
      },
      //加载数据列表
      GetDataList(){
        let param = {
          cid:this.info.search.cid,
          brandId:this.info.search.brandId,
          searchName:this.info.search.searchName,
          spuType:this.info.search.spuType,
          isShow:this.info.search.isShow,
          page:this.info.page,
          size:this.info.size
        }
        this.$api.goods.PageSkuList2(param).then(res=>{
          if(res.data.status === 200){
            this.info.dataList = res.data.data;  //获取数据
            //分页
            this.info.total = res.data.total
            this.info.page = res.data.pageNumber
          }
        }).catch(e=>{
          console.log(e)
        })
      },
      //选中多选框
      btnCheckbox(selection){
        this.info.ids = selection.map(item=>item.skuNumber)
      },
      //分页-页数变化时
      btnPagechange(page){
          this.info.page = page
          this.GetDataList() //加载Table数据
      },
      //分页-单页记录数变化时
      btnSizechange(size){
          this.info.size = size
          this.info.page = 1
          this.GetDataList() //加载Table数据
      },
      //新增编辑部门信息
      btnEditInfo(id){
        this.info.id = id
        if(id==0){
            this.BoxEditInfo.title = "新增商品规格"
        }else{
            this.BoxEditInfo.title = "编辑商品规格"
        }  
        this.BoxEditInfo.isVisible = true;
      },
      //删除按钮
      btnDeleteInfo(skuNumber){
        let param = [skuNumber]
        this.$api.goods.DeleteSku(param).then(res=>{
          if(res.data.status === 200){
            ElMessage({type:'success',message:res.data.msg})
            this.GetDataList()
          }
          else{
            ElMessage({type:'error',message:res.data.msg})
          }
        })
      },
      //批量删除
      btnBatchDeleteInfo(){
        if(this.info.ids.length<1){
          ElMessage.error('请选择要删除的选项')
          return false
        }

        this.$api.goods.DeleteSku(this.info.ids).then(res=>{
          if(res.data.status === 200){
            ElMessage.success(res.data.msg)
            this.GetDataList()
          }
          else{
            ElMessage.error(res.data.msg)
          }
        })
      },
            
      //重置按钮
      btnResetSearch(){
        this.info.search.cid=0;
        this.info.search.brandId=0;
        this.info.search.searchName='';
        this.info.search.spuType=0;
        this.info.search.isShow=-1;
        this.GetDataList()
      },
      //导出产品规格列表
      btnExportInfo(){
        let param = {
          cid:this.info.search.cid,
          brandId:this.info.search.brandId,
          searchName:this.info.search.searchName,
          spuType:this.info.search.spuType,
          isShow:this.info.search.isShow,
          page:this.info.page,
          size:this.info.size
        }
        this.$api.goods.ExportSku(param).then(res=>{
          if(res.data.status === 200){
              const alink = document.createElement('a');
              alink.href = res.data.data;
              document.body.appendChild(alink);
              alink.click();
              document.body.removeChild(alink)
          }
          else
          {
              ElMessage.error(res.data.msg)
          }
        }).catch(e=>{
            console.log(e)
        })
      },

      //关闭弹出框
      btnCloseBox(){
          this.BoxEditInfo.isVisible = false;
      },

    },
    mounted(){
      this.GetTreeBrandList()  //品牌列表
      this.GetDataList()  //加载数据Table数据
    }
}
</script>

<style scoped>
.defUnitItem{color: rgb(57, 116, 245);font-size: 12px;}
</style>