<template>
  <div>
    <!-- 搜索功能 -->
    <el-row>
        <el-col :span="24">
            <el-form :inline="true">

                <el-form-item label="调价编号">
                    <el-input v-model="info.search.adjustNumber" maxlength="20" class="search-150"></el-input>
                </el-form-item>

                <el-form-item label="部门">
                    <el-tree-select v-model="info.search.selectDepartmentList" :data="departmentList" multiple clearable :default-expand-all="true" collapse-tags collapse-tags-tooltip class="search-200">
                    </el-tree-select>
                </el-form-item>

                <el-form-item label="大区">
                    <el-select v-model="info.search.areaNumberList" multiple clearable collapse-tags collapse-tags-tooltip class="search-200">
                        <el-option v-for="userArea in userAreaList" :key="userArea.areaNumber" :label="userArea.areaName" :value="userArea.areaNumber"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="状态">
                    <el-select v-model="info.search.state" class="search-150">
                        <el-option :value="0" label="全部"></el-option>
                        <el-option :value="1" label="新建单"></el-option>
                        <el-option :value="2" label="待审核"></el-option>
                        <el-option :value="3" label="已驳回"></el-option>
                        <el-option :value="4" label="已审核并上报"></el-option>
                        <el-option :value="5" label="已生效"></el-option>
                        <el-option :value="9" label="已作废"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="备注">
                    <el-input v-model="info.search.remark" maxlength="50" class="search-150"></el-input>
                </el-form-item>

                <el-form-item label="名称">
                    <el-input v-model="info.search.searchName" maxlength="50" placeholder="类目、品牌、产品名称" class="search-200"></el-input>
                </el-form-item>

                <el-form-item label="调价日期">
                    <el-date-picker
                        v-model="info.search.datePoint"
                        type="daterange"
                        range-separator="To"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        style="width:250px"
                    />
                </el-form-item>

                <el-form-item>
                    <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
                    <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
                </el-form-item>

            </el-form>
        </el-col>
    </el-row>


    <div>
        <!-- 功能栏 -->
        <el-row :gutter="10" class="operation">
            <el-col :span="15">
                <el-button class="iconfont icon-exchangerate" plain @click="btnRefresh">刷新</el-button>
            </el-col>

            <el-col :span="9" class="operation_hidden">
                <el-button-group>
                                                    
                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="流程图"
                    placement="top">
                    <el-button class="iconfont icon-icon__liuchengtu" @click="btnNoFlow"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="打印"
                    placement="top">
                    <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="导出数据"
                    placement="top">
                    <el-button class="iconfont icon-download" @click="btnOpenExport"></el-button>
                    </el-tooltip>
                </el-button-group>        
                
            </el-col>
        </el-row>

        <!-- 报价明细列表 -->
        <el-table :data="dataList" border stripe :height="tableHeight">
            <el-table-column label="编号"  width="160">
                <template v-slot="scope">
                    <span>{{scope.row.adjustNumber}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="departmentName" label="部门名称" min-width="100">
                <template v-slot="scope">
                  <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.departmentName"
                    placement="top"
                  >
                  <div class="cellOverFlow">{{scope.row.departmentName}}</div>
                  </el-tooltip>
                </template>
            </el-table-column>

            <el-table-column prop="areaName" label="大区" min-width="90" show-overflow-tooltip>
            </el-table-column>

            <el-table-column prop="cidName" label="产品类目" min-width="90" show-overflow-tooltip></el-table-column>
            <el-table-column prop="brandName" label="品牌" min-width="80" show-overflow-tooltip></el-table-column>
            <el-table-column prop="spuName" label="产品名称" min-width="110" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="skuName" label="规格名称" min-width="90" show-overflow-tooltip/>

            <el-table-column label="默认单位" width="85">
                <template #header>
                    <div class="defPrice">默认单位</div>
                </template>
                <template v-slot="scope">
                    <span>{{scope.row.defUnitName}}</span>
                </template>
            </el-table-column>

            <el-table-column label="成本价" width="115">
                <template #header>
                    <div>成本价</div>
                    <div class="defPrice PriceSize">(默认单位)</div>
                </template>
                <template v-slot="scope">
                    <el-input-number v-if="scope.row.isEdit==1" v-model="scope.row.defCostPrice" :precision="2" :controls="false" style="width:90px"></el-input-number>
                    <span v-else>{{this.myRound(scope.row.defCostPrice,2)}}</span>
                </template>
            </el-table-column>

            <el-table-column label="零售价"  width="115">
                <template #header>
                    <div>零售价</div>
                    <div class="defPrice PriceSize">(默认单位)</div>
                </template>
                <template v-slot="scope">
                    <el-input-number v-if="scope.row.isEdit==1" v-model="scope.row.defRetailPrice" :precision="2" :controls="false" style="width:90px"></el-input-number>
                    <span v-else>{{scope.row.defRetailPrice}}</span>
                </template>
            </el-table-column>

            <el-table-column  label="批发价"  width="115">
                <template #header>
                    <div>批发价</div>
                    <div class="defPrice PriceSize">(默认单位)</div>
                </template>
                <template v-slot="scope">
                    <el-input-number v-if="scope.row.isEdit==1" v-model="scope.row.defWholePrice" :precision="2" :controls="false" style="width:90px"></el-input-number>
                    <span v-else>{{scope.row.defWholePrice}}</span>
                </template>
            </el-table-column>

            <el-table-column  label="VIP价"  width="115">
                <template #header>
                    <div>VIP价</div>
                    <div class="defPrice PriceSize">(默认单位)</div>
                </template>
                <template v-slot="scope">
                    <el-input-number v-if="scope.row.isEdit==1" v-model="scope.row.defVipPrice" :precision="2" :controls="false" style="width:90px"></el-input-number>
                    <span v-else>{{scope.row.defVipPrice}}</span>
                </template>
            </el-table-column>

            <el-table-column label="最小单位" width="85">
                <template #header>
                    <div class="minPrice">最小单位</div>
                </template>
                <template v-slot="scope">
                    <span>{{scope.row.minUnitName}}</span>
                </template>
            </el-table-column>
            <el-table-column label="成本价" width="115">
                <template #header>
                    <div>成本价</div>
                    <div class="minPrice PriceSize">(最小单位)</div>
                </template>
                <template v-slot="scope">
                    <el-input-number v-if="scope.row.isEdit==1" v-model="scope.row.minCostPrice" :precision="2" :controls="false" style="width:90px"></el-input-number>
                    <span v-else>{{this.myRound(scope.row.minCostPrice,2)}}</span>
                </template>
            </el-table-column>
            <el-table-column label="零售价" width="115">
                <template #header>
                    <div>零售价</div>
                    <div class="minPrice PriceSize">(最小单位)</div>
                </template>
                <template v-slot="scope">
                    <el-input-number v-if="scope.row.isEdit==1" v-model="scope.row.minRetailPrice" :precision="2" :controls="false" style="width:90px"></el-input-number>
                    <span v-else>{{scope.row.minRetailPrice}}</span>
                </template>
            </el-table-column>
            <el-table-column  label="批发价" width="115">
                <template #header>
                    <div>批发价</div>
                    <div class="minPrice PriceSize">(最小单位)</div>
                </template>
                <template v-slot="scope">
                    <el-input-number v-if="scope.row.isEdit==1" v-model="scope.row.minWholePrice" :precision="2" :controls="false" style="width:90px"></el-input-number>
                    <span v-else>{{scope.row.minWholePrice}}</span>
                </template>
            </el-table-column>

            <el-table-column  label="VIP价" width="115">
                <template #header>
                    <div>VIP价</div>
                    <div class="minPrice PriceSize">(最小单位)</div>
                </template>
                <template v-slot="scope">
                    <el-input-number v-if="scope.row.isEdit==1" v-model="scope.row.minVipPrice" :precision="2" :controls="false" style="width:90px"></el-input-number>
                    <span v-else>{{scope.row.minVipPrice}}</span>
                </template>
            </el-table-column>

            <el-table-column label="状态">
                <template #default="scope">
                    <el-tag v-if="scope.row.state ==1">新建单</el-tag>
                    <el-tag v-if="scope.row.state ==2">待审核</el-tag>
                    <el-tag v-if="scope.row.state ==3" type="danger">已驳回</el-tag>
                    <el-tag v-if="scope.row.state ==4" type="warning">已审核并上报</el-tag>
                    <el-tag v-if="scope.row.state ==5" type="success">已生效</el-tag>
                    <el-tag v-if="scope.row.state ==9" type="info">已作废</el-tag>
                </template>
            </el-table-column>
            
            <el-table-column label="调价时间" width="110">
                <template #default="scope">
                    <el-tooltip
                        class="box-item"
                        effect="dark"
                        :content="scope.row.adjustDate"
                        placement="top"
                    >
                    <span>{{DateFormat(scope.row.adjustDate)}}</span>
                    </el-tooltip>                
                </template>
            </el-table-column>

            <el-table-column prop="remark" label="备注" min-width="90" show-overflow-tooltip>
            </el-table-column>

        </el-table>
    </div>
    

    <el-affix position="bottom">
        <el-row class="page_two">
            <el-col :span="24">
                <!-- 分页 -->
                <el-container class="page_right">
                    <el-pagination
                    v-model:currentPage="info.page"
                    v-model:page-size="info.size"
                    :page-sizes="[50, 200, 500, 1000]"
                    :background="true"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="info.total"
                    @current-change="btnPagechange"
                    @size-change="btnSizechange"
                    >
                    </el-pagination>
                </el-container>
            </el-col>
        </el-row>        
    </el-affix>

    <!-- 导出-弹出框 -->
    <el-dialog title="创建导出记录"
    v-model="BoxExportInfo.isVisible"
    width="350">
        <el-divider></el-divider>

        <el-form :inline="true"
        label-width="80px"
        label-position="right">
            <el-form-item label="导出标题">
                <template #label>
                    <span style="color:red;">*</span>
                    <span>导出标题</span>
                </template>
                <el-input v-model="BoxExportInfo.exportTitle" class="search-200" maxlength="50"></el-input>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button type="primary" @click="btnExportInfo">导出</el-button>
            <el-button @click="BoxExportInfo.isVisible = false">关闭</el-button>
        </template>
    </el-dialog>

  </div>
</template>

<script>
import UserDptList from '@/utils/mixins/UserDptList.js'   //用户关联部门列表
import UserAreaList from '@/utils/mixins/UserAreaList.js'  //用户关联大区列表
import TableHeight from '@/utils/mixins/TableHeight.js'  //设置Table高度
import { ElLoading, ElMessage } from 'element-plus'
export default {
    mixins:[UserDptList,UserAreaList,TableHeight],
    data(){
        return{
            dataList:[],  //table数据
            info:{
                page:1,
                size:50,
                total:0,  //总记录数
                id:0,  //新增、编辑 的参数
                ids:[],   //删除 的参数
                search:{
                    adjustNumber:'',
                    selectDepartmentList:[],  //部门
                    areaNumberList:[],   //大区列表
                    datePoint:'',   //报价日期
                    state:0,      //状态
                    remark:'',   //备注
                    searchName:'',  //聚合搜索
                },
            },
            //导出-弹出框
            BoxExportInfo:{
                isVisible:false,
                exportTitle:'',  //导出记录标题
            },
        }
    },
    methods:{
        //加载数据列表
        GetDataList(){
            //日期范围
            if(this.info.search.datePoint == null){
                this.info.search.datePoint = ''
            }

            //获取、转换部门
            let newDepartmentList = []
            if(this.info.search.selectDepartmentList!=null){
                newDepartmentList = this.info.search.selectDepartmentList.map(item=>{
                    return item
                })
            }

            let param = {
                adjustNumber:this.info.search.adjustNumber,
                departmentList:newDepartmentList,
                areaNumberList:this.info.search.areaNumberList,  //大区
                remark:this.info.search.remark,
                state:this.info.search.state,   //状态
                searchName:this.info.search.searchName,
                startDate:this.DateFormat(this.info.search.datePoint[0]),
                endDate:this.DateFormat(this.info.search.datePoint[1]),
                page:this.info.page,
                size:this.info.size
            }
            this.$api.finance.PagePriceAdjustDetailList(param).then(res=>{
                if(res.data.status === 200){
                    this.dataList = res.data.data;
                    //分页
                    this.info.total = res.data.total;
                    this.info.page = res.data.pageNumber;
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

        //分页-页数变化时
        btnPagechange(page){
            this.info.page = page
            this.GetDataList() //加载Table数据
        },
        //分页-单页记录数变化时
        btnSizechange(size){
            this.info.size = size
            this.info.page = 1
            this.GetDataList() //加载Table数据
        },

        //重置按钮
        btnResetSearch(){
            this.info.search.adjustNumber="";  //调价编号
            this.info.search.remark="";   //备注
            this.info.search.selectDepartmentList = [];
            this.info.search.areaNumberList=[];
            this.info.search.datePoint = "";
            this.info.search.state=0;
            this.info.search.searchName="";
            this.info.ids = [];  //编号数组
            this.info.page = 1;
            this.GetDataList()
        }, 

        //导出弹出框
        btnOpenExport(){
            this.BoxExportInfo.isVisible=true;
        },
        //导出业务单列表到Excel
        btnExportInfo(){

            //打开遮罩层
            const loading = ElLoading.service({
                lock:true,
                text:'正在导出数据,请稍候!',
                background:'rgba(0, 0, 0, 0.7)',
            })

            //日期范围
            if(this.info.search.datePoint == null){
                this.info.search.datePoint = ''
            }

            //获取、转换部门
            let newDepartmentList = []
            if(this.info.search.selectDepartmentList!=null){
                newDepartmentList = this.info.search.selectDepartmentList.map(item=>{
                    return item
                })
            }

            let param = {
                adjustNumber:this.info.search.adjustNumber,
                departmentList:newDepartmentList,
                areaNumberList:this.info.search.areaNumberList,  //大区
                remark:this.info.search.remark,
                state:this.info.search.state,   //状态
                searchName:this.info.search.searchName,
                startDate:this.DateFormat(this.info.search.datePoint[0]),
                endDate:this.DateFormat(this.info.search.datePoint[1]),
                exportTitle:this.BoxExportInfo.exportTitle,   //重要：导出标题
                page:this.info.page,
                size:this.info.size
            }
            this.$api.finance.ExportPriceAdjustDetail(param).then(res=>{
                loading.close();
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    this.BoxExportInfo.isVisible=false;  //关闭导出记录-弹出框
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

    },
    mounted(){
        this.GetDataList();
    },

}
</script>

<style scoped>
.defPrice{color: rgb(57, 116, 245);}
.minPrice{color: red;}
.PriceSize{font-size: 12px;}
</style>