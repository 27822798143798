<template>
  <div>
    <!-- 非现金业务列表 -->
    <el-row>
        <el-col :span="24">
            <el-form :inline="true">

                <el-form-item label="单号">
                    <el-input v-model="info.search.billNumber" maxlength="20" class="search-150"></el-input>
                </el-form-item>

                <el-form-item label="部门">
                    <el-tree-select v-model="info.search.selectDepartmentList" :data="departmentList" multiple clearable :default-expand-all="true" collapse-tags collapse-tags-tooltip class="search-200">
                    </el-tree-select>
                </el-form-item>

                <el-form-item label="财务类型">
                    <el-select v-model="info.search.billType" class="search-150">
                        <el-option label="全部" :value="0" />
                        <el-option v-for="typeInfo in financeTypeList" :key="typeInfo.typeValue" :label="typeInfo.typeName" :value="typeInfo.typeValue"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="会计科目">
                    <el-tree-select v-model="info.search.selectSubIdList" :data="selectSubjectList" multiple :default-expand-all="true" collapse-tags collapse-tags-tooltip class="search-200"> 
                    </el-tree-select>
                </el-form-item>

                <el-form-item label="状态">
                    <el-select v-model="info.search.stateList" multiple class="search-150" collapse-tags collapse-tags-tooltip>
                        <el-option :value="0" label="全部"></el-option>
                        <el-option :value="1" label="新建单"></el-option>
                        <el-option :value="2" label="待审核"></el-option>
                        <el-option :value="3" label="已驳回"></el-option>
                        <el-option :value="4" label="审核通过并上报"></el-option>
                        <el-option :value="5" label="审核已完成"></el-option>
<!--                    <el-option :value="10" label="已关单"></el-option> -->
                    </el-select>
                </el-form-item>

                <el-form-item label="记账日期">
                    <el-date-picker
                        v-model="info.search.datePoint"
                        type="daterange"
                        range-separator="To"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        style="width:250px"
                    />
                </el-form-item>

                <el-form-item>
                    <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
                    <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
                </el-form-item>
            </el-form>            
        </el-col>
    </el-row>

    <div>
        <!-- 功能栏 -->
        <el-row :gutter="10" class="operation">
            <el-col :span="15">
                <el-button v-if="CheckUserButtonAuth('BtnFinanceRefresh')" class="iconfont icon-exchangerate" plain @click="btnRefresh">刷新</el-button>
                <!-- <el-button class="iconfont icon-ashbin" type="danger" plain @click="btnBatchDeleteInfo">删除</el-button> -->
            </el-col>

            <el-col :span="9" class="operation_hidden">
                <el-button-group>
                    
                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="导出PDF"
                    placement="top">
                    <el-button class="iconfont icon-xiazaiPDF" @click="btnNoFlow"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="打印"
                    placement="top">
                    <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="导出数据"
                    placement="top">
                    <el-button class="iconfont icon-download" @click="btnNoExport"></el-button>
                    </el-tooltip>
                </el-button-group> 
            </el-col>
        </el-row>

        <!-- 数据列表 -->
        <el-table :data="dataList" border stripe show-summary :height="GetDataHeight">
            <el-table-column prop="id" type="selection" width="55"></el-table-column>
            <el-table-column label="财务单号" width="165">
                <template v-slot="scope">
                    <span>{{scope.row.billNumber}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="departmentName" label="部门名称" width="150">
                <template v-slot="scope">
                    <el-tooltip
                        class="box-item"
                        effect="dark"
                        :content="scope.row.departmentName"
                        placement="top"
                    >
                    <div class="cellOverFlow">{{scope.row.departmentName}}</div>
                  </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column prop="financeTypeName" label="财务类型" min-width="100">
            </el-table-column>
            
            <el-table-column label="会计科目" min-width="140">
                <template #default="scope">
                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.subFullName"
                    placement="top"
                  >
                  <div class="cellOverFlow">{{scope.row.subFullName}}</div>
                  </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column prop="totalPrice" label="交易金额(元)" width="110"></el-table-column>
            <el-table-column label="状态">
                <template #default="scope">
                    <el-tag v-if="scope.row.state ==1">新建单</el-tag>
                    <el-tag v-if="scope.row.state ==2">待审核</el-tag>
                    <el-tag v-if="scope.row.state ==3" type="danger">已驳回</el-tag>
                    <el-tag v-if="scope.row.state ==4" type="warning">审核通过并上报</el-tag>
                    <el-tag v-if="scope.row.state ==5" type="success">审核已完成</el-tag>
                    <el-tag v-if="scope.row.state ==10">已关单</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="记账时间" width="110">
                <template #default="scope">
                    <el-tooltip
                        class="box-item"
                        effect="dark"
                        :content="scope.row.billTime"
                        placement="top"
                    >
                    <div class="cellOverFlow">{{DateFormat(scope.row.billTime)}}</div>
                  </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column label="创建时间" width="110">
                <template #default="scope">
                    <el-tooltip
                        class="box-item"
                        effect="dark"
                        :content="scope.row.createTime"
                        placement="top"
                    >
                    <div class="cellOverFlow">{{DateFormat(scope.row.createTime)}}</div>
                  </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column label="备注" width="100">
                <template v-slot="scope">
                    <el-tooltip
                        class="box-item"
                        effect="dark"
                        :content="scope.row.remark"
                        placement="top">
                        <div class="cellOverFlow">{{scope.row.remark}}</div>
                    </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="120">
              <template v-slot="scope">
                <el-button size="small" type="primary" @click="btnEditInfo(scope.row.billNumber)">详情</el-button>
               
              </template>
            </el-table-column>

        </el-table>
    </div>

    <!-- 分页 -->
    <el-affix position="bottom">
        <el-row class="page_two">
            <el-col :span="24">
                <!-- 分页 -->
                <el-container class="page_right">
                    <el-pagination
                    v-model:currentPage="info.page"
                    v-model:page-size="info.size"
                    :page-sizes="[50, 200, 500, 1000]"
                    :background="true"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="info.total"
                    @current-change="btnPagechange"
                    @size-change="btnSizechange"
                    >
                    </el-pagination>
                </el-container>
            </el-col>
        </el-row>        
    </el-affix>

    <!-- 编辑 弹出框 -->
    <el-drawer
      :title="BoxEditInfo.title" 
      v-model="BoxEditInfo.isVisible"
      direction="ltr"
      size="85%"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      @closed="GetDataList">
        <EditNoCash :editId="info.id" :dataList="dataList" @closeBox="btnCloseBox"></EditNoCash>
    </el-drawer>

  </div>
</template>

<script>
import subjectList from '@/utils/mixins/SubjectList.js'   //会计科目-下拉列表-目录树
import UserDptList from '@/utils/mixins/UserDptList.js'   //部门-下拉列表-目录树
import FinaceTypeList from '@/utils/mixins/FinanceTypeList.js'  //财务单类型列表

import EditNoCash from '@/views/Fms/NoCash/EditNoCash.vue'  //新增、编辑详情页
import { ElLoading, ElMessage } from 'element-plus'
export default {
    mixins:[UserDptList,subjectList,FinaceTypeList],
    components:{
        EditNoCash,
    },
    data(){
        return{
            dataList:[],  //Table数据
            info:{
                page:1,
                size:50,
                total:0,  //总记录数
                id:0,  //新增、编辑 的参数
                ids:[],   //删除 的参数
                search:{
                    billNumber:'',
                    selectDepartmentList:[],   //部门组
                    billType:0,   //财务类型
                    datePoint:'',    //记账时间范围
                    selectSubIdList:[],   //会计科目
                    stateList:[],  //状态
                },
            }, 
            //编辑-弹出框
            BoxEditInfo:{
              isVisible:false,
              title:'非现金财务单详情'
            },           
        }
    },
    computed:{
        //自适应窗体高度
        GetDataHeight(){
            return window.innerHeight-270
        }
    },
    methods:{
      
        //加载数据列表
        GetDataList(){
            //打开遮罩层
            const loading = ElLoading.service({
                lock:true,
                text:'正在提交,请稍候!',
                background:'rgba(0, 0, 0, 0.7)',
            })

            //记账时间范围
            if(this.info.search.datePoint == null){
                this.info.search.datePoint = ''
            }

            //获取、转换部门
            let newDepartmentList = []
            if(this.info.search.selectDepartmentList!=null){
                newDepartmentList = this.info.search.selectDepartmentList.map(item=>{
                    return item
                })
            }

            //会计科目
            let newSubList = []
            if(this.info.search.selectSubIdList !=null){
                newSubList = this.info.search.selectSubIdList.map(item=>{
                    return item
                })
            }

            let param = {
                billNumber:this.info.search.billNumber,
                departmentList:newDepartmentList,
                billType:this.info.search.billType,
                subIdList:newSubList,
                stateList:this.info.search.stateList,
                startDate:this.DateFormat(this.info.search.datePoint[0]),
                endDate:this.DateFormat(this.info.search.datePoint[1]),
                page:this.info.page,
                size:this.info.size
            }
            this.$api.finance.PageFinanceNoCashList(param).then(res=>{
                loading.close();
                if(res.data.status === 200){
                    this.dataList = res.data.data;  //获取数据
                    //分页
                    this.info.total = res.data.total
                    this.info.page = res.data.pageNumber
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

        //选中多选框
        btnCheckbox(selection){
            this.info.ids = selection.map(item=>item.billNumber)
        },
        //分页-页数变化时
        btnPagechange(page){
            this.info.page = page
            this.GetDataList() //加载Table数据
        },
        //分页-单页记录数变化时
        btnSizechange(size){
            this.info.size = size
            this.info.page = 1
            this.GetDataList() //加载Table数据
        },
        //财务单详情
        btnEditInfo(id){
            this.info.id = id
            this.BoxEditInfo.isVisible = true;
        },

        //重置按钮
        btnResetSearch(){
            this.info.search.billNumber ='';  //财务单号
            this.info.search.selectDepartmentList=[];  //所属部门
            this.info.search.billType = 0;  //财务类型
            this.info.search.stateList = [];   //状态列表
            this.info.search.datePoint = '';   //记账日期
            this.info.search.selectSubIdList = [];   //会计科目
            this.info.ids = [];  //编号数组
            this.info.page = 1;
            this.GetDataList();
            
        },

        //关闭弹出框
        btnCloseBox(){
            this.BoxEditInfo.isVisible = false;
        },

    },
    mounted(){
        this.GetDataList();
    },
}
</script>

<style>

</style>