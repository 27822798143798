<template>
  <div>
    <!-- 创建对账单弹出框 -->
    <el-divider></el-divider>

    <el-tabs v-model="info.activeName">
        <el-tab-pane label="对账单" name="TabInfo">
            <TabCycle ref="tabInfo"></TabCycle>
        </el-tab-pane>

        <el-tab-pane label="发票信息" name="TabInvoice">
            <el-divider class="tabInvoice">我方发票信息</el-divider>
            <TabOurInvoice></TabOurInvoice>

            <el-divider class="tabInvoice">对方发票信息</el-divider>
            <TabTradeInvoice></TabTradeInvoice>
        </el-tab-pane>

        <el-tab-pane label="回执单" name="Tabsss">
            <TabReceipt></TabReceipt>
        </el-tab-pane>

        <el-tab-pane label="备注记录" name="tabRecord">
            <TabCycleRecord></TabCycleRecord>
        </el-tab-pane>

    </el-tabs>

    <div class="btnGroup">
        <el-button v-if="CheckUserButtonAuth('BtnBusinessSave')" type="primary" @click="btnSaveCycleAllInfo">保存</el-button>
        <el-button @click="btnClose">关闭</el-button>
    </div>

  </div>
</template>

<script>
import TabCycle from '@/views/Fms/Cycle/TabCycleInfo.vue'
import TabOurInvoice from '@/views/Fms/Cycle/TabOurInvoice.vue'
import TabTradeInvoice from '@/views/Fms/Cycle/TabTradeInvoice.vue'
import TabReceipt from '@/views/Fms/Cycle/TabReceipt.vue'
import TabCycleRecord from '@/views/Fms/Cycle/TabCycleRecord.vue'

import { mapMutations, mapState } from 'vuex'
import { ElMessage } from 'element-plus'

export default {
    props:['numberList','newCycle'],
    components:{
        TabCycle,
        TabOurInvoice,
        TabTradeInvoice,
        TabReceipt,
        TabCycleRecord,
    },
    data(){
        return{
            info:{
                activeName:'TabInfo',
            },

        }
    },
    computed:{
        ...mapState('Finance',['cycleInfo']),
    },
    methods:{
        ...mapMutations('Finance',['SetCycleInfo']),

        //加载数据
        GetDataInfo(){
            this.btnClearData();
        },

        //保存
        btnSaveCycleAllInfo(){
            //#region 财务单列表
            if(this.numberList == null || this.numberList.length<=0){
                ElMessage.error('请选择财务单');
                return false;
            }

            let newOriginList = [];
            this.numberList.forEach(item => {
                let originInfo = {
                    systemNumber:'',
                    cycleNumber:'',
                    originNumber:item,
                }
                newOriginList.push(originInfo);
            });
            this.cycleInfo.originList = newOriginList;
            //#endregion

            this.$api.finance.SaveCycleAllInfo(this.cycleInfo).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    this.btnClose();
                }else{
                    ElMessage.error(res.data.msg);
                }
            })

        },

        //清空数据
        btnClearData(){
            let newInfo = {
                info:{
                    systemNumber:'',
                    cycleNumber:'',
                    title:'',
                    tradeType:0,
                    tradeNumber:'',
                    startDate:'',
                    endDate:'',
                    totalPrice:0,
                    queueTime:'',
                    payTime:'',
                    accountNumber:'',
                    isFit:0,
                    state:1,
                },
                receiptInfo:{
                    id:0,
                    systemNumber:'',
                    cycleNumber:'',
                    invoiceNumber:'',
                    invoicePrice:0,
                    sendDate:'',
                    receiveDate:'',
                    originalFile:'',
                    isDel:0,
                },
                originList:[],
                ourInvoiceList:[],
                tradeInvoiceList:[],
                recordList:[],
            }
            //获取主信息
            if(this.newCycle !=null){
                newInfo.info.departmentId = this.newCycle.departmentId;
                newInfo.info.tradeNumber = this.newCycle.tradeNumber;
                this.newCycle.priceList.forEach(element=>{
                    newInfo.info.totalPrice = newInfo.info.totalPrice + parseFloat(element)
                })
            }
            //我方默认发票
            this.$api.finance.GetDefInvoiceInfo().then(res=>{
                if(res.data.status === 200){
                    res.data.data.id=0;
                    newInfo.ourInvoiceList.push(res.data.data);
                }
            });            
            //交易方默认发票(目前只支持供应商。 客户和员工没有发票信息)
            if(this.newCycle !=null && this.newCycle.tradeType===1){
                let param2 = {
                    supplierNumber:this.newCycle.tradeNumber,  //供应商编号
                }
                this.$api.supplier.GetDefInvoice(param2).then(res=>{
                    if(res.data.status === 200){
                        if(res.data.data != null){
                            res.data.data.id = 0;
                            newInfo.tradeInvoiceList.push(res.data.data);
                        }                        
                    }
                })
            }
            //加载
            setTimeout(()=>{
                this.SetCycleInfo(newInfo);
            },500)
            
        },

        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },
    },
    mounted(){
        this.GetDataInfo();
    }
    
}
</script>

<style scoped>
.tabInvoice{margin-top: 15px;}
</style>