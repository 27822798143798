<template>
  <div>
    <!-- 工作表 -->

    <div>
      <!-- 功能栏 -->
      <el-row :gutter="10" class="operation">
        <el-col :span="15">
            <el-button class="iconfont icon-exchangerate" type="primary" plain @click="UpdateMonthWorkSheet()">更新设置</el-button>
        </el-col>

        <el-col :span="9" class="operation_hidden">
            <el-button-group>
                                            
                <el-tooltip
                class="box-item"
                effect="dark"
                content="流程图"
                placement="top">
                <el-button class="iconfont icon-icon__liuchengtu" @click="btnNoFlow"></el-button>
                </el-tooltip>

                <el-tooltip
                class="box-item"
                effect="dark"
                content="打印"
                placement="top">
                <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
                </el-tooltip>

                <el-tooltip
                class="box-item"
                effect="dark"
                content="导出数据"
                placement="top">
                <el-button class="iconfont icon-download" @click="btnNoExport"></el-button>
                </el-tooltip>
            </el-button-group>    
            
        </el-col>
      </el-row>

      <!-- 日历 -->
      <el-calendar v-model="CurrentDate" :first-day-of-week="1">
        <template #date-cell="{data}">
            <div @click="ShowDateInfo(data.day)">
                {{data.day.split('-').splice(2).join('-')}}
                <div v-for="(item,index) in sheetList" :key="index">
                    <div v-if="item.ruleDay == data.day">
                        <el-button type="primary" circle v-if="item.ruleType==2">班</el-button>
                        <el-button type="danger"  circle v-else>假</el-button>
                    </div>
                </div>
            </div>         
        </template>
      </el-calendar>

    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { ElMessage } from 'element-plus'
export default {
  data(){
    return{
      CurrentDate:new Date(),  //当日
      CurrentMonth:moment(new Date()).format("YYYY-MM"),  //当月
      sheetList:[],  //工作表
    }
  },
  methods:{
    //加载指定月的数据
    GetDataInfo(){
      let param = {
        yearMonth:this.CurrentMonth
      }
      this.$api.oa.ListWorkSheet(param).then(res=>{
        if(res.data.status === 200){
          if(res.data.data !=null){
            this.sheetList = res.data.data.map(item => {
              item.ruleDay = moment(item.ruleDay).format("YYYY-MM-DD")
              return item
            });
          }
        }
      })
    },

    //更新指定月份工作表
    UpdateMonthWorkSheet(){
      let param = {
        yearMonth:moment(this.CurrentMonth).format("YYYY-MM")
      }
      this.$api.oa.BatchSaveWorkSheet(param).then(res=>{
        if(res.data.status === 200){
          ElMessage.success(res.data.msg);
          //重新加载
          this.GetDataInfo();
        }
      })
    },

    //获取单日信息
    ShowDateInfo(date){
      console.log(date)
    },

  },
  created(){
    this.$nextTick(()=>{
      // 点击上个月
      let prevBtn = document.querySelector('.el-calendar__button-group .el-button-group>button:nth-child(1)');
      prevBtn.addEventListener('click', () => {
        this.CurrentMonth = moment(this.CurrentDate).format("YYYY-MM");
        this.GetDataInfo();
      })
      // 点击今天
      let currBtn = document.querySelector('.el-calendar__button-group .el-button-group>button:nth-child(2)');
      currBtn.addEventListener('click', () => {
        this.CurrentMonth = moment(this.CurrentDate).format("YYYY-MM");
        this.GetDataInfo();
      })
      // 点击下个月
      let nextBtn = document.querySelector('.el-calendar__button-group .el-button-group>button:nth-child(3)');
      nextBtn.addEventListener('click', () => {
        this.CurrentMonth = moment(this.CurrentDate).format("YYYY-MM");
        this.GetDataInfo();
      })
    })
  },
  mounted(){
    this.GetDataInfo();  //加载当前月工作表
  }
}
</script>

<style>
.rili {top:3px;margin-left: 15px;}
</style>