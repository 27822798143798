<template>
  <div>
    <!-- 部门交易统计 -->

    <!-- 标题 -->
    <el-row>
        <el-col :span="24" class="title">
            <h3>部门交易统计</h3>
        </el-col>        
    </el-row>

    <!-- 查询条件 -->
    <div>
        <el-form :inline="true">

            <el-form-item label="大区">
                <el-select v-model="search.anList" class="search-200" :clearable="true" multiple>
                    <el-option v-for="areaInfo in allAreaList" :key="areaInfo.areaNumber" :label="areaInfo.areaName" :value="areaInfo.areaNumber"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="部门">
                <el-tree-select v-model="search.dptList" :data="departmentList" :default-expand-all="true" :clearable="true" class="search-200" multiple />
            </el-form-item>

            <el-form-item label="日期">
                <el-date-picker
                    v-model="search.datePoint"
                    type="daterange"
                    range-separator="To"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    style="width:250px"
                />
            </el-form-item>

            <el-form-item>
                <el-button class="iconfont icon-search" plain type="primary">查询</el-button>
                <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
            </el-form-item>

        </el-form>
    </div>

    <!-- 统计结果 -->
    <el-row :gutter="10">

         <!-- 采购 -->
        <el-col :span="6">
            <el-card class="box-card">
                <template #header>
                <div class="card-header">
                    <span>采购统计</span>
                </div>
                </template>
                <div class="cardMain">
                    <div>采购额：<span class="mainPrice">564613.46</span>元</div>
                    <div>采购单数：<span class="mainPrice">564</span>单</div>
                </div>      
            </el-card>
        </el-col>

        <!-- 销售 -->
        <el-col :span="6">
            <el-card class="box-card">
                <template #header>
                <div class="card-header">
                    <span>销售统计</span>
                </div>
                </template>
                <div class="cardMain">
                    <div>销售额：<span class="mainPrice">564613.46</span>元</div>
                    <div>销售单数：<span class="mainPrice">564</span>单</div>
                    <div>毛利润：<span class="mainPrice">64613.46</span>元</div>
                    <div>毛利率：<span class="mainPrice">24.36</span>%</div>
                </div>     
            </el-card>
        </el-col>

        <!-- 退货单 -->
        <el-col :span="6">
            <el-card class="box-card">
                <template #header>
                <div class="card-header">
                    <span>退货统计</span>
                </div>
                </template>
                <div class="cardMain">
                    <div>退货额：<span class="mainPrice">564613.46</span>元</div>
                    <div>退货单数：<span class="mainPrice">564</span>单</div>
                    <div>毛利润：<span class="mainPrice">64613.46</span>元</div>
                    <div>毛利率：<span class="mainPrice">24.36</span>%</div>
                </div>     
            </el-card>
        </el-col>

        <!-- 加工 -->
        <el-col :span="6">
            <el-card class="box-card">
                <template #header>
                <div class="card-header">
                    <span>加工统计</span>
                </div>
                </template>
                <div class="cardMain">
                    <el-progress type="circle" :width="200" :stroke-width="20" :percentage="90" />
                </div>        
            </el-card>
        </el-col>

        <!-- 加工 -->
        <el-col :span="6">
            <el-card class="box-card">
                <template #header>
                <div class="card-header">
                    <span>财务统计</span>
                </div>
                </template>
                <div class="cardMain">
                    <div>收款：<span class="mainPrice">23313.12</span>元</div>
                    <div>付款：<span class="mainPrice">13223.11</span>元</div>
                    <div>财务单：<span class="mainPrice">564</span>单</div>
                    <div>未结款：<span class="mainPrice">156</span>单</div>
                    <div>已结款：<span class="mainPrice">86</span>单</div>
                </div>        
            </el-card>
        </el-col>

        <!-- 零售 -->
        <el-col :span="6">
            <el-card class="box-card">
                <template #header>
                <div class="card-header">
                    <span>零售统计</span>
                </div>
                </template>
                <div class="cardMain">
                    <div>销售额：<span class="mainPrice">564613.46</span>元</div>
                    <div>销售单数：<span class="mainPrice">564</span>单</div>
                    <div>毛利润：<span class="mainPrice">64613.46</span>元</div>
                    <div>毛利率：<span class="mainPrice">24.36</span>%</div>
                </div>        
            </el-card>
        </el-col>

        <!-- 退零单 -->
        <el-col :span="6">
            <el-card class="box-card">
                <template #header>
                <div class="card-header">
                    <span>退零统计</span>
                </div>
                </template>
                <div class="cardMain">
                    <div>销售额：<span class="mainPrice">564613.46</span>元</div>
                    <div>销售单数：<span class="mainPrice">564</span>单</div>
                    <div>毛利润：<span class="mainPrice">64613.46</span>元</div>
                    <div>毛利率：<span class="mainPrice">24.36</span>%</div>
                </div>        
            </el-card>
        </el-col>

        <!-- 电商 -->
        <el-col :span="6">
            <el-card class="box-card">
                <template #header>
                <div class="card-header">
                    <span>电商统计</span>
                </div>
                </template>
                <div class="cardMain">
                    <div>销售额：<span class="mainPrice">564613.46</span>元</div>
                    <div>销售单数：<span class="mainPrice">564</span>单</div>
                    <div>毛利润：<span class="mainPrice">64613.46</span>元</div>
                    <div>毛利率：<span class="mainPrice">24.36</span>%</div>
                </div>        
            </el-card>
        </el-col>

    </el-row>

  </div>
</template>

<script>
import UserDptList from '@/utils/mixins/UserDptList.js'
export default {
    mixins:[UserDptList],
    data(){
        return{
            search:{
                anList:[],
                dptList:[],  //部门选中列表
                datePoint:'',
            },
            allAreaList:[],  //大区列表
        }
    },
    methods:{
        
    },
}
</script>

<style scoped>
.title{text-align: center;}

.box-card{margin-top: 10px;}
.cardMain{height: 200px;}
.mainPrice{color: #cc0033;font-size: 25px;}
</style>