<template>
  <div>

    <!-- 搜索功能 -->
    <el-row :style="showSearch.search_hidden">
        <el-col :span="24">
            <el-form :inline="true">

                <el-form-item label="排班日期">
                    <el-date-picker
                        v-model="info.search.datePoint"
                        type="daterange"
                        range-separator="To"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                    />
                </el-form-item>

                <el-form-item label="员工姓名">
                  <el-select v-model="info.search.userNumberList" clearable multiple class="search-200">
                      <el-option v-for="info in selectUserList" :key="info.userNumber" :label="info.userName" :value="info.userNumber"></el-option>
                  </el-select>
                </el-form-item>

                <el-form-item>
                <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
                <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
                </el-form-item>

            </el-form>
          </el-col>
    </el-row>

    <!-- 主内容区 -->
    <div>
      <!-- 功能栏 -->
        <el-row :gutter="10" class="operation">
          <el-col :span="15">
            <el-button class="iconfont icon-add" type="primary" plain @click="btnEditInfo(0)">新增</el-button>
            <el-popconfirm 
                title="确认要删除吗?"
                @confirm="btnBatchDeleteInfo">
                  <template #reference>
                    <el-button class="iconfont icon-ashbin" type="danger" plain>删除</el-button>
                  </template>
                </el-popconfirm>
          </el-col>

          <el-col :span="9" class="operation_hidden">
            <el-button-group>
                                            
                <el-tooltip
                class="box-item"
                effect="dark"
                content="流程图"
                placement="top">
                <el-button class="iconfont icon-icon__liuchengtu" @click="btnNoFlow"></el-button>
                </el-tooltip>

                <el-tooltip
                class="box-item"
                effect="dark"
                content="打印"
                placement="top">
                <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
                </el-tooltip>

                <el-tooltip
                class="box-item"
                effect="dark"
                content="导出数据"
                placement="top">
                <el-button class="iconfont icon-download" @click="btnNoExport"></el-button>
                </el-tooltip>
            </el-button-group>    
            
          </el-col>
        </el-row>

      <!-- 列表 -->
      <el-table :data="info.dataList" border stripe :height="GetDataHeight" style="width: 100%" @select="btnCheckbox" @select-all="btnCheckbox">
        <el-table-column prop="id" type="selection" width="50" />  
        <el-table-column label="日期" >
          <template #default="scope">
            <span>{{DateFormat(scope.row.today)}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="departmentName" label="部门"  />
        <el-table-column prop="userName" label="员工姓名"  />
        <el-table-column prop="className" label="班次" />
        <el-table-column label="操作">
          <template v-slot="scope">              
            <el-popconfirm 
              title="确认要删除吗?"
              @confirm="btnDeleteInfo(scope.row.id)">
                <template #reference>
                  <el-button size="small" type="danger">删除</el-button>
                </template>
              </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <el-container class="page_one">
          <el-pagination
            v-model:currentPage="info.page"
            v-model:page-size="info.size"
            :page-sizes="[50, 200, 500]"
            :background="true"
            layout="total, sizes, prev, pager, next, jumper"
            :total="info.total"
            @current-change="btnPagechange"
            @size-change="btnSizechange"
          >
          </el-pagination>
      </el-container>

    </div>

    <!-- 新增、编辑 弹出框 -->
    <el-dialog :title="BoxEditInfo.title" 
      v-model="BoxEditInfo.isVisible"
      width="50%"
      :draggable="true"
    :close-on-click-modal="false"
      :destroy-on-close="true"
      @closed="GetDataList">
        <EditSchedule @closeBox="btnCloseBox"></EditSchedule>
    </el-dialog>


  </div>
</template>

<script>
import EditSchedule from '@/views/Oa/Schedule/EditSchedule.vue'

import AllUserList from '@/utils/mixins/AllUserList.js'
import { ElMessage } from 'element-plus'
export default {
    components:{
        EditSchedule
    },
    mixins:[AllUserList],
    data(){
      return{
        info:{
          dataList:[],  //Table数据
          page:1,   //页数
          size:50,  //单页记录数
          total:0,  //总记录数
          id:0,  //新增、编辑 的参数
          ids:[],   //删除 的参数
          search:{  //搜索条件
            datePoint:'',
            userNumberList:[],
          }
        },
        //编辑-弹出框
        BoxEditInfo:{
          isVisible:false,
          title:'新增排班'
        }

      }
    },
    computed:{
        //自适应窗体高度
        GetDataHeight(){
            return window.innerHeight-290
        }
    },
    methods:{
        //获取数据列表
        GetDataList(){
          if(this.info.search.datePoint == null){
            this.info.search.datePoint = ''
          }

          let param = {
            startDate:this.DateFormat(this.info.search.datePoint[0]),
            endDate:this.DateFormat(this.info.search.datePoint[1]),
            userNumberList:this.info.search.userNumberList,
            page:this.info.page,
            size:this.info.size
          }
          
          this.$api.oa.ListSchedule(param).then(res=>{
            if(res.data.status === 200){
              this.info.dataList = res.data.data;
              //分页
              this.info.total = res.data.total
              this.info.page = res.data.pageNumber
            }
          })
        },

        //选中多选框
        btnCheckbox(selection){
            this.info.ids = selection.map(item=>item.id)
        },
        //分页-页数变化时
        btnPagechange(page){
            this.info.page = page
            this.GetDataList() //加载Table数据
        },
        //分页-单页记录数变化时
        btnSizechange(size){
            this.info.size = size
            this.info.page = 1
            this.GetDataList() //加载Table数据
        },

        //编辑
        btnEditInfo(id){
            this.info.id = id
            this.BoxEditInfo.isVisible = true;
        },

        //关闭弹出框
        btnCloseBox(){
            this.BoxEditInfo.isVisible = false;
        },

        //删除按钮
        btnDeleteInfo(id){
            let param = [id]
            this.$api.oa.DeleteSchedule(param).then(res=>{
            if(res.data.status === 200){
                ElMessage.success(res.data.msg)
                this.GetDataList()
            }
            else{
                ElMessage.error(res.data.msg)
            }
            })
        },
        //批量删除
        btnBatchDeleteInfo(){
            if(this.info.ids.length<1){
                ElMessage.error('请选择要删除的选项')
                return false
            }

            this.$api.oa.DeleteSchedule(this.info.ids).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg)
                    this.GetDataList()
                }
                else{
                    ElMessage.error(res.data.msg)
                }
            })
        },

        //重置按钮
        btnResetSearch(){
            this.info.search.userNumberList = [];
            this.info.search.datePoint = '',
            this.info.page = 1,
            this.GetDataList()
        },     

    },
    mounted(){
      this.GetDataList();   //获取列表
    }
}
</script>

<style>

</style>