<template>
  <div>
    <!-- 系统设置 -->

    <el-descriptions title="系统设置"
    :column="3"
    border>
        <template #extra>
          <el-button type="primary" @click="btnSaveSetSystem">保存设置</el-button>
        </template>

        <el-descriptions-item label="是否开启价格参考功能">
            <el-select v-model="info.priceFlag" class="search-200">
              <el-option label="关闭" :value="0"></el-option>
              <el-option label="开启" :value="1"></el-option>
            </el-select>
        </el-descriptions-item>

        <el-descriptions-item label="库存滞销预警天数">            
            <el-input-number v-model="info.stockDay" :min="0" :max="100"></el-input-number>
        </el-descriptions-item>

        <el-descriptions-item label="微信AppSecret">            
            <el-input v-model="info.wxAppSecret" maxlength="200"></el-input>
        </el-descriptions-item>

        <el-descriptions-item label="微信商户ID">            
            <el-input v-model="info.wxMchid" maxlength="200"></el-input>
        </el-descriptions-item>


    </el-descriptions>

  </div>
</template>

<script>
import { ElMessage } from 'element-plus';
export default {
  data(){
    return{
      info:{
        id:0,
        systemNumber:'',
        priceFlag:0,   //开启报价参考功能
        stockDay:5,   //库存积压预警天数
      }
    }
  },
  methods:{
    //加载数据
    GetDataInfo(){
      this.$api.common.GetSetSystemInfo().then(res=>{
        if(res.data.status===200){
          this.info = res.data.data;
        }
      })
    },

    //保存
    btnSaveSetSystem(){
      this.$api.common.SaveSetSystemInfo(this.info).then(res=>{
        if(res.data.status === 200){
          ElMessage.success(res.data.msg);
        }else{
          ElMessage.error(res.data.msg);
        }
      })
    },

  },
  mounted(){
    this.GetDataInfo();
  },
}
</script>

<style>

</style>