<template>
  <div>
    <!-- 报价分享单 -->

    <!-- 搜索功能 -->
    <el-row>
        <el-col :span="24">
            <el-form :inline="true">

                <el-form-item label="报价单号">
                    <el-input v-model="info.search.shareNumber" placeholder="报价单号" class="search-200"></el-input>
                </el-form-item>

                <el-form-item label="名称">
                    <el-input v-model="info.search.title" placeholder="名称" class="search-200"></el-input>
                </el-form-item>

                <el-form-item>
                    <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
                    <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
                </el-form-item>

            </el-form>
        </el-col>
    </el-row>

    <div>
        <!-- 功能栏 -->
        <el-row :gutter="10" class="operation">
            <el-col :span="15">
                <el-button v-if="CheckUserButtonAuth('BtnPriceQuoteRefresh')" class="iconfont icon-add" type="primary" plain @click="btnEditInfo('0')">新增</el-button>
                <el-button v-if="CheckUserButtonAuth('BtnPriceQuoteDelete')" class="iconfont icon-ashbin" type="danger" plain @click="btnBatchDeleteInfo">删除</el-button>
            </el-col>

            <el-col :span="9" class="operation_hidden">
                <el-button-group>
                                                    
                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="流程图"
                    placement="top">
                    <el-button class="iconfont icon-icon__liuchengtu" @click="btnNoFlow"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="打印"
                    placement="top">
                    <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="导出数据"
                    placement="top">
                    <el-button class="iconfont icon-download" @click="btnNoExport"></el-button>
                    </el-tooltip>
                </el-button-group>        
                
            </el-col>
        </el-row>

        <!-- 报价单列表 -->
        <el-table :data="dataList" border stripe :height="tableHeight" @select="btnCheckbox" @select-all="btnCheckbox">
            <el-table-column type="selection" width="55" prop="shareNumber"></el-table-column>
            <el-table-column label="单号"  min-width="150">
                <template v-slot="scope">
                    <span>{{scope.row.shareNumber}}</span>
                </template>
            </el-table-column>

            <el-table-column prop="title" label="名称" min-width="90" show-overflow-tooltip></el-table-column>
          
           <el-table-column prop="createName" label="创建人" min-width="90" show-overflow-tooltip/>
            
            <el-table-column label="创建时间" min-width="110">
                <template #default="scope">
                    <el-tooltip
                        class="box-item"
                        effect="dark"
                        :content="scope.row.createTime"
                        placement="top"
                    >
                    <span>{{DateFormat(scope.row.createTime)}}</span>
                    </el-tooltip>                
                </template>
            </el-table-column>
            
            <el-table-column label="操作" width="180" fixed="right">
              <template v-slot="scope">
                <el-button size="small" type="warning" @click="btnEditInfo(scope.row.shareNumber)">分享</el-button>
                <el-button size="small" type="primary" @click="btnEditInfo(scope.row.shareNumber)">详情</el-button>
                <el-button v-if="CheckUserButtonAuth('BtnPriceAdjustDelete')" size="small" type="danger" @click="btnDeleteInfo(scope.row.shareNumber)">删除</el-button>
              </template>
            </el-table-column>

        </el-table>
    </div>
    

    <el-affix position="bottom">
        <el-row class="page_two">
            <el-col :span="24">
                <!-- 分页 -->
                <el-container class="page_right">
                    <el-pagination
                    v-model:currentPage="info.page"
                    v-model:page-size="info.size"
                    :page-sizes="[50, 200, 500]"
                    :background="true"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="info.total"
                    @current-change="btnPagechange"
                    @size-change="btnSizechange"
                    >
                    </el-pagination>
                </el-container>
            </el-col>
        </el-row>        
    </el-affix>

    <!-- 新增、编辑 弹出框 -->
    <el-drawer
      :title="BoxEditInfo.title" 
      v-model="BoxEditInfo.isVisible"
      direction="ltr"
      size="85%"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      @closed="GetDataList">
        <EditPriceShare :editId="info.id" :dataList="dataList" @closeBox="btnCloseBox"></EditPriceShare>
    </el-drawer>

  </div>
</template>

<script>
import EditPriceShare from '@/views/Fms/PriceShare/EditPriceShare.vue'
import TableHeight from '@/utils/mixins/TableHeight.js'  //设置Table高度
import { ElMessage, ElMessageBox } from 'element-plus'
export default {
    mixins:[TableHeight],
    components:{
        EditPriceShare,
    },
    data(){
        return{
            dataList:[],  //table数据
            info:{
                page:1,
                size:50,
                total:0,  //总记录数
                id:0,  //新增、编辑 的参数
                ids:[],   //删除 的参数
                search:{
                    shareNumber:'',  //报价单号
                    title:'',    //名称
                },
            },
            //编辑-弹出框
            BoxEditInfo:{
              isVisible:false,
              title:'报价单'
            },
        }
    },
    methods:{
        //加载数据列表
        GetDataList(){
            let param = {
                shareNumber:this.info.search.shareNumber,
                title:this.info.search.title,
                page:this.info.page,
                size:this.info.size
            }
            this.$api.finance.PagePriceShareList(param).then(res=>{
                if(res.data.status===200){
                    this.dataList = res.data.data;
                    //分页
                    this.info.total = res.data.total;
                    this.info.page = res.data.pageNumber;
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

        //选中多选框
        btnCheckbox(selection){
            this.info.ids = selection.map(item=>item.shareNumber)
        },
        //分页-页数变化时
        btnPagechange(page){
            this.info.page = page
            this.GetDataList() //加载Table数据
        },
        //分页-单页记录数变化时
        btnSizechange(size){
            this.info.size = size
            this.info.page = 1
            this.GetDataList() //加载Table数据
        },

        //重置按钮
        btnResetSearch(){
            this.info.search.shareNumber="";  
            this.info.search.title="";   //名称
            this.info.ids = [];  //编号数组
            this.info.page = 1;
            this.GetDataList()
        }, 

        //新增编辑信息
        btnEditInfo(id){
            this.info.id = id
            if(id==0){
                this.BoxEditInfo.title = "新增报价单"
            }else{
                this.BoxEditInfo.title = "编辑报价单"
            }  
            this.BoxEditInfo.isVisible = true;
        },

        //删除按钮
        btnDeleteInfo(adjustNumber){
            ElMessageBox.confirm('确定要删除该报价单吗?','删除提示',{
                confirmButtonText: '确认',
                cancelButtonText: '取消'
            }).then(()=>{
                let param = [adjustNumber]
                this.$api.finance.DeletePriceShare(param).then(res=>{
                    if(res.data.status === 200){
                        ElMessage.success(res.data.msg)
                        this.GetDataList()
                    }
                    else{
                        ElMessage.error(res.data.msg)
                    }
                })
            }).catch(()=>{
                //取消
            })
        },
        //批量删除
        btnBatchDeleteInfo(){
            if(this.info.ids.length<1){
                ElMessage.error('请选择要删除的选项')
                return false
            }

            ElMessageBox.confirm('确定要删除该报价单吗?','删除提示',{
                confirmButtonText: '确认',
                cancelButtonText: '取消'
            }).then(()=>{
                this.$api.finance.DeletePriceShare(this.info.ids).then(res=>{
                    if(res.data.status === 200){
                        ElMessage.success(res.data.msg)
                        this.GetDataList()
                    }
                    else{
                        ElMessage.error(res.data.msg)
                    }
                })
            }).catch(()=>{
                //取消
            })  
        },

        //关闭弹出框
        btnCloseBox(){
            this.BoxEditInfo.isVisible = false;
        },

    },
    mounted(){
        this.GetDataList();
    },
}
</script>

<style>

</style>