<template>
  <div>
    <!-- 对账单信息 -->

    <el-form :inline="true"
    v-model="cycleInfo.info"
    label-width="110px"
    label-position="right">
        <el-row v-if="cycleInfo.info">

            <el-col :span="8">
                <el-form-item label="部门">
                    <template #label>
                        <span style="color:red;">*</span>
                        <span>部门</span>
                    </template>
                    <el-tree-select v-model="cycleInfo.info.departmentId" :data="departmentList" :default-expand-all="true" class="search-200">
                    </el-tree-select>
                </el-form-item>
            </el-col>

            <el-col :span="8">
                <el-form-item label="账期说明">
                    <template #label>
                        <span style="color:red;">*</span>
                        <span>账期说明</span>
                    </template>
                    <el-input v-model="cycleInfo.info.title" class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :span="8">
                <el-form-item label="交易者">
                    <template #label>
                        <span style="color:red;">*</span>
                        <span>交易者</span>
                    </template>
                    <el-select v-model="cycleInfo.info.tradeNumber" filterable class="search-200">
                        <el-option v-for="trade in partTradeList" :key="trade.tradeNumber" :label="trade.tradeName+ '(' + trade.tradeTypeName+')'" :value="trade.tradeNumber" />
                    </el-select>
                </el-form-item>
            </el-col>

            <el-col :span="8">
                <el-form-item label="账期开始日期">
                    <el-date-picker v-model="cycleInfo.info.startDate" type="date" placeholder="账期开始日期" value-format="YYYY-MM-DD" style="width:200px" />
                </el-form-item>
            </el-col>

            <el-col :span="8">
                <el-form-item label="账期结束日期">
                    <el-date-picker v-model="cycleInfo.info.endDate" type="date" placeholder="账期结束日期" value-format="YYYY-MM-DD" style="width:200px" />
                </el-form-item>
            </el-col>

            <el-col :span="8">
                <el-form-item label="总价(元)">
                    <el-input v-model="cycleInfo.info.totalPrice" disabled class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :span="8">
                <el-form-item label="支付方式">
                    <template #label>
                        <span style="color:red;">*</span>
                        <span>支付方式</span>
                    </template>
                    <el-select v-model="cycleInfo.info.accountNumber" class="search-200">
                        <el-option v-for="accountInfo in selectAccountList" :key="accountInfo.accountNumber" :label="accountInfo.accountName" :value="accountInfo.accountNumber"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>

            <el-col :span="8">
                <el-form-item label="排款日期">
                    <el-date-picker v-model="cycleInfo.info.queueTime" type="datetime" placeholder="排款日期" value-format="YYYY-MM-DD HH:mm:ss" style="width:200px" />
                </el-form-item>
            </el-col>

            <el-col :span="8">
                <el-form-item label="用款时间">
                    <el-date-picker v-model="cycleInfo.info.payTime" type="datetime" placeholder="用款时间" value-format="YYYY-MM-DD HH:mm:ss" style="width:200px" />
                </el-form-item>
            </el-col>

            <el-col :span="8">
                <el-form-item label="符合付款条件">    
                    <el-select v-model="cycleInfo.info.isFit" class="search-200">
                        <el-option :value="0" label="否"></el-option>
                        <el-option :value="1" label="是"></el-option>
                    </el-select>                
                </el-form-item>
            </el-col>

            <el-col :span="8">
                <el-form-item label="状态">
                    <el-tag v-if="cycleInfo.info.state == 1">新建单</el-tag>
                    <el-tag v-if="cycleInfo.info.state == 2">提交审核</el-tag>
                    <el-tag v-if="cycleInfo.info.state == 3">已驳回</el-tag>
                    <el-tag v-if="cycleInfo.info.state == 4">已审核</el-tag>
                    <el-tag v-if="cycleInfo.info.state == 7">已完成</el-tag>
                </el-form-item>
            </el-col>
            
        </el-row>
    </el-form>
  </div>
</template>

<script>
import UserDptList from '@/utils/mixins/UserDptList.js'
import accountList from '@/utils/mixins/AccountList.js'  //账户-下拉列表
import PartTradeList from '@/utils/mixins/PartTradeList.js'  //交易者列表
import { mapState } from 'vuex'
export default {
    mixins:[UserDptList,accountList,PartTradeList],  //部门列表、账户列表
    computed:{
        ...mapState('Finance',['cycleInfo'])
    },
    methods:{
    }
}
</script>

<style>

</style>