<template>
  <div>
    <!-- 报价单主信息 -->

    <el-form :inline="true"
    v-model="priceAdjustInfo.info"
    label-width="100px"
    label-position="right">
        <el-row v-if="priceAdjustInfo.info">

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="调价编号">
                    <el-input v-model="priceAdjustInfo.info.adjustNumber" disabled class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="所属部门">
                  <template #label>
                      <span style="color:red;">*</span>
                      <span>所属部门</span>
                  </template>
                    <el-tree-select v-model="priceAdjustInfo.info.departmentId" :data="departmentList" :default-expand-all="true" class="search-200"/>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="所属大区">
                    <template #label>
                        <span style="color:red;">*</span>
                        <span>所属大区</span>
                    </template>
                    <el-select v-model="priceAdjustInfo.info.areaNumber" class="search-200">
                        <el-option v-for="userArea in userAreaList" :key="userArea.areaNumber" :label="userArea.areaName" :value="userArea.areaNumber"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="调价日期">
                  <template #label>
                      <span style="color:red;">*</span>
                      <span>调价日期</span>
                  </template>
                    <el-date-picker v-model="priceAdjustInfo.info.adjustDate" type="date" placeholder="报价日期" value-format="YYYY-MM-DD" style="width:200px"/>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="备注">
                    <el-input v-model="priceAdjustInfo.info.remark" class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="审核状态">
                  <el-tag v-if="priceAdjustInfo.info.state ==1">新建单</el-tag>
                  <el-tag v-if="priceAdjustInfo.info.state ==2">待审核</el-tag>
                  <el-tag v-if="priceAdjustInfo.info.state ==3" type="danger">已驳回</el-tag>
                  <el-tag v-if="priceAdjustInfo.info.state ==4" type="warning">已审核并上报</el-tag>
                  <el-tag v-if="priceAdjustInfo.info.state ==5" type="success">已生效</el-tag>
                  <el-tag v-if="priceAdjustInfo.info.state ==9" type="info">已作废</el-tag>
                </el-form-item>
            </el-col>


        </el-row>
    </el-form>

  </div>
</template>

<script>
import UserDptList from '@/utils/mixins/UserDptList.js'
import { mapState } from 'vuex'
export default {
    mixins:[UserDptList],
    data(){
        return{
            userAreaList:[],    //用户关联的大区
        }
    },
    computed:{
        ...mapState('Finance',['priceAdjustInfo'])
    },
    methods:{
        //加载用户关联的大区列表
        SelectUserAreaList(){
            this.$api.system.SelectUserAreaList().then(res=>{
                if(res.data.status === 200){
                    this.userAreaList = res.data.data;
                    //新增时，默认加载第一个大区
                    if(this.userAreaList.length>0 && this.priceAdjustInfo.info.adjustNumber==''){
                        this.priceAdjustInfo.info.areaNumber = this.userAreaList[0].areaNumber;
                    }
                }else{
                    this.userAreaList = [];
                }
            })
        },

    },
}
</script>

<style>

</style>