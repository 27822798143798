<template>
  <div>

    <el-descriptions
        :column="3"
        border
        v-if="financeInfo.info">
        <el-descriptions-item label="财务单号">
            {{financeInfo.info.billNumber}}
        </el-descriptions-item>

        <el-descriptions-item label="审核状态">
            <span v-if="financeInfo.info.state ==1" class="itemState">新建单</span>
            <span v-if="financeInfo.info.state ==2" class="itemState">已驳回</span>
            <span v-if="financeInfo.info.state ==3" class="itemState">已审核并上报</span>
            <span v-if="financeInfo.info.state ==4" class="itemState">已完成</span>
            <span v-if="financeInfo.info.state ==5" class="itemState">已结款</span>
            <span v-if="financeInfo.info.state ==6" class="itemState">已结算</span>
            <span v-if="financeInfo.info.state ==10" class="itemState">已关单</span>
        </el-descriptions-item>

        <el-descriptions-item label="支付状态">
            <span v-if="financeInfo.info.payState==0" class="itemState">未支付</span>
            <span v-if="financeInfo.info.payState==1" class="itemState">已支付</span>
        </el-descriptions-item>

        <el-descriptions-item label="所属部门">
            {{financeInfo.info.departmentName}}
        </el-descriptions-item>

        <el-descriptions-item label="财务类型">
            <span class="itemPrice">{{financeInfo.info.billTypeName}}</span>
        </el-descriptions-item>

        <el-descriptions-item label="会计科目">
            {{financeInfo.info.subFullName}}
        </el-descriptions-item>


        <el-descriptions-item label="交易者">
            {{financeInfo.info.tradeName}}
        </el-descriptions-item>

        <el-descriptions-item label="账户">
            {{financeInfo.info.accountName}}
        </el-descriptions-item>

        <el-descriptions-item label="交易者类型">
            <span v-if="financeInfo.info.tradeType==1">供应商</span>
            <span v-if="financeInfo.info.tradeType==2">客户</span>
            <span v-if="financeInfo.info.tradeType==3">员工</span>
        </el-descriptions-item>

        <el-descriptions-item label="应付金额">
            <span class="itemPrice2">{{financeInfo.info.payablePrice}}</span>            
        </el-descriptions-item>

        <el-descriptions-item label="实付金额">
            <span class="itemPrice">{{myRound(financeInfo.info.totalPrice,2)}}</span>            
        </el-descriptions-item>

        <el-descriptions-item label="标识">
            <el-button v-if="financeInfo.info.isCheap == 1" type="danger" circle size="small" >惠</el-button>
        </el-descriptions-item>

        <el-descriptions-item label="记账时间">
            {{financeInfo.info.billTime}}
        </el-descriptions-item>

        <el-descriptions-item label="用款时间">
            {{financeInfo.info.payTime}}
        </el-descriptions-item>

        <el-descriptions-item label="备注">
            {{financeInfo.info.remark}}
        </el-descriptions-item>

    </el-descriptions>

  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    computed:{
        ...mapState('Erp',['financeInfo'])   //财务单信息
    },
}
</script>

<style scoped>
.itemState{font-size: 18px;font-weight: bold;color: #6e82dd;}
.itemPrice{font-size: 18px;font-weight: bold;color: #e21818;}
.itemPrice2{font-size: 18px;font-weight: bold;color: rgb(152, 157, 162);}
</style>