<template>
  <div v-if="financeInfo.info">
    <!-- 业务单列表 -->
    <el-table v-if="financeInfo.info.originType ==25" :data="financeInfo.originList" border stripe style="width: 100%">
        <el-table-column prop="billNumber" label="业务单号" width="170" />
        <el-table-column label="业务类型" min-width="95">
            <template #default="scope">
                <el-tag v-if="scope.row.originType == 1" type="danger">采购单</el-tag>
                <el-tag v-if="scope.row.originType == 2" type="success">销售单</el-tag>
                <el-tag v-if="scope.row.originType == 3" type="info">零售单</el-tag>
                <el-tag v-if="scope.row.originType == 4" type="info">差额单</el-tag>                    
                <el-tag v-if="scope.row.originType == 14" type="success">退供单</el-tag>
                <el-tag v-if="scope.row.originType == 15" type="danger">退货单</el-tag>
                <el-tag v-if="scope.row.originType == 18" type="info">退零单</el-tag>
                <el-tag v-if="scope.row.originType == 41" type="danger">采购差额单</el-tag>
                <el-tag v-if="scope.row.originType == 42" type="success">销售差额单</el-tag>
            </template>
        </el-table-column> 
        <el-table-column prop="departmentName" label="部门" min-width="150">
            <template v-slot="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.departmentName"
                    placement="top"
                >
                <div class="cellOverFlow">{{scope.row.departmentName}}</div>
                </el-tooltip>
            </template>
        </el-table-column>

        <el-table-column prop="areaName" label="大区" min-width="90" show-overflow-tooltip>
        </el-table-column>

        <el-table-column label="交易者类型" min-width="95">
                <template v-slot="scope">
                    <el-tag v-if="scope.row.tradeType ==1" type="danger">供应商</el-tag>
                    <el-tag v-if="scope.row.tradeType ==2" type="success">客户</el-tag>
                    <el-tag v-if="scope.row.tradeType ==3" type="info">员工</el-tag>
                    <el-tag v-if="scope.row.tradeType ==4" type="warning">散客</el-tag>
                </template>
            </el-table-column>
        <el-table-column prop="tradeName" label="交易者" min-width="120">
            <template v-slot="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.tradeName"
                    placement="top"
                >
                <div class="cellOverFlow">{{scope.row.tradeName}}</div>
                </el-tooltip>
            </template>
        </el-table-column>
        <el-table-column label="产品类目"  min-width="95">
            <template v-slot="scope">
                <span>{{scope.row.cidName}}</span>
            </template>
        </el-table-column>
        <el-table-column label="品牌">
            <template v-slot="scope">
                <span>{{scope.row.brandName}}</span>
            </template>
        </el-table-column>
        <el-table-column label="产品/类型" min-width="150">
            <template #default="scope">
                <el-tooltip
                class="box-item"
                effect="dark"
                :content="scope.row.spuName"
                placement="top"
                >
                <div class="cellOverFlow">{{scope.row.spuName}}</div>
                </el-tooltip>
            </template>
        </el-table-column>
        <el-table-column label="规格/说明" min-width="100">
            <template v-slot="scope">
                <span>{{scope.row.skuName}}</span>
            </template>
        </el-table-column>
        <el-table-column prop="unitName" label="单位" />
        <el-table-column prop="unitPrice" label="单价(元)" />
        <el-table-column prop="itemNum" label="数量" />
        <el-table-column prop="totalPrice" label="总价(元)" />  
        <el-table-column prop="billTime" label="记账时间" width="110">
            <template #default="scope">
                <el-tooltip
                class="box-item"
                effect="dark"
                :content="scope.row.billTime"
                placement="top"
                >
                {{DateFormat(scope.row.billTime)}}
                </el-tooltip>
            </template>
        </el-table-column>
        <el-table-column label="创建日期" width="110">
            <template #default="scope">
            <span>{{DateFormat(scope.row.createTime)}}</span>
            </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作">
            <template #default="scope">
                <el-button size="small" type="warning" @click="OpenBoxRecord(scope.row.billNumber,scope.row.originNumber,scope.row.originType)">备注</el-button>
            </template>
        </el-table-column>
    </el-table>
    <!-- 合计 -->
    <div v-if="financeInfo.info.originType==25">
        <el-row class="BillHeJi" v-if="financeInfo.totalInfo">
            <el-col :span="1">合计</el-col>
            <el-col :span="3" class="fkPrice">采购单:{{this.myRound(financeInfo.totalInfo.purchasePrice,2)}}</el-col>
            <el-col :span="3" class="fkPrice">退货单:{{this.myRound(financeInfo.totalInfo.returnSalePrice,2)}}</el-col>
            <el-col :span="3" class="fkPrice">采购差额单:{{this.myRound(financeInfo.totalInfo.purchaseDiffPrice,2)}}</el-col>
            <el-col :span="3" class="skPrice">销售单:{{this.myRound(financeInfo.totalInfo.salePrice,2)}}</el-col>
            <el-col :span="3" class="skPrice">退供单:{{this.myRound(financeInfo.totalInfo.returnPurchasePrice,2)}}</el-col>
            <el-col :span="3" class="skPrice">销售差额单:{{this.myRound(financeInfo.totalInfo.saleDiffPrice,2)}}</el-col>
            <el-col :span="3" class="hjPrice">合计金额:{{this.myRound(financeInfo.totalInfo.totalPrice,2)}}</el-col>
        </el-row>
    </div>

    <!-- 工资单列表 -->
    <el-table v-if="financeInfo.info.originType ==9" :data="financeInfo.originList" border stripe show-summary style="width: 100%">
        <el-table-column prop="wageNumber" label="工资单号" width="170" />
        <el-table-column prop="month" label="月份"></el-table-column>
        <el-table-column prop="userName" label="员工姓名">
            <template v-slot="scope">
                {{scope.row.userName}}
            </template>
        </el-table-column>
        <el-table-column label="单据类型">
            <template #default="scope">
                <el-tag v-if="scope.row.billType == 1">工资</el-tag>
                <el-tag v-if="scope.row.billType == 2" type="danger">奖金</el-tag>
            </template>
        </el-table-column>
        <el-table-column prop="realWage" label="实发金额(元)"></el-table-column>
        <el-table-column label="状态">
            <template #default="scope">
                <el-tag v-if="scope.row.state ==1">新建单</el-tag>
                <el-tag v-if="scope.row.state ==2">待审核</el-tag>
                <el-tag v-if="scope.row.state ==3" type="danger">已驳回</el-tag>
                <el-tag v-if="scope.row.state ==4">审核通过并上报</el-tag>
                <el-tag v-if="scope.row.state ==5" type="success">已完成</el-tag>
                <el-tag v-if="scope.row.state ==10">已关闭</el-tag>
            </template>
        </el-table-column>
        <el-table-column label="创建日期" width="110">
            <template #default="scope">
                <span>{{DateFormat(scope.row.createTime)}}</span>
            </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作">
            <template #default="scope">
                <el-button size="small" type="warning" @click="OpenBoxRecord(scope.row.wageNumber,scope.row.wageNumber,9)">备注</el-button>
            </template>
        </el-table-column>
    </el-table>

    <!-- 费用单列表 -->
    <el-table v-if="financeInfo.info.originType ==10" :data="financeInfo.originList" border stripe show-summary style="width: 100%">
        <el-table-column label="单据编号" width="170">
                <template v-slot="scope">
                    <span>{{scope.row.costNumber}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="departmentName" label="部门名称"></el-table-column>
            <el-table-column label="交易者类型">
                <template v-slot="scope">
                    <el-tag v-if="scope.row.tradeType ==1" type="danger">供应商</el-tag>
                    <el-tag v-if="scope.row.tradeType ==2" type="success">客户</el-tag>
                    <el-tag v-if="scope.row.tradeType ==3" type="info">员工</el-tag>
                    <el-tag v-if="scope.row.tradeType ==4" type="info">散客</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="tradeName" label="交易者" />
            <el-table-column prop="costName" label="说明"></el-table-column>
            <el-table-column prop="billTypeName" label="费用单据类型"></el-table-column>
            <el-table-column prop="totalPrice" label="总金额"></el-table-column>
            <el-table-column label="状态">
                <template #default="scope">
                    <el-tag v-if="scope.row.state ==1" type="info">新建单</el-tag>
                    <el-tag v-if="scope.row.state ==2">待审核</el-tag>
                    <el-tag v-if="scope.row.state ==3" type="danger">已驳回</el-tag>
                    <el-tag v-if="scope.row.state ==4" type="warning">审核通过并上报</el-tag>
                    <el-tag v-if="scope.row.state ==5" type="success">审核已完成</el-tag>
                    <el-tag v-if="scope.row.state ==10">已关单</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="财务单标识">
                <template #default="scope">
                    <el-tag v-if="scope.row.financeFlag == 0" type="danger">未生成</el-tag>
                    <el-tag v-if="scope.row.financeFlag == 1" type="info">已生成</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="记账时间" width="110">
                <template #default="scope">
                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.billTime"
                    placement="top"
                  >
                  {{DateFormat(scope.row.billTime)}}
                  </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作">
                <template #default="scope">
                    <el-button size="small" type="warning" @click="OpenBoxRecord(scope.row.costNumber,scope.row.costNumber,10)">备注</el-button>
                </template>
            </el-table-column>

    </el-table>

    <!-- 原始单据备注记录-弹出框 -->
    <el-dialog :title="BoxRecord.title"
    v-model="BoxRecord.isVisible"
    width="40%"
    :destroy-on-close="true">
        <BoxOriginRecord :ywNumber="BoxRecord.billNumber" :number="BoxRecord.originNumber" :type="BoxRecord.originType" :dataList="financeInfo.originList" @closeBox="CloseBoxRecord"></BoxOriginRecord>
    </el-dialog>

  </div>
</template>

<script>
import BoxOriginRecord from '@/views/Fms/Finance/BoxOriginRecord.vue'
import { mapState } from 'vuex'
export default {
    components:{
        BoxOriginRecord
    },
    data(){
        return{
            //备注-弹出框
            BoxRecord:{
                isVisible:false,
                title:'备注信息',
                billNumber:'',   //业务单号
                originNumber:'', //原始单号 
                originType:0,  
            }
        }
    },
    computed:{
        ...mapState('Erp',['financeInfo'])
    },
    methods:{

        //打开-弹出框
        OpenBoxRecord(billNumber,originNumber,originType){
            this.BoxRecord.isVisible = true;
            this.BoxRecord.billNumber = billNumber;
            this.BoxRecord.originNumber = originNumber;
            this.BoxRecord.originType = originType;
        },
        //关闭-弹出框
        CloseBoxRecord(){
            this.BoxRecord.isVisible = false;
        }
    }
}
</script>

<style scoped>
.BillHeJi{background-color: #fafafa;height: 40px;border: 1px solid #f1f1f1;align-items: center;display: flex;font-size: 15px;}
.BillHeJi .fkPrice{ color: #f56c6c;} /** 付款样式 */
.BillHeJi .skPrice{ color: #67c23a;} /** 收款样式 */
.BillHeJi .hjPrice{color: #d12121;font-weight: bold;}  /** 合计样式  */
</style>