<template>
  <div>
    <!-- 新增导出框 -->
    <el-divider></el-divider>

    <el-form :inline="true"
    v-model="info"
    label-width="80px"
    label-position="right">
        <el-row v-if="info">

            <el-col :span="12">
                <el-form-item label="导出编号">
                    <el-input v-model="info.exportNumber" class="search-200" disabled></el-input>
                </el-form-item>
            </el-col>

            <el-col :span="12">
                <el-form-item label="单据类型">
                    <el-select v-model="info.originType" class="search-200" disabled>
                      <el-option :value="1" label="采购单"></el-option>
                      <el-option :value="2" label="销售单"></el-option>
                      <el-option :value="3" label="零售单"></el-option>
                      <el-option :value="4" label="差额单"></el-option>
                      <el-option :value="7" label="盘点单"></el-option>
                      <el-option :value="14" label="退供单"></el-option>
                      <el-option :value="15" label="退货单"></el-option>
                      <el-option :value="18" label="退零单"></el-option>
                      <el-option :value="25" label="业务单"></el-option>
                      <el-option :value="36" label="财务差额项"></el-option>
                      <el-option :value="40" label="库存"></el-option>
                      <el-option :value="45" label="库存账"></el-option>
                      <el-option :value="46" label="交易账"></el-option>
                      <el-option :value="50" label="调价表"></el-option>
                      <el-option :value="53" label="报价单"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>

            <el-col :span="12">
                <el-form-item label="标题">
                    <el-input v-model="info.title" class="search-200" ></el-input>
                </el-form-item>
            </el-col>

            <el-col :span="12">
                <el-form-item label="创建时间">
                    <el-input v-model="info.createTime" class="search-200" disabled></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="完成时间">
                    <el-input v-model="info.completeTime" class="search-200" disabled></el-input>
                </el-form-item>
            </el-col>

            <el-col :span="12">
                <el-form-item label="创建人">
                    <el-input v-model="info.createName" class="search-200" disabled></el-input>
                </el-form-item>
            </el-col>

            <el-col :span="12">
                <el-form-item label="备注">
                    <el-input v-model="info.remark" class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :span="12">
                <el-form-item label="文件地址">
                    <el-link :href="info.fileUrl" target="_blank">文件下载</el-link>
                </el-form-item>
            </el-col>

            <el-col :span="12">
                <el-form-item label="状态">
                    <el-tag v-if="info.state ==1" type="danger">待查询</el-tag>
                    <el-tag v-if="info.state ==2" type="info">已完成</el-tag>
                </el-form-item>
            </el-col>
            
        </el-row>
    </el-form>

    <div class="btnGroup">
        <el-button type="primary" @click="btnSaveData()">保存</el-button>
        <el-button @click="btnClose">关闭</el-button>

        <el-button-group>
            <el-button @click="btnPrevious" class="iconfont icon-houtui_shangyibu_zuojiantou_shangyiye">上一条</el-button>
            <el-button @click="btnNext">下一条<el-icon class="iconfont icon-qianjin_xiayibu_youjiantou_xiayiye"></el-icon></el-button>
        </el-button-group>
    </div>

  </div>
</template>

<script>
import { ElLoading, ElMessage } from 'element-plus'
export default {
    props:['editId','dataList'],
    data(){
        return{
            info:{
                exportNumber:'',
                originType:1,
                title:'',
                remark:'',
                state:1,
                isDel:0,
            },
            strExportNumber:this.editId,   //默认编号
        }
    },
    methods:{
        //加载数据
        GetDataInfo(){
            if(this.strExportNumber!=0){
                let param = {
                    exportNumber:this.strExportNumber
                }
                this.$api.oa.GetExportInfo(param).then(res=>{
                    if(res.data.status === 200){
                        this.info = res.data.data;
                    }else{  
                        ElMessage.error(res.data.msg);
                    }
                })
            }else{
                this.btnClearData();
            }
        },

        //保存信息
        btnSaveData(){
            //打开遮罩层
            const loading = ElLoading.service({
                lock:true,
                text:'正在提交,请稍候!',
                background:'rgba(0, 0, 0, 0.7)',
            })

            this.$api.oa.SaveExportInfo(this.info).then(res=>{
                loading.close();
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

        //清空数据
        btnClearData(){
            this.info = {
                exportNumber:'',
                originType:1,
                title:'',
                remark:'',
                state:1,
                fileUrl:'',
                isDel:0,
            }
        },

        //上一条
        btnPrevious(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.exportNumber === this.strExportNumber
            })
            let prevIndex = currentIndex -1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有上一条了");
                return false;
            }
            else{
                this.strExportNumber = prevInfo.exportNumber
                this.GetDataInfo()
            }
            
        },
        //下一条
        btnNext(){
            let currentIndex = this.dataList.findIndex((item)=>{
                return item.exportNumber === this.strExportNumber
            })
            let prevIndex = currentIndex + 1;
            let prevInfo = this.dataList[prevIndex];
            if(prevInfo==null){
                ElMessage.warning("没有下一条了");
                return false;
            }
            else{
                this.strExportNumber = prevInfo.exportNumber
                this.GetDataInfo()
            }
        },

        //关闭按钮
        btnClose(){
          this.$emit('closeBox')  //关闭弹出框
        },

      
    },
    mounted(){
        this.GetDataInfo();
    }
}
</script>

<style>

</style>