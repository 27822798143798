/**
 * 财务单类型列表 - 全系统通用
 */

const FinanceTypeList = {
    data(){
        return{
            financeTypeList:[],    //财务单类型列表
        }
    },
    methods:{

        //财务类型(字典)
        SelectFinanceTypeList(){
            this.$api.common.SelectFinanceTypeList().then(res=>{
                if(res.data.status === 200){
                    this.financeTypeList = res.data.data;
                }
            })
        },
    },
    mounted(){
        this.SelectFinanceTypeList();
    },
}

export default FinanceTypeList;