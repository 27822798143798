<template>
  <div>
    <!-- 选择入库单据（采购单、退货单、调拨单、加工单） -->
    <el-divider></el-divider>

    <el-row>
        <el-form :inline="true">

            <el-form-item label="单据类型">
                <el-radio-group v-model="info.search.billType" @change="ChangeBillType">
                    <el-radio-button :value="2">销售单</el-radio-button>
                    <el-radio-button :value="6">加工单</el-radio-button>
                    <el-radio-button :value="13">移仓单</el-radio-button>
                    <el-radio-button :value="14">退供单</el-radio-button>
                </el-radio-group> 
            </el-form-item>
            
            <el-form-item label="部门">
                <el-tree-select v-model="info.search.selectDepartmentList" :data="allDptList" multiple clearable :default-expand-all="true"  collapse-tags collapse-tags-tooltip class="search-200">
                </el-tree-select>
            </el-form-item>

            <el-form-item label="大区">
                <el-select v-model="info.search.areaNumberList" multiple clearable collapse-tags collapse-tags-tooltip class="search-200">
                    <el-option v-for="userArea in userAreaList" :key="userArea.areaNumber" :label="userArea.areaName" :value="userArea.areaNumber"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="原始单号">
                <el-input v-model="info.search.billNumber" class="search-200"></el-input>
            </el-form-item>

            <el-form-item>
                <el-button class="iconfont icon-search" type="primary" plain @click="GetBillList">搜索</el-button>
                <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
            </el-form-item>

        </el-form>
    </el-row>

    <!-- 主单据列表 -->
    <el-table :data="billList" border stripe style="width: 100%" @row-click="TableRowClick" highlight-current-row>
        <el-table-column prop="billNumber" label="单据编号" width="160"></el-table-column>
        <el-table-column label="单据类型" width="90">
            <template v-slot="scope">
                <span v-if="scope.row.billType == 2">销售单</span>
                <span v-if="scope.row.billType == 6">加工单</span>
                <span v-if="scope.row.billType == 13">移仓单</span>
                <span v-if="scope.row.billType == 14">退供单</span>  
            </template>
        </el-table-column>
        <el-table-column prop="departmentName" label="部门" min-width="110">
            <template v-slot="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.departmentName"
                    placement="top">
                    <div class="cellOverFlow">{{scope.row.departmentName}}</div>
                </el-tooltip>
            </template>
        </el-table-column>

        <el-table-column prop="areaName" label="大区" min-width="90" show-overflow-tooltip>
        </el-table-column>

        <el-table-column label="交易者类型" min-width="95">
            <template v-slot="scope">
                <el-tag v-if="scope.row.tradeType ==1" type="danger">供应商</el-tag>
                <el-tag v-if="scope.row.tradeType ==2" type="success">客户</el-tag>
                <el-tag v-if="scope.row.tradeType ==3" type="info">员工</el-tag>
                <el-tag v-if="scope.row.tradeType ==4" type="info">散客</el-tag>
            </template>
        </el-table-column>

        <el-table-column label="交易者" min-width="120">
            <template v-slot="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.tradeName"
                    placement="top">
                    <div class="cellOverFlow">{{scope.row.tradeName}}</div>
                </el-tooltip>
            </template>
        </el-table-column>

        <el-table-column label="状态">
            <template v-slot="scope">
                <div v-if="scope.row.billType == 2">
                    <el-tag v-if="scope.row.state == 4" type="success">已审核</el-tag>
                    <el-tag v-if="scope.row.state == 5" type="info">已出库</el-tag>
                </div>
                <div v-if="scope.row.billType == 6">
                    <el-tag v-if="scope.row.state == 4" type="success">已审核</el-tag>
                    <el-tag v-if="scope.row.state == 5" type="danger">加工中</el-tag>
                    <el-tag v-if="scope.row.state == 6" type="info">待成品入库</el-tag>
                </div>
                <div v-if="scope.row.billType == 13">
                    <el-tag v-if="scope.row.state == 4" type="success">已审核</el-tag>
                    <el-tag v-if="scope.row.state == 5" type="danger">已出库</el-tag>
                    <el-tag v-if="scope.row.state == 6" type="info">已入库</el-tag>
                </div>
                <div v-if="scope.row.billType == 14">
                    <el-tag v-if="scope.row.state == 4" type="success">已审核</el-tag>
                    <el-tag v-if="scope.row.state == 5" type="info">已出库</el-tag>
                </div>
            </template>
        </el-table-column>

        <el-table-column label="备注说明" min-width="120">
            <template v-slot="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.remark"
                    placement="top">
                    <div class="cellOverFlow">{{scope.row.remark}}</div>
                </el-tooltip>
            </template>
        </el-table-column>

        <el-table-column prop="billTime" label="记账时间" width="110">
            <template #default="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.billTime"
                    placement="top"
                >
                <span>{{DateFormat(scope.row.billTime)}}</span>
                </el-tooltip>
            </template>
        </el-table-column>

    </el-table>

    <!-- 分页 -->
    <el-container class="page_one">
        <el-pagination
        v-model:currentPage="info.page"
        v-model:page-size="info.size"
        :page-sizes="[20, 50, 100, 200]"
        :background="true"
        layout="total, sizes, prev, pager, next, jumper"
        :total="info.total"
        @current-change="btnPagechange"
        @size-change="btnSizechange"
        >
        </el-pagination>
    </el-container>

    <!-- 单据明细列表 -->
    <el-divider class="CenterLine">单据明细列表</el-divider>
    <el-table :data="billDetailList" border stripe >
        <el-table-column prop="warehouseName" label="仓库" />
        <el-table-column prop="cidName" label="产品类目"></el-table-column>
        <el-table-column prop="brandName" label="品牌"></el-table-column>
        <el-table-column prop="spuName" label="产品名称" min-width="90">
            <template v-slot="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.spuName"
                    placement="top">
                    <div class="cellOverFlow">{{scope.row.spuName}}</div>
                </el-tooltip>
            </template>
        </el-table-column>
        <el-table-column prop="skuName" label="规格名称" />
        <el-table-column label="单位">
            <template v-slot="scope">
                <span>{{scope.row.unitName}}</span>
            </template>
        </el-table-column>
        <el-table-column prop="planNum" label="计划数量"></el-table-column>
        <el-table-column prop="realNum" label="已完成数量"></el-table-column>
    </el-table>

  </div>
</template>

<script>
import UserAreaList from '@/utils/mixins/UserAreaList.js'  //用户关联大区列表
import AllDptList from '@/utils/mixins/AllDptList.js'  //全部部门列表
export default {
    mixins:[UserAreaList,AllDptList],
    data(){
        return{
            info:{
                page:1,   //页数
                size:10,  //单页记录数
                total:0,  //总记录数
                ids:[],   //单据数组
                search:{
                    billType:2,     //单据类型 默认：销售单
                    selectDepartmentList:[],  //部门
                    areaNumberList:[],   //大区列表
                    billNumber:'',  //单据编号
                }
            },                 
            billList:[],  //单据列表
            billDetailList:[],   //单据明细列表
        }
    },
    methods:{

        //搜索项-单据类型-发生改变后
        ChangeBillType(){
            this.GetBillList();
        },

        //获取单据列表
        GetBillList(){
            //获取、转换部门
            let newDepartmentList = []
            if(this.info.search.selectDepartmentList!=null){
                newDepartmentList = this.info.search.selectDepartmentList.map(item=>{
                    return item
                })
            }

            let param = {
                departmentList:newDepartmentList,
                areaNumberList:this.info.search.areaNumberList,   //大区列表
                billType:this.info.search.billType,   //单据类型
                billNumber:this.info.search.billNumber,   //原始单号
                inOutStock:1, //出库操作
                page:this.info.page,
                size:this.info.size
            }
            this.$api.wms.GetOriginList(param).then(res=>{
                if(res.data.status === 200){
                    this.billList = res.data.data;
                    //分页
                    this.info.total = res.data.total
                    this.info.page = res.data.pageNumber
                }else{
                    this.billList = []; //清空数据
                }
            })
        },

        //获取单据明细列表
        GetBillDetailList(){
            let param = {
                billType:this.info.search.billType,
                billNumber:this.info.ids[0],
                inOutStock:1, //出库操作
            }
            this.$api.wms.GetOriginDetailList(param).then(res=>{
                if(res.data.status === 200){
                    this.billDetailList = res.data.data;
                }else{
                    this.billDetailList = [];  //清空明细
                }
            })
        },

        //分页-页数变化时
        btnPagechange(page){
            this.info.page = page
            this.GetBillList() //加载Table数据
        },
        //分页-单页记录数变化时
        btnSizechange(size){
            this.info.size = size
            this.info.page = 1
            this.GetBillList() //加载Table数据
        },

        //重置按钮
        btnResetSearch(){
            this.info.search.billNumber='';
            this.info.search.selectDepartmentList = [];
            this.info.search.areaNumberList=[];  //大区列表
            this.info.search.billType = 2;
            this.GetBillList();
        },

        //选中行时(标准模板)
        TableRowClick(row){
            // let isExist = false; //判断是否存在
            // this.info.ids = this.info.ids.filter(item=>{                
            //     if(row.billNumber != item){
            //         return item;
            //     }else{                    
            //         //如果存在
            //         isExist = true;
            //     }                
            // })
            
            // if(isExist){
            //     this.$refs.currentTable.toggleRowSelection(row,false);
            // }else{
            //     this.info.ids.push(row.billNumber)
            //     this.$refs.currentTable.toggleRowSelection(row,true);
            // }
            if(row !=null){
                this.info.ids = [row.billNumber];   //主表编号
                this.GetBillDetailList();  //加载明细列表
            }else{
                this.billDetailList = [];  //清空明细
            }

        },

    },
    mounted(){
        this.GetBillList();
    },
}
</script>

<style scoped>
.CenterLine{margin-top: 55px;}
</style>