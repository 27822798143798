<template>
  <div>
    <!-- 选择产品 -->
    <div class="skuBtnList" v-if="priceAdjustInfo.info">
        <el-button v-if="priceAdjustInfo.info.state==1 || priceAdjustInfo.info.state==3" class="iconfont icon-add" type="success" plain @click="BtnSelectAdjustSku">选择调价表产品</el-button>
        <el-button v-if="priceAdjustInfo.info.state==1 || priceAdjustInfo.info.state==3" class="iconfont icon-add" type="warning" plain @click="BtnSelectStockSku">选择库存产品</el-button>
        <el-button v-if="priceAdjustInfo.info.state==1 || priceAdjustInfo.info.state==3" class="iconfont icon-add" type="primary" plain @click="BtnSelectSku">选择产品</el-button>      
    </div>

    <!-- 报价明细列表 -->
    <el-table :data="priceAdjustInfo.detailList" border stripe >
        <el-table-column prop="cidName" label="产品类目" min-width="90"></el-table-column>
        <el-table-column prop="brandName" label="品牌" min-width="80"></el-table-column>
        <el-table-column prop="spuName" label="产品名称" min-width="90">
            <template v-slot="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.spuName"
                    placement="top">
                    <div class="cellOverFlow">{{scope.row.spuName}}</div>
                </el-tooltip>
            </template>
        </el-table-column>
        <el-table-column prop="skuName" label="规格名称" min-width="90"/>

        <el-table-column label="默认单位" width="85">
            <template #header>
                <div class="defPrice">默认单位</div>
            </template>
            <template v-slot="scope">
                <span>{{scope.row.defUnitName}}</span>
            </template>
        </el-table-column>

        <el-table-column label="成本价" width="115">
            <template #header>
                <div>成本价</div>
                <div class="defPrice PriceSize">(默认单位)</div>
            </template>
            <template v-slot="scope">
                <el-input-number v-if="scope.row.isEdit==1" v-model="scope.row.defCostPrice" :precision="2" :controls="false" style="width:90px"></el-input-number>
                <span v-else>{{this.myRound(scope.row.defCostPrice,2)}}</span>
            </template>
        </el-table-column>

        <el-table-column label="零售价"  width="115">
            <template #header>
                <div>零售价</div>
                <div class="defPrice PriceSize">(默认单位)</div>
            </template>
            <template v-slot="scope">
                <el-input-number v-if="scope.row.isEdit==1" v-model="scope.row.defRetailPrice" :precision="2" :controls="false" style="width:90px"></el-input-number>
                <span v-else>{{scope.row.defRetailPrice}}</span>
            </template>
        </el-table-column>

        <el-table-column  label="批发价"  width="115">
            <template #header>
                <div>批发价</div>
                <div class="defPrice PriceSize">(默认单位)</div>
            </template>
            <template v-slot="scope">
                <el-input-number v-if="scope.row.isEdit==1" v-model="scope.row.defWholePrice" :precision="2" :controls="false" style="width:90px"></el-input-number>
                <span v-else>{{scope.row.defWholePrice}}</span>
            </template>
        </el-table-column>

        <el-table-column  label="VIP价"  width="115">
            <template #header>
                <div>VIP价</div>
                <div class="defPrice PriceSize">(默认单位)</div>
            </template>
            <template v-slot="scope">
                <el-input-number v-if="scope.row.isEdit==1" v-model="scope.row.defVipPrice" :precision="2" :controls="false" style="width:90px"></el-input-number>
                <span v-else>{{scope.row.defVipPrice}}</span>
            </template>
        </el-table-column>

        <el-table-column label="最小单位" width="85">
            <template #header>
                <div class="minPrice">最小单位</div>
            </template>
            <template v-slot="scope">
                <span>{{scope.row.minUnitName}}</span>
            </template>
        </el-table-column>
        <el-table-column label="成本价" width="115">
            <template #header>
                <div>成本价</div>
                <div class="minPrice PriceSize">(最小单位)</div>
            </template>
            <template v-slot="scope">
                <el-input-number v-if="scope.row.isEdit==1" v-model="scope.row.minCostPrice" :precision="2" :controls="false" style="width:90px"></el-input-number>
                <span v-else>{{this.myRound(scope.row.minCostPrice,2)}}</span>
            </template>
        </el-table-column>
        <el-table-column label="零售价" width="115">
            <template #header>
                <div>零售价</div>
                <div class="minPrice PriceSize">(最小单位)</div>
            </template>
            <template v-slot="scope">
                <el-input-number v-if="scope.row.isEdit==1" v-model="scope.row.minRetailPrice" :precision="2" :controls="false" style="width:90px"></el-input-number>
                <span v-else>{{scope.row.minRetailPrice}}</span>
            </template>
        </el-table-column>
        <el-table-column  label="批发价" width="115">
            <template #header>
                <div>批发价</div>
                <div class="minPrice PriceSize">(最小单位)</div>
            </template>
            <template v-slot="scope">
                <el-input-number v-if="scope.row.isEdit==1" v-model="scope.row.minWholePrice" :precision="2" :controls="false" style="width:90px"></el-input-number>
                <span v-else>{{scope.row.minWholePrice}}</span>
            </template>
        </el-table-column>

        <el-table-column  label="VIP价" width="115">
            <template #header>
                <div>VIP价</div>
                <div class="minPrice PriceSize">(最小单位)</div>
            </template>
            <template v-slot="scope">
                <el-input-number v-if="scope.row.isEdit==1" v-model="scope.row.minVipPrice" :precision="2" :controls="false" style="width:90px"></el-input-number>
                <span v-else>{{scope.row.minVipPrice}}</span>
            </template>
        </el-table-column>

        
        <el-table-column label="操作" width="130" fixed="right">
            <template v-slot="scope">
                <el-button v-if="scope.row.isEdit == 0 && (priceAdjustInfo.info.state==1 || priceAdjustInfo.info.state==3)" size="small" type="primary" @click="EditRow(scope.row.id)">编辑</el-button>
                <el-popconfirm v-if="priceAdjustInfo.info.state==1 || priceAdjustInfo.info.state==3"
                title="确认要删除吗?"
                @confirm="DeleteRow(scope.row.adjustNumber,scope.row.id,scope.$index)">
                    <template #reference>
                    <el-button size="small" type="danger">删除</el-button>
                    </template>
                </el-popconfirm>
            </template>
        </el-table-column>
    </el-table>

    <!-- 选择调价表产品-弹出框1 -->
    <el-dialog :title="BoxAdjustSku.title"
        v-model="BoxAdjustSku.isVisible"
        width="70%"
        :destroy-on-close="true">
            <SelectAdjustSkuList ref="SelectAdjustSku"></SelectAdjustSkuList>
        <template #footer>
            <div style="padding-top:35px">
                <el-button type="primary" @click="BtnSelectAdjustSkuData">选择</el-button>
                <el-button @click="BoxAdjustSku.isVisible = false">取消</el-button>
            </div>            
        </template>
    </el-dialog>

    <!-- 选择库存产品-弹出框2 -->
    <el-dialog :title="BoxStockSku.title" 
        v-model="BoxStockSku.isVisible"
        width="70%"
        :destroy-on-close="true">
            <SelectStockSkuListTwo :areaNum="priceAdjustInfo.info.areaNumber" ref="SelectStockSku"></SelectStockSkuListTwo>
        <template #footer>
            <div style="padding-top:35px">
                <el-button type="primary" @click="BtnSelectStockSkuData">选择</el-button>
                <el-button @click="BoxStockSku.isVisible = false">取消</el-button>
            </div>            
        </template>
    </el-dialog>

    <!-- 选择产品-弹出框3 -->
    <el-dialog :title="BoxGoodsSku.title" 
        v-model="BoxGoodsSku.isVisible"
        width="70%"
        :destroy-on-close="true">
            <SelectAllSkuList ref="SelectSku"></SelectAllSkuList>
        <template #footer>
            <div style="padding-top:35px">
                <el-button type="primary" @click="btnSelectSkuData">选择</el-button>
                <el-button @click="BoxGoodsSku.isVisible = false">取消</el-button>
            </div>            
        </template>
    </el-dialog>

  </div>
</template>


<script>
import SelectAdjustSkuList from '@/views/Fms/PriceAdjust/BoxAdjustList.vue'  //调价单产品列表
import SelectAllSkuList from '@/components/common/SelectAllSkuList.vue'   //产品列表
import SelectStockSkuListTwo from '@/components/common/SelectStockSkuListTwo.vue'  //库存产品列表
import { mapState } from 'vuex'
import { ElMessage } from 'element-plus'
export default {
    components:{
        SelectAdjustSkuList,
        SelectAllSkuList,
        SelectStockSkuListTwo,
    },
    data(){
        return{
            //选择调价表产品-弹出框
            BoxAdjustSku:{
                title:'选择调价表产品',
                isVisible:false,
            },         
            //选择库存产品-弹出框
            BoxStockSku:{
                title:'选择库存产品',
                isVisible:false,
            },
            //选择产品-弹出框
            BoxGoodsSku:{
                title:'选择产品',
                isVisible:false,
            },
        }
    },
    computed:{
        ...mapState('Finance',['priceAdjustInfo'])
    },
    methods:{
        //选择调价表产品 ===4===
        BtnSelectAdjustSku(){
            this.BoxAdjustSku.isVisible=true;
        },
        //关闭调价产品
        BtnSelectAdjustSkuData(){
            let itemList = this.$refs.SelectAdjustSku.selectedDetail;
            if(itemList!=null && itemList.length>0){
                let dupNum =0;  //重复数
                itemList.forEach(item=>{
                    //判断是否有重复
                    let isExist = false;
                    this.priceAdjustInfo.detailList.forEach(element =>{
                        if(element.skuNumber === item.skuNumber){
                            isExist=true;
                            dupNum +=1;
                        }
                    })
                    //新增
                    if(!isExist){
                        let newRow = {
                            id:0,
                            systemNumber:item.systemNumber,
                            adjustNumber:'',
                            cid:item.cid,         //分类编号
                            cidName:item.cidName,    //分类名称
                            brandId:item.brandId,    //品牌编号
                            brandName:item.brandName,   //品牌名称
                            spuNumber:item.spuNumber,
                            spuName:item.spuName,
                            skuNumber:item.skuNumber,
                            skuName:item.skuName,
                            defUnitId:item.defUnitId,   //默认单位编号
                            defUnitName:item.defUnitName,  //默认单位名称
                            defCostPrice:item.defCostPrice,  //默认成本价
                            defRetailPrice:item.defRetailPrice,  //默认零售价
                            defWholePrice:item.defWholePrice,   //默认批发价
                            defVipPrice:item.defVipPrice,   //默认vip价
                            minUnitId:item.minUnitId,
                            minUnitName:item.minUnitName,
                            minCostPrice:item.minUnitPrice,   //最小成本价
                            minRetailPrice:item.minRetailPrice,  //最小零售价
                            minWholePrice:item.minWholePrice,   //最小批发价
                            minVipPrice:item.minVipPrice,   //最小vip价
                            remark:'',    //备注
                            isDel:0,
                            isEdit:1,
                        }
                        this.priceAdjustInfo.detailList.push(newRow);
                    }                    
                })
                //提示
                if(dupNum>0){
                    ElMessage.warning('已过滤掉'+dupNum+'个重复产品');
                }
            }
            this.BoxAdjustSku.isVisible=false;
        },


        //选择库存产品 ===2====
        BtnSelectStockSku(){
            this.BoxStockSku.isVisible=true;
        },
        //关闭库存产品
        BtnSelectStockSkuData(){
            let itemList = this.$refs.SelectStockSku.selectedItem;
            if(itemList!=null && itemList.length>0){
                let dupNum =0;  //重复数
                itemList.forEach(item=>{
                    //判断是否有重复
                    let isExist = false;
                    this.priceAdjustInfo.detailList.forEach(element =>{
                        if(element.skuNumber === item.skuNumber){
                            isExist=true;
                            dupNum +=1;
                        }
                    })
                    //新增
                    if(!isExist){
                        let newRow = {
                            id:0,
                            systemNumber:item.systemNumber,
                            adjustNumber:'',
                            cid:item.cid,         //分类编号
                            cidName:item.cidName,    //分类名称
                            brandId:item.brandId,    //品牌编号
                            brandName:item.brandName,   //品牌名称
                            spuNumber:item.spuNumber,
                            spuName:item.spuName,
                            skuNumber:item.skuNumber,
                            skuName:item.skuName,
                            defUnitId:item.defUnitId,   //默认单位编号
                            defUnitName:item.defUnitName,  //默认单位名称
                            defCostPrice:item.defUnitPrice,  //默认成本价
                            defRetailPrice:0,  //默认零售价
                            defWholePrice:0,   //默认批发价
                            defVipPrice:0,   //默认vip价
                            minUnitId:item.minUnitId,
                            minUnitName:item.minUnitName,
                            minCostPrice:item.minUnitPrice,   //最小成本价
                            minRetailPrice:0,  //最小零售价
                            minWholePrice:0,   //最小批发价
                            minVipPrice:0,   //最小vip价
                            remark:'',    //备注
                            isDel:0,
                            isEdit:1,
                        }
                        this.priceAdjustInfo.detailList.push(newRow);
                    }                    
                })
                //提示
                if(dupNum>0){
                    ElMessage.warning('已过滤掉'+dupNum+'个重复产品');
                }
            }
            this.BoxStockSku.isVisible=false;
        },


        //选择产品  ===3===
        BtnSelectSku(){
            this.BoxGoodsSku.isVisible=true;
        },
        //关闭-选择产品
        btnSelectSkuData(){
            let itemList = this.$refs.SelectSku.selectedItem;
            if(itemList!=null && itemList.length>0){
                let duplicateNum =0;  //重复数
                itemList.forEach(item=>{
                    //判断是否有重复
                    let isExist = false;
                    this.priceAdjustInfo.detailList.forEach(element =>{
                        if(element.skuNumber === item.skuNumber){
                            isExist=true;
                            duplicateNum +=1;  //记录重复记录数
                        }
                    })
                    //新增
                    if(!isExist){
                        let newRow = {
                            id:0,
                            systemNumber:item.systemNumber,
                            adjustNumber:'',
                            cid:item.cid,         //分类编号
                            cidName:item.cidName,    //分类名称
                            brandId:item.brandId,    //品牌编号
                            brandName:item.brandName,   //品牌名称
                            spuNumber:item.spuNumber,
                            spuName:item.spuName,
                            skuNumber:item.skuNumber,
                            skuName:item.skuName,
                            defUnitId:item.defUnitId,   //默认单位编号
                            defUnitName:item.defUnitName,  //默认单位名称
                            defCostPrice:0,  //默认成本价
                            defRetailPrice:0,  //默认零售价
                            defWholePrice:0,   //默认批发价
                            defVipPrice:0,   //默认vip价
                            minUnitId:item.minUnitId,
                            minUnitName:item.minUnitName,
                            minCostPrice:0,   //最小成本价
                            minRetailPrice:0,  //最小零售价
                            minWholePrice:0,   //最小批发价
                            minVipPrice:0,   //最小vip价
                            remark:'',    //备注
                            isDel:0,
                            isEdit:1,
                        }
                        this.priceAdjustInfo.detailList.push(newRow);
                    }                    
                })
                //提示
                if(duplicateNum>0){
                    ElMessage.warning('已过滤掉'+duplicateNum+'个重复产品');
                }
            }
            this.BoxGoodsSku.isVisible=false;
        },


        //编辑行
        EditRow(id){
            this.priceAdjustInfo.detailList = this.priceAdjustInfo.detailList.map(item=>{
                if(item.id === id){
                    item.isEdit = 1;
                }
                return item;
            })
        },
        //删除行
        DeleteRow(adjustNumber, id, index){
            this.priceAdjustInfo.detailList.splice(index,1);
            if(id!=0){
                let param = {
                    adjustNumber:adjustNumber,
                    id:id
                }
                this.$api.finance.DeletePriceAdjustDetail(param).then(res=>{
                    if(res.data.status===200){
                        ElMessage.success(res.data.msg)
                    }else{
                        ElMessage.error(res.data.msg);
                    }
                })
            }
        },
    },
}
</script>

<style scoped>
.defPrice{color: rgb(57, 116, 245);}
.minPrice{color: red;}
.PriceSize{font-size: 12px;}
</style>