<template>
  <div>

      <!-- 搜索功能 -->
      <el-row>
          <el-col :span="24">
              <el-form :inline="true">

                <el-form-item label="搜索内容">
                  <el-input v-model="info.search.searchName" placeholder="简称、全称、联系人、备注" class="search-200"></el-input>
                </el-form-item>

                <el-form-item label="显示标识">
                    <el-select v-model="info.search.isShow" class="search-150">
                        <el-option label="全部" :value="-1"></el-option>
                        <el-option label="隐藏" :value="0"></el-option>
                        <el-option label="显示" :value="1"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="开票标识">
                    <el-select v-model="info.search.isInvoice" class="search-150">
                        <el-option label="全部" :value="-1"></el-option>
                        <el-option label="否" :value="0"></el-option>
                        <el-option label="是" :value="1"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item>
                  <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
                  <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
                </el-form-item>

              </el-form>
          </el-col>
      </el-row>

      <!-- 主内容区 -->
      <div>
          <!-- 功能栏 -->
          <el-row :gutter="10" class="operation">
            <el-col :span="15">
              <el-button class="iconfont icon-add" type="primary" plain @click="btnEditInfo('0')">新增</el-button>
              <el-popconfirm 
                  title="确认要删除吗?"
                  @confirm="btnBatchDeleteInfo">
                  <template #reference>
                      <el-button class="iconfont icon-ashbin" type="danger" plain>删除</el-button>
                  </template>
              </el-popconfirm>
            </el-col>

            <el-col :span="9" class="operation_hidden">
              <el-button-group>
                                            
                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="流程图"
                    placement="top">
                    <el-button class="iconfont icon-icon__liuchengtu" @click="btnNoFlow"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="打印"
                    placement="top">
                    <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="导出数据"
                    placement="top">
                    <el-button class="iconfont icon-download" @click="btnExportInfo"></el-button>
                    </el-tooltip>
                </el-button-group>             
              
            </el-col>
          </el-row>

          <!-- 列表 -->
          <el-table :data="info.dataList" border stripe :height="GetDataHeight" style="width: 100%" @select="btnCheckbox" @select-all="btnCheckbox">
            <el-table-column prop="id" type="selection" width="50" />
            <el-table-column prop="supplierName" label="供应商简称" show-overflow-tooltip width="150" />
            <el-table-column prop="supplierAlias" label="供应商全称" show-overflow-tooltip width="150" />
            <el-table-column prop="linkMan" label="联系人" />
            <el-table-column prop="linkPhone" label="联系电话" />
            <el-table-column prop="mainBusiness" label="主营业务" show-overflow-tooltip width="200" />
            <el-table-column prop="remark" label="备注说明" show-overflow-tooltip width="200" />

            <el-table-column label="开票标识" min-width="85">
                <template #default="scope">
                  <el-tag v-if="scope.row.isInvoice == 1">是</el-tag>
                  <el-tag v-if="scope.row.isInvoice == 0" type="info">否</el-tag>
                </template>
            </el-table-column>

            <el-table-column label="显示标识" min-width="85">
                <template #default="scope">
                  <el-tag v-if="scope.row.isShow == 1">显示</el-tag>
                  <el-tag v-if="scope.row.isShow == 0" type="info">隐藏</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="创建日期" width="110">
              <template #default="scope">
                <span>{{DateFormat(scope.row.createTime)}}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="130" fixed="right">
              <template v-slot="scope">              
                <el-button size="small" type="primary" @click="btnEditInfo(scope.row.supplierNumber)">详情</el-button>
                <el-popconfirm 
                  title="确认要删除吗?"
                  @confirm="btnDeleteInfo(scope.row.supplierNumber)">
                    <template #reference>
                      <el-button size="small" type="danger">删除</el-button>
                    </template>
                  </el-popconfirm>
              </template>
            </el-table-column>
          </el-table>
      </div>

      <el-affix position="bottom">
          <el-row class="page_two">
              <el-col :span="24">
                  <!-- 分页 -->
                  <el-container class="page_right">
                      <el-pagination
                      v-model:currentPage="info.page"
                      v-model:page-size="info.size"
                      :page-sizes="[50, 200, 500, 1000]"
                      :background="true"
                      layout="total, sizes, prev, pager, next, jumper"
                      :total="info.total"
                      @current-change="btnPagechange"
                      @size-change="btnSizechange"
                      >
                      </el-pagination>
                  </el-container>
              </el-col>
          </el-row>        
      </el-affix>

      <!-- 新增、编辑 弹出框 -->
      <el-dialog :title="BoxEditInfo.title" 
      v-model="BoxEditInfo.isVisible"
      width="800"
      :draggable="true"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      @closed="GetDataList">
        <EditSupplier :editId="info.id" :dataList="info.dataList" @closeBox="btnCloseBox"></EditSupplier>
      </el-dialog>

  </div>
</template>

<script>
import EditSupplier from '@/views/Pms/Supplier/EditSupplier.vue'
import { ElMessage } from 'element-plus'
export default {
    name:'ListSupplier',
    components:{
      EditSupplier
    },
    computed:{
        //自适应窗体高度
        GetDataHeight(){
            return window.innerHeight-235
        }
    },
    data(){
        return{
            info:{
                dataList:[],  //Table数据
                page:1,   //页数
                size:50,  //单页记录数
                total:0,  //总记录数
                id:0,  //新增、编辑 的参数
                ids:[],   //删除 的参数
                search:{
                    searchName:'',  //聚合查询条件
                    isShow:-1,  //显示标识
                    isInvoice:-1,  //开票标识
                }
            },
            //编辑-弹出框
            BoxEditInfo:{
              isVisible:false,
              title:'新增供应商'
            },
        }
    },
    methods:{
      //加载数据列表
      GetDataList(){
        let param = {
          searchName:this.info.search.searchName,
          isShow:this.info.search.isShow,
          isInvoice:this.info.search.isInvoice,
          page:this.info.page,
          size:this.info.size
        }
        this.$api.supplier.PageSupplierList(param).then(res=>{
          if(res.data.status === 200){
            this.info.dataList = res.data.data;  //获取数据
            //分页
            this.info.total = res.data.total
            this.info.page = res.data.pageNumber
          }
        }).catch(e=>{
          console.log(e)
        })
      },
      //选中多选框
      btnCheckbox(selection){
        this.info.ids = selection.map(item=>item.supplierNumber)
      },
      //分页-页数变化时
      btnPagechange(page){
          this.info.page = page
          this.GetDataList() //加载Table数据
      },
      //分页-单页记录数变化时
      btnSizechange(size){
          this.info.size = size
          this.info.page = 1
          this.GetDataList() //加载Table数据
      },
      //新增编辑部门信息
      btnEditInfo(id){
        this.info.id = id
        if(id==0){
            this.BoxEditInfo.title = "新增供应商"
        }else{
            this.BoxEditInfo.title = "编辑供应商"
        }  
        this.BoxEditInfo.isVisible = true;
      },
      
      //删除按钮
      btnDeleteInfo(supplierNumber){
        let param = [supplierNumber]
        this.$api.supplier.DeleteSupplier(param).then(res=>{
          if(res.data.status === 200){
            ElMessage.success(res.data.msg)
            this.GetDataList()
          }
          else{
            ElMessage.error(res.data.msg)
          }
        })
      },
      //批量删除
      btnBatchDeleteInfo(){
        if(this.info.ids.length<1){
          ElMessage.error('请选择要删除的选项')
          return false
        }

        this.$api.supplier.DeleteSupplier(this.info.ids).then(res=>{
          if(res.data.status === 200){
            ElMessage.success(res.data.msg)
            this.GetDataList()
          }
          else{
            ElMessage.error(res.data.msg)
          }
        })
      },

      //导出供应商列表
      btnExportInfo(){
        let param = {
          searchName:this.info.search.searchName,
          isShow:this.info.search.isShow,
          isInvoice:this.info.search.isInvoice,
          page:this.info.page,
          size:this.info.size
        }

        this.$api.supplier.ExportSupplier(param).then(res=>{
          if(res.data.status === 200){
              const alink = document.createElement('a');
              alink.href = res.data.data;
              document.body.appendChild(alink);
              alink.click();
              document.body.removeChild(alink)
          }else{
            ElMessage.error(res.data.msg);
          }
        })
      },

      //重置按钮
      btnResetSearch(){
        this.info.search.searchName='';
        this.info.search.isShow = -1;  //是否显示
        this.info.search.isInvoice=-1;  //开票标识
        this.info.ids = [];  //编号数组
        this.GetDataList();
      },

      //关闭弹出框
      btnCloseBox(){
          this.BoxEditInfo.isVisible = false;
      },

    },
    mounted(){
      this.GetDataList() //加载数据

    }

}
</script>

<style>

</style>