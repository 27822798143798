<template>
  <div>
    <!-- 列表 -->
    <el-table :data="checkInfo.stateList" border style="width: 100%">
        <el-table-column prop="skuName" label="状态">
            <template v-slot="scope">
                <span v-if="scope.row.state ==1">新建单</span>
                <span v-if="scope.row.state ==2">盘点中</span>
                <span v-if="scope.row.state ==3">完成盘点</span>
                <span v-if="scope.row.state ==4">待复盘</span>
                <span v-if="scope.row.state ==5">已完成</span>
                <!-- <span v-if="scope.row.state ==10">已关单</span> -->
            </template>
        </el-table-column>

        <el-table-column label="备注" prop="remark" min-width="200" show-overflow-tooltip></el-table-column>

        <el-table-column label="创建时间">
            <template v-slot="scope">
                <span>{{DateTimeFormat(scope.row.createTime)}}</span>
            </template>
        </el-table-column>

        <el-table-column label="创建者">
            <template v-slot="scope">
                <span>{{scope.row.createName}}</span>
            </template>
        </el-table-column>

    </el-table>

  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    computed:{
        ...mapState('Erp',['checkInfo'])
    },
}
</script>

<style>

</style>