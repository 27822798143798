<template>
  <div>

    <!-- 日历 -->
    <el-calendar v-model="CurrentDate" :first-day-of-week="1" class="Schedule">
        <template #date-cell="{data}">
            <div @click="ShowDateInfo(data.day)" class="UserCell">
                <div>{{data.day.split('-').splice(2).join('-')}}</div>
                <!-- 排班人员 -->
                <span v-for="(item,index) in sheetList" :key="index">
                    <span v-if="item.today == data.day">
                        <span type="primary" class="UserInfo">{{item.userName}}</span>
                    </span>
                </span>
            </div>         
        </template>
    </el-calendar>

    <!-- 今日排班 - 弹出框 -->
    <el-dialog title="当日排班表"
    v-model="BoxDaySchedule.isVisible"
    width="40%"
    :draggable="true"
    :close-on-click-modal="false"
    :destroy-on-close="true">
        <BoxDaySchedule :day="dayInfo"></BoxDaySchedule>
    </el-dialog>

  </div>
</template>

<script>
import BoxDaySchedule from '@/views/Oa/Schedule/BoxDaySchedule.vue'
import moment from 'moment'
export default {
    components:{
        BoxDaySchedule
    },
    data(){
        return{
            CurrentDate:new Date(),  //
            dayInfo:'',  //日期
            sheetList:[],
            //今日排班-弹出框
            BoxDaySchedule:{
              isVisible:false
            },
        }
    },
    computed:{
        //自适应窗体高度
        GetDataHeight(){
            return window.innerHeight-250
        }
    },
    methods:{
        //获取单日信息
        ShowDateInfo(day){
            this.BoxDaySchedule.isVisible = true;
            this.dayInfo = day;
        },
        //
        GetDataInfo(curentDate){
            let param = {
                date:curentDate
            }
            this.$api.oa.GetSingleMonthScheduleList(param).then(res=>{
                if(res.data.status === 200){
                    if(res.data.data !=null){
                        this.sheetList = res.data.data.map(item=>{
                            item.today = moment(item.today).format("YYYY-MM-DD")
                            return item
                        });
                    }
                }
            })
        }
    },
    created(){
        this.$nextTick(()=>{
        // 点击上个月
        let prevBtn = document.querySelector('.el-calendar__button-group .el-button-group>button:nth-child(1)');
        prevBtn.addEventListener('click', () => {
            let clickDate = moment(this.CurrentDate).format("YYYY-MM-DD");
            this.GetDataInfo(clickDate);
        })
        // 点击今天
        let currBtn = document.querySelector('.el-calendar__button-group .el-button-group>button:nth-child(2)');
        currBtn.addEventListener('click', () => {
            let clickDate = moment(this.CurrentDate).format("YYYY-MM-DD");
            this.GetDataInfo(clickDate);
        })
        // 点击下个月
        let nextBtn = document.querySelector('.el-calendar__button-group .el-button-group>button:nth-child(3)');
        nextBtn.addEventListener('click', () => {
            let clickDate = moment(this.CurrentDate).format("YYYY-MM-DD");
            this.GetDataInfo(clickDate);
        })
        })
    },
    mounted(){
        let currentDate = moment(this.CurrentDate).format("YYYY-MM-DD");
        this.GetDataInfo(currentDate);
    }
    
}
</script>

<style>
.Schedule .el-calendar-table {height: 739px;}
.Schedule .UserCell {overflow: hidden;height: 105px;text-overflow: ellipsis;display: block;overflow-y:auto;}
.Schedule .UserInfo{margin: 0px 5px;}

</style>