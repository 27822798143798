<template>
  <div>
    <!-- 单品销售排行 -->

    <!-- 标题 -->
    <el-row>
        <el-col :span="24" class="title">
            <h3>产品热销排行</h3>
        </el-col>        
    </el-row>

    <!-- 查询条件 -->
    <div>
        <el-form :inline="true">
            <el-form-item label="部门">
                <el-tree-select v-model="info.search.selectDepartmentList" :data="allDptList" multiple :default-expand-all="true" collapse-tags collapse-tags-tooltip class="search-200">
                </el-tree-select>
            </el-form-item>

            <el-form-item label="交易者">
                <el-select v-model="info.search.tradeNumber" filterable clearable class="search-200">
                    <el-option v-for="trade in allTradeList" :key="trade.tradeNumber" :label="trade.tradeName+ '(' + trade.tradeTypeName+')'" :value="trade.tradeNumber" />
                </el-select>
            </el-form-item>

            <el-form-item label="单据类型">
                <el-select v-model="info.search.originTypeList" multiple collapse-tags collapse-tags-tooltip class="search-150">
                    <el-option label="采购单" :value="1" />
                    <el-option label="销售单" :value="2" />
                    <el-option label="零售单" :value="3" />
                    <el-option label="差额单" :value="4" />
                    <el-option label="退供单" :value="14" />
                    <el-option label="退货单" :value="15" />
                    <el-option label="采购差额单" :value="41" />
                    <el-option label="销售差额单" :value="42" />
                </el-select>
            </el-form-item>

            <el-form-item label="名称">
                <el-input v-model="info.search.searchName" placeholder="品牌、分类、产品名称" class="search-200"></el-input>
            </el-form-item>

            <el-form-item label="月份">
                <el-date-picker
                    v-model="info.search.datePoint"
                    type="daterange"
                    range-separator="To"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    style="width:250px"
                />
            </el-form-item>
            
            <el-form-item>
                <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
                <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
            </el-form-item>

        </el-form>
    </div>
    
    <!-- 数据结果 -->
    <el-table :data="dataList" border stripe show-summary style="width: 100%;" :height="GetDataHeight">
        <el-table-column prop="skuNumber" label="编号"></el-table-column>
        <el-table-column prop="cidName" label="产品分类"></el-table-column>
        <el-table-column prop="brandName" label="品牌"></el-table-column>
        <el-table-column prop="spuName" label="产品名称"></el-table-column>
        <el-table-column prop="skuName" label="产品规格"></el-table-column>
        <el-table-column prop="minUnitName" label="最小单位"></el-table-column>
        <el-table-column prop="minUnitNum" label="最小单位数量">
            <template v-slot="scope">
                {{this.myRound(scope.row.minUnitNum,2)}}
            </template>
        </el-table-column>
        <el-table-column prop="minUnitPrice" label="最小单位单价">
            <template v-slot="scope">
                {{this.myRound(scope.row.minUnitPrice,2)}}
            </template>
        </el-table-column>
        <el-table-column prop="totalPrice" label="总金额">

        </el-table-column>
        <el-table-column label="操作" width="130">
            <template v-slot="scope">
                <el-button size="small" type="primary" class="iconfont icon-data-view" @click="btnOpenBoxUnitPrice(scope.row.skuNumber)">历史单价</el-button>
            </template>
        </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-affix position="bottom">
        <el-row class="page_two">
            <el-col :span="24">
                <!-- 分页 -->
                <el-container class="page_right">
                    <el-pagination
                    v-model:currentPage="info.page"
                    v-model:page-size="info.size"
                    :page-sizes="[50, 200, 500, 1000]"
                    :background="true"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="info.total"
                    @current-change="btnPagechange"
                    @size-change="btnSizechange"
                    >
                    </el-pagination>
                </el-container>
            </el-col>
        </el-row>        
    </el-affix>

    <!-- <div class="dataSource">
        数据来源：业务单
    </div> -->

    <!-- 单瓶价值走势图 -->
    <el-dialog title="单品价格走势图"
    v-model="BoxPriceView.isVisible"
    width="900px"
    :draggable="true"
    :close-on-click-modal="false"
    :destroy-on-close="true">
        <BoxUnitPrice></BoxUnitPrice>
    </el-dialog>

  </div>
</template>

<script>
import AllDptList from '@/utils/mixins/AllDptList.js'  //全部部门列表
import CategoryList from '@/utils/mixins/CategoryList.js'
import AllTradeList from '@/utils/mixins/AllTradeList.js'  //交易者列表

import BoxUnitPrice from '@/views/Ams/Goods/BoxUnitPrice.vue' //单价走势图
import { ElMessage } from 'element-plus'
export default {
    mixins:[AllDptList,CategoryList,AllTradeList],
    components:{
        BoxUnitPrice
    },
    data(){
        return{
            dataList:[],   //数据结果
            info:{
                page:1,
                size:50,
                total:0,  //总记录数
                id:0,  //新增、编辑 的参数
                ids:[],   //删除 的参数
                //查询条件
                search:{
                    selectDepartmentList:[],
                    tradeNumber:'',  //交易者编号
                    searchName:'',  //产品名称
                    originTypeList:[],   //原始单据类型
                },
            },
            beginDate:this.DateFormat(new Date()),   //开始日期
            endDate:this.DateFormat(new Date()),   //结束日期  
            //单价-弹出框
            BoxPriceView:{
                isVisible:false,
            }               
        }
    },
    computed:{
        //自适应窗体高度
        GetDataHeight(){
            return window.innerHeight-260
        }
    },
    methods:{
        //加载数据
        GetDataList(){
            //获取、转换部门
            let newDepartmentList = []
            if(this.info.search.selectDepartmentList!=null){
                newDepartmentList = this.info.search.selectDepartmentList.map(item=>{
                    return item
                })
            }

            let param = {
                departmentList:newDepartmentList,           //部门列表
                startDate:this.DateFormat(this.info.search.datePoint[0]),   //制单日期
                endDate:this.DateFormat(this.info.search.datePoint[1]),
                tradeNumber:this.info.search.tradeNumber,   //交易者编号
                searchName:this.info.search.searchName,   //聚合查询
                originTypeList:this.info.search.originTypeList,   //原始单据类型
                page:this.info.page,
                size:this.info.size
            }
            this.$api.stat.StatGoodsRank(param).then(res=>{
                if(res.data.status==200){
                    this.dataList = res.data.data;
                    //分页
                    this.info.total = res.data.total
                    this.info.page = res.data.pageNumber
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

        //分页-页数变化时
        btnPagechange(page){
            this.info.page = page
            this.GetDataList() //加载Table数据
        },
        //分页-单页记录数变化时
        btnSizechange(size){
            this.info.size = size;
            this.info.page = 1;
            this.GetDataList() //加载Table数据
        },

        //重置查询条件
        btnResetSearch(){
            this.info.search = {
                selectDepartmentList:[],
                tradeType:0,
                tradeNumber:'',  //交易者编号
                searchName:'',  //名称
                originTypeList:[],
            };     
            this.GetbeginDate();
            this.GetDataList();
        },

        //计算日期
        GetbeginDate(){
            var date = new Date();
            date.setDate(date.getDate()-7);
            var year = date.getFullYear();
            var month = (date.getMonth() + 1).toString().padStart(2,'0');
            var day = date.getDate().toString().padStart(2,'0');
            var startDate = year + '-' + month + '-'+day;
            this.beginDate = this.DateFormat(startDate);
            this.info.search.datePoint = [this.beginDate,this.endDate];
        },

        //打开-单价走势-弹框
        btnOpenBoxUnitPrice(){
            this.BoxPriceView.isVisible=true;
        },
        //关闭-单价走势-弹框
        btnCloseBoxUnitPrice(){
            this.BoxPriceView.isVisible=false;
        },

    },
    mounted(){
        this.GetbeginDate();  //设置开始日期
        this.GetDataList();  //获取数据

    }
}
</script>

<style scoped>
.title{text-align: center;}
.dataSource{margin-left: 40px;font-size: 14px;color: #ccc;}
</style>