<template>
  <div>
    <!-- 可选择的调价表列表 -->
    <el-divider></el-divider>

    <!-- 搜索功能 -->
    <el-row>
        <el-col :span="24">
            <el-form :inline="true">

                <el-form-item label="调价编号">
                    <el-input v-model="info.search.adjustNumber" maxlength="20" class="search-150"></el-input>
                </el-form-item>

                <el-form-item label="部门">
                    <el-tree-select v-model="info.search.selectDepartmentList" :data="departmentList" multiple :default-expand-all="true" collapse-tags collapse-tags-tooltip class="search-200">
                    </el-tree-select>
                </el-form-item>

                <el-form-item label="大区">
                    <el-select v-model="info.search.areaNumberList" multiple collapse-tags collapse-tags-tooltip class="search-200">
                        <el-option v-for="userArea in userAreaList" :key="userArea.areaNumber" :label="userArea.areaName" :value="userArea.areaNumber"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="状态">
                    <el-select v-model="info.search.state" class="search-150">
                        <el-option :value="0" label="全部"></el-option>
                        <el-option :value="1" label="新建单"></el-option>
                        <el-option :value="2" label="待审核"></el-option>
                        <el-option :value="3" label="已驳回"></el-option>
                        <!-- <el-option :value="4" label="已审核并上报"></el-option> -->
                        <el-option :value="5" label="已生效"></el-option>
                        <el-option :value="9" label="已作废"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item>
                    <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
                    <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
                </el-form-item>

            </el-form>
        </el-col>
    </el-row>

    <!-- 调价表列表 -->
    <el-table :data="dataList" border stripe height="250px" @current-change="ClickRowData" highlight-current-row>
        <el-table-column prop="adjustNumber" label="编号"></el-table-column>
        <el-table-column prop="departmentName" label="部门名称" min-width="100" show-overflow-tooltip></el-table-column>
        <el-table-column prop="areaName" label="大区" min-width="90" show-overflow-tooltip></el-table-column>
        <el-table-column prop="remark" label="备注" min-width="100" show-overflow-tooltip></el-table-column>
        <el-table-column label="状态">
            <template #default="scope">
                <el-tag v-if="scope.row.state ==1">新建单</el-tag>
                <el-tag v-if="scope.row.state ==2">待审核</el-tag>
                <el-tag v-if="scope.row.state ==3" type="danger">已驳回</el-tag>
                <el-tag v-if="scope.row.state ==4" type="warning">已审核并上报</el-tag>
                <el-tag v-if="scope.row.state ==5" type="success">已生效</el-tag>
                <el-tag v-if="scope.row.state ==9" type="info">已作废</el-tag>
            </template>
        </el-table-column>
        <el-table-column label="调价时间" width="110">
            <template #default="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.adjustDate"
                    placement="top"
                >
                <span>{{DateFormat(scope.row.adjustDate)}}</span>
                </el-tooltip>                
            </template>
        </el-table-column>
    </el-table>

    <el-affix position="bottom">
        <el-row class="page_two">
            <el-col :span="24">
                <!-- 分页 -->
                <el-container class="page_right">
                    <el-pagination
                    v-model:currentPage="info.page"
                    v-model:page-size="info.size"
                    :page-sizes="[20, 50, 200, 500]"
                    :background="true"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="info.total"
                    @current-change="btnPagechange"
                    @size-change="btnSizechange"
                    >
                    </el-pagination>
                </el-container>
            </el-col>
        </el-row>        
    </el-affix>

    <!-- 调价明细列表 -->
    <el-divider class="CenterLine">调价明细列表</el-divider>
    <el-table :data="dataDetailList" border stripe height="250px" @select="btnCheckbox" @select-all="btnAllCheckbox" @row-click="TableRowClick" ref="adjustDetailList">
        <el-table-column prop="id" type="selection"></el-table-column>
        <el-table-column prop="cidName" label="产品类目" min-width="90"></el-table-column>
        <el-table-column prop="brandName" label="品牌" min-width="80"></el-table-column>
        <el-table-column prop="spuName" label="产品名称" min-width="110">
            <template v-slot="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.spuName"
                    placement="top">
                    <div class="cellOverFlow">{{scope.row.spuName}}</div>
                </el-tooltip>
            </template>
        </el-table-column>
        <el-table-column prop="skuName" label="规格名称" min-width="90"/>

        <el-table-column label="默认单位" width="85">
            <template #header>
                <div class="defPrice">默认单位</div>
            </template>
            <template v-slot="scope">
                <span>{{scope.row.defUnitName}}</span>
            </template>
        </el-table-column>

        <el-table-column label="成本价" width="115">
            <template #header>
                <div>成本价</div>
                <div class="defPrice PriceSize">(默认单位)</div>
            </template>
            <template v-slot="scope">
                <span>{{this.myRound(scope.row.defCostPrice,2)}}</span>
            </template>
        </el-table-column>

        <el-table-column label="零售价"  width="115">
            <template #header>
                <div>零售价</div>
                <div class="defPrice PriceSize">(默认单位)</div>
            </template>
            <template v-slot="scope">
                <span>{{scope.row.defRetailPrice}}</span>
            </template>
        </el-table-column>

        <el-table-column  label="批发价"  width="115">
            <template #header>
                <div>批发价</div>
                <div class="defPrice PriceSize">(默认单位)</div>
            </template>
            <template v-slot="scope">
                <span>{{scope.row.defWholePrice}}</span>
            </template>
        </el-table-column>

        <el-table-column  label="VIP价"  width="115">
            <template #header>
                <div>VIP价</div>
                <div class="defPrice PriceSize">(默认单位)</div>
            </template>
            <template v-slot="scope">
                <span>{{scope.row.defVipPrice}}</span>
            </template>
        </el-table-column>

        <el-table-column label="最小单位" width="85">
            <template #header>
                <div class="minPrice">最小单位</div>
            </template>
            <template v-slot="scope">
                <span>{{scope.row.minUnitName}}</span>
            </template>
        </el-table-column>
        <el-table-column label="成本价" width="115">
            <template #header>
                <div>成本价</div>
                <div class="minPrice PriceSize">(最小单位)</div>
            </template>
            <template v-slot="scope">
                <span>{{this.myRound(scope.row.minCostPrice,2)}}</span>
            </template>
        </el-table-column>
        <el-table-column label="零售价" width="115">
            <template #header>
                <div>零售价</div>
                <div class="minPrice PriceSize">(最小单位)</div>
            </template>
            <template v-slot="scope">
                <span>{{scope.row.minRetailPrice}}</span>
            </template>
        </el-table-column>
        <el-table-column  label="批发价" width="115">
            <template #header>
                <div>批发价</div>
                <div class="minPrice PriceSize">(最小单位)</div>
            </template>
            <template v-slot="scope">
                <span>{{scope.row.minWholePrice}}</span>
            </template>
        </el-table-column>

        <el-table-column  label="VIP价" width="115">
            <template #header>
                <div>VIP价</div>
                <div class="minPrice PriceSize">(最小单位)</div>
            </template>
            <template v-slot="scope">
                <span>{{scope.row.minVipPrice}}</span>
            </template>
        </el-table-column>

    </el-table>

  </div>
</template>

<script>
import UserDptList from '@/utils/mixins/UserDptList.js'   //用户关联部门列表
import UserAreaList from '@/utils/mixins/UserAreaList.js'  //用户关联大区列表

import { ElMessage } from 'element-plus'
export default {
    mixins:[UserDptList,UserAreaList],
    data(){
        return{
            dataList:[],  //table数据
            info:{
                page:1,
                size:50,
                total:0,  //总记录数
                id:0,  //新增、编辑 的参数
                ids:[],   //删除 的参数
                search:{
                    adjustNumber:'',
                    selectDepartmentList:[],  //部门
                    areaNumberList:[],   //大区列表
                    datePoint:'',   //报价日期
                    state:5,      //状态
                }
            },

            dataDetailList:[],  //调价明细列表

            selectedInfo:{},  //选中的主信息
            selectedDetail:[],  //选中的明细列表
        }
    },
    methods:{

        //加载数据列表
        GetDataList(){
            //日期范围
            if(this.info.search.datePoint == null){
                this.info.search.datePoint = ''
            }

            //获取、转换部门
            let newDepartmentList = []
            if(this.info.search.selectDepartmentList!=null){
                newDepartmentList = this.info.search.selectDepartmentList.map(item=>{
                    return item
                })
            }

            let param = {
                adjustNumber:this.info.search.adjustNumber,
                departmentList:newDepartmentList,
                areaNumberList:this.info.search.areaNumberList,  //大区
                remark:this.info.search.remark,
                state:this.info.search.state,   //状态
                startDate:this.DateFormat(this.info.search.datePoint[0]),
                endDate:this.DateFormat(this.info.search.datePoint[1]),
                page:this.info.page,
                size:this.info.size
            }
            this.$api.finance.PagePriceAdjustList(param).then(res=>{
                if(res.data.status === 200){
                    this.dataList = res.data.data;
                    //分页
                    this.info.total = res.data.total;
                    this.info.page = res.data.pageNumber;
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

        //分页-页数变化时
        btnPagechange(page){
            this.info.page = page
            this.GetDataList() //加载Table数据
        },
        //分页-单页记录数变化时
        btnSizechange(size){
            this.info.size = size
            this.info.page = 1
            this.GetDataList() //加载Table数据
        },

        //重置按钮
        btnResetSearch(){
            this.info.search.adjustNumber="";  //调价编号
            this.info.search.remark="";   //备注
            this.info.search.selectDepartmentList = [];
            this.info.search.datePoint = "";
            this.info.search.state=0;
            this.info.ids = [];  //编号数组
            this.info.page = 1;
            this.GetDataList()
        }, 

        //加载明细数据列表
        GetAdjustDetailList(){
            let param = {
                adjustNumber:this.selectedInfo.adjustNumber
            }
            this.$api.finance.GetPriceAdjustDetailList(param).then(res=>{
                if(res.data.status === 200){
                    this.dataDetailList = res.data.data;
                    if(this.dataDetailList !=null){
                        //设置全选中
                        this.$refs.adjustDetailList.toggleAllSelection();  //全选
                    }
                }else{
                    this.dataDetailList = [];  //清空明细
                }
            })
        },

        //单击-选择主单据
        ClickRowData(row){
            if(row !=null){
                this.selectedInfo = row;  //选中的主表
                this.GetAdjustDetailList();  //加载明细列表
            }else{
                this.dataDetailList = [];  //清空明细
            }
        },

        //明细表-选中多选框
        btnCheckbox(row){
            this.selectedDetail = row;
        },
        //明细表-全选
        btnAllCheckbox(){
            this.selectedDetail = this.dataDetailList;
        },
        //明细表-选中行时(标准模板)
        TableRowClick(row){
            let isExist = false; //判断是否存在
            this.selectedDetail = this.selectedDetail.filter(item=>{                
                if(row.id != item.id){
                    return item;
                }else{                    
                    //如果存在
                    isExist = true;
                }                
            })
            
            if(isExist){
                this.$refs.adjustDetailList.toggleRowSelection(row,false);
            }else{
                this.selectedDetail.push(row)
                this.$refs.adjustDetailList.toggleRowSelection(row,true);
            }
        },

    },
    mounted(){
        this.GetDataList();
    },
}
</script>

<style scoped>
.defPrice{color: rgb(57, 116, 245);}
.minPrice{color: red;}
.PriceSize{font-size: 12px;}
</style>