<template>
  <div>
    <el-divider></el-divider>

    <div id="printData">
        <div>
            <el-row>
                <el-col :span="7" class="titleLeft">打印时间：{{printTime}}</el-col>
                <el-col :span="10" class="title"><h3>业务单</h3></el-col>
                <el-col :span="7" class="titleRight">公司名称：{{CurrentsystemInfo.companyName}}</el-col>
            </el-row>
            <el-divider></el-divider>
        </div>
        <!-- <table class="DataInfo">
            
        </table> -->

        <table class="DataDetail">
            <tr>
                <th>业务单号</th>
                <th>单据类型</th>
                <th>部门</th>
                <th>交易者类型</th>
                <th>交易者</th>
                <th>产品类目</th>
                <th>品牌</th>
                <th>产品/类型</th>
                <th>规格/说明</th>
                <th>单位</th>
                <th>单价</th>
                <th>数量</th>
                <th>总价(元)</th>
                <th>记账时间</th>
                <th>创建时间</th>            
                <th>制单人</th>
                <th>备注</th>
            </tr>
            <tr v-for="detail in dataList" :key="detail.billNumber">
                <td>{{detail.billNumber}}</td>
                <td>
                    <span v-if="detail.originType==1">采购单</span>
                    <span v-if="detail.originType==2">销售单</span>
                    <span v-if="detail.originType==3">零售单</span>
                    <span v-if="detail.originType==4">差额单</span>
                    <span v-if="detail.originType==14">退供单</span>
                    <span v-if="detail.originType==15">退货单</span>
                    <span v-if="detail.originType==18">退零单</span>
                    <span v-if="detail.originType==41">采购差额单</span>
                    <span v-if="detail.originType==42">销售差额单</span>
                </td>
                <td>{{detail.departmentName}}</td>
                <td>
                    <span v-if="detail.tradeType==1">供应商</span>
                    <span v-if="detail.tradeType==2">客户</span>
                    <span v-if="detail.tradeType==3">员工</span>
                    <span v-if="detail.tradeType==4">散客</span>
                </td>
                <td>{{detail.tradeName}}</td>
                <td>{{detail.cidName}}</td>
                <td>{{detail.brandName}}</td>
                <td>{{detail.spuName}}</td>
                <td>{{detail.skuName}}</td>
                <td>{{detail.unitName}}</td>
                <td>{{detail.unitPrice}}</td>
                <td>{{detail.itemNum}}</td>
                <td>{{detail.totalPrice}}</td>                
                <td>{{DateFormat(detail.billTime)}}</td>
                <td>{{DateFormat(detail.createTime)}}</td>
                <td>{{detail.createName}}</td>
                <td>{{detail.remark}}</td>
            </tr>
        </table>

    </div>

    <!-- 按钮 -->
    <div class="btnGroup">
        <el-button type="primary" class="iconfont icon-dayin" v-print="printObj" id="btnPrint">打印</el-button>
        <el-button @click="btnClose">关闭</el-button>
    </div>

  </div>
</template>

<script>
import moment from 'moment'
import { mapState } from 'vuex'
export default {
    props:['dataList'],
    data(){
        return{
            printTime:moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
            printObj: {
              id:'printData',
              preview: false,
              popTitle: '打印预览',
            }
        }
    },
    computed:{
        ...mapState('Master',['CurrentsystemInfo'])
    },
    methods:{
        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },
    },
    mounted(){
        document.getElementById("btnPrint").click();
    },
    
    
}
</script>

<style scoped>
#printData {font-size: 12px;overflow: hidden;} /**防止多打印一张 */
#printData .titleLeft{display: flex;align-items: flex-end;}
#printData .title {text-align: center;font-size: 14px;}
#printData .titleRight{display: flex;align-items: flex-end;justify-content: flex-end;}
#printData .DataInfo{width: 100%;}
#printData .DataInfo td {padding: 5px 0px;}

#printData .DataDetail {width: 100%;border-collapse: collapse;}
#printData .DataDetail th {border: 1px solid #ccc;}
#printData .DataDetail td {border: 1px solid #ccc;}
</style>