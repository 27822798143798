<template>
  <div>
    <!-- 排班设置 -->

    <el-tabs v-model="info.activeName">
        <el-tab-pane label="排班设置" name="TabDay">
            <TabDay></TabDay>
        </el-tab-pane>

        <el-tab-pane label="排班工作表" name="TabSheet">
            <TabSheet></TabSheet>
        </el-tab-pane>
    </el-tabs>

  </div>
</template>

<script>
import TabDay from '@/views/Oa/Schedule/TabDay.vue'
import TabSheet from '@/views/Oa/Schedule/TabSheet.vue'
export default {
    components:{
        TabDay,
        TabSheet
    },
    data(){
        return{
            info:{
                activeName:'TabDay'
            }
        }
    }

}
</script>

<style>

</style>