/**
 * 统计管理模块
 * 
 */


//货品量统计
import ListStatGoods from '@/views/Ams/GoodsVolumeAnalyse.vue'
//营业额统计
import TurnoverAnalyse from '@/views/Ams/TurnoverAnalyse.vue'
//销售员业绩统计
import SalerAnalyse from '@/views/Ams/Saler/SalerAnalyse.vue'

//产品统计
import RankGoodsPrice from '@/views/Ams/Goods/RankGoodsPrice.vue'  //单品价格走势图

import RankProfitCid from '@/views/Ams/Goods/RankProfitCid.vue'  //利润排行(按分类)
import RankProfitSpu from '@/views/Ams/Goods/RankProfitSpu.vue' //利润排行(按产品)
import RankProfitSku from '@/views/Ams/Goods/RankProfitSku.vue' //利润排行(按规格)

//财务统计
import BigDataYearFinance from '@/views/Ams/BigData/BigDataYearFinance.vue'   //单年财务统计
import AccountAnalyse from '@/views/Ams/AccountAnalyse.vue'  //账户统计

//交易统计
import VolumeDay from '@/views/Ams/Volume/VolumeDay.vue'  //统计单日交易量、交易额
import VolumeMonth from '@/views/Ams/Volume/VolumeMonth.vue' //统计单月交易量、交易额
import VolumeYear from '@/views/Ams/Volume/VolumeYear.vue'  //统计单年交易量、交易额
import VolumeDpt from '@/views/Ams/Volume/VolumeDpt.vue'   //部门交易统计

//客户统计
import CustomerRank from '@/views/Ams/Customer/CustomerRank.vue'  //客户交易排名统计
import CustomerProfitRank from '@/views/Ams/Customer/CustomerProfitRank.vue'  //客户利润排行
import CustomerNoPay from '@/views/Ams/Customer/CustomerNoPay.vue'   //应收账款统计

//供应商统计
import SupplierNoPay from '@/views/Ams/Supplier/SupplierNoPay.vue'  //应付账款统计


export default[
    {
        //货品量统计
        name:'ListStatGoods',
        path:'liststatgoods',
        component:ListStatGoods
    },
    {
        //营业额统计
        name:'TurnoverAnalyse',
        path:'turnoveranalyse',
        component:TurnoverAnalyse
    },
    {
        //账户统计
        name:'AccountAnalyse',
        path:'accountanalyse',
        component:AccountAnalyse
    },
    {
        //销售员业绩统计
        name:'SalerAnalyse',
        path:'saleranalyse',
        component:SalerAnalyse
    },

    {
        //全年财务统计
        name:'BigDataYearFinance',
        path:'bigdatayearfinance',
        component:BigDataYearFinance
    },

    {
        //单日-交易量、交易额
        name:'VolumeDay',
        path:'volumeday',
        component:VolumeDay
    },
    {
        //单月-交易量、交易额
        name:'VolumeMonth',
        path:'volumemonth',
        component:VolumeMonth
    },
    {
        //单年-交易量、交易额
        name:'VolumeYear',
        path:'volumeyear',
        component:VolumeYear
    },
    {
        //部门-交易统计
        name:'VolumeDpt',
        path:'VolumeDpt',
        component:VolumeDpt,
    },

    {
        //客户交易排名统计
        name:'CustomerRank',
        path:'customerrank',
        component:CustomerRank,
    },
    {
        //客户利润排行(按分类)
        name:'CustomerProfitRank',
        path:'customerprofitrank',
        component:CustomerProfitRank
    },
    {
        //应收账款统计
        name:'CustomerNoPay',
        path:'customernopay',
        component:CustomerNoPay
    },

    {
        //产品-产品热销排行
        name:'RankGoodsPrice',
        path:'rankgoodsprice',
        component:RankGoodsPrice
    },
    {
        //产品-利润排行(按分类)
        name:'RankProfitCid',
        path:'rankprofitcid',
        component:RankProfitCid,
    },
    {
        //产品-利润排行(按产品)
        name:'RankProfitSpu',
        path:'rankprofitspu',
        component:RankProfitSpu,
    },
    {
        //产品-利润排行(按规格)
        name:'RankProfitSku',
        path:'rankprofitsku',
        component:RankProfitSku,
    },

    {
        //供应商-应付账款统计
        name:'SupplierNoPay',
        path:'suppliernopay',
        component:SupplierNoPay,
    },
]