<template>
  <div class="editInfo">
    <!--  新增、编辑 报价单 -->
    <el-divider></el-divider>

    <div>
      <!-- Tab标签 -->
      <TabPriceShare ref="tabPrice"></TabPriceShare>

      <el-divider>报价单明细</el-divider>
      <TabPriceShareDetail></TabPriceShareDetail>
    </div>
    
    <!-- 功能按钮组 -->
    <div class="editFooter" v-if="priceShareInfo.info">
        <el-button type="primary" @click="btnSaveData">保存</el-button>
        <el-button @click="btnClose">关闭</el-button>

        <el-button-group>
            <el-button @click="btnPrevious" class="iconfont icon-houtui_shangyibu_zuojiantou_shangyiye">上一条</el-button>
            <el-button @click="btnNext">下一条<el-icon class="iconfont icon-qianjin_xiayibu_youjiantou_xiayiye"></el-icon></el-button>
        </el-button-group>
    </div>


  </div>
</template>

<script>
import TabPriceShare from '@/views/Fms/PriceShare/TabPriceShare.vue'
import TabPriceShareDetail from '@/views/Fms/PriceShare/TabPriceShareDetail.vue'
import { ElLoading, ElMessage } from 'element-plus'
import { mapMutations, mapState } from 'vuex'
export default {
    props:['editId','dataList'],
    components:{
      TabPriceShare,
      TabPriceShareDetail,
    },
    data(){
      return{
        info:{
          shareNumber:this.editId,
        }
      }
    },
    computed:{
        ...mapState('Finance',['priceShareInfo'])
    },
    methods:{
      ...mapMutations('Finance',['SetPriceShareInfo']),

      //加载数据
      GetDataInfo(){
        if(this.info.shareNumber!=0){
          let param = {
            shareNumber:this.info.shareNumber
          }
          this.$api.finance.GetPriceShareAllInfo(param).then(res=>{
            if(res.data.status === 200){
              this.SetPriceShareInfo(res.data.data);
            }else{
              this.btnClearData();
            }
          })
        }else{
          this.btnClearData();
        }
      },

      //保存数据
      btnSaveData(){
        //打开遮罩层
        const loading = ElLoading.service({
            lock:true,
            text:'正在提交,请稍候!',
            background:'rgba(0, 0, 0, 0.7)',
        })
        this.$api.finance.SavePriceShareAllInfo(this.priceShareInfo).then(res=>{
          loading.close();
          if(res.data.status === 200){
            ElMessage.success(res.data.msg);
            //刷新页面
            this.info.shareNumber= res.data.data;
            this.GetDataInfo();
          }else{
            ElMessage.error(res.data.msg);
          }
        })
      },

      //关闭按钮
      btnClose(){
          this.$emit('closeBox')  //关闭弹出框
      },

      //清空数据
      btnClearData(){
        var newInfo = {
          info:{
            shareNumber:'',
            title:'',
            createTime:'',
            createName:'',
            creator:'',
          },
          detailList:[],
        }
        this.SetPriceShareInfo(newInfo);
      },

      //上一条
      btnPrevious(){
          let currentIndex = this.dataList.findIndex((item)=>{
              return item.shareNumber === this.info.shareNumber
          })
          let prevIndex = currentIndex -1;
          let prevInfo = this.dataList[prevIndex];
          if(prevInfo==null){
              ElMessage.warning("没有上一条了");
              return false;
          }
          else{
              this.info.shareNumber = prevInfo.shareNumber
              this.GetDataInfo()
          }
          
      },
      //下一条
      btnNext(){
          let currentIndex = this.dataList.findIndex((item)=>{
              return item.shareNumber === this.info.shareNumber
          })
          let prevIndex = currentIndex + 1;
          let prevInfo = this.dataList[prevIndex];
          if(prevInfo==null){
              ElMessage.warning("没有下一条了");
              return false;
          }
          else{
              this.info.adjustNumber = prevInfo.adjustNumber
              this.GetDataInfo()
          }
      },

    },
    mounted(){
      this.GetDataInfo();
    },

}
</script>

<style>

</style>