<template>
  <div>
    <!-- 搜索功能 -->
      <el-row :style="showSearch.search_hidden">
          <el-col :span="24">
              <el-form :inline="true">
                
                <el-form-item label="部门">
                    <el-tree-select v-model="info.search.selectDepartmentList" :data="departmentList" multiple clearable :default-expand-all="true" collapse-tags collapse-tags-tooltip class="search-200">
                    </el-tree-select>
                </el-form-item>

                <el-form-item label="物流单号">
                  <el-input v-model="info.search.billNumber" class="search-200"></el-input>
                </el-form-item>

                <el-form-item label="员工姓名">
                    <el-select v-model="info.search.receiver" clearable class="search-200">
                        <el-option v-for="info in selectUserList" :key="info.userNumber" :label="info.userName" :value="info.userNumber"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="状态">
                    <el-select v-model="info.search.state" class="search-200">
                        <el-option :value="0" label="全部"></el-option>
                        <el-option :value="1" label="新建单"></el-option>
                        <el-option :value="2" label="已锁单"></el-option>
                        <el-option :value="3" label="已接单"></el-option>
                        <el-option :value="4" label="已完成"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item>
                <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
                <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
                </el-form-item>

              </el-form>
          </el-col>
      </el-row>

    <!-- 主内容区 -->
    <div>
        <!-- 功能栏 -->
        <el-row :gutter="10" class="operation">
        <el-col :span="15">
            <el-button class="iconfont icon-add" type="primary" plain @click="btnEditInfo('0')">新增</el-button>
            <el-popconfirm 
                title="确认要删除吗?"
                @confirm="btnBatchDeleteInfo">
                <template #reference>
                    <el-button class="iconfont icon-ashbin" type="danger" plain>删除</el-button>
                </template>
            </el-popconfirm>
        </el-col>

        <el-col :span="9" class="operation_hidden">
            <el-button-group>
                                            
                <el-tooltip
                class="box-item"
                effect="dark"
                content="流程图"
                placement="top">
                <el-button class="iconfont icon-icon__liuchengtu" @click="btnNoFlow"></el-button>
                </el-tooltip>

                <el-tooltip
                class="box-item"
                effect="dark"
                content="打印"
                placement="top">
                <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
                </el-tooltip>

                <el-tooltip
                class="box-item"
                effect="dark"
                content="导出数据"
                placement="top">
                <el-button class="iconfont icon-download" @click="btnNoExport"></el-button>
                </el-tooltip>
            </el-button-group>    
            
        </el-col>
        </el-row>

        <!-- 列表 -->
        <el-table :data="info.dataList" border stripe :height="GetDataHeight" style="width: 100%" @select="btnCheckbox" @select-all="btnCheckbox">
        <el-table-column type="selection" width="50" />  
        <el-table-column prop="transNumber" label="物流单号" width="170" />
        <el-table-column prop="departmentName" label="部门" min-width="100"/>
        <el-table-column prop="state" label="物流状态" min-width="100">
            <template #default="scope">
                <el-tag v-if="scope.row.state ==1">新建单</el-tag>
                <el-tag v-if="scope.row.state ==2" type="danger">已锁单</el-tag>
                <el-tag v-if="scope.row.state ==3" type="success">已接单</el-tag>
                <el-tag v-if="scope.row.state ==4" type="info">已完成</el-tag>
            </template>
        </el-table-column>
        <el-table-column prop="title" label="标题" min-width="150">
            <template v-slot="scope">
                <el-tooltip
                class="box-item"
                effect="dark"
                :content="scope.row.title"
                placement="top"
                >
                <div class="cellOverFlow">{{scope.row.title}}</div>
                </el-tooltip>
            </template>
        </el-table-column>
        <el-table-column prop="receiverName" label="接单人" min-width="100"/>

        <el-table-column label="指定接货时间" min-width="110">
            <template #default="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.receiveTime"
                    placement="top"
                  >
                  <span>{{DateFormat(scope.row.receiveTime)}}</span>
                </el-tooltip>
            </template>
        </el-table-column>

        <el-table-column label="创建日期" width="110">
            <template #default="scope">
                <span>{{DateFormat(scope.row.createTime)}}</span>
            </template>
        </el-table-column>
        <el-table-column label="操作" width="140" fixed="right">
            <template v-slot="scope">              
            <el-button size="small" type="primary" @click="btnEditInfo(scope.row.transNumber)">详情</el-button>
            <el-popconfirm 
                title="确认要删除吗?"
                @confirm="btnDeleteInfo(scope.row.transNumber)">
                <template #reference>
                    <el-button size="small" type="danger">删除</el-button>
                </template>
                </el-popconfirm>
            </template>
        </el-table-column>
        </el-table>

    </div>

    <el-affix position="bottom">
        <el-row class="page_two">
            <el-col :span="24">
                <!-- 分页 -->
                <el-container class="page_right">
                    <el-pagination
                    v-model:currentPage="info.page"
                    v-model:page-size="info.size"
                    :page-sizes="[50, 200, 500, 1000]"
                    :background="true"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="info.total"
                    @current-change="btnPagechange"
                    @size-change="btnSizechange"
                    >
                    </el-pagination>
                </el-container>
            </el-col>
        </el-row>        
    </el-affix>


    <!-- 新增、编辑 弹出框 -->
    <el-dialog :title="BoxEditInfo.title" 
    v-model="BoxEditInfo.isVisible"
    width="80%"
    :draggable="true"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    @closed="GetDataList">
        <EditTransport :editId="info.id" :dataList="info.dataList" @closeBox="btnCloseBox"></EditTransport>
    </el-dialog>

  </div>
</template>

<script>
import UserDptList from '@/utils/mixins/UserDptList.js'
import AllUserList from '@/utils/mixins/AllUserList.js'
import EditTransport from '@/views/Tms/Transport/EditTransport.vue'
import { ElMessage } from 'element-plus'
export default {
    name:'ListTransport',
    mixins:[UserDptList,AllUserList],
    components:{
        EditTransport
    },
    data(){
        return{
            info:{
                dataList:[],  //Table数据
                page:1,
                size:50,
                total:0,  //总记录数
                id:0,  //新增、编辑 的参数
                ids:[],   //删除 的参数
                search:{
                    selectDepartmentList:[],
                    receiver:'',
                    state:0,
                },
            },
            //编辑-弹出框
            BoxEditInfo:{
              isVisible:false,
              title:'新增物流单'
            },
            
        }
    },
    computed:{
        //自适应窗体高度
        GetDataHeight(){
            return window.innerHeight-240
        }
    },
    methods:{
        //加载数据列表
        GetDataList(){
            //获取、转换部门
            let newDepartmentList = []
            if(this.info.search.selectDepartmentList!=null){
                newDepartmentList = this.info.search.selectDepartmentList.map(item=>{
                    return item
                })
            }

            let param = {
                departmentList:newDepartmentList,
                receiver:this.info.search.receiver,
                state:this.info.search.state,
                page:this.info.page,
                size:this.info.size
            }
            this.$api.erp.ListTransport(param).then(res=>{
                if(res.data.status === 200){
                    this.info.dataList = res.data.data;
                    //分页
                    this.info.total = res.data.total
                    this.info.page = res.data.pageNumber
                }else{
                    ElMessage.error(res.data.msg);
                }
            }).catch(e=>{
                console.log(e.message)
            })
        },
        //选中多选框
        btnCheckbox(selection){
            this.info.ids = selection.map(item=>item.transNumber)
        },
        //分页-页数变化时
        btnPagechange(page){
            this.info.page = page
            this.GetDataList() //加载Table数据
        },
        //分页-单页记录数变化时
        btnSizechange(size){
            this.info.size = size
            this.info.page = 1
            this.GetDataList() //加载Table数据
        },

        //编辑物流单
        btnEditInfo(transNumber){
            this.info.id = transNumber
            if(transNumber==0){
                this.BoxEditInfo.title = "新增物流单";
            }else{
                this.BoxEditInfo.title = "编辑物流单";
            }
            this.BoxEditInfo.isVisible = true;
        },
        //关闭弹出框
        btnCloseBox(){
            this.BoxEditInfo.isVisible = false;
        },

        //删除按钮
        btnDeleteInfo(transNumber){
            let param = [transNumber]
            this.$api.erp.DeleteTransportAllInfo(param).then(res=>{
            if(res.data.status === 200){
                ElMessage.success(res.data.msg)
                this.GetDataList()
            }
            else{
                ElMessage.error(res.data.msg)
            }
            })
        },
        //批量删除
        btnBatchDeleteInfo(){
            if(this.info.ids.length<1){
                ElMessage.error('请选择要删除的选项')
                return false
            }

            this.$api.erp.DeleteTransportAllInfo(this.info.ids).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg)
                    this.GetDataList()
                }
                else{
                    ElMessage.error(res.data.msg)
                }
            })
        },


        //重置按钮
        btnResetSearch(){
            this.info.search.selectDepartmentList = [];
            this.info.search.receiver='';
            this.info.search.state=0;
            this.info.page = 1,
            this.GetDataList()
        },    

    },
    mounted(){
        this.GetDataList();   //加载数据
    }
}
</script>

<style>

</style>