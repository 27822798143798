<template>
  <div>
    <!-- 报价单明细列表 -->

    <!-- 选择产品 -->
    <div class="skuBtnList">
        <el-button class="iconfont icon-add" type="primary" @click="BtnOpenQuoteSku">选择价格表产品</el-button>
        <el-button class="iconfont icon-add" type="success" @click="BtnOpenShareSku">选择报价单产品</el-button>
    </div>

    <!-- 报价明细列表 -->
    <el-table :data="priceShareInfo.detailList" border stripe >
        <el-table-column prop="cidName" label="产品类目" min-width="90"></el-table-column>
        <el-table-column prop="brandName" label="品牌" min-width="80"></el-table-column>
        <el-table-column prop="spuName" label="产品名称" min-width="90">
            <template v-slot="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.spuName"
                    placement="top">
                    <div class="cellOverFlow">{{scope.row.spuName}}</div>
                </el-tooltip>
            </template>
        </el-table-column>
        <el-table-column prop="skuName" label="规格名称" min-width="90"/>

        <el-table-column label="单位" width="85">
            <template v-slot="scope">
                <span>{{scope.row.unitName}}</span>
            </template>
        </el-table-column>

        <el-table-column label="成本价" width="115">
            <template v-slot="scope">
                <span>{{this.myRound(scope.row.costPrice,2)}}</span>
            </template>
        </el-table-column>

        <el-table-column label="单价"  width="115">
            <template v-slot="scope">
                <el-input-number v-if="scope.row.isEdit==1" v-model="scope.row.unitPrice" :precision="2" :controls="false" style="width:90px"></el-input-number>
                <span v-else>{{scope.row.unitPrice}}</span>
            </template>
        </el-table-column>
        
        <el-table-column label="操作" width="130" fixed="right">
            <template v-slot="scope">
                <el-button v-if="scope.row.isEdit == 0" size="small" type="primary" @click="EditRow(scope.row.id)">编辑</el-button>
                <el-popconfirm
                title="确认要删除吗?"
                @confirm="DeleteRow(scope.row.shareNumber,scope.row.id,scope.$index)">
                    <template #reference>
                    <el-button size="small" type="danger">删除</el-button>
                    </template>
                </el-popconfirm>
            </template>
        </el-table-column>
    </el-table>

    <!-- 选择价格表产品-弹出框 -->
    <el-dialog :title="BoxQuoteSku.title"
    v-model="BoxQuoteSku.isVisible"
    width="70%"
    :destroy-on-close="true">
        <BoxPriceQuote ref="SelectPriceQuote"></BoxPriceQuote>
        <template #footer>
            <div style="padding-top:35px">
                <el-button type="primary" @click="BtnCloseQuoteSku">选择</el-button>
                <el-button @click="BoxQuoteSku.isVisible = false">取消</el-button>
            </div>            
        </template>
    </el-dialog>

    <!-- 选择报价单产品-弹出框 -->
    <el-dialog :title="BoxShareSku.title"
    v-model="BoxShareSku.isVisible"
    width="70%"
    :destroy-on-close="true">
        <BoxPriceShare ref="SelectPriceShare"></BoxPriceShare>
        <template #footer>
            <div style="padding-top:35px">
                <el-button type="primary" @click="BtnCloseShareSku">选择</el-button>
                <el-button @click="BoxShareSku.isVisible = false">取消</el-button>
            </div>            
        </template>
    </el-dialog>

  </div>
</template>

<script>
import BoxPriceQuote from '@/views/Fms/PriceShare/BoxPriceQuote.vue'  //价格表产品
import BoxPriceShare from '@/views/Fms/PriceShare/BoxPriceShare.vue'  //报价单产品
import { ElMessage } from 'element-plus'
import { mapState } from 'vuex'
export default {
    components:{
        BoxPriceQuote,
        BoxPriceShare,
    },
    data(){
        return{
            //选择价格表产品-弹出框
            BoxQuoteSku:{
                title:'选择价格表产品',
                isVisible:false,
            },
            //选择报价单产品-弹出框
            BoxShareSku:{
                title:'选择报价单产品',
                isVisible:false,
            },
        }
    },
    computed:{
        ...mapState('Finance',['priceShareInfo'])
    },
    methods:{
        //打开-选择价格表产品-弹出框
        BtnOpenQuoteSku(){
            this.BoxQuoteSku.isVisible=true;
        },
        //关闭-选择价格表产品-弹出框
        BtnCloseQuoteSku(){
            let itemInfo = this.$refs.SelectPriceQuote.selectedInfo;  //选择条件
            let itemList = this.$refs.SelectPriceQuote.selectedItem;  //选中列表
            if(itemList!=null && itemList.length>0){
                let dupNum =0;  //重复数
                itemList.forEach(item=>{
                    //判断是否有重复
                    let isExist = false;
                    this.priceShareInfo.detailList.forEach(element =>{
                        if(element.skuNumber === item.skuNumber){
                            isExist=true;
                            dupNum +=1;
                        }
                    })
                    //新增
                    if(!isExist){
                        let newRow = {
                            id:0,
                            systemNumber:item.systemNumber,
                            cid:item.cid,         //分类编号
                            cidName:item.cidName,    //分类名称
                            brandId:item.brandId,    //品牌编号
                            brandName:item.brandName,   //品牌名称
                            spuNumber:item.spuNumber,
                            spuName:item.spuName,
                            skuNumber:item.skuNumber,
                            skuName:item.skuName,
                            isDel:0,
                            isEdit:1,
                        }
                        //单位
                        if(itemInfo.unitType==1){
                            newRow.unitId=item.defUnitId;  //默认单位
                            newRow.unitName=item.defUnitName;
                            newRow.costPrice=item.defCostPrice; 
                            //价格
                            if(itemInfo.priceType==1){
                                newRow.unitPrice = item.defRetailPrice;  //默认零售价
                            }
                            if(itemInfo.priceType==2){
                                newRow.unitPrice = item.defWholePrice;  //默认批发价
                            }
                            if(itemInfo.priceType==3){
                                newRow.unitPrice = item.defVipPrice;   //默认VIP价
                            }
                        }else{
                            newRow.unitId=item.minUnitId;  //最小单位
                            newRow.unitName=item.minUnitName;
                            newRow.costPrice = item.minCostPrice;
                            //价格
                            if(itemInfo.priceType==1){
                                newRow.unitPrice = item.minRetailPrice;  //最小单位零售价
                            }
                            if(itemInfo.priceType==2){
                                newRow.unitPrice = item.minWholePrice;  //最小单位批发价
                            }
                            if(itemInfo.priceType==3){
                                newRow.unitPrice = item.defVipPrice;   //最小单位VIP价
                            }
                        }
                        
                        this.priceShareInfo.detailList.push(newRow);
                    }
                })
                //提示
                if(dupNum>0){
                    ElMessage.warning('已过滤掉'+dupNum+'个重复产品');
                }
            }
            this.BoxQuoteSku.isVisible=false;
        },


        //打开-选择报价单产品-弹出框
        BtnOpenShareSku(){
            this.BoxShareSku.isVisible=true;
        },
        //关闭-选择报价单产品-弹出框
        BtnCloseShareSku(){
            let itemList = this.$refs.SelectPriceShare.selectedDetail;   //选中产品列表
            if(itemList!=null && itemList.length>0){
                let dupNum =0;  //重复数
                itemList.forEach(item=>{
                    //判断是否有重复
                    let isExist = false;
                    this.priceShareInfo.detailList.forEach(element=>{
                        if(element.skuNumber === item.skuNumber){
                            isExist =true;
                            dupNum +=1;
                        }
                    })
                    //新增
                    if(!isExist){
                        let newRow = {
                            id:0,
                            systemNumber:item.systemNumber,
                            cid:item.cid,         //分类编号
                            cidName:item.cidName,    //分类名称
                            brandId:item.brandId,    //品牌编号
                            brandName:item.brandName,   //品牌名称
                            spuNumber:item.spuNumber,
                            spuName:item.spuName,
                            skuNumber:item.skuNumber,
                            skuName:item.skuName,
                            unitId:item.unitId,
                            unitName:item.unitName,
                            costPrice:item.costPrice,
                            unitPrice:item.unitPrice,
                            isDel:0,
                            isEdit:1,
                        }
                        this.priceShareInfo.detailList.push(newRow);
                    }
                })
                //提示
                if(dupNum>0){
                    ElMessage.warning('已过滤掉'+dupNum+'个重复产品');
                }
            }
            this.BoxShareSku.isVisible=false;
        },


        //编辑行
        EditRow(id){
            this.priceShareInfo.detailList = this.priceShareInfo.detailList.map(item=>{
                if(item.id === id){
                    item.isEdit = 1;
                }
                return item;
            })
        },
        //删除行
        DeleteRow(shareNumber, id, index){
            this.priceShareInfo.detailList.splice(index,1);
            if(id!=0){
                let param = {
                    shareNumber:shareNumber,
                    id:id
                }
                this.$api.finance.DeletePriceShareDetail(param).then(res=>{
                    if(res.data.status===200){
                        ElMessage.success(res.data.msg)
                    }else{
                        ElMessage.error(res.data.msg);
                    }
                })
            }
        },

    },
}
</script>

<style>

</style>