<template>
  <div>
    <!-- 添加到分享单 -->
    <el-form :inline="true"
    v-model="priceShareInfo.info"
    label-width="100px"
    label-position="right">
        <el-row v-if="priceShareInfo.info">

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="报价号">
                    <el-input v-model="priceShareInfo.info.shareNumber" disabled class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="标题">
                    <template #label>
                        <span style="color:red;">*</span>
                        <span>标题</span>
                    </template>
                    <el-input v-model="priceShareInfo.info.title" class="search-200"></el-input>
                </el-form-item>
            </el-col>

            <el-col :md="8" :sm="12" :xs="24">
                <el-form-item label="创建人">
                    <el-input v-model="priceShareInfo.info.createName" disabled class="search-200"></el-input>
                </el-form-item>
            </el-col>

        </el-row>
    </el-form>


  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    computed:{
        ...mapState('Finance',['priceShareInfo'])
    },
}
</script>

<style>

</style>