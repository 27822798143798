<template>
  <div>

    <!-- 搜索功能 -->
    <el-row>
        <el-col :span="24">
            <el-form :inline="true">

                <el-form-item label="类型名称">
                  <el-input v-model="info.search.typeName" class="search-200"></el-input>
                </el-form-item>

                <el-form-item label="显示标识">
                  <el-select v-model="info.search.isShow" class="search-200">
                    <el-option :value="-1" label="全部"></el-option>
                    <el-option :value="0" label="隐藏"></el-option>
                    <el-option :value="1" label="显示"></el-option>
                  </el-select>
                </el-form-item>

                <el-form-item>
                <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
                <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
                </el-form-item>

            </el-form>
          </el-col>
    </el-row>

    <!-- 主内容区 -->
    <div>
      <!-- 功能栏 -->
      <el-row :gutter="10" class="operation">
        <el-col :span="15">
          <el-button v-if="CheckUserButtonAuth('BtnFinanceTypeRefresh')" class="iconfont icon-exchangerate" plain @click="btnRefresh">刷新</el-button>
          <el-button v-if="CheckUserButtonAuth('BtnFinanceTypeAdd')" class="iconfont icon-add" type="primary" plain @click="btnEditInfo(0)">新增</el-button>
          <el-button v-if="CheckUserButtonAuth('BtnFinanceTypeDelete')" class="iconfont icon-ashbin" type="danger" plain @click="btnBatchDeleteInfo">删除</el-button>
        </el-col>

        <el-col :span="9" class="operation_hidden">
          <el-button-group>
                                            
              <el-tooltip
              class="box-item"
              effect="dark"
              content="流程图"
              placement="top">
              <el-button class="iconfont icon-icon__liuchengtu" @click="btnNoFlow"></el-button>
              </el-tooltip>

              <el-tooltip
              class="box-item"
              effect="dark"
              content="打印"
              placement="top">
              <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
              </el-tooltip>

              <el-tooltip
              class="box-item"
              effect="dark"
              content="导出数据"
              placement="top">
              <el-button class="iconfont icon-download" @click="btnExportInfo"></el-button>
              </el-tooltip>
          </el-button-group>        
          
        </el-col>
      </el-row>

      <!-- 列表 -->
      <el-table :data="info.dataList" border stripe :height="GetDataHeight" style="width: 100%" @select="btnCheckbox" @select-all="btnCheckbox">
        <el-table-column prop="id" type="selection" width="50" />  
        <el-table-column prop="id" label="编号" />
        <el-table-column prop="typeName" label="类型名称" />
        <el-table-column prop="typeValue" label="类型值" />

        <el-table-column label="计算方式" min-width="85">
            <template #default="scope">
                <el-tag v-if="scope.row.way ==0" type="danger">减</el-tag>
                <el-tag v-if="scope.row.way ==1">加</el-tag>
            </template>
        </el-table-column> 

        <el-table-column prop="sort" label="排序" />

        <el-table-column label="显示标识" min-width="85">
            <template #default="scope">
                <el-tag v-if="scope.row.isShow == 1">显示</el-tag>
                <el-tag v-if="scope.row.isShow == 0" type="info">隐藏</el-tag>
            </template>
        </el-table-column>  

        <el-table-column label="操作" width="130" fixed="right">
          <template v-slot="scope">              
            <el-button v-if="CheckUserButtonAuth('BtnFinanceTypeDetail')" size="small" type="primary" @click="btnEditInfo(scope.row.id)">详情</el-button>
            <el-button v-if="CheckUserButtonAuth('BtnFinanceTypeDelete')" size="small" type="danger" @click="btnDeleteInfo(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <el-container class="page_one">
          <el-pagination
            v-model:currentPage="info.page"
            v-model:page-size="info.size"
            :page-sizes="[50, 200, 500]"
            :background="true"
            layout="total, sizes, prev, pager, next, jumper"
            :total="info.total"
            @current-change="btnPagechange"
            @size-change="btnSizechange"
          >
          </el-pagination>
      </el-container>

    </div>

    <!-- 新增、编辑 弹出框 -->
    <el-dialog :title="BoxEditInfo.title" 
      v-model="BoxEditInfo.isVisible"
      width="450"
      :draggable="true"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      @closed="GetDataList">
        <EditType :editId="info.id" @closeBox="btnCloseBox"></EditType>
    </el-dialog>

  </div>
</template>

<script>
import { ElMessage, ElMessageBox } from 'element-plus'
import EditType from '@/views/Common/FinanceType/EditType.vue'
export default {
    name:'ListType',
    components:{
        EditType
    },
    data(){
      return{
        info:{
          dataList:[],  //Table数据
          page:1,   //页数
          size:50,  //单页记录数
          total:0,  //总记录数
          id:0,  //新增、编辑 的参数
          ids:[],   //删除 的参数
          search:{  //搜索条件
            typeName:'',
            isShow:-1,   //显示标识
          }
        },
        //编辑-弹出框
        BoxEditInfo:{
          isVisible:false,
          title:'新增类型'
        }

      }
    },
    computed:{
        //自适应窗体高度
        GetDataHeight(){
            return window.innerHeight-180
        }
    },
    methods:{
      //加载数据列表
      GetDataList(){
        let param = {
          typeName:this.info.search.typeName,
          isShow:this.info.search.isShow,
          page:this.info.page,
          size:this.info.size
        }
        this.$api.common.PageFinanceTypeList(param).then(res=>{
          if(res.data.status === 200){
            this.info.dataList = res.data.data;  //获取数据
            //分页
            this.info.total = res.data.total
            this.info.page = res.data.pageNumber
          }
        }).catch(e=>{
          console.log(e)
        })
      },
      //选中多选框
      btnCheckbox(selection){
        this.info.ids = selection.map(item=>item.id)
      },
      //分页-页数变化时
      btnPagechange(page){
          this.info.page = page
          this.GetDataList() //加载Table数据
      },
      //分页-单页记录数变化时
      btnSizechange(size){
          this.info.size = size
          this.info.page = 1
          this.GetDataList() //加载Table数据
      },
      
      //新增编辑部门信息
      btnEditInfo(id){
        this.info.id = id
        if(id==0){
            this.BoxEditInfo.title = "新增类型"
        }else{
            this.BoxEditInfo.title = "编辑类型"
        }  
        this.BoxEditInfo.isVisible = true;
      },
      //删除按钮
      btnDeleteInfo(id){
        ElMessageBox.confirm('确定要删除该选中项吗?','删除提示',{
          confirmButtonText: '确认',
          cancelButtonText: '取消'
        }).then(()=>{
          let param = [id]
          this.$api.common.DeleteFinanceType(param).then(res=>{
            if(res.data.status === 200){
              ElMessage.success(res.data.msg)
              this.GetDataList()
            }
            else{
              ElMessage.error(res.data.msg)
            }
          })
        }).catch(()=>{
          //取消
        })
        
      },
      //批量删除
      btnBatchDeleteInfo(){
        if(this.info.ids.length<1){
          ElMessage.error('请选择要删除的选项')
          return false
        }

        ElMessageBox.confirm('确定要删除该选中项吗?','删除提示',{
          confirmButtonText: '确认',
          cancelButtonText: '取消'
        }).then(()=>{
          this.$api.common.DeleteFinanceType(this.info.ids).then(res=>{
            if(res.data.status === 200){
              ElMessage.success(res.data.msg)
              this.GetDataList()
            }
            else{
              ElMessage.error(res.data.msg)
            }
          })
        }).catch(()=>{
          //取消
        })
        
      },
      
      //重置按钮
      btnResetSearch(){
        this.info.search.typeName='';
        this.info.search.isShow=-1;
        this.info.ids = [];  //编号数组
        this.GetDataList()
      },
      //导出系统列表
      btnExportInfo(){
        let param = {
          typeName:this.info.search.typeName,
          isShow:this.info.search.isShow,
          page:this.info.page,
          size:this.info.size
        }
        this.$api.goods.ExportBrand(param).then(res=>{
          if(res.data.status === 200){
            const alink = document.createElement('a');
            alink.href = res.data.data;
            document.body.appendChild(alink);
            alink.click();
            document.body.removeChild(alink)
          }
          else
          {
            ElMessage.error(res.data.msg)
          }
        }).catch(e=>{
          console.log(e)
        })
      },
      //关闭弹出框
      btnCloseBox(){
          this.BoxEditInfo.isVisible = false;
      },

    },
    mounted(){
      this.GetDataList()  //加载数据Table数据
    }

}
</script>

<style>

</style>