<template>
  <div>
    <!-- 从报价单选择产品 -->
    <el-divider></el-divider>

    <!-- 搜索功能 -->
    <el-row>
        <el-col :span="24">
            <el-form :inline="true">

                <el-form-item label="报价单号">
                    <el-input v-model="info.search.shareNumber" maxlength="20" class="search-150"></el-input>
                </el-form-item>

                <el-form-item label="标题">
                    <el-input v-model="info.search.title" maxlength="20" class="search-150"></el-input>
                </el-form-item>


                <el-form-item>
                    <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
                    <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
                </el-form-item>

            </el-form>
        </el-col>
    </el-row>


    <!-- 报价单列表 -->
    <el-table :data="dataList" border stripe height="250px" @current-change="ClickRowData" highlight-current-row>
        <el-table-column prop="shareNumber" label="报价单号"></el-table-column>
        <el-table-column prop="title" label="标题"></el-table-column>
        <el-table-column prop="createName" label="创建人"></el-table-column>
        <el-table-column prop="createTime" label="创建时间"></el-table-column>
    </el-table>

    <el-affix position="bottom">
        <el-row class="page_two">
            <el-col :span="24">
                <!-- 分页 -->
                <el-container class="page_right">
                    <el-pagination
                    v-model:currentPage="info.page"
                    v-model:page-size="info.size"
                    :page-sizes="[20, 50, 200, 500]"
                    :background="true"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="info.total"
                    @current-change="btnPagechange"
                    @size-change="btnSizechange"
                    >
                    </el-pagination>
                </el-container>
            </el-col>
        </el-row>        
    </el-affix>

    <!-- 报价单明细列表 -->
    <el-divider class="CenterLine">报价单明细列表</el-divider>
    <el-table :data="dataDetailList" border stripe height="250px" @select="btnCheckbox" @select-all="btnAllCheckbox" @row-click="TableRowClick" ref="shareDetailList">
        <el-table-column prop="id" type="selection"></el-table-column>
        <el-table-column prop="cidName" label="产品类目" min-width="90"></el-table-column>
        <el-table-column prop="brandName" label="品牌" min-width="80"></el-table-column>
        <el-table-column prop="spuName" label="产品名称" min-width="90">
            <template v-slot="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.spuName"
                    placement="top">
                    <div class="cellOverFlow">{{scope.row.spuName}}</div>
                </el-tooltip>
            </template>
        </el-table-column>
        <el-table-column prop="skuName" label="规格名称" min-width="90"/>

        <el-table-column label="单位" width="85">
            <template v-slot="scope">
                <span>{{scope.row.unitName}}</span>
            </template>
        </el-table-column>

        <el-table-column label="成本价" width="115">
            <template v-slot="scope">
                <span>{{this.myRound(scope.row.costPrice,2)}}</span>
            </template>
        </el-table-column>

        <el-table-column label="单价"  width="115">
            <template v-slot="scope">
                <span>{{scope.row.unitPrice}}</span>
            </template>
        </el-table-column>
        
    </el-table>


  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
export default {
    data(){
        return{
            dataList:[],  //table数据
            info:{
                page:1,
                size:50,
                total:0,  //总记录数
                id:0,  //新增、编辑 的参数
                ids:[],   //删除 的参数
                search:{
                    shareNumber:'',
                    title:'',
                }
            },

            dataDetailList:[],  //调价明细列表

            selectedInfo:{},  //选中的主信息
            selectedDetail:[],  //选中的明细列表
        }
    },
    methods:{
        //加载数据列表
        GetDataList(){
            let param = {
                shareNumber:this.info.search.shareNumber,
                title:this.info.search.title,
                page:this.info.page,
                size:this.info.size
            }
            this.$api.finance.PagePriceShareList(param).then(res=>{
                if(res.data.status===200){
                    this.dataList = res.data.data;
                    //分页
                    this.info.total = res.data.total;
                    this.info.page = res.data.pageNumber;
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

        //分页-页数变化时
        btnPagechange(page){
            this.info.page = page
            this.GetDataList() //加载Table数据
        },
        //分页-单页记录数变化时
        btnSizechange(size){
            this.info.size = size
            this.info.page = 1
            this.GetDataList() //加载Table数据
        },

        //重置按钮
        btnResetSearch(){
            this.info.search.shareNumber="";
            this.info.search.title="";
            this.info.ids = [];  //编号数组
            this.info.page = 1;
            this.GetDataList()
        },

        //加载明细数据列表
        GetShareDetailList(){
            let param = {
                shareNumber:this.selectedInfo.shareNumber
            }
            this.$api.finance.GetPriceShareDetailList(param).then(res=>{
                if(res.data.status === 200){
                    this.dataDetailList = res.data.data;
                    if(this.dataDetailList !=null){
                        //设置全选中
                        this.$refs.shareDetailList.toggleAllSelection();  //全选
                    }
                }else{
                    this.dataDetailList = [];  //清空明细
                }
            })
        },

        //单击-选择主单据
        ClickRowData(row){
            if(row !=null){
                this.selectedInfo = row;  //选中的主表
                this.GetShareDetailList();  //加载明细列表
            }else{
                this.dataDetailList = [];  //清空明细
            }
        },

        //明细表-选中多选框
        btnCheckbox(row){
            this.selectedDetail = row;
        },
        //明细表-全选
        btnAllCheckbox(){
            this.selectedDetail = this.dataDetailList;
        },
        //明细表-选中行时(标准模板)
        TableRowClick(row){
            let isExist = false; //判断是否存在
            this.selectedDetail = this.selectedDetail.filter(item=>{                
                if(row.id != item.id){
                    return item;
                }else{                    
                    //如果存在
                    isExist = true;
                }                
            })
            
            if(isExist){
                this.$refs.shareDetailList.toggleRowSelection(row,false);
            }else{
                this.selectedDetail.push(row)
                this.$refs.shareDetailList.toggleRowSelection(row,true);
            }
        },


    },
    mounted(){
        this.GetDataList();
    },
}
</script>

<style>

</style>