<template>
  <div>
    <!-- 业务对账单 -->
    <el-row>
      <el-col :span="24">
          <el-form :inline="true">
              <el-form-item label="对账单号">
                <el-input v-model="info.search.cycleNumber"></el-input>
              </el-form-item>

              <el-form-item label="部门">
                <el-tree-select v-model="info.search.selectDepartmentList" :data="departmentList" multiple clearable :default-expand-all="true" collapse-tags collapse-tags-tooltip class="search-200">
                </el-tree-select>
              </el-form-item>

              <el-form-item label="交易者">
                <el-select v-model="info.search.tradeNumber" filterable clearable class="search-200">
                    <el-option v-for="trade in allTradeList" :key="trade.tradeNumber" :label="trade.tradeName+ '(' + trade.tradeTypeName+')'" :value="trade.tradeNumber" />
                </el-select>
            </el-form-item>

            <el-form-item>
                <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
                <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
            </el-form-item>

          </el-form>
      </el-col>
    </el-row>

    <!-- 数据列表 -->
    <div>
        <!-- 功能栏 -->
        <el-row :gutter="10" class="operation">
            <el-col :span="15">
                <!-- <el-button class="iconfont icon-add" type="primary" plain @click="btnNewCycleInfo">新增</el-button> -->
                <el-button class="iconfont icon-ashbin" type="danger" plain @click="btnBatchDeleteInfo">删除</el-button>
                
            </el-col>

            <el-col :span="9" class="operation_hidden">
                <el-button-group>
                                                
                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="流程图"
                    placement="top">
                    <el-button class="iconfont icon-icon__liuchengtu" @click="btnNoFlow"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="打印"
                    placement="top">
                    <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="导出数据"
                    placement="top">
                    <el-button class="iconfont icon-download" @click="btnNoExport"></el-button>
                    </el-tooltip>
                </el-button-group>    
                
            </el-col>
        </el-row>

        <!-- 列表 -->
        <el-table :data="dataList" show-summary border stripe :height="GetDataHeight" style="width: 100%" @select="btnCheckbox" @select-all="btnCheckbox">
          <el-table-column prop="cycleNumber" type="selection" width="55" />  
          <el-table-column label="编号" width="170">
              <template #default="scope">
                  <span>{{scope.row.cycleNumber}}</span>
              </template>
          </el-table-column>
          <el-table-column label="部门" min-width="110">
              <template v-slot="scope">
                  <el-tooltip
                      class="box-item"
                      effect="dark"
                      :content="scope.row.departmentName"
                      placement="top">
                      <div class="cellOverFlow">{{scope.row.departmentName}}</div>
                  </el-tooltip>
              </template>
          </el-table-column>
 
          <el-table-column label="交易者类型" min-width="90">
              <template v-slot="scope">
                  <el-tag v-if="scope.row.tradeType ==1" type="danger">供应商</el-tag>
                  <el-tag v-if="scope.row.tradeType ==2" type="success">客户</el-tag>
                  <el-tag v-if="scope.row.tradeType ==3" type="info">员工</el-tag>
                  <el-tag v-if="scope.row.tradeType ==4" type="info">散客</el-tag>
              </template>
          </el-table-column>

          <el-table-column label="交易者" min-width="100">
              <template v-slot="scope">
                  <el-tooltip
                      class="box-item"
                      effect="dark"
                      :content="scope.row.tradeName"
                      placement="top">
                      <div class="cellOverFlow">{{scope.row.tradeName}}</div>
                  </el-tooltip>
              </template>
          </el-table-column>
          
          <el-table-column prop="totalPrice" label="总金额(元)" min-width="120"/>
             
          <el-table-column label="创建时间" min-width="110">
              <template #default="scope">
                  <el-tooltip
                      class="box-item"
                      effect="dark"
                      :content="scope.row.createTime"
                      placement="top"
                  >
                  <span>{{DateFormat(scope.row.createTime)}}</span>
                  </el-tooltip>
              </template>
          </el-table-column>

          <el-table-column label="创建人" min-width="100">
              <template v-slot="scope">
                  <el-tooltip
                      class="box-item"
                      effect="dark"
                      :content="scope.row.createName"
                      placement="top">
                      <div class="cellOverFlow">{{scope.row.createName}}</div>
                  </el-tooltip>
              </template>
          </el-table-column>

          <el-table-column label="备注" min-width="100">
              <template v-slot="scope">
                  <el-tooltip
                      class="box-item"
                      effect="dark"
                      :content="scope.row.remark"
                      placement="top">
                      <div class="cellOverFlow">{{scope.row.remark}}</div>
                  </el-tooltip>
              </template>
          </el-table-column>
          
          <el-table-column label="操作" width="130" fixed="right">
              <template v-slot="scope">        
                  <el-dropdown size="small">
                      <el-button type="primary" size="small">操作<el-icon class="el-icon--right"><arrow-down /></el-icon></el-button>
                      <template #dropdown>
                          <el-dropdown-menu>
                              <el-dropdown-item @click="btnEditInfo(scope.row.cycleNumber)">详情</el-dropdown-item>
                              <el-dropdown-item @click="btnDeleteInfo(scope.row.cycleNumber)">删除</el-dropdown-item>
                              <el-dropdown-item @click="btnShareCycle(scope.row.cycleNumber)">分享</el-dropdown-item>
                          </el-dropdown-menu>
                      </template>
                  </el-dropdown>      

              </template>
          </el-table-column>

        </el-table>
    </div>

    <!-- 分页 -->
    <el-affix position="bottom">
        <el-row class="page_two">
            <el-col :span="24">
                <!-- 分页 -->
                <el-container class="page_right">
                    <el-pagination
                    v-model:currentPage="info.page"
                    v-model:page-size="info.size"
                    :page-sizes="[50, 200, 500, 1000]"
                    :background="true"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="info.total"
                    @current-change="btnPagechange"
                    @size-change="btnSizechange"
                    >
                    </el-pagination>
                </el-container>
            </el-col>
        </el-row>        
    </el-affix>

    <!-- 新增业务对账单 -->
    <el-dialog title="新增业务对账单"
    v-model="BoxNewInfo.isVisible"
    width="780px"
    :draggable="true"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    @closed="GetDataList">
        <NewCycleInfo @closeBox="btnCloseNewCycle"></NewCycleInfo>
    </el-dialog>

    
    <!-- 新增、编辑 弹出框 -->
    <el-drawer
      :title="BoxEditInfo.title" 
      v-model="BoxEditInfo.isVisible"
      direction="ltr"
      size="85%"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      @closed="GetDataList">
        <EditCycleBusiness :editId="info.id" :dataList="dataList" @closeBox="btnCloseBox"></EditCycleBusiness>
    </el-drawer>

  </div>
</template>

<script>
import NewCycleInfo from '@/views/Fms/CycleBusiness/NewCycleBusiness.vue'  //新增-对账单
import EditCycleBusiness from '@/views/Fms/CycleBusiness/EditCycleBusiness.vue'  //编辑-对账单 

import UserDptList from '@/utils/mixins/UserDptList.js'  //部门列表
import AllTradeList from '@/utils/mixins/AllTradeList.js'  //交易者列表
import { ArrowDown } from '@element-plus/icons-vue'
import { ElMessage, ElMessageBox } from 'element-plus'
export default {
  mixins:[UserDptList,AllTradeList],
  components:{
    NewCycleInfo,
    EditCycleBusiness,
    ArrowDown
  },
  data(){
    return{
      dataList:[],  //Table数据
      info:{
        page:1,
        size:50,
        total:0,  //总记录数
        id:0,  //新增、编辑 的参数
        ids:[],   //删除 的参数
        search:{
          cycleNumber:'',  //
          selectDepartmentList:[],
          tradeNumber:'',
        }
      },
      //新增-弹出框
      BoxNewInfo:{
        isVisible:false,
      },
      //编辑-弹出框
      BoxEditInfo:{
          isVisible:false,
          title:'业务对账单'
      },

    }
  },
  computed:{
      //自适应窗体高度
      GetDataHeight(){
          return window.innerHeight-235
      }
  },
  methods:{
    //加载数据列表
    GetDataList(){
      //获取、转换部门
      let newDepartmentList = []
      if(this.info.search.selectDepartmentList!=null){
          newDepartmentList = this.info.search.selectDepartmentList.map(item=>{
              return item
          })
      }

      let param = {
        cycleNumber:this.info.search.cycleNumber,
        departmentList:newDepartmentList,
        tradeNumber:this.info.search.tradeNumber,
        page:this.info.page,
        size:this.info.size
      }
      this.$api.erp.PageCycleBusinessList(param).then(res=>{
        if(res.data.status === 200){
          this.dataList = res.data.data;
          //分页
          this.info.total = res.data.total
          this.info.page = res.data.pageNumber
        }
      })
    },

    //选中多选框
    btnCheckbox(selection){
        this.info.ids = selection.map(item=>item.cycleNumber)
    },

    //分页-页数变化时
    btnPagechange(page){
        this.info.page = page
        this.GetDataList() //加载Table数据
    },
    //分页-单页记录数变化时
    btnSizechange(size){
        this.info.size = size;
        this.info.page = 1;
        this.GetDataList() //加载Table数据
    },

    //删除按钮
    btnDeleteInfo(cycleNumber){
        ElMessageBox.confirm('确定要删除该对账单吗?','删除提示',{
            confirmButtonText: '确认',
            cancelButtonText: '取消'
        }).then(()=>{
            let param = [cycleNumber]
            this.$api.erp.DeleteCycleBusiness(param).then(res=>{
            if(res.data.status === 200){
                ElMessage.success(res.data.msg)
                this.GetDataList()
            }
            else{
                ElMessage.error(res.data.msg)
            }
            })
        }).catch(()=>{

        })

        
    },
    //批量删除
    btnBatchDeleteInfo(){
        if(this.info.ids.length<1){
            ElMessage.error('请选择要删除的选项')
            return false
        }

        ElMessageBox.confirm('确定要删除选中的对账单吗？',"删除提示",{
            confirmButtonText:'确认',
            cancelButtonText:'取消'
        }).then(()=>{
            this.$api.erp.DeleteCycleBusiness(this.info.ids).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg)
                    this.GetDataList()
                }
                else{
                    ElMessage.error(res.data.msg)
                }
            })
        }).catch(()=>{
            
        })
        
    },

    //重置按钮
    btnResetSearch(){
        this.info.search.cycleNumber = '';
        this.info.search.selectDepartmentList=[],  //所属部门
        this.info.search.tradeNumber='';
        this.info.search.datePoint = '';
        this.info.ids = [];  //编号数组
        this.info.page = 1;
        this.GetDataList()
    },


    //打开新增业务对账单
    btnNewCycleInfo(){
        this.BoxNewInfo.isVisible = true;
    },
    //关闭新增业务对账单
    btnCloseNewCycle(){
        this.BoxNewInfo.isVisible = false;
    },


    //查看业务对账单
    btnEditInfo(id){
        this.info.id = id
        this.BoxEditInfo.isVisible = true;
    },
    //关闭弹出框
    btnCloseBox(){
        this.BoxEditInfo.isVisible = false;
    },

    //分享对账单
    btnShareCycle(cycleNumber){
      let url = `https://share.bjszhgx.cn/#/pages/cycleBusiness?cycleNumber=`+cycleNumber+'#/';
      ElMessageBox.alert(url,'分享地址',{
        confirmButtonText: '关闭',
      }).then(()=>{

      }).catch(()=>{
        
      })
    },

  },
  mounted(){
    this.GetDataList();
  },
}
</script>

<style>

</style>