<template>
  <div>
      
      <!-- 列表 -->
        <el-table :data="purchaseInfo.stateList" border style="width: 100%">
            <el-table-column prop="skuName" label="状态">
                <template v-slot="scope">
                    <span v-if="scope.row.state ==1">新建单</span>
                    <span v-if="scope.row.state ==2">待审核</span>
                    <span v-if="scope.row.state ==3">已驳回</span>
                    <span v-if="scope.row.state ==4">已审核</span>
                    <span v-if="scope.row.state ==5">已发货</span>
                    <span v-if="scope.row.state ==6">已收货</span>
                    <span v-if="scope.row.state ==7">已入库</span>
                    <span v-if="scope.row.state ==9">已取消</span>
                    <span v-if="scope.row.state ==10">已完成</span>
                </template>
            </el-table-column>

            <el-table-column label="备注" prop="remark" min-width="200" show-overflow-tooltip></el-table-column>

            <el-table-column label="创建时间">
                <template v-slot="scope">
                    <span>{{DateTimeFormat(scope.row.createTime)}}</span>
                </template>
            </el-table-column>

            <el-table-column label="创建者">
                <template v-slot="scope">
                    <span>{{scope.row.createName}}</span>
                </template>
            </el-table-column>

        </el-table>

  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    data(){
        return{
            dataList:[],  //数据列表
        }
    },
    computed:{
        ...mapState('Purchase',['purchaseInfo'])
    },

}
</script>

<style>

</style>