/**
 * Table高度设置通用
 */

const TableHeight = {
    data(){
        return{
            tableHeight:0,  //table高度
        }
    },
    methods:{
        //获取Table高度
        GetTableHeight(){
            this.tableHeight = window.innerHeight-180
        },
    },
    mounted(){
        this.GetTableHeight();
    },
}

export default TableHeight