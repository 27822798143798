<template>
  <div>
      
      <el-form :inline="true"
        v-model="goodsInfo.info"
        label-width="80px"
        class="tabSpu"
        label-position="right">
            <el-row v-if="goodsInfo.info">

                <el-col :span="12">
                    <el-form-item label="商品类目">
                        <template #label>
                            <span style="color:red;">*</span>
                            <span>商品类目</span>
                        </template>
                        <el-tree-select v-model="goodsInfo.info.cid" :data="selectCategoryList" :default-expand-all="true" @change="ChangeCategory" filterable class="search-200"/>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="品牌">
                        <template #label>
                            <span style="color:red;">*</span>
                            <span>品牌</span>
                        </template>
                        <el-select v-model="goodsInfo.info.brandId" placeholder="请选择" filterable class="search-200">
                            <el-option
                                v-for="item in allBrandList"
                                :key="item.id"
                                :label="item.label"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="商品名称">
                        <template #label>
                            <span style="color:red;">*</span>
                            <span>商品名称</span>
                        </template>
                        <!-- <el-input v-model="goodsInfo.info.spuName" class="search-200"></el-input> -->
                        <el-select v-model="goodsInfo.info.spuNumber" filterable style="width:165px;">
                            <el-option v-for="item in spuList" :key="item.id" :value="item.spuNumber" :label="item.spuName"></el-option>
                        </el-select>
                        <el-button class="iconfont icon-add" @click="BoxSpuInfo.isVisible=true" style="width:35px;" />
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="规格">
                        <template #label>
                            <span style="color:red;">*</span>
                            <span>规格名称</span>
                        </template>
                        <el-input v-model="goodsInfo.info.skuName" class="search-200"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="商品类型">
                        <el-select v-model="goodsInfo.info.spuType" placeholder="请选择" class="search-200">
                            <el-option label="商品" :value="1"></el-option>
                            <el-option label="耗材" :value="2"></el-option>
                            <el-option label="周转物料" :value="3"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="商品条码">
                        <el-input v-model="goodsInfo.info.skuCode" class="search-200"></el-input>
                    </el-form-item>
                </el-col>


                <el-col :span="12">
                    <el-form-item label="显示标识">
                        <el-select v-model="goodsInfo.info.isShow" class="search-200">
                            <el-option label="隐藏" :value="0"></el-option>
                            <el-option label="显示" :value="1"></el-option>
                        </el-select>
                        <!-- 提示 -->
                        <el-tooltip
                            class="box-item"
                            effect="dark"
                            content="用于控制在制单时是否显示该商品"
                            placement="top">
                            <i class="iconfont icon-wenhao"></i>
                        </el-tooltip>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="安全库存">
                        <el-input-number v-model="goodsInfo.info.warnStock" :controls="false" class="search-200"></el-input-number>
                        <!-- 提示 -->
                        <el-tooltip
                            class="box-item"
                            effect="dark"
                            content="默认单位"
                            placement="top">
                            <i class="iconfont icon-wenhao"></i>
                        </el-tooltip> 
                    </el-form-item>
                </el-col>
               

                <el-col :span="12">
                    <el-form-item label="最小单位">
                        <el-select v-model="goodsInfo.info.minUnitId" class="search-200" @change="ChangeMinUnit">
                            <el-option v-for="item in allUnitList" :key="item.unitId" :label="item.unitName" :value="item.unitId"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="默认单位">
                        <el-select v-model="goodsInfo.info.defUnitId" class="search-200">
                            <el-option v-for="item in allUnitList" :key="item.unitId" :label="item.unitName" :value="item.unitId"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>

            </el-row>


      </el-form>

    <!-- 新增产品 弹出框 -->
    <el-dialog title="新增商品"
        v-model="BoxSpuInfo.isVisible"
        width="330"
        :draggable="true"
        :close-on-click-modal="false"
        :destroy-on-close="true"
        @closed="ChangeCategory">
        <BoxSpu :editCid="cid" @closeBox="btnCloseBox"></BoxSpu>
    </el-dialog>
  </div>
</template>

<script>
import CategoryList from '@/utils/mixins/CategoryList.js'  //负责加载类目下拉框
import BoxSpu from '@/views/Goods/Spu/BoxSpu.vue' //新增产品信息
import { mapState } from 'vuex'
export default {
    name:'TabSpu',  //产品信息-标签页
    mixins:[CategoryList],
    components:{
        BoxSpu
    },
    data(){
        return{
            cid:0,  //选择后的-分类编号          
            spuList:[],  //产品列表
            allBrandList:[],  //全部品牌列表
            allUnitList:[],  //全部单位列表

            BoxSpuInfo:{
                isVisible:false
            },
        }
    },
    computed:{
        ...mapState('Erp',['goodsInfo'])
    },
    methods:{

        //分类改变时
        ChangeCategory(){
            this.cid = this.goodsInfo.info.cid;
            this.GetSpuList();  //加载产品列表
        },

        //获取产品列表
        GetSpuList(){
            let param = {
                cid:this.goodsInfo.info.cid
            }
            this.$api.goods.SelectSpuList(param).then(res=>{
                if(res.data.status === 200){
                    this.spuList = res.data.data;
                }
            })
        },

        //获取品牌下拉列表
        GetAllBrandList(){
            let param = {
                cid:0
            }
            this.$api.goods.SelectCategoryBrandList(param).then(res=>{
                if(res.data.status === 200){
                    this.allBrandList = res.data.data
                }
            })
        },

        //获取全部单位列表
        SelectAllUnitList(){
            this.$api.goods.GetUnitList().then(res=>{
                if(res.data.status === 200){
                    this.allUnitList = res.data.data;
                    if(this.allUnitList.length>0){
                        if(this.goodsInfo.info.minUnitId == 0){
                            this.goodsInfo.info.minUnitId = this.allUnitList[0].unitId;  //最小单位
                            this.goodsInfo.info.minUnitName= this.allUnitList[0].unitName;
                        }
                        if(this.goodsInfo.info.defUnitId == 0){
                            this.goodsInfo.info.defUnitId = this.allUnitList[0].unitId;  //默认单位
                            this.goodsInfo.info.defUnitName= this.allUnitList[0].unitName;
                        }
                        
                    }
                }
            })
        },

        //当最小单位发生改变时
        ChangeMinUnit(){
            if(this.goodsInfo.info.minUnitId !=0){
                //获取单位名称
                this.allUnitList.forEach(item=>{
                    if(this.goodsInfo.info.minUnitId == item.unitId){
                        this.goodsInfo.info.minUnitName = item.unitName;
                    }
                })
                //更新已有的单位列表
                this.goodsInfo.skuUnitList = this.goodsInfo.skuUnitList.map(item=>{
                    item.minUnitName = this.goodsInfo.info.minUnitName;  //重新更新最小单位
                    return item;
                })
            }
        },

        //关闭弹出框
        btnCloseBox(){
            this.BoxSpuInfo.isVisible = false;
        },

    },
    mounted(){
        this.GetAllBrandList();
        this.SelectAllUnitList();
    },
}
</script>

<style>
</style>