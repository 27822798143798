<template>
  <div>
    <!-- 搜索功能 -->
    <el-row :style="showSearch.search_hidden">
        <el-col :span="24">
            <el-form :inline="true">
            
                <el-form-item label="盘点单号">
                    <el-input v-model="info.search.checkNumber" maxlength="20" class="search-150"></el-input>
                </el-form-item>

                <el-form-item label="部门">
                    <el-tree-select v-model="info.search.selectDepartmentList" :data="departmentList" multiple clearable :default-expand-all="true" collapse-tags collapse-tags-tooltip class="search-200">
                    </el-tree-select>
                </el-form-item>

                <el-form-item label="大区">
                    <el-select v-model="info.search.areaNumberList" multiple clearable collapse-tags collapse-tags-tooltip class="search-200">
                        <el-option v-for="userArea in userAreaList" :key="userArea.areaNumber" :label="userArea.areaName" :value="userArea.areaNumber"></el-option>
                    </el-select>
                </el-form-item>
                
                <el-form-item label="盘点名称">
                    <el-input v-model="info.search.checkName" class="search-150"></el-input>
                </el-form-item>

                <el-form-item label="状态">
                    <el-select v-model="info.search.state" class="search-150">
                        <el-option :value="0" label="全部"></el-option>
                        <el-option :value="1" label="新建单"></el-option>
                        <el-option :value="2" label="盘点中"></el-option>
                        <el-option :value="3" label="完成盘点"></el-option>
                        <el-option :value="4" label="待复盘"></el-option>
                        <el-option :value="5" label="已完成"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="创建日期">
                    <el-date-picker
                        v-model="info.search.datePoint"
                        type="daterange"
                        range-separator="To"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期" 
                        style="width:250px"/>
                </el-form-item>

                <el-form-item>
                <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
                <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
                </el-form-item>

            </el-form>
        </el-col>
    </el-row>

    <!-- 主内容区 -->
    <div>
        <!-- 功能栏 -->
        <el-row :gutter="10" class="operation">
        <el-col :span="15">
            <el-button v-if="CheckUserButtonAuth('BtnCheckAdd')" class="iconfont icon-add" type="primary" plain @click="btnEditInfo('0')">新增</el-button>
            <el-popconfirm v-if="CheckUserButtonAuth('BtnCheckDelete')"
                title="确认要删除吗?" 
                @confirm="btnBatchDeleteInfo">
                <template #reference>
                    <el-button class="iconfont icon-ashbin" type="danger" plain>删除</el-button>
                </template>
            </el-popconfirm>
            
        </el-col>

        <el-col :span="9" class="operation_hidden">
            <el-button-group>
                                            
                <el-tooltip
                class="box-item"
                effect="dark"
                content="流程图"
                placement="top">
                <el-button class="iconfont icon-icon__liuchengtu" @click="btnNoFlow"></el-button>
                </el-tooltip>

                <el-tooltip
                class="box-item"
                effect="dark"
                content="打印"
                placement="top">
                <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
                </el-tooltip>

                <el-tooltip v-if="CheckUserButtonAuth('BtnCheckExport')"
                class="box-item"
                effect="dark"
                content="导出数据"
                placement="top">
                <el-button class="iconfont icon-download" @click="btnOperExport"></el-button>
                </el-tooltip>
            </el-button-group>    
            
        </el-col>
        </el-row>

        <!-- 列表 -->
        <el-table :data="info.dataList" border stripe :height="GetDataHeight" style="width: 100%" @select="btnCheckbox" @select-all="btnCheckbox">
        <el-table-column type="selection" width="50" />  
        <el-table-column prop="checkNumber" label="单号" width="170" />

        <el-table-column label="部门" min-width="110">
            <template v-slot="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.departmentName"
                    placement="top">
                    <div class="cellOverFlow">{{scope.row.departmentName}}</div>
                </el-tooltip>
            </template>
        </el-table-column>

        <el-table-column prop="areaName" label="大区" min-width="90" show-overflow-tooltip>
        </el-table-column>
        
        <el-table-column label="盘点名称"  min-width="100">
            <template v-slot="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.checkName"
                    placement="top">
                    <div class="cellOverFlow">{{scope.row.checkName}}</div>
                </el-tooltip>
            </template>
        </el-table-column>
        
        <el-table-column prop="state" label="状态">
            <template #default="scope">
                <el-tag v-if="scope.row.state ==1">新建单</el-tag>
                <el-tag v-if="scope.row.state ==2" type="primary">盘点中</el-tag>
                <el-tag v-if="scope.row.state ==3" type="success">完成盘点</el-tag>
                <el-tag v-if="scope.row.state ==4" type="danger">待复盘</el-tag>
                <el-tag v-if="scope.row.state ==5" type="info">已完成</el-tag>
            </template>
        </el-table-column>
        <el-table-column label="创建日期" min-width="110">
            <template #default="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.createTime"
                    placement="top"
                >
                <span>{{DateFormat(scope.row.createTime)}}</span>
                </el-tooltip>
            </template>
        </el-table-column>

        <el-table-column label="制单人" property="createName" show-overflow-tooltip min-width="90"></el-table-column>
        
        <el-table-column label="操作" width="130" fixed="right">
            <template v-slot="scope">              
            <el-button size="small" type="primary" @click="btnEditInfo(scope.row.checkNumber)">详情</el-button>
            <el-popconfirm v-if="CheckUserButtonAuth('BtnCheckDelete')"
                title="确认要删除吗?"
                @confirm="btnDeleteInfo(scope.row.checkNumber)">
                <template #reference>
                    <el-button size="small" type="danger">删除</el-button>
                </template>
                </el-popconfirm>
            </template>
        </el-table-column>
        </el-table>

    </div>

    <el-affix position="bottom">
        <el-row class="page_two">
            <el-col :span="24">
                <!-- 分页 -->
                <el-container class="page_right">
                    <el-pagination
                    v-model:currentPage="info.page"
                    v-model:page-size="info.size"
                    :page-sizes="[50, 200, 500, 1000]"
                    :background="true"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="info.total"
                    @current-change="btnPagechange"
                    @size-change="btnSizechange"
                    >
                    </el-pagination>
                </el-container>
            </el-col>
        </el-row>        
    </el-affix>

    <!-- 新增、编辑 弹出框 -->
    <el-drawer
      :title="BoxEditInfo.title" 
      v-model="BoxEditInfo.isVisible"
      direction="ltr"
      size="85%"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      @closed="GetDataList">
        <EditCheck :editId="info.id" :dataList="info.dataList" @closeBox="btnCloseBox"></EditCheck>
    </el-drawer>

    <!-- 导出-弹出框 -->
    <el-dialog title="创建导出记录"
    v-model="BoxExportInfo.isVisible"
    width="350">
        <el-divider></el-divider>

        <el-form :inline="true"
        label-width="80px"
        label-position="right">
            <el-form-item label="导出标题">
                <template #label>
                    <span style="color:red;">*</span>
                    <span>导出标题</span>
                </template>
                <el-input v-model="BoxExportInfo.exportTitle" class="search-200" maxlength="50"></el-input>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button type="primary" @click="btnExportCheck">导出</el-button>
            <el-button @click="BoxExportInfo.isVisible = false">关闭</el-button>
        </template>
    </el-dialog>

  </div>
</template>

<script>
import UserDptList from '@/utils/mixins/UserDptList.js'   //部门-下拉列表-目录树
import UserAreaList from '@/utils/mixins/UserAreaList.js'  //用户关联大区列表
import { ElMessage } from 'element-plus'
import EditCheck from '@/views/Wms/Check/EditCheck.vue'
export default {
    mixins:[UserDptList,UserAreaList],
    components:{
        EditCheck
    },
    data(){
        return{
            info:{
                dataList:[],  //Table数据
                page:1,
                size:50,
                total:0,  //总记录数
                id:0,  //新增、编辑 的参数
                ids:[],   //删除 的参数
                search:{
                    checkNumber:'',  //编号
                    checkName:'',   //盘点名称
                    selectDepartmentList:[],   //部门
                    areaNumberList:[],   //大区列表
                    state:0,    //状态
                    datePoint:'',   //日期范围
                }
            },
            //编辑-弹出框
            BoxEditInfo:{
              isVisible:false,
              title:'加工详情'
            },
            //导出-弹出框
            BoxExportInfo:{
                isVisible:false,
                exportTitle:'',  //导出记录标题
            },
        }
    },
    computed:{
        //自适应窗体高度
        GetDataHeight(){
            return window.innerHeight-180
        }
    },
    methods:{
        //加载数据列表
        GetDataList(){
            //时间范围
            if(this.info.search.datePoint == null){
                this.info.search.datePoint = ''
            }

            //获取、转换部门
            let newDepartmentList = []
            if(this.info.search.selectDepartmentList!=null){
                newDepartmentList = this.info.search.selectDepartmentList.map(item=>{
                    return item
                })
            }

            let param = {
                checkNumber:this.info.search.checkNumber,
                departmentList:newDepartmentList,
                areaNumberList:this.info.search.areaNumberList,  //大区
                checkName:this.info.search.checkName,
                state:this.info.search.state,
                startDate:this.DateFormat(this.info.search.datePoint[0]),
                endDate:this.DateFormat(this.info.search.datePoint[1]),
                page:this.info.page,
                size:this.info.size
            }
            this.$api.wms.ListCheck(param).then(res=>{
                if(res.data.status === 200){
                    this.info.dataList = res.data.data; 
                    //分页
                    this.info.total = res.data.total
                    this.info.page = res.data.pageNumber
                }else{
                    ElMessage.error(res.data.msg);
                }
            }).catch(e=>{
                console.log(e)
            })
        },

        //选中多选框
        btnCheckbox(selection){
            this.info.ids = selection.map(item=>item.checkNumber)
        },
        //分页-页数变化时
        btnPagechange(page){
            this.info.page = page
            this.GetDataList() //加载Table数据
        },
        //分页-单页记录数变化时
        btnSizechange(size){
            this.info.size = size
            this.info.page = 1
            this.GetDataList() //加载Table数据
        },

        //新增编辑部门信息
        btnEditInfo(checkNumber){
            this.info.id = checkNumber
            if(checkNumber==0){
                this.BoxEditInfo.title = "新增盘点单"
            }else{
                this.BoxEditInfo.title = "编辑盘点单"
            }
            this.BoxEditInfo.isVisible = true;
        },
        //关闭弹出框
        btnCloseBox(){
            this.BoxEditInfo.isVisible = false;
        },

        //删除按钮
        btnDeleteInfo(checkNumber){
            let param = [checkNumber]
            this.$api.wms.DeleteCheckAllInfo(param).then(res=>{
            if(res.data.status === 200){
                ElMessage.success(res.data.msg)
                this.GetDataList()
            }
            else{
                ElMessage.error(res.data.msg)
            }
            })
        },
        //批量删除
        btnBatchDeleteInfo(){
            if(this.info.ids.length<1){
                ElMessage.error('请选择要删除的选项')
                return false
            }

            this.$api.wms.DeleteCheckAllInfo(this.info.ids).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg)
                    this.GetDataList()
                }
                else{
                    ElMessage.error(res.data.msg)
                }
            })
        },

        //重置按钮
        btnResetSearch(){
            this.info.search.checkNumber='';  //单号
            this.info.search.selectDepartmentList = [];
            this.info.search.areaNumberList = [];  //大区列表
            this.info.search.checkName='';
            this.info.search.state=0;
            this.info.search.datePoint = '',
            this.info.page = 1,
            this.GetDataList()
        }, 

        //导出弹出框
        btnOperExport(){
            this.BoxExportInfo.isVisible=true;
        },
        //导出数据
        btnExportCheck(){
            //时间范围
            if(this.info.search.datePoint == null){
                this.info.search.datePoint = ''
            }

            //获取、转换部门
            let newDepartmentList = []
            if(this.info.search.selectDepartmentList!=null){
                newDepartmentList = this.info.search.selectDepartmentList.map(item=>{
                    return item
                })
            }

            let param = {
                checkNumber:this.info.search.checkNumber,
                departmentList:newDepartmentList,
                areaNumberList:this.info.search.areaNumberList,  //大区
                checkName:this.info.search.checkName,
                state:this.info.search.state,
                startDate:this.DateFormat(this.info.search.datePoint[0]),
                endDate:this.DateFormat(this.info.search.datePoint[1]),
                exportTitle:this.BoxExportInfo.exportTitle,   //重要：导出标题
                page:this.info.page,
                size:this.info.size
            }
            this.$api.wms.ExportCheck(param).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    this.BoxExportInfo.isVisible=false;  //关闭导出记录-弹出框
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },


    },
    mounted(){
        this.GetDataList();  //加载数据
    }
}
</script>

<style>

</style>