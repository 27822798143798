<template>
  <div>
    <!-- 应收账款统计 -->

    <!-- 标题 -->
    <el-row>
        <el-col :span="24" class="title">
            <h3>应收账款统计</h3>
        </el-col>        
    </el-row>

    <!-- 查询条件 -->
    <div>
      <el-form :inline="true">

        <el-form-item label="大区">
          <el-select v-model="search.areaNumber" class="search-200" :clearable="true">
            <el-option v-for="areaInfo in allAreaList" :key="areaInfo.areaNumber" :label="areaInfo.areaName" :value="areaInfo.areaNumber"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="部门">
          <el-tree-select v-model="search.departmentId" :data="allDptList" :default-expand-all="true" :clearable="true" class="search-200" />
        </el-form-item>

        <el-form-item label="交易者">
            <el-select v-model="search.tradeNumber" filterable clearable class="search-150">
                <el-option v-for="trade in allTradeList" :key="trade.tradeNumber" :label="trade.tradeName+ '(' + trade.tradeTypeName+')'" :value="trade.tradeNumber" />
            </el-select>
        </el-form-item>

        <el-form-item label="日期">
          <el-date-picker
              v-model="search.datePoint"
              type="daterange"
              range-separator="To"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              style="width:250px"
          />
        </el-form-item>

        <el-form-item>
          <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
          <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
        </el-form-item>
      </el-form>
    </div>

    <!-- 数据结果 -->
    <el-row :gutter="10">
        <!-- 左侧汇总列表 -->
        <el-col :span="8">
            <el-table :data="dataList" border show-summary stripe height="700px" highlight-current-row>
                <el-table-column prop="tradeNumber" label="编号"></el-table-column>
                <el-table-column prop="tradeName" label="交易者" width="150" show-overflow-tooltip></el-table-column>
                <el-table-column prop="totalPrice" label="应收账款" sortable></el-table-column>
                <el-table-column label="操作" width="75">
                    <template v-slot="scope">
                        <el-button type="primary" size="small" @click="BtnLookDetail(scope.row.tradeNumber)">详情</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-col>

        <!-- 右侧单个交易者明细列表 -->
        <el-col :span="16">
            <el-table :data="detailList" border show-summary stripe height="700px">
                <el-table-column label="业务单号" width="170">
                    <template v-slot="scope">
                        <span>{{scope.row.billNumber}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="单据类型" width="85">
                    <template #default="scope">
                        <el-tag v-if="scope.row.originType == 1" type="danger">采购单</el-tag>
                        <el-tag v-if="scope.row.originType == 2" type="success">销售单</el-tag>
                        <el-tag v-if="scope.row.originType == 3" type="info">零售单</el-tag>
                        <el-tag v-if="scope.row.originType == 4" type="warning">差额单</el-tag>                    
                        <el-tag v-if="scope.row.originType == 14" type="warning">退供单</el-tag>
                        <el-tag v-if="scope.row.originType == 15" type="warning">退货单</el-tag>
                        <el-tag v-if="scope.row.originType == 17" type="info">网络订单</el-tag>
                    </template>
                </el-table-column> 
                <el-table-column prop="departmentName" label="部门名称" width="150">
                    <template v-slot="scope">
                        <el-tooltip
                            class="box-item"
                            effect="dark"
                            :content="scope.row.departmentName"
                            placement="top"
                        >
                        <div class="cellOverFlow">{{scope.row.departmentName}}</div>
                    </el-tooltip>
                    </template>
                </el-table-column>

                <el-table-column prop="areaName" label="大区" min-width="90" show-overflow-tooltip>
                </el-table-column>
                
                <el-table-column label="交易者类型" min-width="95">
                    <template v-slot="scope">
                        <el-tag v-if="scope.row.tradeType == 1" type="danger">供应商</el-tag>
                        <el-tag v-if="scope.row.tradeType == 2" type="success">客户</el-tag>
                        <el-tag v-if="scope.row.tradeType == 3" type="info">员工</el-tag>
                        <el-tag v-if="scope.row.tradeType == 4" type="info">散客</el-tag>
                        <el-tag v-if="scope.row.tradeType == 5" type="info">会员</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="交易者" property="tradeName" show-overflow-tooltip min-width="120">
                </el-table-column>

                <el-table-column label="产品类目" show-overflow-tooltip min-width="90">
                    <template v-slot="scope">
                        <span>{{scope.row.cidName}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="品牌">
                    <template v-slot="scope">
                        <span>{{scope.row.brandName}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="产品/类型" min-width="160">
                    <template v-slot="scope">
                        <el-tooltip
                            class="box-item"
                            effect="dark"
                            :content="scope.row.spuName"
                            placement="top"
                        >
                        <div class="cellOverFlow">{{scope.row.spuName}}</div>
                    </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column label="规格/说明" show-overflow-tooltip min-width="100">
                    <template v-slot="scope">
                        <span>{{scope.row.skuName}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="单位">
                    <template v-slot="scope">
                        <span>{{scope.row.unitName}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="单价" min-width="80">
                    <template v-slot="scope">
                        <span>{{scope.row.unitPrice}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="数量" min-width="80">
                    <template v-slot="scope">
                        <span>{{scope.row.itemNum}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="totalPrice" label="总价(元)" min-width="95" />     

                <el-table-column label="财务标识" width="95">
                    <template #default="scope">
                        <el-tag v-if="scope.row.financeFlag == 0" type="danger">未生成</el-tag>
                        <el-tag v-if="scope.row.financeFlag == 1" type="info">已生成</el-tag>
                        <el-tag v-if="scope.row.financeFlag == 7" type="info">不生成</el-tag>
                    </template>
                </el-table-column>

                <el-table-column label="支付状态" width="95">
                    <template #default="scope">
                        <el-tag v-if="scope.row.payState == 0" type="danger">未支付</el-tag>
                        <el-tag v-if="scope.row.payState == 1" type="info">已支付</el-tag>
                    </template>
                </el-table-column>

                <el-table-column prop="billTime" label="记账时间" width="110">
                    <template v-slot="scope">
                        <el-tooltip
                            class="box-item"
                            effect="dark"
                            :content="scope.row.billTime"
                            placement="top"
                        >
                        <div class="cellOverFlow">{{DateFormat(scope.row.billTime)}}</div>
                    </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column label="创建日期" width="110">
                    <template v-slot="scope">
                        <el-tooltip
                            class="box-item"
                            effect="dark"
                            :content="scope.row.createTime"
                            placement="top"
                        >
                        <div class="cellOverFlow">{{DateFormat(scope.row.createTime)}}</div>
                    </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column label="制单人" property="createName" show-overflow-tooltip min-width="90"></el-table-column>

                <el-table-column label="备注" min-width="100">
                    <template v-slot="scope">
                        <el-tooltip
                            class="box-item"
                            effect="dark"
                            :content="scope.row.remark"
                            placement="top"
                        >
                        <div class="cellOverFlow">{{scope.row.remark}}</div>
                    </el-tooltip>
                    </template>
                </el-table-column>

            </el-table>
        </el-col>
    </el-row>
  </div>
</template>

<script>
import AllAreaList from '@/utils/mixins/AllAreaList.js'  //全部大区列表
import AllDptList from '@/utils/mixins/AllDptList.js'  //全部部门列表
import AllTradeList from '@/utils/mixins/AllTradeList.js'  //交易者列表
import { ElLoading, ElMessage } from 'element-plus'
export default {
    mixins:[AllAreaList,AllDptList,AllTradeList],
    data(){
        return{
            search:{
                areaNumber:'',  //大区编号
                departmentId:0,
                tradeNumber:'',   //交易者编号
                datePoint:[this.beginDate,this.endDate],
            },
            dataList:[],    //数据列表 
            detailList:[],  //右侧明细列表
            beginDate:this.DateFormat(new Date()),   //开始日期
            endDate:this.DateFormat(new Date()),   //结束日期 
        }
    },
    methods:{
        //获取数据
        GetDataList(){
            //打开遮罩层
            const loading = ElLoading.service({
                lock:true,
                text:'正在生成数据,请稍候!',
                background:'rgba(0, 0, 0, 0.7)',
            })

            if(this.search.datePoint == null){
                this.search.datePoint = ''
            }

            let param = {
                areaNumber:this.search.areaNumber,
                departmentId:this.search.departmentId,
                tradeNumber:this.search.tradeNumber,
                cidList:this.search.cidList,
                startDate:this.DateFormat(this.search.datePoint[0]),   //制单日期 
                endDate:this.DateFormat(this.search.datePoint[1]),
            }
            this.$api.stat.StatCustomerNoPayList(param).then(res=>{
                loading.close();
                if(res.data.status ==200){
                    this.dataList = res.data.data;  //获取数据
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

        //查看明细
        BtnLookDetail(tradeNumber){
            if(this.search.datePoint == null){
                this.search.datePoint = ''
            }

            let param = {
                areaNumber:this.search.areaNumber,
                departmentId:this.search.departmentId,
                tradeNumber:tradeNumber,   //参数：交易者编号
                startDate:this.DateFormat(this.search.datePoint[0]),   //制单日期 
                endDate:this.DateFormat(this.search.datePoint[1]),
            }
            this.$api.stat.StatCustomerNoPayDetailList(param).then(res=>{
                if(res.data.status === 200){
                    this.detailList = res.data.data;
                }else{
                    ElMessage.error(res.data.msg);
                    this.detailList =[];  //清空
                }
            })
        },

        //重置
        btnResetSearch(){
            this.search.areaNumber='';
            this.search.departmentId=0;
            this.search.tradeNumber='';
            this.search.datePoint='';
            this.GetDataList();
            this.detailList=[];  //清空明细
        },
    },
    mounted(){
        this.GetDataList();
    },
}
</script>

<style scoped>
/* 标题 */
.title{text-align: center;}
</style>