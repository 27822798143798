<template>
  <div>
    <!-- 报价单 -->

    <!-- 搜索功能 -->
    <el-row>
        <el-col :span="24">
            <el-form :inline="true">

                <el-form-item label="部门">
                    <el-tree-select v-model="info.search.selectDepartmentList" :data="departmentList" multiple clearable :default-expand-all="true" collapse-tags collapse-tags-tooltip class="search-200">
                    </el-tree-select>
                </el-form-item>

                <el-form-item label="大区">
                    <el-select v-model="info.search.areaNumberList" multiple clearable collapse-tags collapse-tags-tooltip class="search-200">
                        <el-option v-for="userArea in userAreaList" :key="userArea.areaNumber" :label="userArea.areaName" :value="userArea.areaNumber"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="产品类目">
                    <el-tree-select v-model="info.search.cidList" :data="selectCategoryList" :default-expand-all="true" show-checkbox multiple filterable collapse-tags collapse-tags-tooltip class="search-200"/>
                </el-form-item>

                <el-form-item label="名称">
                    <el-input v-model="info.search.searchName" placeholder="分类、产品名称、品牌" class="search-200"></el-input>
                </el-form-item>

                <el-form-item>
                    <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
                    <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
                </el-form-item>

            </el-form>
        </el-col>
    </el-row>


    <div>
        <!-- 功能栏 -->
        <el-row :gutter="10" class="operation">
            <el-col :span="15">
                <el-button v-if="CheckUserButtonAuth('BtnPriceQuoteRefresh')" class="iconfont icon-exchangerate" plain @click="btnRefresh">刷新</el-button>
                <el-button v-if="CheckUserButtonAuth('BtnPriceQuoteDelete')" class="iconfont icon-ashbin" type="danger" plain @click="btnBatchDeleteInfo">删除</el-button>
            </el-col>

            <el-col :span="9" class="operation_hidden">
                <el-button-group>
                                                    
                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="流程图"
                    placement="top">
                    <el-button class="iconfont icon-icon__liuchengtu" @click="btnNoFlow"></el-button>
                    </el-tooltip>

                    <el-tooltip
                    class="box-item"
                    effect="dark"
                    content="打印"
                    placement="top">
                    <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
                    </el-tooltip>

                    <el-tooltip v-if="CheckUserButtonAuth('BtnPriceQuoteExport')"
                    class="box-item"
                    effect="dark"
                    content="导出数据"
                    placement="top">
                    <el-button class="iconfont icon-download" @click="btnOpenExport"></el-button>
                    </el-tooltip>
                </el-button-group>        
                
            </el-col>
        </el-row>

        <!-- 报价明细列表 -->
        <el-table :data="dataList" border stripe :height="tableHeight" @select="btnCheckbox" @select-all="btnCheckbox">
            <el-table-column type="selection" width="55" prop="id"></el-table-column>
            <el-table-column label="编号"  width="70">
                <template v-slot="scope">
                    <span>{{scope.row.id}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="departmentName" label="部门名称" min-width="100">
                <template v-slot="scope">
                  <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.departmentName"
                    placement="top"
                  >
                  <div class="cellOverFlow">{{scope.row.departmentName}}</div>
                  </el-tooltip>
                </template>
            </el-table-column>

            <el-table-column prop="areaName" label="大区" min-width="90" show-overflow-tooltip>
            </el-table-column>

            <el-table-column prop="cidName" label="产品类目" min-width="90" show-overflow-tooltip></el-table-column>
            <el-table-column prop="brandName" label="品牌" min-width="80" show-overflow-tooltip></el-table-column>
            <el-table-column prop="spuName" label="产品名称" min-width="110" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="skuName" label="规格名称" min-width="90" show-overflow-tooltip/>

            <el-table-column label="默认单位" width="85">
                <template #header>
                    <div class="defPrice">默认单位</div>
                </template>
                <template v-slot="scope">
                    <span>{{scope.row.defUnitName}}</span>
                </template>
            </el-table-column>

            <el-table-column label="成本价" width="115">
                <template #header>
                    <div>成本价</div>
                    <div class="defPrice PriceSize">(默认单位)</div>
                </template>
                <template v-slot="scope">
                    <el-input-number v-if="scope.row.isEdit==1" v-model="scope.row.defCostPrice" :precision="2" :controls="false" style="width:90px"></el-input-number>
                    <span v-else>{{this.myRound(scope.row.defCostPrice,2)}}</span>
                </template>
            </el-table-column>

            <el-table-column label="零售价"  width="115">
                <template #header>
                    <div>零售价</div>
                    <div class="defPrice PriceSize">(默认单位)</div>
                </template>
                <template v-slot="scope">
                    <el-input-number v-if="scope.row.isEdit==1" v-model="scope.row.defRetailPrice" :precision="2" :controls="false" style="width:90px"></el-input-number>
                    <span v-else>{{scope.row.defRetailPrice}}</span>
                </template>
            </el-table-column>

            <el-table-column  label="批发价"  width="115">
                <template #header>
                    <div>批发价</div>
                    <div class="defPrice PriceSize">(默认单位)</div>
                </template>
                <template v-slot="scope">
                    <el-input-number v-if="scope.row.isEdit==1" v-model="scope.row.defWholePrice" :precision="2" :controls="false" style="width:90px"></el-input-number>
                    <span v-else>{{scope.row.defWholePrice}}</span>
                </template>
            </el-table-column>

            <el-table-column  label="VIP价"  width="115">
                <template #header>
                    <div>VIP价</div>
                    <div class="defPrice PriceSize">(默认单位)</div>
                </template>
                <template v-slot="scope">
                    <el-input-number v-if="scope.row.isEdit==1" v-model="scope.row.defVipPrice" :precision="2" :controls="false" style="width:90px"></el-input-number>
                    <span v-else>{{scope.row.defVipPrice}}</span>
                </template>
            </el-table-column>

            <el-table-column label="最小单位" width="85">
                <template #header>
                    <div class="minPrice">最小单位</div>
                </template>
                <template v-slot="scope">
                    <span>{{scope.row.minUnitName}}</span>
                </template>
            </el-table-column>
            <el-table-column label="成本价" width="115">
                <template #header>
                    <div>成本价</div>
                    <div class="minPrice PriceSize">(最小单位)</div>
                </template>
                <template v-slot="scope">
                    <el-input-number v-if="scope.row.isEdit==1" v-model="scope.row.minCostPrice" :precision="2" :controls="false" style="width:90px"></el-input-number>
                    <span v-else>{{this.myRound(scope.row.minCostPrice,2)}}</span>
                </template>
            </el-table-column>
            <el-table-column label="零售价" width="115">
                <template #header>
                    <div>零售价</div>
                    <div class="minPrice PriceSize">(最小单位)</div>
                </template>
                <template v-slot="scope">
                    <el-input-number v-if="scope.row.isEdit==1" v-model="scope.row.minRetailPrice" :precision="2" :controls="false" style="width:90px"></el-input-number>
                    <span v-else>{{scope.row.minRetailPrice}}</span>
                </template>
            </el-table-column>
            <el-table-column  label="批发价" width="115">
                <template #header>
                    <div>批发价</div>
                    <div class="minPrice PriceSize">(最小单位)</div>
                </template>
                <template v-slot="scope">
                    <el-input-number v-if="scope.row.isEdit==1" v-model="scope.row.minWholePrice" :precision="2" :controls="false" style="width:90px"></el-input-number>
                    <span v-else>{{scope.row.minWholePrice}}</span>
                </template>
            </el-table-column>

            <el-table-column  label="VIP价" width="115">
                <template #header>
                    <div>VIP价</div>
                    <div class="minPrice PriceSize">(最小单位)</div>
                </template>
                <template v-slot="scope">
                    <el-input-number v-if="scope.row.isEdit==1" v-model="scope.row.minVipPrice" :precision="2" :controls="false" style="width:90px"></el-input-number>
                    <span v-else>{{scope.row.minVipPrice}}</span>
                </template>
            </el-table-column>
            
            <el-table-column label="更新时间" width="110">
                <template #default="scope">
                    <el-tooltip
                        class="box-item"
                        effect="dark"
                        :content="scope.row.updateTime"
                        placement="top"
                    >
                    <span>{{DateFormat(scope.row.updateTime)}}</span>
                    </el-tooltip>                
                </template>
            </el-table-column>

        </el-table>
    </div>
    

    <el-affix position="bottom">
        <el-row class="page_two">
            <el-col :span="24">
                <!-- 分页 -->
                <el-container class="page_right">
                    <el-pagination
                    v-model:currentPage="info.page"
                    v-model:page-size="info.size"
                    :page-sizes="[50, 200, 500]"
                    :background="true"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="info.total"
                    @current-change="btnPagechange"
                    @size-change="btnSizechange"
                    >
                    </el-pagination>
                </el-container>
            </el-col>
        </el-row>        
    </el-affix>

    <!-- 导出-弹出框 -->
    <el-dialog title="创建导出记录"
    v-model="BoxExportInfo.isVisible"
    width="350">
        <el-divider></el-divider>

        <el-form :inline="true"
        label-width="80px"
        label-position="right">
            <el-form-item label="导出标题">
                <template #label>
                    <span style="color:red;">*</span>
                    <span>导出标题</span>
                </template>
                <el-input v-model="BoxExportInfo.exportTitle" class="search-200" maxlength="50"></el-input>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button type="primary" @click="btnExportInfo">导出</el-button>
            <el-button @click="BoxExportInfo.isVisible = false">关闭</el-button>
        </template>
    </el-dialog>

  </div>
</template>

<script>
import UserDptList from '@/utils/mixins/UserDptList.js'   //用户关联部门列表
import UserAreaList from '@/utils/mixins/UserAreaList.js'  //用户关联大区列表
import CategoryList from '@/utils/mixins/CategoryList.js'  //产品类目
import TableHeight from '@/utils/mixins/TableHeight.js'  //设置Table高度

import { ElLoading, ElMessage, ElMessageBox } from 'element-plus'
export default {
    mixins:[UserDptList,UserAreaList,CategoryList,TableHeight],
    data(){
        return{
            dataList:[],  //table数据
            info:{
                page:1,
                size:50,
                total:0,  //总记录数
                id:0,  //新增、编辑 的参数
                ids:[],   //删除 的参数
                search:{
                    selectDepartmentList:[],  //部门
                    areaNumberList:[],   //大区列表
                    cidList:[],  //产品类目
                    searchName:'',    //聚合查询
                },
            },
            //导出-弹出框
            BoxExportInfo:{
                isVisible:false,
                exportTitle:'',  //导出记录标题
            },
        }
    },
    methods:{
        //加载数据列表
        GetDataList(){

            //获取、转换部门
            let newDepartmentList = []
            if(this.info.search.selectDepartmentList!=null){
                newDepartmentList = this.info.search.selectDepartmentList.map(item=>{
                    return item
                })
            }

            let param = {
                departmentList:newDepartmentList,
                areaNumberList:this.info.search.areaNumberList,  //大区
                cidList:this.info.search.cidList,
                searchName:this.info.search.searchName,
                page:this.info.page,
                size:this.info.size
            }
            this.$api.finance.PagePriceQuoteList(param).then(res=>{
                if(res.data.status === 200){
                    this.dataList = res.data.data;
                    //分页
                    this.info.total = res.data.total;
                    this.info.page = res.data.pageNumber;
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

        //选中多选框
        btnCheckbox(selection){
            this.info.ids = selection.map(item=>item.id)
        },
        //分页-页数变化时
        btnPagechange(page){
            this.info.page = page
            this.GetDataList() //加载Table数据
        },
        //分页-单页记录数变化时
        btnSizechange(size){
            this.info.size = size
            this.info.page = 1
            this.GetDataList() //加载Table数据
        },

        //批量删除
        btnBatchDeleteInfo(){
            if(this.info.ids.length<1){
                ElMessage.error('请选择要删除的选项')
                return false
            }

            ElMessageBox.confirm('确定要删除该报价记录吗?','删除提示',{
                confirmButtonText: '确认',
                cancelButtonText: '取消'
            }).then(()=>{
                this.$api.finance.DeletePriceQuoteList(this.info.ids).then(res=>{
                    if(res.data.status === 200){
                        ElMessage.success(res.data.msg)
                        this.GetDataList()
                    }
                    else{
                        ElMessage.error(res.data.msg)
                    }
                })
            }).catch(()=>{
                //取消
            })  
        },

        //重置按钮
        btnResetSearch(){
            this.info.search.selectDepartmentList = [];
            this.info.search.areaNumberList=[];
            this.info.search.cidList=[];
            this.info.search.searchName="";   //聚合查询
            this.info.ids = [];  //编号数组
            this.info.page = 1;
            this.GetDataList()
        }, 

        //导出弹出框
        btnOpenExport(){
            this.BoxExportInfo.isVisible=true;
        },
        //导出业务单列表到Excel
        btnExportInfo(){

            //打开遮罩层
            const loading = ElLoading.service({
                lock:true,
                text:'正在导出数据,请稍候!',
                background:'rgba(0, 0, 0, 0.7)',
            })

            //获取、转换部门
            let newDepartmentList = []
            if(this.info.search.selectDepartmentList!=null){
                newDepartmentList = this.info.search.selectDepartmentList.map(item=>{
                    return item
                })
            }

            let param = {
                departmentList:newDepartmentList,
                areaNumberList:this.info.search.areaNumberList,  //大区
                cidList:this.info.search.cidList,
                searchName:this.info.search.searchName,  //聚合查询
                exportTitle:this.BoxExportInfo.exportTitle,   //重要：导出标题
                page:this.info.page,
                size:this.info.size
            }
            this.$api.finance.ExportPriceQuote(param).then(res=>{
                loading.close();
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    this.BoxExportInfo.isVisible=false;  //关闭导出记录-弹出框
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

    },
    mounted(){
        this.GetDataList();
    },
}
</script>

<style scoped>
.defPrice{color: rgb(57, 116, 245);}
.minPrice{color: red;}
.PriceSize{font-size: 12px;}
</style>