/**
 * 通用模块接口列表
 */

 import base from '../base'   //导入接口域名列表
 import axios from '@/utils/http'   //导入http中创建的axios实例

 const common = {

    //#region 数据字典

    //获取数据字典列表
    PageDictList(param){
        return axios.post(`${base.base}/Common/PageDictList`,param)
    },

    //获取单个数据字典信息
    SingleDict(param){
        return axios.get(`${base.base}/Common/SingleDict`,{
            params:param
        })
    },

    //保存-数据字典
    SaveDict(param){
        return axios.post(`${base.base}/Common/SaveDict`,param)
    },

    //删除-数据字典
    DeleteDict(param){
        return axios.post(`${base.base}/Common/DeleteDict`,param)
    },

    //下拉列表 - 数据字典
    SelectDict(param){
        return axios.get(`${base.base}/Common/SelectDict`,{
            params:param
        })
    },

    //#endregion

    //#region 编号管理

    //获取编号列表
    ListNumber(param){
        return axios.get(`${base.base}/Common/ListNumber`,{
            params:param
        })
    },

    //获取单个编号：编号
    SingleNumber(param){
        return axios.get(`${base.base}/Common/SingleNumber`,{
            params:param
        })
    },

    //保存-编号信息
    SaveNumber(param){
        return axios.post(`${base.base}/Common/SaveNumber`,param)
    },

    //删除-编号
    DeleteNumber(param){
        return axios.post(`${base.base}/Common/DeleteNumber`,param)
    },

    //获取增长编号：字段名称
    GetNumber(param){
        return axios.get(`${base.base}/Common/GetNumber`,{
            params:param
        })
    },

    //获取17位随机编号
    GetSerNumber(){
        return axios.get(`${base.base}/Common/GetSerNumber`)
    },

    //获取带字母前缀的数字编号
    GetSpeNumber(param){
        return axios.get(`${base.base}/Common/GetSpeNumber`,{
            params:param
        })
    },

    //#endregion

    //#region 省市区

    //获取省市区列表(带分页)
    ListArea(param){
        return axios.get(`${base.base}/Common/ListArea`,{
            params:param
        })
    },

    //获取单个地区
    SingleArea(param){
        return axios.get(`${base.base}/Common/SingleArea`,{
            params:param
        })
    },

    //保存地区信息
    SaveArea(param){
        return axios.post(`${base.base}/Common/SaveArea`,param)
    },

    //删除地区
    DeleteArea(param){
        return axios.post(`${base.base}/Common/DeleteArea`,param)
    },

    //获取省市区 目录树
    SelectTreeAreaList(){
        return axios.get(`${base.base}/Common/SelectTreeAreaList`)
    },

    //获取省市区 目录树 (不带附加目录的)
    SelectTreeAreaList2(){
        return axios.get(`${base.base}/Common/SelectTreeAreaList2`)
    },

    //#endregion

    //#region 文件上传相关地址

    //获取指定文件夹下的文件列表
    GetFileList(param){
        return axios.get(`${base.base}/File/GetFileList`,{
            params:param
        })
    },

    //删除指定的文件
    DeleteFileList(param){
        return axios.post(`${base.base}/File/DeleteFileList`,param)
    },

    //#endregion

    //#region 组管理

    //组列表
    ListGroup(param){
        return axios.post(`${base.base}/Group/ListGroup`,param)
    },

    //获取单个信息
    GetGroupAllInfo(param){
        return axios.get(`${base.base}/Group/GetGroupAllInfo`,{
            params:param
        })
    },

    //保存组信息
    SaveGroupAllInfo(param){
        return axios.post(`${base.base}/Group/SaveGroupAllInfo`,param)
    },

    //删除组信息
    DeleteGroupAllInfo(param){
        return axios.post(`${base.base}/Group/DeleteGroupAllInfo`,param)
    },

    //#endregion

    //#region 系统设置

    //获取
    GetSetSystemInfo(){
        return axios.get(`${base.base}/SetSystem/GetSetSystemInfo`)
    },

    //保存
    SaveSetSystemInfo(param){
        return axios.post(`${base.base}/SetSystem/SaveSetSystemInfo`,param)
    },

    //获取系统设置信息(缓存)
    GetCacheSetSystemInfo(){
        return axios.get(`${base.base}/SetSystem/GetCacheSetSystemInfo`)
    },
    
    //#endregion

    //#region 个人设置

    //列表
    PageSetUserList(param){
        return axios.post(`${base.base}/SetUser/PageSetUserList`,param)
    },

    //单个
    GetSetUser(param){
        return axios.get(`${base.base}/SetUser/GetSetUser`,{
            params:param
        })
    },

    //保存
    SaveSetUser(param){
        return axios.post(`${base.base}/SetUser/SaveSetUser`,param)
    },

    //删除
    DeleteSetUser(param){
        return axios.post(`${base.base}/SetUser/DeleteSetUser`,param)
    },

    //查询个人设置(缓存)
    GetCacheSetUserInfo(){
        return axios.get(`${base.base}/SetUser/GetCacheSetUserInfo`)
    },

    //#endregion

    //#region 部门设置

    //列表-分页
    PageSetDptList(param){
        return axios.post(`${base.base}/SetDpt/PageSetDptList`,param)
    },

    //单个
    GetSetDptInfo(param){
        return axios.get(`${base.base}/SetDpt/GetSetDptInfo`,{
            params:param
        })
    },

    //保存
    SaveSetDptInfo(param){
        return axios.post(`${base.base}/SetDpt/SaveSetDptInfo`,param)
    },

    //删除(所有信息)
    DeleteSetDptAllInfo(param){
        return axios.post(`${base.base}/SetDpt/DeleteSetDptAllInfo`,param)
    },

    //获取单个部门设置信息(缓存)
    GetCacheSetDptInfo(param){
        return axios.get(`${base.base}/SetDpt/GetCacheSetDptInfo`,{
            params:param
        })
    },


    //#endregion

    //#region 部门关联产品

    //部门关联产品列表-分页
    PageSetDptSkuList(param){
        return axios.post(`${base.base}/SetDpt/PageSetDptSkuList`,param)
    },

    //保存部门关联的产品
    SaveSetDptSku(param){
        return axios.post(`${base.base}/SetDpt/SaveSetDptSku`,param)
    },

    //删除关联产品
    DeleteSetDptSku(param){
        return axios.post(`${base.base}/SetDpt/DeleteSetDptSku`,param)
    },

    //#endregion
    
    //#region 移动端版本设置

    //列表
    PageMobileVersionList(param){
        return axios.post(`${base.base}/SetMobileVersion/PageMobileVersionList`,param)
    },

    //单个
    GetMobileVersionInfo(param){
        return axios.get(`${base.base}/SetMobileVersion/GetMobileVersionInfo`,{
            params:param
        })
    },

    //保存
    SaveMobileVersion(param){
        return axios.post(`${base.base}/SetMobileVersion/SaveMobileVersion`,param)
    },

    //删除
    DeleteMobileVersion(param){
        return axios.post(`${base.base}/SetMobileVersion/DeleteMobileVersion`,param)
    },

    //#endregion

    //#region 财务类型

    //列表
    PageFinanceTypeList(param){
        return axios.post(`${base.base}/FinanceType/PageFinanceTypeList`,param)
    },

    //单个信息
    SingleFinanceType(param){
        return axios.get(`${base.base}/FinanceType/SingleFinanceType`,{
            params:param
        })
    },

    //保存
    SaveFinanceType(param){
        return axios.post(`${base.base}/FinanceType/SaveFinanceType`,param)
    },

    //删除
    DeleteFinanceType(param){
        return axios.post(`${base.base}/FinanceType/DeleteFinanceType`,param)
    },

    //下拉列表
    SelectFinanceTypeList(){
        return axios.get(`${base.base}/FinanceType/SelectPartFinanceTypeList`)
    },

    //#endregion

 }

 export default common
 