<template>
  <div>
    <!-- 合同基本信息 -->
    <el-form :inline="true"
        v-model="contractInfo.info"
        label-width="100px"
        label-position="right">
            <el-row v-if="contractInfo.info">

                <el-col :span="24">
                    <el-form-item label="合同标题">
                      <template #label>
                            <span style="color:red;">*</span>
                            <span>合同标题</span>
                        </template>
                      <el-input v-model="contractInfo.info.title" style="width:270px"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="24">
                    <el-form-item label="交易者">
                        <el-select v-model="contractInfo.info.tradeNumber" filterable class="search-200">
                            <el-option v-for="trade in partTradeList" :key="trade.tradeNumber" :label="trade.tradeName+ '(' + trade.tradeTypeName+')'" :value="trade.tradeNumber" />
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :span="24">
                  <el-form-item label="起止日期">
                      <el-date-picker
                          v-model="contractInfo.info.datePoint"
                          type="daterange"
                          range-separator="To"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          style="width:250px"
                      />
                  </el-form-item>
                </el-col>

                <el-col :span="24">
                  <el-form-item label="合同文件" >
                    <template #label>
                        <span style="color:red;">*</span>
                        <span>合同文件</span>
                    </template>

                    <el-link :href="contractInfo.info.fileUrl" target="_blank">合同地址</el-link>

                    <el-upload
                    :action="GetFileUrl2('Contract')"
                    :headers="GetHeader"
                    :show-file-list="false"
                    :before-upload="BeforeUpload"
                    :on-success="UploadSuccess">
                        <!-- <el-tag class="UploadImg">上传合同</el-tag> -->
                        <el-button size="small" type="warning">上传合同</el-button>
                    </el-upload>

                  </el-form-item>
                </el-col>

                <el-col :span="24">
                    <el-form-item label="状态">
                      <el-tag v-if="contractInfo.info.state ==1">新创建</el-tag>
                      <el-tag v-if="contractInfo.info.state ==2">已发送</el-tag>
                      <el-tag v-if="contractInfo.info.state ==3">签署中</el-tag>
                      <el-tag v-if="contractInfo.info.state ==4">已完成</el-tag>
                      <el-tag v-if="contractInfo.info.state ==5">已逾期</el-tag>
                      <el-tag v-if="contractInfo.info.state ==6">已取消</el-tag>                        
                    </el-form-item>
                </el-col>
                
            </el-row>
      </el-form>

  </div>
</template>

<script>
import { mapState } from 'vuex';
import Authorize from '@/utils/authorize.js'
import { ElMessage } from 'element-plus';

import PartTradeList from '@/utils/mixins/PartTradeList.js'  //交易者列表
export default {
  mixins:[PartTradeList],
  data(){
    return{
      info:{
        tradeList:[],  //交易者列表
      }
    }
  },
  computed:{
    //添加Token - 重要
    GetHeader(){
      return {
        "Authorization":"Bearer "+ Authorize.getToken()
      }
    },
    ...mapState('Oa',['contractInfo']),
  },
  methods:{

    //上传图片之前操作
    BeforeUpload(file){
        const fileType = file.type;
        const fileSize = file.size;
        
        if(fileType !== 'image/jpeg' && fileType !== 'image/png' && fileType!=='application/pdf'){
          ElMessage.error('格式错误，上传支持:jpeg|png|pdf 格式')
          return false;
        }
        //文件大小
        if(fileSize/1024/1024 > 5){
          ElMessage.error('上传文件大小不能超过5Mb')
          return false;
        }

    },
    //上传完成
    UploadSuccess(res){
        if(res.status === 200){
          ElMessage.success('上传成功');
          this.contractInfo.info.fileUrl = res.data
        }
    },
    

  }

}
</script>

<style scoped>
</style>