<template>
  <div class="system_header">
    <div style="width:60%;">
        <el-row>
            <!-- 隐藏、显示菜单按钮 -->
            <el-col :span="2">
                <div class="header_MenuCollapse">
                    <i class="iconfont icon-menu" style="font-size:25px;" @click="work_UpdateIsMenuCollapse(!isMenuCollapse)"></i>
                </div>
            </el-col>

            <!-- 系统模块列表 -->
            <el-col :span="22">
                <div class="head_menu">
                    <el-link v-for="info in moduleList" :key="info.modeuleId" :underline="false" :class="'iconfont ' + info.icon" :id="'module'+info.moduleId" @click="GetMenuByModule(info.moduleId)">
                        <span>{{info.moduleName}}</span>
                    </el-link>
                    
                </div>
            </el-col>
        </el-row>
    </div>

    <div class="home_head_user">
        <el-link :underline="false" class="iconfont icon-quanping" @click="screen">
            <span>{{screenName}}</span>
        </el-link>
        <el-link :underline="false" class="iconfont icon-customer-service" @click="BoxService.isVisible = true">
            <span>技术支持</span>
        </el-link>        
        <el-dropdown>
            <span>
                {{currentUserName}}
                <i class="iconfont icon-arrow-down"></i>
            </span>
            <template #dropdown>
            <el-dropdown-menu>
                <el-dropdown-item @click="BoxUserPwd.isVisible = true">修改密码</el-dropdown-item>
                <el-dropdown-item @click="BoxUserInfo.isVisible = true">用户信息</el-dropdown-item>
                <el-dropdown-item @click="BoxSystemInfo.isVisible = true">企业信息</el-dropdown-item>
            </el-dropdown-menu>
            </template>
        </el-dropdown>
        <el-button type="info" @click="btnExitSystem">退出</el-button>
    </div>

    <!-- 修改用户密码 -->
    <el-dialog title="修改登录密码"
    width="400"
    v-model="BoxUserPwd.isVisible"
    :destroy-on-close="true">
        <UpdateUserPwd @closeBox="CloseUserPwd"></UpdateUserPwd>
    </el-dialog>

    <!-- 修改用户信息 -->
    <el-dialog title="用户信息"
    width="600"
    v-model="BoxUserInfo.isVisible"
    :destroy-on-close="true">
        <LookUserInfo @closeBox="CloseUserInfo"></LookUserInfo>
    </el-dialog>

    <!-- 修改企业信息 -->
    <el-dialog title="企业信息"
    width="700"
    v-model="BoxSystemInfo.isVisible"
    :destroy-on-close="true">
        <LookSystemInfo @closeBox="CloseSystemInfo"></LookSystemInfo>
    </el-dialog>

    <!-- 技术支持 -->
    <el-dialog title="技术支持"
    width="400"
    v-model="BoxService.isVisible"
    :destroy-on-close="true">
        <el-divider></el-divider>
        <div class="jishuzhichi">
            <span>工作日：9:00 - 17:00</span>
            <span>电话：010-63611239</span>  
        </div>
        <template #footer>
            <el-button @click="BoxService.isVisible = false">关闭</el-button>
        </template>
    </el-dialog>

  </div>

</template>

<script>
import {mapState,mapActions,mapMutations} from 'vuex'
import Authorize from '@/utils/authorize.js'
import UpdateUserPwd from '@/views/system/UpdateUserPwd.vue'   //修改用户密码
import LookUserInfo from '@/views/system/LookUserInfo.vue'   //查看用户信息
import LookSystemInfo from '@/views/system/LookSystemInfo.vue'  //查看系统信息
import { ElMessage } from 'element-plus'
export default {
    name:'SystemHeader',
    data(){
        return{
            screenName:'全屏',
            moduleList:[],    //模块列表

            //弹出框 - 修改用户密码
            BoxUserPwd:{
                isVisible:false,
            },
            //弹出框 - 修改用户信息
            BoxUserInfo:{
                isVisible:false,
            },
            //弹出框 - 修改企业信息
            BoxSystemInfo:{
                isVisible:false,
            },
            //弹出框 - 技术支持
            BoxService:{
                isVisible:false,
            }

        }
    },
    components:{
        UpdateUserPwd,   //修改用户密码
        LookUserInfo,  //查看用户信息
        LookSystemInfo,  //查看系统信息
    },
    computed:{
        ...mapState('Master',['currentUserName','isMenuCollapse','isMenuCollapse','moduleId']),
        ...mapState('SetUp',['setUserInfo','setSysInfo']),  //个人设置、系统设置
    },
    methods:{
        ...mapActions('Master',['work_UpdateIsMenuCollapse','work_SetModuleId','work_GetModuleId']),
        ...mapMutations('Master',['UpdateIsMenuCollapse','ClearTag','SetUserAllMenuList','SetModuleId']),
        ...mapMutations('SetUp',['SetSetUserInfo','SetSetSysInfo']), //个人设置、系统设置
       
        //打开修改用户密码 ★★★
        CloseUserPwd(){
            this.BoxUserPwd.isVisible = false;
        },        

        //关闭修改用户信息 ★★★
        CloseUserInfo(){
            this.BoxUserInfo.isVisible = false;
        },

        //打开修改系统信息 ★★★
        CloseSystemInfo(){
            this.BoxSystemInfo.isVisible = false;
        }, 

        //退出系统
        btnExitSystem(){
            Authorize.clearToken() //清空Token
            localStorage.removeItem("TagList") //清空本地TagList，因为可能登录的权限不同
            this.ClearTag() //清空Tag标签
            this.$router.replace('/login')
            this.work_SetModuleId(0);  //设置模块编号
        },

        //全屏方法
        screen() {
            let element = document.documentElement;
            if (this.fullscreen) {
                this.screenName = "全屏";
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.webkitCancelFullScreen) {
                    document.webkitCancelFullScreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                }
            } else {
                this.screenName = "退出全屏";
                if (element.requestFullscreen) {
                    element.requestFullscreen();
                } else if (element.webkitRequestFullScreen) {
                    element.webkitRequestFullScreen();
                } else if (element.mozRequestFullScreen) {
                    element.mozRequestFullScreen();
                } else if (element.msRequestFullscreen) {
                // IE11
                element.msRequestFullscreen();
                }
            }
            this.fullscreen = !this.fullscreen;
        },
        
        //加载系统关联的模块列表
        GetModuleList(){
            this.$api.system.GetSystemModuleList().then(res=>{
                if(res.data.status === 200){
                    this.moduleList = res.data.data;
                    //获取第一个模块的菜单列表
                    if(localStorage.moduleId ==0 || localStorage.moduleId ==undefined){
                        this.work_SetModuleId(this.moduleList[0].moduleId);
                    }else{
                        this.work_GetModuleId();  //获取本地缓存的编号
                    }                    
                    this.GetMenuByModule(this.moduleId);
                }
            })           

        },

        //通过模块编号获取菜单列表
        GetMenuByModule(moduleId){
            let param = {
                moduleId:moduleId
            }
            this.$api.system.ListUserAllMenuByModuleId(param).then(res=>{
                if(res.data.status === 200){
                    //点击模块时更新本地缓存的模块编号
                    this.work_SetModuleId(moduleId);  
                    //删除模块原有的选中的样式
                    let moduleArrary = document.getElementsByClassName('focusModule')
                    if(moduleArrary.length>0){
                        moduleArrary[0].classList.remove('focusModule')
                    }
                    //设置新的模块选中样式     
                    let moduleInfo = document.getElementById('module'+moduleId);
                    moduleInfo.classList.add('focusModule')
                    //获取当前模块的菜单列表
                    this.SetUserAllMenuList(res.data.data);
                }else{
                    //错误时不更新模块编号
                    ElMessage.error(res.data.msg);
                    //将菜单制空
                    this.SetUserAllMenuList(null);
                }
            })
        },

        //加载用户制单限制日期
        GetSetUser(){
            this.$api.common.GetCacheSetUserInfo().then(res=>{
                if(res.data.status === 200){
                    let dateNum = res.data.data.limitDay;
                    sessionStorage.setItem("DisableDate", dateNum); //session缓存
                    this.SetSetUserInfo(res.data.data);  //获取个人设置
                }else{
                    sessionStorage.setItem("DisableDate", 2);  //如果没有用户设置，默认两天
                }
            })
        },

        //加载系统设置
        GetSetSysInfo(){
            this.$api.common.GetCacheSetSystemInfo().then(res=>{
                if(res.data.status === 200){
                    if(res.data.status === 200){
                        this.SetSetSysInfo(res.data.data);  //设置系统信息
                    }
                }
            })
        },

    },
    mounted(){
        this.GetModuleList();  //加载系统关联的模块
        this.GetSetUser();  //加载个人设置
        this.GetSetSysInfo();  //加载系统设置
    }
}
</script>

    
<style scoped>
     .system_header{
         padding: 0px 20px;
         height: 98%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #d8dce5;
    }
    .head_menu .el-link{padding-right:25px;}
    .head_menu .el-link span{padding-left: 2px;}
    /* .head_menu .iconfont {font-size: 17px;} */
    .focusModule {color: #409eff;font-weight: bolder;}  /* 选中模块样式 */

    .home_head_user{display: flex;align-items:center;}
    .home_head_user .el-link{margin-right: 15px;}
    .home_head_user .el-link span{padding-left: 2px;}
    .home_head_user .iconfont {font-size: 15px;}
    .home_head_user .el-dropdown{margin-right: 15px;}

    /*技术支持*/
    .jishuzhichi span{padding-right: 20px;}
</style>