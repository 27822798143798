<template>
  <div>
    <!-- 工作日规则设置 -->

    <el-tabs v-model="info.activeName">

        <el-tab-pane label="按周设置" name="TabWeek">
            <ListWeek></ListWeek>
        </el-tab-pane>

        <el-tab-pane label="按日设置" name="TabDay">
            <ListDay></ListDay>
        </el-tab-pane>

        <el-tab-pane label="常班工作表" name="sheet">
            <ListSheet></ListSheet>
        </el-tab-pane>

    </el-tabs>
  </div>
</template>

<script>
import ListWeek from '@/views/Oa/Rule/ListWeek.vue'
import ListDay from '@/views/Oa/Rule/ListDay.vue'
import ListSheet from '@/views/Oa/Rule/ListSheet.vue'
export default {
    components:{
        ListWeek,
        ListDay,
        ListSheet,
    },
    data(){
        return{
            info:{
                activeName:'TabWeek'
            }
        }
    }
}
</script>

<style>

</style>