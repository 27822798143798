<template>
  <!-- 选择所有产品列表 -->
  <div>
      <el-divider></el-divider>

        <!-- 搜索功能 -->
        <el-row>
            <el-col :span="24">
                <el-form :inline="true"
                label-width="80px">

                    <el-form-item label="产品类目">
                        <el-tree-select v-model="info.search.cid" :data="selectCategoryList" @change="ChangeTreeSelect" :default-expand-all="true" class="search-200"/>
                    </el-form-item>

                    <el-form-item label="品牌">
                        <el-select v-model="info.search.brandId" placeholder="请选择" class="search-200">
                                <el-option
                                v-for="item in treeBrandList"
                                :key="item.id"
                                :label="item.label"
                                :value="item.id">
                                </el-option>
                            </el-select>
                    </el-form-item>

                    <el-form-item label="名称">
                        <el-input v-model="info.search.searchName" placeholder="分类、品牌、产品" v-on:keyup.enter="GetDataList" class="search-200"></el-input>
                    </el-form-item>

                    <el-form-item>
                        <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
                        <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
                    </el-form-item>

                </el-form>
                </el-col>
        </el-row>
        <!-- 内容列表 -->
        <div>
            <!-- 列表 -->
            <el-table :data="info.dataList" border stripe style="width: 100%" :height="500" @select="btnCheckbox" @select-all="btnAllCheckbox" @row-click="TableRowClick" ref="table"> 
                <el-table-column prop="id" type="selection" width="50" />  
                <el-table-column prop="cidName" label="类目" min-width="90"/>
                <el-table-column prop="brandName" label="品牌" min-width="80"/>
                <el-table-column prop="spuName" label="产品名称" min-width="100">
                    <template v-slot="scope">
                        <el-tooltip
                            class="box-item"
                            effect="dark"
                            :content="scope.row.spuName"
                            placement="top">
                            <div class="cellOverFlow">{{scope.row.spuName}}</div>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column prop="skuName" label="规格" min-width="100"/>
                <!-- <el-table-column label="图片">
                    <template v-slot="scope">
                        <el-image v-if="scope.row.skuImg"
                            style="width: 70px; height: 70px"
                            :src="scope.row.skuImg"
                            :preview-src-list="[scope.row.skuImg]"
                            :hide-on-click-modal="true"
                            fit="cover">
                        </el-image>
                        <span v-else></span>
                    </template>
                </el-table-column> -->
                <el-table-column label="产品类型" min-width="90">
                    <template v-slot="scope">
                        <el-tag v-if="scope.row.spuType ==1">产品</el-tag>
                        <el-tag v-if="scope.row.spuType ==2" type="warning">耗材</el-tag>
                    </template>
                </el-table-column>
            </el-table>

        </div>
    
    <el-affix position="bottom">
        <el-row class="page_two">
            <el-col :span="24">
                <!-- 分页 -->
                <el-container class="page_right">
                    <el-pagination
                    v-model:currentPage="info.page"
                    v-model:page-size="info.size"
                    :page-sizes="[20, 50, 200, 500]"
                    :background="true"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="info.total"
                    @current-change="btnPagechange"
                    @size-change="btnSizechange"
                    >
                    </el-pagination>
                </el-container>
            </el-col>
        </el-row>        
    </el-affix>

  </div>
</template>

<script>
import CategoryList from '@/utils/mixins/CategoryList.js'
export default {
    name:'SelectAllSkuList',
    mixins:[CategoryList],
    data(){
        return{
            info:{
                dataList:[],  //Table数据
                page:1,   //页数
                size:20,  //单页记录数
                total:0,  //总记录数
                id:0,  //新增、编辑 的参数
                ids:[],   //删除 的参数
                search:{  //搜索条件
                    cid:0,
                    brandId:0,
                    searchName:''
                }
            },
            //选择后内容
            selectedItem:[],
            //搜索 - 品牌列表
            treeBrandList:[],
        }
    },
    methods:{        
        //类目下拉列表改变后
        ChangeTreeSelect(){
            //重新加载品牌
            this.GetTreeBrandList()
        },
        //获取品牌下拉列表
        GetTreeBrandList(){
            let param = {
                cid:this.info.search.cid
            }
            this.$api.goods.SelectCategoryBrandList(param).then(res=>{
                if(res.data.status === 200){
                    this.treeBrandList = res.data.data
                }
            }).catch(e=>{
                console.log(e)
            })
        },

        //加载数据列表-全部产品
        GetDataList(){
            let param = {
                cid:this.info.search.cid,
                brandId:this.info.search.brandId,
                searchName:this.info.search.searchName,
                isShow:1,
                page:this.info.page,
                size:this.info.size
            }
            this.$api.goods.ListSku(param).then(res=>{
                if(res.data.status === 200){
                    this.info.dataList = res.data.data;  //获取数据
                    //分页
                    this.info.total = res.data.total
                    this.info.page = res.data.pageNumber
                }
            }).catch(e=>{
                console.log(e.message)
            })
        },
        //选中多选框
        btnCheckbox(row){
            this.selectedItem = row
        },
        //全选
        btnAllCheckbox(){
            this.selectedItem = this.info.dataList
        },
        //分页-页数变化时
        btnPagechange(page){
            this.info.page = page
            this.GetDataList() //加载Table数据
        },
        //分页-单页记录数变化时
        btnSizechange(size){
            this.info.size = size
            this.info.page = 1
            this.GetDataList() //加载Table数据
        },
        //重置按钮
        btnResetSearch(){
            this.info.search.cid=0,
            this.info.search.brandId=0,
            this.info.search.searchName='',
            this.GetDataList()
        },

        //选中行时(标准模板)
        TableRowClick(row){
            let isExist = false; //判断是否存在
            this.selectedItem = this.selectedItem.filter(item=>{                
                if(row.skuNumber != item.skuNumber){
                    return item;
                }else{                    
                    //如果存在
                    isExist = true;
                }                
            })
            
            if(isExist){
                this.$refs.table.toggleRowSelection(row,false);
            }else{
                this.selectedItem.push(row)
                this.$refs.table.toggleRowSelection(row,true);
            }
        },

    },
    mounted(){
        this.GetTreeBrandList()  //品牌列表
        this.GetDataList()  //加载数据Table数据
    }
}
</script>

<style scoped>
.VueTreeSelect{width: 200px;}
</style>