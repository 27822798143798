/**
 * SetUp 设置模块相关数据
 */

var actions = {

}

var mutations = {
    //设置系统信息
    SetSetSysInfo(state,value){
        state.setSysInfo = value
    },
    //设置部门信息
    SetSetDptInfo(state,value){
        state.setDptInfo = value
    },
    //设置用户信息
    SetSetUserInfo(state,value){
        state.setUserInfo = value
    },
}

var state = {
    setSysInfo:{},  //设置系统信息
    setDptInfo:{},  //设置部门信息
    setUserInfo:{},  //设置用户信息
}

export default{
    namespaced:true,
    actions,
    mutations,
    state
}