<template>
  <div>
    <el-divider></el-divider>

    <el-form :inline="true"
        v-model="info"
        label-width="100px"
        label-position="right">
        <el-row v-if="info">

            <el-col :span="14">
                <el-form-item label="排班日期">
                    <el-date-picker
                        v-model="info.datePoint"
                        type="daterange"
                        range-separator="To"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                    />
                </el-form-item>
            </el-col>

            <el-col :span="10">
                <el-form-item label="班次">
                    <el-select v-model="info.classId" class="search-200">
                        <el-option v-for="classes in classList" :key="classes.id" :label="classes.className" :value="classes.id" />
                        
                    </el-select>
                </el-form-item>
            </el-col>                

        </el-row>
    </el-form>

    <!-- 员工列表 -->
    <el-table border :data="userList" @select="btnCheckbox" @select-all="btnCheckbox">
        <el-table-column prop="id" type="selection" width="55" />  
        <el-table-column prop="userName" label="员工姓名"></el-table-column>
        <el-table-column label="性别">
            <template #default="scope">
                <span v-if="scope.row.sex ==0">女</span>
                <span v-if="scope.row.sex ==1">男</span>
                <span v-if="scope.row.sex ==2">未知</span>
            </template>
        </el-table-column>
        <el-table-column prop="departmentName" label="部门"></el-table-column>
    </el-table>

    <!-- 功能按钮组 -->
    <div class="btnGroup">
        <el-button type="primary" @click="btnSaveData">保存</el-button>
        <el-button @click="btnClose">关闭</el-button>
    </div>

  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
export default {
    data(){
        return{
            info:{
                datePoint:'',
                startDate:'',
                endDate:'',
                classId:0,
                userList:[],
            },
            userList:[],  //员工列表
            classList:[],  //班次列表
        }
    },
    methods:{
        //加载用户列表(排班)
        LoadUserList(){
          let param = {
            clockInType:2
          }
          this.$api.system.GetUserListByClockInType(param).then(res=>{
            if(res.data.status === 200){
              this.userList = res.data.data;
            }
          })
        },

        //加载班次列表
        LoadClassList(){
            this.$api.oa.SelectClassesList().then(res=>{
                if(res.data.status === 200){
                    this.classList = res.data.data;
                    if(this.classList.length>0){
                        this.info.classId=this.classList[0].id;
                    }
                }
            })
        },

        //选中多选框
        btnCheckbox(selection){
            this.info.userList = []
            selection.map(item=>{
                let newInfo = {
                    userNumber:item.userNumber,
                    userName:item.userName,
                    departmentId:item.departmentId,
                    departmentName:item.departmentName
                }
                this.info.userList.push(newInfo);
            })
        },

        //保存数据
        btnSaveData(){
            if(this.info.datePoint == null || this.info.datePoint==''){
                ElMessage.error('请选择排班日期范围');
                return false;
            }
            
            if(this.info.userList == null || this.info.userList.length <=0){
                ElMessage.error('请选择排班员工');
            }

            this.info.startDate = this.DateFormat(this.info.datePoint[0]);
            this.info.endDate = this.DateFormat(this.info.datePoint[1]);
            //班次
            this.classList.forEach(item=>{
                if(this.info.classId === item.id){
                    this.info.className = item.className;
                }
            })

            this.$api.oa.BatchSaveSchedule(this.info).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    this.btnClose();
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

        //关闭按钮
        btnClose(){
            this.$emit('closeBox')  //关闭弹出框
        },

    },
    mounted(){
        this.LoadUserList();
        this.LoadClassList();
    }
}
</script>

<style>

</style>