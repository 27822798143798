<template>
  <div>
    <!-- 单品价格走势图 -->

    <!-- 统计图 -->
    <div id="main" class="echarts" style="width:880px;height:500px;" ></div>

    


  </div>
</template>

<script>
import * as echarts from 'echarts'  //Echarts 图标组件
import moment from 'moment';
import { ElMessage } from 'element-plus';
export default {
    data(){
        return{
            //返回实体
            info:{
                unitName:'斤',  //最小单位名称
                purchasePriceList:[],  //采购单价
                salePriceList:[],   //销售单价
                purchaseNumList:[],  //采购量
                saleNumList:[],    //销售量
            },
        }
    },
    methods:{
        //示例
        myEcharts(){
            var myChart = echarts.init(document.getElementById('main'));
            
            //配置图表
            var option = {
              tooltip: {
                trigger: 'axis',   //显示标签
              },
              legend:{
                top:'bottom',
                data:['采购价','销售价','采购量','销售量'] //示例图
              },
              xAxis: {
                type: 'category',
                data: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31],
              },
              yAxis: [
                    {
                        type: 'value',
                        name:'金额',
                        position: 'left',
                        axisLabel: {
                            formatter: '{value} 元'
                        }
                    },
                    {
                        type:'value',
                        name:'交易量',
                        position: 'right',
                        axisLabel: {
                            formatter: '{value} '+this.info.unitName
                        }
                    },
              ],
              series: [
                {
                  name:'采购价',
                  yAxisIndex: 0,  //用的是左侧Y坐标
                  data: this.info.purchasePriceList,
                  type: 'line',
                  smooth: true,   //平滑曲线
                  itemStyle:{
                    color:'#9F5F9F'
                  },
                  connectNulls:true,  //连上断开的点
                },
                {
                  name:'销售价',
                  yAxisIndex: 0,  //用的是左侧Y坐标
                  data: this.info.salePriceList,
                  type: 'line',  //折线图
                  smooth: true,   //平滑曲线
                  itemStyle:{
                    color:'#FF4500'
                  },
                  connectNulls:true,  //连上断开的点
                },

                {
                  name:'采购量',
                  yAxisIndex: 1,  //用的是右侧Y坐标
                  data: this.info.purchaseNumList,
                  type: 'bar',  //柱状图
                  itemStyle:{
                    color:'#33ccff'
                  }
                },
                {
                  name:'销售量',
                  yAxisIndex: 1,  //用的是右侧Y坐标
                  data: this.info.saleNumList,
                  type: 'bar',  //柱状图
                  itemStyle:{
                    color:'#DC143C'
                  }
                },
                
              ]
            };
            myChart.setOption(option);
        },

        //加载数据
        async LoadDataView(){
            //年月
            let strMonth = moment(this.search.currentMonth).format("YYYY-MM")
            var dateArr = strMonth.split('-');
            //获取、转换部门
            let newDepartmentList = []
            if(this.search.selectDepartmentList!=null){
                newDepartmentList = this.search.selectDepartmentList.map(item=>{
                    return item
                })
            }

            let param = {
                departmentList:newDepartmentList,
                searchName:this.search.searchName,
                tradeType:this.search.tradeType,
                tradeNumber:this.search.tradeNumber,
                year:dateArr[0],
                month:dateArr[1],
            }
          await this.$api.stat.GetHistoryPriceStat(param).then(res=>{
            if(res.data.status === 200){
              this.info = res.data.data;
              this.myEcharts();
            }else{
                ElMessage.error(res.data.msg);
            }
          })
        },


    },
    mounted(){
        this.myEcharts();
    }
}
</script>

<style>

</style>