/**
 * 通用Vuex
 * 财务相关数据
 */


var actions = {

}

var mutations = {

    //设置对账单
    SetCycleInfo(state,value){
        state.cycleInfo = value
    },
    //设置无现金财务单
    SetNoCashInfo(state,value){
        state.noCashInfo = value
    },
    //设置调价表
    SetPriceAdjustInfo(state,value){
        state.priceAdjustInfo = value
    },
    //设置报价单
    SetPriceShareInfo(state,value){
        state.priceShareInfo = value
    },
    
}

var state = {
    cycleInfo:{},   //对账单
    noCashInfo:{},  //无现金财务单
    priceAdjustInfo:{},  //调价表
    priceShareInfo:{},   //报价单
}

export default {
    namespaced:true,
    actions,
    mutations,
    state
}