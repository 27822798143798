<template>
  <div>
    <!-- 搜索功能 -->
    <el-row :style="showSearch.search_hidden">
        <el-col :span="24">
            <el-form :inline="true">

            <el-form-item label="交易者">
                <el-select v-model="info.search.tradeNumber" filterable clearable class="search-150">
                    <el-option v-for="trade in allTradeList" :key="trade.tradeNumber" :label="trade.tradeName+ '(' + trade.tradeTypeName+')'" :value="trade.tradeNumber" />
                </el-select>
            </el-form-item>

            <el-form-item label="合同标题">
                <el-input v-model="info.search.title" class="search-200"></el-input>
            </el-form-item>
            
            <el-form-item label="状态">
                <el-select v-model="info.search.state" class="search-200">
                    <el-option :value="0" label="全部"></el-option>
                    <el-option :value="1" label="新创建"></el-option>
                    <el-option :value="2" label="已发送"></el-option>
                    <el-option :value="3" label="签署中"></el-option>
                    <el-option :value="4" label="已完成"></el-option>
                    <el-option :value="5" label="已逾期"></el-option>
                    <el-option :value="6" label="已取消"></el-option>
                </el-select>
            </el-form-item>

            

            <el-form-item>
            <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
            <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
            </el-form-item>

            </el-form>
        </el-col>
    </el-row>

    <!-- 主内容区 -->
    <div>
        <!-- 功能栏 -->
        <el-row :gutter="10" class="operation">
        <el-col :span="15">
            <el-button class="iconfont icon-add" type="primary" plain @click="btnEditInfo('0')">新增</el-button>
            <el-popconfirm 
                title="确认要删除吗?"
                @confirm="btnBatchDeleteInfo">
                <template #reference>
                    <el-button class="iconfont icon-ashbin" type="danger" plain>删除</el-button>
                </template>
            </el-popconfirm>
        </el-col>

        <el-col :span="9" class="operation_hidden">
            <el-button-group>
                                            
                <el-tooltip
                class="box-item"
                effect="dark"
                content="流程图"
                placement="top">
                <el-button class="iconfont icon-icon__liuchengtu" @click="btnNoFlow"></el-button>
                </el-tooltip>

                <el-tooltip
                class="box-item"
                effect="dark"
                content="打印"
                placement="top">
                <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
                </el-tooltip>

                <el-tooltip
                class="box-item"
                effect="dark"
                content="导出数据"
                placement="top">
                <el-button class="iconfont icon-download" @click="btnNoExport"></el-button>
                </el-tooltip>
            </el-button-group>   
             
        </el-col>
        </el-row>

        <!-- 列表 -->
        <el-table :data="info.dataList" border stripe :height="GetDataHeight" style="width: 100%" @select="btnCheckbox" @select-all="btnCheckbox">
        <el-table-column prop="id" type="selection" width="50" />  
        <el-table-column prop="contractNumber" label="合同单号" width="170" />
        <el-table-column label="交易者类型">
            <template v-slot="scope">
                <el-tag v-if="scope.row.tradeType ==1" type="danger">供应商</el-tag>
                <el-tag v-if="scope.row.tradeType ==2" type="success">客户</el-tag>
                <el-tag v-if="scope.row.tradeType ==3" type="info">员工</el-tag>
            </template>
        </el-table-column>
        <el-table-column label="交易者">
            <template v-slot="scope">
                <span>{{scope.row.tradeName}}</span>
            </template>
        </el-table-column>
        <el-table-column label="合同标题" width="170">
            <template v-slot="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.title"
                    placement="top">
                    <div class="cellOverFlow">
                        <el-link :href="scope.row.fileUrl" target="_blank">{{scope.row.title}}</el-link>
                    </div>
                </el-tooltip>                
            </template>
        </el-table-column>
        <el-table-column prop="state" label="合同状态">
            <template #default="scope">
                <el-tag v-if="scope.row.state ==1">新创建</el-tag>
                <el-tag v-if="scope.row.state ==2" type="danger">已发送</el-tag>
                <el-tag v-if="scope.row.state ==3" type="success">签署中</el-tag>
                <el-tag v-if="scope.row.state ==4" type="warning">已完成</el-tag>
                <el-tag v-if="scope.row.state ==5">已逾期</el-tag>
                <el-tag v-if="scope.row.state ==6" type="info">已取消</el-tag>
            </template>
        </el-table-column>

        <el-table-column label="合同起止日期" width="220">
            <template #default="scope">
            <span>{{DateFormat(scope.row.startDate)}} - {{DateFormat(scope.row.endDate)}}</span>
            </template>
        </el-table-column>
        <el-table-column label="创建日期" width="110">
            <template #default="scope">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="scope.row.createTime"
                    placement="top"
                  >
                  <span>{{DateFormat(scope.row.createTime)}}</span>
                </el-tooltip>
            </template>
        </el-table-column>
        <el-table-column label="操作" width="150" fixed="right">
            <template v-slot="scope">              
            <el-button size="small" type="primary" @click="btnEditInfo(scope.row.contractNumber)">详情</el-button>
            <el-popconfirm 
                title="确认要删除吗?"
                @confirm="btnDeleteInfo(scope.row.contractNumber)">
                <template #reference>
                    <el-button size="small" type="danger">删除</el-button>
                </template>
                </el-popconfirm>
            </template>
        </el-table-column>
        </el-table>

        <!-- 分页 -->
        <el-container class="page_one">
            <el-pagination
            v-model:currentPage="info.page"
            v-model:page-size="info.size"
            :page-sizes="[50, 200, 500]"
            :background="true"
            layout="total, sizes, prev, pager, next, jumper"
            :total="info.total"
            @current-change="btnPagechange"
            @size-change="btnSizechange"
            >
            </el-pagination>
        </el-container>

    </div>

    <!-- 新增、编辑 弹出框 -->
    <el-dialog :title="BoxEditInfo.title" 
    v-model="BoxEditInfo.isVisible"
    width="550"
    :draggable="true"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    @closed="GetDataList">
        <EditContract :editId="info.id" :dataList="info.dataList" @closeBox="btnCloseBox"></EditContract>
    </el-dialog>

  </div>
</template>

<script>
import AllTradeList from '@/utils/mixins/AllTradeList.js'  //交易者列表

import EditContract from '@/views/Oa/Contract/EditContract.vue'
import { ElMessage } from 'element-plus'
export default {
    name:'ListContract',
    mixins:[AllTradeList],
    components:{
        EditContract
    },
    data(){
        return{
            info:{
                dataList:[],  //Table数据
                page:1,
                size:50,
                total:0,  //总记录数
                id:0,  //新增、编辑 的参数
                ids:[],   //删除 的参数
                search:{
                    tradeType:0,
                    tradeNumber:'',
                    title:'',
                    state:0
                },
                tradeList:[],   //交易者列表
            },            
            //编辑-弹出框
            BoxEditInfo:{
              isVisible:false,
              title:'合同详情'
            },

        }
    },
    computed:{
        //自适应窗体高度
        GetDataHeight(){
            return window.innerHeight-240
        }
    },
    methods:{

        //加载数据列表
        GetDataList(){
            let param = {
                tradeType:this.info.search.tradeType,
                tradeNumber:this.info.search.tradeNumber,
                title:this.info.search.title,
                state:this.info.search.state,
                page:this.info.page,
                size:this.info.size
            }
            this.$api.oa.PageContractList(param).then(res=>{
                if(res.data.status === 200){
                    this.info.dataList = res.data.data;
                    //分页
                    this.info.total = res.data.total
                    this.info.page = res.data.pageNumber
                }
            }).catch(e=>{
                console.log(e)
            })
        },
        //选中多选框
        btnCheckbox(selection){
            this.info.ids = selection.map(item=>item.contractNumber)
        },
        //分页-页数变化时
        btnPagechange(page){
            this.info.page = page
            this.GetDataList() //加载Table数据
        },
        //分页-单页记录数变化时
        btnSizechange(size){
            this.info.size = size
            this.info.page = 1
            this.GetDataList() //加载Table数据
        },

        //编辑合同
        btnEditInfo(contractNumber){
            this.info.id = contractNumber
            this.BoxEditInfo.isVisible = true;
        },
        //关闭弹出框
        btnCloseBox(){
            this.BoxEditInfo.isVisible = false;
        },

        //删除按钮
        btnDeleteInfo(contractNumber){
            let param = [contractNumber]
            this.$api.oa.DeleteContract(param).then(res=>{
            if(res.data.status === 200){
                ElMessage.success(res.data.msg)
                this.GetDataList()
            }
            else{
                ElMessage.error(res.data.msg)
            }
            })
        },
        //批量删除
        btnBatchDeleteInfo(){
            if(this.info.ids.length<1){
                ElMessage.error('请选择要删除的选项')
                return false
            }

            this.$api.oa.DeleteContract(this.info.ids).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg)
                    this.GetDataList()
                }
                else{
                    ElMessage.error(res.data.msg)
                }
            })
        },

        //重置按钮
        btnResetSearch(){
            this.info.search.tradeType=0;
            this.info.search.tradeNumber='';
            this.info.search.title='';
            this.info.search.state=0;
            this.info.page = 1;
            this.GetDataList()
        },    


    },
    mounted(){
        this.GetDataList();   //加载数据
    }
}
</script>

<style>

</style>