<template>
  <div>
    <!-- 财务差额项列表 -->
    <el-row>
        <el-col :span="24">
            <el-form :inline="true">

            <el-form-item label="财务单号">
                <el-input v-model="info.search.billNumber" class="search-150"></el-input>
            </el-form-item>

            <el-form-item label="部门">
                <el-tree-select v-model="info.search.selectDepartmentList" :data="departmentList" multiple clearable :default-expand-all="true" class="search-200" collapse-tags collapse-tags-tooltip>
                </el-tree-select>
            </el-form-item>

            <el-form-item label="交易者">
                <el-select v-model="info.search.tradeNumber" filterable clearable class="search-150">
                    <el-option v-for="trade in allTradeList" :key="trade.tradeNumber" :label="trade.tradeName+ '(' + trade.tradeTypeName+')'" :value="trade.tradeNumber" />
                </el-select>
            </el-form-item>

            <el-form-item label="创建日期">
                <el-date-picker
                    v-model="info.search.datePoint"
                    type="daterange"
                    range-separator="To"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    style="width:250px"
                />
            </el-form-item>

            <el-form-item>
                <el-button class="iconfont icon-search" type="primary" plain @click="GetDataList">搜索</el-button>
                <el-button class="iconfont icon-exchangerate" plain @click="btnResetSearch">重置</el-button>
            </el-form-item>

            </el-form>
        </el-col>
    </el-row>

    <!-- 主内容区 -->
    <div>
        <!-- 功能栏 -->
        <el-row :gutter="10" class="operation">
        <el-col :span="15">
            <el-button class="iconfont icon-exchangerate" plain @click="btnRefresh">刷新</el-button>
            <!-- <el-button class="iconfont icon-ashbin" type="danger" plain>删除</el-button> -->
        </el-col>

        <el-col :span="9" class="operation_hidden">
            <el-button-group>
                
                <el-tooltip
                class="box-item"
                effect="dark"
                content="流程图"
                placement="top">
                <el-button class="iconfont icon-icon__liuchengtu" @click="btnNoFlow"></el-button>
                </el-tooltip>

                <el-tooltip
                class="box-item"
                effect="dark"
                content="打印"
                placement="top">
                <el-button class="iconfont icon-dayin" @click="btnNoPrint"></el-button>
                </el-tooltip>

                <el-tooltip
                class="box-item"
                effect="dark"
                content="导出数据"
                placement="top">
                <el-button class="iconfont icon-download" @click="btnOperExport"></el-button>
                </el-tooltip>
            </el-button-group>
        </el-col>
        </el-row>

        <!-- 列表 -->
        <el-table :data="dataList" border stripe show-summary style="width: 100%;" :height="tableHeight" >
            <el-table-column prop="id" type="selection" width="55" />  
            <el-table-column label="财务单号" width="170">
                <template v-slot="scope">
                    <span>{{scope.row.billNumber}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="departmentName" label="部门名称" width="150">
                <template v-slot="scope">
                    <el-tooltip
                        class="box-item"
                        effect="dark"
                        :content="scope.row.departmentName"
                        placement="top"
                    >
                    <div class="cellOverFlow">{{scope.row.departmentName}}</div>
                  </el-tooltip>
                </template>
            </el-table-column>

            <el-table-column prop="financeTypeName" label="财务类型" min-width="100">
            </el-table-column>
            
            <el-table-column label="交易者类型" min-width="95">
                <template v-slot="scope">
                    <el-tag v-if="scope.row.tradeType == 1" type="danger">供应商</el-tag>
                    <el-tag v-if="scope.row.tradeType == 2" type="success">客户</el-tag>
                    <el-tag v-if="scope.row.tradeType == 3" type="info">员工</el-tag>
                    <el-tag v-if="scope.row.tradeType == 4" type="info">散客</el-tag>
                    <el-tag v-if="scope.row.tradeType == 5" type="info">会员</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="交易者" property="tradeName" show-overflow-tooltip min-width="120">
            </el-table-column>
 
            <el-table-column prop="totalPrice" label="差额金额" width="110"></el-table-column>
            <el-table-column prop="content" label="差额说明" min-width="110" show-overflow-tooltip />
            <el-table-column label="凭证">
                <template v-slot="scope">
                    <!-- 无 -->
                    <span v-if="scope.row.fileType==0" >无</span>
                    <!-- 图片 -->
                    <el-image v-if="scope.row.fileType==1"
                        style="width: 70px; height: 70px;border:1px solid #ccc;"
                        hide-on-click-modal
                        :src="scope.row.fileUrl"
                        :preview-src-list="[scope.row.fileUrl]"
                        fit="cover"
                        />
                    <!-- 文件 -->
                    <el-link v-if="scope.row.fileType==2" :href="scope.row.fileUrl" target="_blank">查看文件</el-link>
                </template>
            </el-table-column>
            <el-table-column label="创建日期" width="110">
                <template v-slot="scope">
                    <el-tooltip
                        class="box-item"
                        effect="dark"
                        :content="scope.row.createTime"
                        placement="top"
                    >
                    <div class="cellOverFlow">{{DateFormat(scope.row.createTime)}}</div>
                  </el-tooltip>
                </template>
            </el-table-column>

            <el-table-column label="用款时间" width="110">
                <template v-slot="scope">
                    <el-tooltip
                        class="box-item"
                        effect="dark"
                        :content="scope.row.payTime"
                        placement="top"
                    >
                    <div class="cellOverFlow">{{DateFormat(scope.row.payTime)}}</div>
                  </el-tooltip>
                </template>
            </el-table-column>

            <el-table-column label="操作" width="100" fixed="right">
                <template v-slot="scope">  
                    <el-button size="small" type="primary" @click="btnEditInfo(scope.row.billNumber)">详情</el-button>    
                    
                </template>
            </el-table-column>
        
        </el-table>
        
    </div>

    <el-affix position="bottom">
        <el-row class="page_two">
            <el-col :span="24">
                <!-- 分页 -->
                <el-container class="page_right">
                    <el-pagination
                    v-model:currentPage="info.page"
                    v-model:page-size="info.size"
                    :page-sizes="[50, 200, 500, 1000]"
                    :background="true"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="info.total"
                    @current-change="btnPagechange"
                    @size-change="btnSizechange"
                    >
                    </el-pagination>
                </el-container>
            </el-col>
        </el-row>        
    </el-affix>

    <!-- 导出-弹出框 -->
    <el-dialog title="创建导出记录"
    v-model="BoxExportInfo.isVisible"
    :close-on-click-modal="false"
    width="350">
        <el-divider></el-divider>

        <el-form :inline="true"
        label-width="80px"
        label-position="right">
            <el-form-item label="导出标题">
                <template #label>
                    <span style="color:red;">*</span>
                    <span>导出标题</span>
                </template>
                <el-input v-model="BoxExportInfo.exportTitle" class="search-200" maxlength="50" ></el-input>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button type="primary" @click="btnExportInfo">导出</el-button>
            <el-button @click="BoxExportInfo.isVisible = false">关闭</el-button>
        </template>
    </el-dialog>

  </div>
</template>

<script>
import UserDptList from '@/utils/mixins/UserDptList.js'   //用户管理部门列表 
import AllTradeList from '@/utils/mixins/AllTradeList.js'  //交易者列表
import TableHeight from '@/utils/mixins/TableHeight.js'  //设置Table高度
import { ElMessage } from 'element-plus'
export default {
    mixins:[UserDptList,AllTradeList,TableHeight],
    data(){
        return{
            dataList:[],  //Table数据
            info:{
                page:1,
                size:50,
                total:0,  //总记录数
                id:0,  //新增、编辑 的参数
                ids:[],   //删除 的参数
                search:{
                    billNumber:'',   //财务单号
                    tradeNumber:'',   //交易者编号
                    selectDepartmentList:[], //选中部门
                    datePoint:'',  //时间段
                }
            },
            //导出-弹出框
            BoxExportInfo:{
                isVisible:false,
                exportTitle:'',  //导出记录标题
            },
        }
    },
    methods:{
        //加载数据列表
        GetDataList(){
            //记账时间
            if(this.info.search.datePoint == null){
                this.info.search.datePoint = ''
            }

            //获取、转换部门
            let newDepartmentList = []
            if(this.info.search.selectDepartmentList!=null){
                newDepartmentList = this.info.search.selectDepartmentList.map(item=>{
                    return item
                })
            }
            
            let param = {
                billNumber:this.info.search.billNumber,           //业务单号
                departmentList:newDepartmentList,           //部门列表                
                tradeNumber:this.info.search.tradeNumber,   //交易者
                startDate:this.DateFormat(this.info.search.datePoint[0]),
                endDate:this.DateFormat(this.info.search.datePoint[1]),
                page:this.info.page,
                size:this.info.size
            }
            this.$api.finance.PageFinanceBalanceList(param).then(res=>{
                if(res.data.status === 200){
                    this.dataList = res.data.data;  //获取数据
                    //分页
                    this.info.total = res.data.total
                    this.info.page = res.data.pageNumber
                }else{
                    ElMessage.error(res.data.msg);
                }
            })
        },

        //分页-页数变化时
        btnPagechange(page){
            this.info.page = page
            this.GetDataList() //加载Table数据
        },
        //分页-单页记录数变化时
        btnSizechange(size){
            this.info.size = size;
            this.info.page = 1;
            this.GetDataList() //加载Table数据
        },
        
        //重置按钮
        btnResetSearch(){
            this.info.search.billNumber=''; 
            this.info.search.tradeNumber='';
            this.info.search.selectDepartmentList = [];           
            this.info.search.datePoint = '';
            this.info.ids = [];  //编号数组
            this.info.page = 1;
            this.GetDataList()
        },

        //导出弹出框
        btnOperExport(){
            this.BoxExportInfo.isVisible=true;
        },
        //导出数据
        btnExportInfo(){
            //记账时间
            if(this.info.search.datePoint == null){
                this.info.search.datePoint = ''
            }

            //获取、转换部门
            let newDepartmentList = []
            if(this.info.search.selectDepartmentList!=null){
                newDepartmentList = this.info.search.selectDepartmentList.map(item=>{
                    return item
                })
            }
            
            let param = {
                billNumber:this.info.search.billNumber,           //业务单号
                departmentList:newDepartmentList,           //部门列表                
                tradeNumber:this.info.search.tradeNumber,   //交易者
                startDate:this.DateFormat(this.info.search.datePoint[0]),
                endDate:this.DateFormat(this.info.search.datePoint[1]),
                exportTitle:this.BoxExportInfo.exportTitle,   //重要：导出标题
                page:this.info.page,
                size:this.info.size
            }

            this.$api.finance.ExportFinanceBalance(param).then(res=>{
                if(res.data.status === 200){
                    ElMessage.success(res.data.msg);
                    this.BoxExportInfo.isVisible=false;  //关闭导出记录-弹出框
                }
                else
                {
                    ElMessage.error(res.data.msg)
                }
            })
        },

    },
    mounted(){
        this.GetDataList();
    },
}
</script>

<style>

</style>